import * as moment from 'moment'
import * as _ from 'lodash'
import { getQueryStringValue, setQueryStringValue } from './querystring.service'
const PERIOD = {
  day: 'day',
  week: 'week',
  month: 'month',
  custom: 'custom'
}

const defaultFormerPeriod = () => {
  return {duration: undefined}
}

const today = () => {
  const startdate = moment()
    .utc()
    .startOf(PERIOD.day)
    .toISOString()
  const enddate = moment(startdate)
    .utc()
    .add(1, PERIOD.day)
    .toISOString()

  return { duration: PERIOD.day, startdate, enddate }
}

const periodFromQueries = () => {
  const startdate = getQueryStringValue("startdate")
  const enddate = getQueryStringValue("enddate")
  const duration = getQueryStringValue("duration")
  if (startdate && enddate && duration){
    return { duration: duration, startdate, enddate }
  } else {
    return undefined
  }
}

const periodZoomFromQueries = () => {
  const startzoomdate = getQueryStringValue("startzoomdate")
  const endzoomdate = getQueryStringValue("endzoomdate")
  if (startzoomdate && endzoomdate){
    return {startdate : startzoomdate, enddate: endzoomdate }
  } else {
    return undefined
  }
}

const updatePeriodQueries = (period,periodZoom) => {
  setQueryStringValue('startdate', period.startdate)
  setQueryStringValue('enddate', period.enddate)
  setQueryStringValue('duration', period.duration)
  setQueryStringValue('startzoomdate', periodZoom.startdate)
  setQueryStringValue('endzoomdate', periodZoom.enddate)
}

const calcTrendingDates = () => {
  const startdate = moment(today())
    .utc()
    .startOf(PERIOD.day)
    .subtract(365, PERIOD.day)
    .toDate()
  const enddate = moment(today())
    .utc()
    .startOf(PERIOD.day)
    .add(365, PERIOD.day)
    .toDate()

  return {
    duration: PERIOD.custom,
    startdate,
    enddate
  }
}

const calcEnd = ({ duration, startdate, enddate }) => {
  let p = {
    duration,
    startdate,
    enddate
  }
  if (duration === PERIOD.day) {
    p = calcEndDay(startdate)
  }
  if (duration === PERIOD.week) {
    p = calcEndWeek(startdate)
  }
  if (duration === PERIOD.month) {
    p = calcEndMonth(startdate)
  }
  if (_.isInteger(duration)) {
    p = calcEndFromSeconds(duration, startdate)
  }
  return p
}

const calcEndDay = startDate => {
  const startdate = moment(startDate)
    .utc()
    .startOf(PERIOD.day)
    .toDate()
  const enddate = moment(startDate)
    .utc()
    .startOf(PERIOD.day)
    .add(1, PERIOD.day)
    .toDate()

  return {
    duration: PERIOD.day,
    startdate,
    enddate
  }
}

const calcEndWeek = startDate => {
  return {
    duration: PERIOD.week,
    startdate: moment(startDate)
      .utc()
      .startOf(PERIOD.day)
      .toDate(),
    enddate: moment(startDate)
      .utc()
      .startOf(PERIOD.day)
      .add(1, PERIOD.week)
      .toDate()
  }
}
const calcEndMonth = startDate => {
  return {
    duration: PERIOD.month,
    startdate: moment(startDate)
      .utc()
      .startOf(PERIOD.day)
      .toDate(),
    enddate: moment(startDate)
      .utc()
      .startOf(PERIOD.day)
      .add(1, PERIOD.month)
      .toDate()
  }
}

const calcEndFromSeconds = (duration, startDate) => {
  return {
    duration,
    startdate: moment(startDate)
      .utc()
      .startOf(PERIOD.day)
      .toDate(),
    enddate: moment(startDate)
      .utc()
      .startOf(PERIOD.day)
      .add(duration, 'seconds')
      .toDate()
  }
}

const toTimestamp = ({ duration, startdate, enddate }) => {
  return {
    duration,
    startdate: moment(startdate)
      .utc()
      .unix(),
    enddate: moment(enddate)
      .utc()
      .unix()
  }
}

const toString = ({ startdate, enddate }) => {
  const start = moment(startdate)
    .utc()
    .format('DD.MM.YYYY')
  const end = moment(enddate)
    .utc()
    .format('DD.MM.YYYY')

  return start + ' - ' + end
}

const toISOString = ({ duration, startdate, enddate }) => {
  return {
    duration,
    startdate: moment(startdate)
      .utc()
      .toISOString(),
    enddate: moment(enddate)
      .utc()
      .toISOString()
  }
}

const toDate = ({ duration, startdate, enddate }) => {
  return {
    duration,
    startdate: moment(startdate)
      .utc()
      .toDate(),
    enddate: moment(enddate)
      .utc()
      .toDate()
  }
}

const previousPeriodDate = ({ duration, startdate, enddate }) => {
  const diff = moment(enddate)
    .utc()
    .diff(moment(startdate).utc(), 'seconds')
  const _startDate = moment(startdate).subtract(diff, 'seconds')
  const _endDate = moment(startdate).utc()

  return {
    duration: diff,
    startdate: moment(_startDate).toDate(),
    enddate: _endDate.toDate()
  }
}

const testPeriodZoom = (periodZoom) => {
  return !(stringDateTime(periodZoom.startdate).substring(11)==='00:00:00.000Z'&&stringDateTime(periodZoom.enddate).substring(11)==='00:00:00.000Z')
}

const stringDateTime = date => {
  return moment.isDate(date)
    ? moment(date)
      .utc()
      .format('DD-MM-YYYY HH:mm:ss')
    : date
}
export {
  PERIOD,
  today,
  periodFromQueries,
  periodZoomFromQueries,
  updatePeriodQueries,
  toTimestamp,
  toString,
  toISOString,
  toDate,
  calcEnd,
  previousPeriodDate,
  testPeriodZoom,
  stringDateTime,
  calcTrendingDates,
  defaultFormerPeriod
}
