import { ClickAwayListener } from '@material-ui/core'
import React, { useState } from 'react'
import * as _ from 'lodash'
import './form.scss'

const BkFieldSet = ({
  title,
  className,
  helper,
  children,
  onFocus,
  onFocusOut
}) => {
  const [focus, setFocus] = useState(false)
  let classes = focus ? 'fieldSet fieldSet--focus' : 'fieldSet'
  if (className !== undefined) classes += ' ' + className
  const focusOutHandler = () => {
    if (focus) {
      setFocus(false)
      if (!_.isUndefined(onFocusOut)) {
        onFocusOut()
      }
    }
  }
  const focusHandler = () => {
    setFocus(true)
    if (!_.isUndefined(onFocus)) {
      onFocus()
    }
  }

  return (
    <ClickAwayListener onClickAway={focusOutHandler}>
      <div className='fieldSetContainer'>
        <fieldset className={classes} onClick={focusHandler}>
          {title && <legend>{title}</legend>}
          <div className='fieldSet__field'>{children}</div>
        </fieldset>
        {helper && <span className='fieldSet__helper'>{helper}</span>}
      </div>
    </ClickAwayListener>
  )
}

export default BkFieldSet
