import { Grid } from '@material-ui/core'
import React from 'react'
import { useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import { DragTypes } from './CustomDashboardEditor'
import { moveBlock } from './store/customDashboardSlice'

const WidgetDropZone = ({ children, xs, newPosition }) => {
  const dispatch = useDispatch()

  const [{ isOver }, drop] = useDrop({
    accept: DragTypes.WIDGET,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    })
  })

  const [, dropBefore] = useDrop({
    accept: DragTypes.WIDGET,
    drop: (block) => {
      dispatch(moveBlock({ newPosition: newPosition, block: block.config }))
      setTimeout(()=>window.dispatchEvent(new Event('resize')), 50)
    }
  })

  const [, dropAfter] = useDrop({
    accept: DragTypes.WIDGET,
    drop: (block) => {
      dispatch(moveBlock({ newPosition: newPosition+1, block: block.config }))
      setTimeout(()=>window.dispatchEvent(new Event('resize')), 50)
    }
  })

  const gridClassName = 'dropContainer'.concat((isOver ? ' dropOver' : ''))

  return (
    <Grid item ref={drop} xs={xs} className={gridClassName}>
      <div ref={dropBefore} className='dropZone before' />
      <div className={"dropWidget"}>
        {children}
      </div>
      <div ref={dropAfter} className='dropZone after' />
    </Grid>
  )
}

export default WidgetDropZone
