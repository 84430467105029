import { getCounts } from './requestor.service'
import * as _ from 'lodash'

const getRanking = async (params, filters) => {
  if (params.title) { delete params.title }
  if (params.size) { delete params.size }
  if (params.type) { delete params.type }
  if (params.action) { delete params.action }
  // if (params.unit) { delete params.unit }
  // if (params.measure) { delete params.measure }

  const response = await getCounts(params, filters)
  const data = response.data
  if (_.isEmpty(data)) {
    return null
  }
  const total = _.get(data, 'count')
  var measures = {}

  // hide Percent Column when exportCSV on rankings.
  if (params && params.showPercentColumn === false) {
    params.resultName ? (
      // If the ranking had column (completion, avg, duration...)
      measures = (!_.isUndefined(data.measures)) ? data.measures.map((m, i) => ({
        [params.dimension]: m[params.dimension] || 'Unknown',
        [params.resultName]: m.measure,
      })) : []
    ) : (
      // If the ranking had column (Total Sessions) with no resultName
      measures = (!_.isUndefined(data.measures)) ? data.measures.map((m, i) => ({
        [params.dimension]: m[params.dimension] || 'Unknown',
        value: m.measure,
      })) : []
    )
  }
  else {
    params.resultName ? (
      // If the ranking had column (completion, avg, duration...)
      measures = (!_.isUndefined(data.measures)) ? data.measures.map((m, i) => ({
        [params.dimension]: m[params.dimension] || 'Unknown',
        [params.resultName]: m.measure,
        percent: _.round((m.measure * 100) / total, 2)
      })) : []
    ) : (
      // If the ranking had column (Total Sessions) with no resultName
      measures = (!_.isUndefined(data.measures)) ? data.measures.map((m, i) => ({
        [params.dimension]: m[params.dimension] || 'Unknown',
        value: m.measure,
        percent: _.round((m.measure * 100) / total, 2)
      })) : []
    )
  }

  const ranking = {
    ...data,
    total,
    measures
  }
  return ranking
}

export { getRanking }