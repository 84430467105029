import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPreferences } from '../../../services/user.service'
import { selectSettings, updateSettings } from '../store/SettingsSlice'
import { updateTimeZone } from '../../filters/store/FiltersSlice'
import moment from 'moment-timezone'

//Initialize the current user settings
const useInitSettings= isLogged => {
    const dispatch = useDispatch()

    const settings = useSelector(selectSettings)

    useEffect(() => {
      const getSettings = async () => {
        getPreferences().then(response => {
          //Store the received settings for the current user
          dispatch( updateSettings(response.data) )

          //Update the filter store too
          const timeZoneInEpoch = moment().tz(response.data.timeZone).utcOffset() * -60 //convert to seconds
          dispatch( updateTimeZone( timeZoneInEpoch));
        })
      }
      if (isLogged) {
        getSettings()
      }
    }, [isLogged]) // eslint-disable-line react-hooks/exhaustive-deps

    return [settings]
  }

  export default useInitSettings