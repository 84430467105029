import { useDispatch } from 'react-redux'
import { initMenu } from '../store/dashboardSlice'
import { getPages } from '../../../services/pages.service'

const useReloadMenu = () => {
  const dispatch = useDispatch()
  const reloadMenu = () => getPages().then(response => {
    const currentDashboard = [
      response.data[0].routerId,
      response.data[0].children[0].routerId
    ]

    initMenu(dispatch, { currentDashboard, menu: response.data })
  })

  return reloadMenu
}



export default useReloadMenu
