import React from 'react'
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next'
import useLegacy from '../../i18n/legacyEffect'
import Errors from '../../components/Errors'
import { selectAppliedFilters } from '../filters/store/FiltersSlice'
import {  useSelector } from 'react-redux'

const SessionDescriptionItem = ({ sessionDescriptionItem, config, itemsPerRow}) => {
  const { t } = useTranslation()
  const { tLegacy } = useLegacy()
  const appliedFilters = useSelector(selectAppliedFilters)
  const displayValue = sessionDescriptionItem.value === '' ? (t('miscellaneous:unknown')):tLegacy(sessionDescriptionItem.measure,sessionDescriptionItem.value)
  return (
    <div className={'sessionDescriptionItem__' + itemsPerRow} data-border >
      <div className='sessionDescriptionItem__title'>{tLegacy('fields',sessionDescriptionItem.measure)}</div>
      {sessionDescriptionItem && sessionDescriptionItem.measure ? (
        <div className='sessionDescriptionItem__value'  data-tip={displayValue} > {displayValue} <ReactTooltip /> </div>
        ) :  (
          <Errors dataError={'error_noData_with_filters'} config={config} item={sessionDescriptionItem} appliedFilters={appliedFilters}></Errors>
        )}

    </div>
  )
}

export default SessionDescriptionItem
