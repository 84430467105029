import React from 'react'
import ReactTooltip from 'react-tooltip';
import Errors from '../../components/Errors'
import { selectAppliedFilters } from '../filters/store/FiltersSlice'
import {  useSelector } from 'react-redux'

const SummaryItem = ({ summary, config }) => {
  const appliedFilters = useSelector(selectAppliedFilters)

  return (
    <div className='summaryItem' data-tip={summary.tooltip} data-border >
      <div className='summaryItem__title'>{summary.title}</div>
      {/* TODO #1938 method temporary to get Error message in summary (To change to be in harmony)  */}
      {/* Condition to check if Only one filter is applicable in Dashboard */}
          {summary && summary.value && summary.value.includes("NaN") ? (
            // <div className='summaryItem__value'>No data available</div>
            <Errors dataError={'error_noData_with_filters'} config={config} item={summary} appliedFilters={appliedFilters}></Errors>
            ) : (summary.limit_to_one_filter_type === true) && (appliedFilters && (appliedFilters.length > 1 || appliedFilters[0] && appliedFilters[0].values.length > 1 )) ? (
              <Errors config={config} item={summary} appliedFilters={appliedFilters}></Errors>
              ) : (
              <div className='summaryItem__value'>{summary.value}</div>
            )}
      <ReactTooltip />
    </div>
  )
}

export default SummaryItem
