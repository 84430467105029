import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {   getFilterForRequest,
  selectPeriodZoom, 
  selectPeriod,
  selectAppliedFilters,
  selectTimeZone,
  selectDefaultFilters } from './store/FiltersSlice'
import { getFilterCounts } from '../../services/requestor.service'
import * as _ from 'lodash'

const TotalSessions = () => {
  const [pourcentSessions, setPourcent] = useState(100)
  // const [measuresSelected, setMeasuresSelected] = useState(0)
  // const [measuresTotal, setMeasuresTotal] = useState(0)
  const period = useSelector(selectPeriod)
  const periodZoom = useSelector(selectPeriodZoom)
  const appliedFilters = useSelector(selectAppliedFilters)
  const timezone = useSelector(selectTimeZone)
  const defaultConfig = useSelector(selectDefaultFilters)
  const filtersForRequest = getFilterForRequest(periodZoom, period, appliedFilters, timezone)
  
  useEffect(() => {
    const data = {...filtersForRequest}
    data.table = defaultConfig.table
    data.db = defaultConfig.db
    getFilterCounts(data).then(res => {
      if (
        res.data &&
        res.data.measures &&
        _.isNumber(res.data.measures.selected) &&
        _.isNumber(res.data.measures.total) &&
        res.data.measures.total > 0
      ) {
        setPourcent(_.round(
          res.data.measures.selected / res.data.measures.total * 100
        ,1))
        //setMeasuresSelected(res.data.measures.selected)
        //setMeasuresTotal(res.data.measures.total)
      }
    })
  }, [period, periodZoom, appliedFilters, timezone])
  // return <span className='totalSessions'>{measuresSelected === measuresTotal ?
  //    pourcentSessions + '% of total' :
  //    measuresSelected + ' out of ' + measuresTotal + ' sessions - ' +pourcentSessions + '% of total'}</span>

  return <span className='totalSessions'>{pourcentSessions}% of total</span>
}

export default TotalSessions