import React from 'react'
import BkButton from './Button'
import BkIcon from './icon'
import FullScreenPortal from './portals/FullScreenPortal'
import './styles/modal.scss'

const Modal = ({ title, children, onClose }) => {
  return (
    <FullScreenPortal>
      <div className='bkDark'>
        <div className='modalContainer'>
          <div className='modal'>
            <header className='modal__header'>
              <h1 className='modal__title'>{title}</h1>
              <div className='modal__actions'>
                <BkButton
                  onClick={onClose}
                  className='bkButton--invisible modal__closeButton'
                >
                  <BkIcon path='close' />
                </BkButton>
              </div>
            </header>
            <div className='modal__content'>{children}</div>
          </div>
        </div>
      </div>
    </FullScreenPortal>
  )
}

export default Modal
