import * as _ from 'lodash'

const BKAPI_URL = _.isUndefined(process.env.REACT_APP_BKAPI_URL)
  ? window.location.protocol + '//' + window.location.host + '/api'
  : process.env.REACT_APP_BKAPI_URL

const BKA_PUBLIC_URL = window.location.protocol + '//' + window.location.host
const AUTH_MODE =  _.isUndefined(process.env.REACT_APP_AUTH_MODE) 
  ? "Legacy"
  : process.env.REACT_APP_AUTH_MODE
const AUTH_MODE_LEGACY = "Legacy" //do not use it in code above cause we should change default value from outside
console.log(AUTH_MODE)
export { 
  BKAPI_URL,
  BKA_PUBLIC_URL,
  AUTH_MODE,
  AUTH_MODE_LEGACY
 }
