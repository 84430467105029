import React, { useState } from 'react'
import { getFilterList } from '../../services/requestor.service'
import { useSelector } from 'react-redux'
import { selectPeriod, getPeriodTimeStamp } from './store/FiltersSlice'
import BkAutocomplete from '../../components/forms/Autocomplete'
import * as _ from 'lodash'
import CurrentFiltersCount from './CurrentFiltersCount'

const SearchFilter = ({ title, id, onChange, selected, legacy, exclude }) => {
  const [options, setOptions] = useState([])
  const [helper, setHelper] = useState(null)
  const period = useSelector(selectPeriod)
  const duration = getPeriodTimeStamp(period)

  const onGetOptionsHandler = value => {
    setHelper('Loading data...')
    getFilterList({
      filterid: id,
      startdate: duration.startdate,
      enddate: duration.enddate,
      value
    })
      .then(res => {
        setOptions(_.compact(res.data))
        setHelper(_.isEmpty(_.compact(res.data)) ? 'No data to display' : null)
      })
      .catch(e => setHelper('No data to display'))
  }

  const onChangeHandler = value => {
    const inOptions = options.find(o => o === value)
    const v = exclude? 
    { operator: !_.isUndefined(inOptions) ? '!=' : '!~', value }
    :
    { operator: !_.isUndefined(inOptions) ? '==' : '~', value }
    const values = !_.isUndefined(selected) ? [...selected, v] : [v]
    onChange(values)
  }

  const fieldLabel = (
    <>
      {title} <CurrentFiltersCount filters={selected} />
    </>
  )

  return (
    <BkAutocomplete
      title={fieldLabel}
      legacy={legacy}
      placeholder='Search...'
      helper={helper}
      onChange={onGetOptionsHandler}
      options={options}
      onApply={onChangeHandler}
      onRemove={onChange}
      selected={selected}
    />
  )
}

export default SearchFilter
