import React, { useEffect, useState } from 'react'
import { getFilterList } from '../../services/requestor.service'
import { useSelector } from 'react-redux'
import { selectPeriod, getPeriodTimeStamp } from './store/FiltersSlice'
import BkMultiSelect from '../../components/forms/MultiSelect'
import CurrentFiltersCount from './CurrentFiltersCount'
import * as _ from 'lodash'
import { filterType } from '../../constants'
import { filtersList } from './filtersList'

const SelectFilter = ({ title, id, onChange, selected, legacy,type, exclude }) => {
  const [options, setOptions] = useState([])
  const [helper, setHelper] = useState(null)
  const [_selected, setSelected] = useState([])
  let emptyValue = [""]

  useEffect(() => {
    if (!_.isUndefined(selected)) setSelected(selected.map(v => v.value))
    if (_.isUndefined(selected)) {
      setSelected([])
    }
  }, [selected])
  const period = useSelector(selectPeriod)
  const duration = getPeriodTimeStamp(period)

  const changeHandler = (values) => {
    
      if (type === filterType.DROPDOWNFIXCONTAINS) {
        if(!exclude) {
          onChange(values.map(v => ({ operator: '~', value: v })))}
        else{
          onChange(values.map(v => ({ operator: '!~', value: v })))
        }
      } else if(!exclude) {
        onChange(values.map(v => ({ operator: '==', value: v })))
      }
      else
        onChange(values.map(v => ({ operator: '!=', value: v })))
    
    setSelected(values)
  }

  const focusHandler = () => {
    if (_.isEmpty(options)) {
      setHelper('Loading data...')
      if (type === filterType.DROPDOWNFIX || type === filterType.DROPDOWNFIXCONTAINS) {
        setOptions(_.compact(filtersList[id]))
        setHelper(null)
      } else {
        getFilterList({
          filterid: id,
          startdate: duration.startdate,
          enddate: duration.enddate
        })
          .then(res => {
            if (res.data[0] === "")
              return (
                setOptions(_.concat(emptyValue, _.compact(res.data)))
              )
            setOptions(_.compact(res.data))
            setHelper(
              _.isEmpty(_.compact(res.data)) ? 'No data to display' : null
            )
          })
          .catch(e => setHelper('No data to display'))
      }
    }
  }

  const fieldLabel = (
    <>
      {title} <CurrentFiltersCount filters={_selected} />
    </>
  )

  return (
    <BkMultiSelect
      title={fieldLabel}
      legacy={legacy}
      options={options}
      onFocus={focusHandler}
      onChange={changeHandler}
      selected={_selected}
      helper={helper}
    />
  )
}

export default SelectFilter
