import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Checkbox,
  CircularProgress
} from '@material-ui/core'
import BkAlert from '../../components/Alert'
import { getPreferences, postPreferences } from '../../services/user.service'
import { updateSettings } from '../settings/store/SettingsSlice'
import { TimeZone } from '../../services/bk-services/timezone'
import moment from 'moment-timezone'

import BkTextField from '../../components/forms/TextField'
import BkSelect from '../../components/forms/Select'
import * as _ from 'lodash'
import BkButton from '../../components/Button'
import '../../components/forms/form.scss'
import { updateTimeZone } from '../filters/store/FiltersSlice'

const Settings = () => {
  const dispatch = useDispatch()

  const [state, setState] = React.useState({
    email: 0,
    sms: 0,
    timeZone: '',
    phoneNumber: '',
    messages: [],
    severity: 'info',
    processing: false
  })

  const catchError = (error) => {
    setState({
      ...state,
      processing: false,
      messages: error.response ? error.response.data.errors : []
    })
  }

  useEffect(() => {
    setState({ ...state, processing: true })
    getPreferences().then((res) => {
      setState({ ...state, processing: false, ...res.data })
    }).catch(catchError)
  }, [])

  const submit = () => {
    setState({ ...state, processing: true })
    //Convert timezone offset to secconds for filters
    const timeZoneInEpoch = moment().tz(state.timeZone).utcOffset() * -60 //convert to seconds

    //Save settings in db
    postPreferences(state).then(res => {
      const messages = []
      const message = _.get(res, ['data', 'message'])
      if (message) {
        messages.push({ message })
      }
      setState({ ...state, messages, processing: false })
      console.log('submit setting', res)
    }).catch(catchError)

    //update store
    dispatch(updateSettings(
      {
        timeZone: state.timeZone,
        email: state.email,
        sms: state.sms
      }
    ));
    //Update the filter store with a timezone offset in seconds
    dispatch( updateTimeZone( timeZoneInEpoch));

  }

  const processing = <CircularProgress />

  const displayMessages = (
    <BkAlert severity={state.severity} className='input'>
      {state &&
      state.messages &&
      state.messages.length > 0 &&
      state?.messages?.map((err, i) => (
        <div key={i}>{err.message}</div>
      ))}
    </BkAlert>
  )

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
    console.log('handleChange', event, state)
  }

  const timeZoneChange = (timeZone) => setState({ ...state, timeZone })

  const phoneNumberChange = (event) => setState({ ...state, phoneNumber: event.target.value })

  const form = (
    <div className='formList'>
      {state.messages.length > 0 ? displayMessages : null}

      <div className='inlineFieldSet'>
        <label className='label' htmlFor='select-timezone'>Timezone</label>
        <div className='inputContainer'>
          <BkSelect
            labelId='select-timezone-label'
            id='select-timezone'
            name='timeZone'
            selected={state.timeZone}
            onChange={timeZoneChange}
            options={TimeZone}
          />
        </div>
      </div>

      {state.sms === 1 && (
        <div className='inlineFieldSet'>
          <label className='label' htmlFor='phone'>Phone Number</label>
          <div className='inputContainer'>
            <BkTextField
              id='phone'
              onChange={phoneNumberChange}
              value={state.phoneNumber}
            />
          </div>
        </div>
      )}
    </div>
  )

  return (
    <div className='widget widget--minHeight'>
      <div className='widget__header'>
        <h1 className='widget__title'>User settings</h1>
      </div>
      <div className='widget__content widget__content--form'>{state.processing ? processing : form}</div>
      <div className='widget__footer'>
        <BkButton onClick={submit}>Apply</BkButton>
        <BkButton className='bkButton--alternate'>Cancel</BkButton>
      </div>
    </div>
  )
}

export default Settings
