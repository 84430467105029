
const { default: bkApi } = require('./bk-api')

const getSections = () => {
  return bkApi.get('/v1/dashboards/sections')
}

const getTemplate = (templateId) => {
  return bkApi.get('/v1/dashboards/templates/' + templateId)
}

const save = template => {
  return bkApi.post('/v1/dashboards/templates/', template)
}

const remove = template => {
  return bkApi.delete('/v1/dashboards/templates/' + template.routerId)
}

const getConfig = (widgetConfig) => {
  return bkApi.post('v1/dashboards/widget/config', widgetConfig)
}

export { getSections, save, remove, getTemplate, getConfig }
