import { createSlice } from '@reduxjs/toolkit'

const burgerSlice = createSlice({
    name: "burger",
    initialState: {
        menu: []
    },
    reducers: {
        updateBurgerMenu: (state, action) => ({
                ...state,
                menu: action.payload,
              })
        }
    }
)

export const { updateBurgerMenu } = burgerSlice.actions;
export const selectBurgerMenu = state => state.burger.menu
export default burgerSlice.reducer
