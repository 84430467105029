import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import './styles/burger-menu.scss'
import BkMenu from './Menu'
import { useDispatch} from 'react-redux'
import { getPage } from '../services/pages.service';
import {updateBurgerMenu } from './store/burgerSlice'


const BurgerMenu = ({ children }) => {
  const [isCollapsed, collapse] = useState(false)
  const collapseHandler = () => collapse(!isCollapsed)
  const dispatch = useDispatch()

  useEffect(() => {
    const getBurgerMenu = async () => {
      const res = await getPage('menuBurger');
      dispatch(updateBurgerMenu(res.data[0].children));
      window.localStorage.setItem('burgerMenu', JSON.stringify(res.data[0].children));
    };
    getBurgerMenu();
  }, []);

  const menu = (
    <BkMenu
      open={isCollapsed}
      className='burgerMenu__menu'
      onCollapse={collapseHandler}
    >
      {children}
    </BkMenu>
  )

  return (
    <div className='burgerMenu'>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        className='burgerMenu__button'
        id='burgermenu'
        onClick={collapseHandler}
      />
      {menu}
    </div>
  )
}

export default BurgerMenu
