import React, { useState } from 'react'
import BkTextField from '../../components/forms/TextField'
import BkButton from '../../components/Button'
//import { changePassword } from '../../services/auth.service'
import { changeUserPassword } from '../../services/user.service'
import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import BkAlert from '../../components/Alert'

export const ChangePassword = ({ params, close, selectedItem }) => {
  const userId = selectedItem.id
  const initialStatus = {
    errors: [],
    processing: false
  }
  const [status, setStatus] = useState(initialStatus)
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const history = useHistory()
  const closeHandler = () => {
    if (close) {
      close()
    } else {
      history.push('/login')
    }
  }

  //--------------------------------------------------------------------
  const updateUserPassword = () => {
    setStatus({ errors: [], processing: true })
    //console.log("Selected user userId=", userId)

    if (newPassword && newPassword === confirmNewPassword) {
      changeUserPassword(userId, newPassword).then(() => {
        setStatus({ errors: [], processing: false })
        closeHandler()
      }).catch((error) => {
        setStatus({
          processing: false,
          errors: error.response ? error.response.data.errors : []
        })
      })
    } else {
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'Password and confirm password don\'t match' }]
      })
    }
  }

  //--------------------------------------------------------------------
  const displayErrors = (
    <BkAlert severity='error' className='input'>
      {status &&
      status.errors &&
      status.errors.length > 0 &&
      status?.errors?.map((err, i) => (
        <div key={i}>{'errors: ' + err.message}</div>
      ))}
    </BkAlert>
  )
  //--------------------------------------------------------------------
  const processing = <CircularProgress />
  //--------------------------------------------------------------------
  const form = (
    <form>
      {status.errors && status.errors.length && status.errors.length > 0 ? displayErrors : null}
      <BkTextField
        placeholder='New password'
        label='New password'
        value={newPassword}
        type='password'
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <BkTextField
        placeholder='New Password Confirm'
        label='Confirm new password'
        value={confirmNewPassword}
        type='password'
        onChange={(e) => setConfirmNewPassword(e.target.value)}
      />
      <div className='modal__footer'>
        <BkButton
          type='submit'
          variant='contained'
          color='primary'
          onClick={updateUserPassword}
        >Save
        </BkButton>
        <BkButton onClick={closeHandler} className='bkButton--alternate'>
          Cancel
        </BkButton>
      </div>
    </form>
  )
  //--------------------------------------------------------------------
  return (
    <div>{status.processing ? processing : form}</div>
  )
}
