import bkApi from './bk-api'

const encrypter = (params) => {
  
    return bkApi.get('/v1/encrypter', {
      params: {
        ...params
      },
    })
  }
  
  export { encrypter }
