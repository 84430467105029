import React from 'react'
import CurrentFilterItem from './CurrentFilterItem'
import CurrentFiltersCount from './CurrentFiltersCount'

const CurrentFilters = ({ filters, count, onRemove }) => {
  return (
    <>
      {!count && (
        <div
          className={filters.length && filters.length === 1 ? 'currentFiltersList currentFiltersList--all1'  
            : filters.length === 2 ? 'currentFiltersList currentFiltersList--all2'
           : filters.length === 3 ? 'currentFiltersList currentFiltersList--all3'
          : filters.length === 4 ? 'currentFiltersList currentFiltersList--all4' 
        : 'currentFiltersList currentFiltersList--all' }
        >
          {filters.map((filter, i) => (
            <CurrentFilterItem
              key={filter.name + '' + i}
              filter={filter}
              onRemove={onRemove}
            />
          ))}
        </div>
      )}
      {count && <CurrentFiltersCount filters={filters} />}
    </>
  )
}
 

export default CurrentFilters
