import { createSlice } from '@reduxjs/toolkit'
import * as _ from 'lodash'

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    currentDashboard: null,
    currentSubMenuType: null,
    currentMenuType: null,
    menu: null,
    displayFilters: false,
    filters: {
      current_filters: [],
      percentage_table: null,
      db: null
    }
  },
  reducers: {
    initMenu: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    changePage: (state, action) => {
      state = {
        ...state,
        currentDashboard: action.payload.currentDashboard,
        currentSubMenuType: action.payload.currentSubMenuType,
        currentMenuType: action.payload.currentMenuType
      }
      return state
    },
    toggleFilters: state => {
      return {
        ...state,
        displayFilters: !state.displayFilters
      }
    }
  }
})

export function initMenu (dispatch, payload) {
  dispatch(dashboardSlice.actions.initMenu(payload))
}

export const changePage = async (dispatch, currentDashboard, currentSubMenuType, currentMenuType) => {
  if (currentSubMenuType === undefined){  //if sub menu type not given try to extract it from currentdashboard
    currentSubMenuType = extractBoardType(currentDashboard)

  }
  if (currentMenuType === undefined) {
    currentMenuType = extractMenuType(currentDashboard)
    if (!currentMenuType){
      currentMenuType = currentSubMenuType
    }
  }
  dispatch(dashboardSlice.actions.changePage({ currentDashboard, currentSubMenuType, currentMenuType }))
}

//extract type of submenu if menu is not a customdashboard
//DO NOT USE if menu is a custom dashboard
export const extractBoardType = currentDashboard => {
  if (!(currentDashboard && currentDashboard[1])) {
    return null
  }
  const id = new RegExp('^[0-9]{2}', 'gm').exec(currentDashboard[1])
  if (id && id[0]) {
    return parseInt(id[0], 10)
  }
  return null
}

//extract type of menu if menu is not a customdashboard
//DO NOT USE if menu is a custom dashboard
export const extractMenuType = currentDashboard => {
  if (!(currentDashboard && currentDashboard[0])) {
    return null
  }
  const id = new RegExp('^[0-9]{2}', 'gm').exec(currentDashboard[0])
  if (id && id[0]) {
    return parseInt(id[0], 10)
  }
  return null
}

export const { toggleFilters } = dashboardSlice.actions

export const selectMenu = state => state.dashboards.menu
export const selectCurrentDashboard = state => state.dashboards.currentDashboard
export const selectSubMenuType = state => state.dashboards.currentSubMenuType
export const selectMenuType = state => state.dashboards.currentMenuType
export const selectRootMenu = state => state.dashboards.menu[0]
//function that can't be in selector cause return a different ref each time but is factorised there
export const getRootPage = menu => [menu.routerId, menu.children[0].routerId]
export const selectDisplayFilters = state => state.dashboards.displayFilters

//get current menu and submenu config from menu to extract title for fullscreen
export const getMenuAndSubMenuConfig = (currentDashboard, menu) => {
  return currentDashboard.reduce((menuEntry, routerId, i) => {
    const root =
      menu.find(m => m.routerId === routerId) ||
      _.get(menuEntry, [i - 1])
    if (root && i === 0) {
      menuEntry.push(root)
    }
    if (root && i > 0) {
      menuEntry.push(root.children.find(m => m.routerId === routerId))
    }
    return menuEntry
  }, [])
}

export const getDashboardTitleFromRouters = (allMenu, routerIdParent, routerIdChild) => {
  const menu = allMenu.find(m => m.routerId === routerIdParent)
  if (menu) {
    const subMenu = menu.children.find(c => c.routerId === routerIdChild)
    if (subMenu) {
      return (menu.title + "/" + subMenu.title)
    }
    else {
      return (menu.title + "/" + routerIdChild)
    }
  }
  else {
    return (routerIdParent + "/" + routerIdChild)
  }
}


export const getAllDashboard = (menu) => {
  return menu.reduce((menuEntry, m) => {
    const children =  m.children.reduce((entry, c) => {
      if (c.routerId !== "new" && c.routerId !== 'import') {

        entry.push({type:m.routerId + '/' + c.routerId, label:m.title + "/" + c.title})
      }
      return entry
    },[])

    return menuEntry.concat(children)
  }, [])
}

export default dashboardSlice.reducer
