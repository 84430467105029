
import { useMemo } from 'react'
import * as _ from 'lodash'
import { useTranslation } from 'react-i18next'

const useLegacy = () => {
  const { t } = useTranslation()

  const translateLegacy = useMemo(() => (dimension, prop) => {
    const value = t('legacy:' + dimension + ':' + prop)
    return value !== dimension + '.' + prop && _.isString(prop) && !prop.includes(':') ? value : prop
  }, [t])

  return { tLegacy: translateLegacy }
}

export default useLegacy
