import React, { useEffect, useState, Fragment } from 'react'
import BkButton from '../../components/Button'
import UserDataWidget from "./UserDataWidget";

import * as PageService from "../../services/pages.service";

import { CreateUser } from './CreateUser'
import Modal from '../../components/Modal'

//----------------------------------------------------------------------
//  Administration Users management:
//----------------------------------------------------------------------
const AdminUsers = ({mainMenu, subMenu}) => {
    const path = [mainMenu, subMenu];
    const [template, setTemplate] = useState(null);

    const [createUserModalToggle, setCreateUserModalToggle] = useState(false)

    //The widgets do not refresh automatically when the database is updated
    //We  add a variable that will trigger a refresh
    const [refresh, setRefresh] = useState(false)
    useEffect(()=>{
        if(refresh){
            setTemplate(null)
            getTemplate(path);
        }

    },[refresh])

    //----------------------------------------------------------------------
    const createUserModal = (
        <Modal title='Create user account' onClose={() => {
            setCreateUserModalToggle(false)
            }
        }>
        <CreateUser close={() => {
            setCreateUserModalToggle(false)
            }
        }

        handleNewUser = {(refr)=>{setRefresh(refr)}}
        />
        </Modal>
    )

    //Page loading using path (check next useEffect)
    const getTemplate = async (path) => {
        const { data } = await PageService.getPage(path[1]);
        setTemplate(data);
        console.log("[ADMIN] reloading=")
    };

    //-----------------------------------------
    //const dispatch = useDispatch();
    useEffect(() => {
        getTemplate(path);
    }, [path[1] ]);



    return  <Fragment>
                <header className="pageContainer__header">
                    <h2 >
                        <img
                            src={require("../../assets/icons/admin.svg").default}
                            alt="Bka100"
                            width="30"
                            height="15"
                        />
                    Add/Remove Accounts</h2>
                    <div  className='widget__actions'>
                    <BkButton className="addUserButton"  onClick={() => setCreateUserModalToggle(true) } variant="contained" color="primary" >
                        Add user
                    </BkButton>
                    </div>
                </header>

                <div className="tabAccountsWidget">
                    {template && template.usersContentBlocks.map((config, i) => (
                        <UserDataWidget key={i} config={config} />
                        ))}
                    {createUserModalToggle && createUserModal}
                </div>
            </Fragment>
}

export default AdminUsers