import React from 'react'
import BkAppBar from '../components/AppBar'
import FiltersContainer from '../features/filters/FiltersContainer'
import BurgerMenu from '../components/BurgerMenu'
import PageContainer from '../features/dashboards/PageContainer'
import UserMenu from '../features/authentication/UserMenu'
import MainNavigation from '../features/dashboards/MainNavigation'
import { Route, Switch } from 'react-router-dom'
import DatepickerMenu from '../features/datepicker/DatepickerMenu'
import BkMenuItem from '../components/MenuItem'
import CustomDashboardManager from '../features/custom-dashboards/CustomDashboardManager'
//User profil info
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../features/authentication/store/authSlice.js'
//Burger menu info
import { selectBurgerMenu } from '../components/store/burgerSlice';

const DashboardLayout = ({ section }) => {

  const user = useSelector(selectCurrentUser)
  const burgerMenu = useSelector(selectBurgerMenu)

  return (
    <>
      <BkAppBar>
        <BurgerMenu>
          <UserMenu className='bkMenu__item' />
          {user && burgerMenu &&
           burgerMenu.map((item) => (
            (item.pfl_id.includes(user.pfl_id) || item.pfl_id.includes(-1)) && item.enabled === true ?
          <BkMenuItem icon={item.icon} to={item.to} isLink={item.enabled}>{item.title}</BkMenuItem>
            : (item.pfl_id.includes(user.pfl_id) || item.pfl_id.includes(-1)) && item.enabled === false ?
          <BkMenuItem icon={item.icon} to={item.to} isLink={item.enabled} className='disabled'>{item.title}</BkMenuItem>
            : <div></div>
          ))}

          {/* Administration displayed for Administrator and Super admimnistrator */}
          {user && (user.pfl_id === 1 || user.pfl_id === 2) && <BkMenuItem icon='admin' to='/admin'>
            Admin Console
          </BkMenuItem> }
          </BurgerMenu>
        <div className='appLogo'>
          <img
            src={require('../assets/images/BkA100.png')}
            alt='Bka100'
            width='117'
            height='42'
          />
        </div>
        <MainNavigation />
        <DatepickerMenu />
      </BkAppBar>
      <div className='container container--fullPage'>
        <Switch>
          <Route path='/dashboards'>
            <FiltersContainer />
            <CustomDashboardManager />
          </Route>
          {section &&
            section.children.map(child => (
              <Route
                path={'/' + section.routerId + '/' + child.routerId}
                key={child.routerId}
              >
                <FiltersContainer config={child} key={section.routerId}/>
                <PageContainer
                  title={child.headerTitle}
                  path={[section.routerId, child.routerId]}
                />
              </Route>
            ))}
        </Switch>
      </div>
    </>
  )
}

export default DashboardLayout
