import bkApi from './bk-api'

export const listDiagnostics = () => {
  return bkApi.get('/v1/diagnostic')
}

export const createDiagnostic = (options) => {
  return bkApi.post('/v1/diagnostic', options)
}

export const deleteDiagnostic = (id) => {
  return bkApi.delete(`/v1/diagnostic/${id}`)
}

export const populateDiagnosticFiles = ({ id, files }) => {
  return Promise.all(
    files.map(e => bkApi.get(`/diagnostics/${id}/${e}`))
  )
}

export const listAlertSeverities = () => {
  return bkApi.get('/v1/diagnostic/alert-severity')
}

export const setAlertSeverity = (options) => {
  return bkApi.put('/v1/diagnostic/alert-severity', options)
}