
import React from 'react'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { Box, Tab, Tabs, AppBar } from "@material-ui/core";

import AdminSettings from './AdminSettings'
import AdminUsers from './AdminUsers'
import AdminGroups from './AdminGroups'
//User profil info
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../authentication/store/authSlice.js'
import { AUTH_MODE, AUTH_MODE_LEGACY } from '../../environment'


//----------------------------------------------------------------------
//  Administration console:
//        -Users management tab
//        -Groups management tab
//        -
//----------------------------------------------------------------------
const Admin = ({mainMenu, subMenu}) => {
  const history = useHistory()
  const user = useSelector(selectCurrentUser)
  //----------------------------------------------------------------------
  // Handling of tab change
  const handleChange = (event, newValue) => {
    history.push(newValue)
  }
  const { path } = useRouteMatch();
  const routeMatch = useRouteMatch([
    `${path}/accounts`,
    `${path}/groups`,
    `${path}/settings`,
    `${path}/diagnostics`
  ]);
  let defaultPath = `${path}/accounts`
  if (AUTH_MODE !== AUTH_MODE_LEGACY){
    defaultPath = `${path}/settings`
  }
  const currentTab = routeMatch?.path ? routeMatch?.path  : defaultPath

  return <div className='bk-tabs'>
    <AppBar position='static'>
      <Tabs value={currentTab} onChange={handleChange}>
        { AUTH_MODE === AUTH_MODE_LEGACY && <Tab value={`${path}/accounts`} to={`${path}/accounts`} label='Manage Accounts' />}
        {([1,2].includes(user.pfl_id )) && <Tab value={`${path}/groups`} to={`${path}/groups`} label='Manage Groups' />}
        {([1,2].includes(user.pfl_id ))  && <Tab value={`${path}/settings`}  to={`${path}/settings`} label='Settings' />}

      </Tabs>
    </AppBar>

    <Switch>
      {/* Tab 1 (only available with authentMode Legacy)*/}
      { AUTH_MODE === AUTH_MODE_LEGACY &&
      ([1,2].includes(user.pfl_id )) &&
      <Route path={`${path}/accounts`}>
        <Box>
          <AdminUsers mainMenu={mainMenu} subMenu={subMenu} />
        </Box>
      </Route>}

      {/* Tab 2 (only available with authentMode Legacy and restricted to Super administrator)*/}
      {([1,2].includes(user.pfl_id ))&&
        <Route path={`${path}/groups`}>
          <Box>
            <AdminGroups mainMenu={mainMenu} subMenu={subMenu} />
          </Box>
        </Route>}

      {/* Tab 3 (restricted to Super administrator)*/}
      {([1,2].includes(user.pfl_id ))&&
        <Route path={`${path}/settings`}>
          <Box>
            <AdminSettings mainMenu={mainMenu} subMenu={subMenu}/>
          </Box>
        </Route>}
      { AUTH_MODE !== AUTH_MODE_LEGACY &&
        <Route>
            <Redirect to={`${path}/groups`} />
        </Route>
      }

      <Route>
        <Redirect to={`${path}/accounts`} />
      </Route>
    </Switch>
  </div>
}

export default Admin
