import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles({
  root: {
    marginBottom: '2rem'
  },
  firstAlert: {
    marginBottom: '1rem'
  }
})

export default function BkAlert ({ severity, children, firstAlert }) {
  const classes = useStyles()
  return (
    <Alert severity={severity} className={firstAlert === "yes" ? classes.firstAlert : classes.root}>
      {children}
    </Alert>
  )
}
