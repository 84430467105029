import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as AuthService from '../../../services/auth.service'
import { logout, selectCurrentUser } from '../store/authSlice'
import { AUTH_MODE, AUTH_MODE_LEGACY } from '../../../environment'

import {
  resetPresetFilter,
  resetapplyFilter,
  selectAppliedFilters
} from '../../filters/store/FiltersSlice'
import * as _ from 'lodash'

const useLogout = () => {
  const dispatch = useDispatch()
  const [loggedStatus, setLoggedStatus] = useState({
    isLogged: null,
    isLoading: false
  })
  const user = useSelector(selectCurrentUser)
  const appliedFilters = useSelector(selectAppliedFilters) //Get applied filters (needed for saving and reseting)

  useEffect(() => {
    if (!_.isEmpty(user.email) && loggedStatus.isLogged !== null) {
      setLoggedStatus({
        isLogged: !_.isEmpty(user.email),
        isLoading: false
      })
    }
  }, [user, loggedStatus])

  const logoutHandler = () => {
    setLoggedStatus({
      ...loggedStatus,
      isLoading: true
    })
    AuthService.logout()
      .then(req => {
        dispatch(logout())
        dispatch(resetPresetFilter()) // reset to null the selected Preset
        dispatch(resetapplyFilter(appliedFilters)) // reset to None the <SelectPreset
        AuthService.removeSession()
        setLoggedStatus({
          isLogged: false,
          isLoading: false
        })

        if (AUTH_MODE !== AUTH_MODE_LEGACY){
          // in none legacy mode ahs to disconnect from auth module
          window.location.href = "/bpk-common/auth/logout?url=/&reason=Session closed by user"
        }

      })
      .catch(() => {
        dispatch(logout())
        AuthService.removeSession()
        setLoggedStatus({
          isLogged: false,
          isLoading: false
        })
      })
  }

  return { logoutHandler, loggedStatus }
}

export default useLogout
