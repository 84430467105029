import React from 'react'
import useLegacy from '../../i18n/legacyEffect'
import Badge from '../../components/Badge'
import * as _ from 'lodash'

const BkSelectedValues = ({ selected, onRemove, legacy }) => {
  const removeHandler = value => {
    const values = selected.filter(v => v !== value)
    onRemove(values)
  }
  const { tLegacy } = useLegacy()

  return (
    <div className='selectedValues'>
      {selected &&
        selected.length > 0 &&
        selected.map((v, i) => (
          <Badge
            key={i}
            label={tLegacy(legacy, _.get(v, 'value') || v)}
            onRemove={() => removeHandler(v)}
          />
        ))}
    </div>
  )
}

export default BkSelectedValues
