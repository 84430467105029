import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { WIDGETS_TYPES } from '../../../constants'
import { getList } from '../../../services/list.service'
import {   getFilterForRequest,
  selectPeriodZoom,
  selectPeriod,
  selectAppliedFilters,
  selectTimeZone, getPeriodTimeStamp } from '../../filters/store/FiltersSlice'

const useTableList = ({ config, offset, orderOn }) => {
  const [list, setlist] = useState(null)

  const period = useSelector(selectPeriod)
  const periodZoom = useSelector(selectPeriodZoom)
  const appliedFilters = useSelector(selectAppliedFilters)
  const timezone = useSelector(selectTimeZone)

  useEffect(() => {
    const filtersForRequest = getFilterForRequest(periodZoom, period, appliedFilters, timezone)
    const dates = getPeriodTimeStamp(period)

    const fetchList = async config => {
      const data = await getList({
        ...config,
        ...dates,
        limit: config.nbItemsPerPage,
        offset: offset || 0,
        orderOn: orderOnToString(orderOn),
      }
      , filtersForRequest)
      if ( config.type === WIDGETS_TYPES.LIST) {
        // apply timezone
        data.items.forEach(mapData => {
          mapData.date_epoch = parseInt(mapData.date_epoch - timezone);
        })
      }

      setlist(data)
    }

    // If config.items is set, use data already loaded in browser.
    // Sorting and slicing is done locally.
    if (config.items) {
      const limit = config.nbItemsPerPage
      const items = [...config.items]

      if (orderOn?.length > 0) {
        naturalSort(items, orderOn)
      }

      const slice = items.slice(
        offset, offset + limit
      )

      setlist({
        distinct: config.items.length,
        limit: config.nbItemsPerPage,
        items: slice,
        count: slice.length,
      })
    } else {
      // Otherwise, fetch data from API.
      // Sorting and slicing is done remotely.
      fetchList(config)
    }
  }, [appliedFilters, period, periodZoom, timezone, config, offset, orderOn])

  return list
}

/**
 * Sort list of objects by object fields using locale collator if String (case-insensitive) and default comparison otherwise.
 * This function supports multiple sorting criteria.
 *
 * @param {Array<Object>} list list to sort (inplace)
 * @param {Array<Array>} fields [field, direction] tuples, example: [['date', 1], ['uuid', -1]]
 * @returns {Array<Object>} the original list
 */
const naturalSort = (list, fields) => {
  const isStringField = fields.map(([field, _]) => (
    list.some(e => typeof e[field] === 'string' || e[field] instanceof String)
  ))

  const collator = Intl.Collator()
  return list.sort((a, b) => fields.reduce((acc, [field, direction], i) => {
    if (isStringField[i]) {
      return acc || collator.compare(a[field], b[field]) * direction
    } else if (a[field] > b[field]) {
      return acc || direction
    } else if (a[field] < b[field]) {
      return acc || -1 * direction
    } else {
      return acc
    }
  }, 0))
}

/**
 * Convert an array of ordering pairs to a SQL ORDER BY clause, expected by the API.
 * @param {Array<Array>} fields [field, direction] tuples, example: [['date', 1], ['uuid', -1]]
 * @returns String, example "date desc, uuid asc"
 */
const orderOnToString = fields => fields?.map(
  ([field, direction]) => [field, direction > 0 ? 'asc' : 'desc'].join(' ')
).join(', ')



export default useTableList
