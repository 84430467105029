import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LimitSelector from './LimitSelector'
import RankingItem from './RankingItem'
import './styles/ranking.scss'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import useRanking from './hooks/rankingEffect'
import * as _ from 'lodash'
import BkButton from '../../components/Button'
import Errors from '../../components/Errors'
import BkIcon from '../../components/icon'
import BkTextField from '../../components/forms/TextField'
import Modal from '../../components/Modal'
import { getRanking } from '../../services/rankings.service'
import {   getFilterForRequest,
  selectPeriodZoom,
  selectPeriod,
  selectAppliedFilters,
  selectTimeZone } from '../filters/store/FiltersSlice'

import CsvLinkButton from "../../components/CsvLinkButton";

const Ranking = ({ config, onFullScreen, isFullScreen, displayHeader }) => {
  const { t } = useTranslation()
  const [limit, setLimit] = useState(10)
  const rankings = useRanking(config, limit)
  const appliedFilters = useSelector(selectAppliedFilters)
  const periodZoom = useSelector(selectPeriodZoom)
  const period = useSelector(selectPeriod)
  const timezone = useSelector(selectTimeZone)
  const [displayRankingCSVModal, setDisplayRankingCSVModal] = useState(false)
  let [value, setValue] = useState("")

  if (rankings && rankings.startdate && rankings.enddate){
    // Format dateTimestamp to date in Input name When Export CSV in RANKING
    var startDTimestamp =  rankings.startdate
    var endDTimestamp = rankings.enddate
    var startD = new Date(startDTimestamp*1000);
    var endD = new Date(endDTimestamp*1000);
    startD = ( ( startD.getMonth()+1 <= 9 ) ? "0"+(startD.getMonth()+1):startD.getMonth()+1 ) + ( ( startD.getDate() <= 9 ) ? "0"+(startD.getDate()):startD.getDate() )  + (startD.getFullYear())
    endD = ( ( endD.getMonth()+1 <= 9 ) ? "0"+(endD.getMonth()+1):endD.getMonth()+1 ) + ( ( endD.getDate() <= 9 ) ? "0"+(endD.getDate()):endD.getDate() )  + (endD.getFullYear())

    value = "Ranking-" + config.dimension + "-" + startD + "-" + endD +".csv"
  }

  // Method used to get data for csv file export (given to csvLinkButton)
  const onExportCsvRanking = async () => {
    console.log("on export...")
    const filtersForRequest = getFilterForRequest(periodZoom, period, appliedFilters, timezone)
    const data = await getRanking({ ...config }, filtersForRequest)
    return data
  }


  const CheckErrorsRankings = () => {
    if (config && config.limit_to_one_filter_type && appliedFilters && appliedFilters.length > 1)
    {
      return <Errors dataError={'error_one_value_in_dashboard'} config={config} appliedFilters={appliedFilters}></Errors>
    }
    else if (!_.isNull(rankings) && _.isEmpty(_.get(rankings, 'measures')))
    {
      return <Errors dataError={"No data available"} config={config} appliedFilters={appliedFilters}></Errors>
    }
    else if (_.isNull(rankings) && _.isEmpty(_.get(rankings, 'measures')))
    {
      return <Errors dataError={'error_noData_with_filters'} config={config} appliedFilters={appliedFilters}></Errors>
    }
    // else if (_.isNull(rankings) && !_.isEmpty(_.get(rankings, 'measures')))
    // {
    //     AppliedFilter > 2 if limit_To_one_filter > 2
    // }
  }

  function With_Or_Without_PercentColum_Ranking() {

    if (config.showPercentColumn === false){
      return (
        // GRID OF 12
        // Ranking with  column_NAME(9)  COLUMN_otherRanking(3) =Grid(12)
        <div className='ranking'>
        <Grid container alignItems='center' className='ranking__header'>
          <Grid item xs={9} className='ranking__title'>
            Ranking <LimitSelector limit={limit} limitHandler={setLimit} />
          </Grid>
        {NameColumnRanking()}
        {NameColumnRanking_Custom()}
        </Grid>
        <div className= {(limit <= 10 ? 'ranking__contentLimit' : 'ranking__content')}>
          {!_.isNull(rankings) &&
            _.get(rankings, 'measures').map((ranking, i) => {
              return (
                i < limit && (
                  <RankingItem
                    key={i}
                    position={i}
                    ranking={ranking}
                    dimension={config.dimension}
                    config={config}
                  />
                )
              )
            })}
          {CheckErrorsRankings()}
        </div>
      </div>
      )
    }
    else {
      return (
        // GRID OF 12
        // Ranking with  column_NAME(6)  COLUMN_TotalSessionRanking(3) AND COLUMN_PERCENT%(3) =Grid(12)
        <div className='ranking'>
        <Grid container alignItems='center' className='ranking__header'>
          <Grid item xs={6} className='ranking__title'>
            Ranking <LimitSelector limit={limit} limitHandler={setLimit} />
          </Grid>
            <Grid item xs={3} className='ranking__legend'>
              {t('ranking:totalSessions')}
            </Grid>
            <Grid item xs={3} className='ranking__legend'>
              %
            </Grid>
        </Grid>

        <div className= {(limit <= 10 ? 'ranking__contentLimit' : 'ranking__content')}>
        {!_.isNull(rankings) &&
          _.get(rankings, 'measures').map((ranking, i) => {
            return (
              i < limit && (
                <RankingItem
                  key={i}
                  position={i}
                  ranking={ranking}
                  dimension={config.dimension}
                  config={config}
                />
              )
            )
          })}
          {_.isNull(rankings) &&  <center>Loading...</center>
          }
          {CheckErrorsRankings()}
      </div>
    </div>
      )
    }
  }

  function NameColumnRanking_Custom() {
    if (config && config.widgetParams && config.widgetParams.function && config.widgetParams.function.label){
      let functionTitle = config.widgetParams.function.label
      let metricName = t('rankingCustomDahsboard:' + config.widgetParams.metric.id)
      let RankingName = functionTitle + " " + metricName

      return (
             <Grid item xs={12} className='ranking__legend'>
                 {RankingName}
             </Grid>
           )
    }
   }

  function NameColumnRanking() {
    return (
          <Grid item xs={3} className='ranking__legend'>
            {config.resultName}
          </Grid>
          )
  }

  const header =  (
    <div className='widgetSection'>
      {displayHeader && (
        <div className='widget__header'>
          <h2 className='widget__title'>{config.title}</h2>
          {!isFullScreen && (
            <nav className='widget__actionsContainer'>
              <ul className='widget__actions'>
                <li className='widget__action'>
                  {rankings && rankings.measures && rankings.measures.length > 0 && (
                    <BkButton
                    className='bkButton--invisible'
                    onClick={() => setDisplayRankingCSVModal(true)}
                    >
                    <BkIcon path='download' modifier='unactive' />
                  </BkButton>
                  )}

                  <BkButton
                    className='bkButton--invisible'
                    onClick={onFullScreen}
                  >
                    <BkIcon path='size' modifier='unactive' />
                  </BkButton>

                </li>
              </ul>
            </nav>
          )}
        </div>
      )}
      {With_Or_Without_PercentColum_Ranking()}
    </div>
  )

  return (
    <>
        <>
        {/* //Modal Export when download CSV for all rankings */}
          {header}
          {displayRankingCSVModal &&
            <Modal title='Download csv document' onClose={() => setDisplayRankingCSVModal(false)}>
              <div className="label">
                <label >Filename (eg. Ranking-videoserver-02162012.csv) : </label>
              </div>
              {/* // TODO change Filename when download whith the input name */}
              <BkTextField value={value} onChange={e => setValue(e.target.value)}/>
              <div className='modal__footer'>
                <CsvLinkButton
                  onClick = {onExportCsvRanking}
                  onQuit  = {() => setDisplayRankingCSVModal(false)}
                  filename={value}
                  />
                <BkButton
                  onClick={() => setDisplayRankingCSVModal(false)}
                  className='bkButton--alternate'
                >
                Cancel
                </BkButton>
              </div>
            </Modal>}
        </>
    </>
  )
}
export default Ranking