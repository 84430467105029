import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import BkButton from '../../components/Button'
import BkTextField from '../../components/forms/TextField'
import BkIcon from '../../components/icon'
import Modal from '../../components/Modal'
import Scrollable from '../../components/Scrollable'
import Footer from '../../layouts/Footer'
import { getTemplate, remove, save } from '../../services/dashboards.service'
import useReloadMenu from '../dashboards/hooks/reloadMenu'
import { changePage, selectRootMenu, getRootPage } from '../dashboards/store/dashboardSlice'
import {
  changeTitle,
  saveTemplate,
  selectTemplate
} from './store/customDashboardSlice'
import WidgetsManager from './WidgetsManager'
import WidgetConfigModal from './WidgetConfigModal'

export const DragTypes = {
  WIDGET: 'widget'
}

const CustomDashboardEditor = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const template = useSelector(selectTemplate)
  const [redirect, setRedirect] = useState(false)
  const [removeModal, displayRemoveModal] = useState(false)
  const rootMenu = useSelector(selectRootMenu)
  const rootDashboard = getRootPage(rootMenu)
  const [displayModal, setDisplayModal] = useState(false)

  const reloadMenu = useReloadMenu()

  const changeTitleHandler = event => {
    dispatch(changeTitle(event.target.value))
  }
  const saveTemplateHandler = () => {
    save(template)
    changePage(dispatch, ['dashboards', _.get(template, ['routerId'])], _.get(template, ['submenuid']))
    setRedirect(true)
  }

  const removeTemplateHandler = () => {
    dispatch(saveTemplate(null))
    remove(template)
    reloadMenu()
    changePage(dispatch, rootDashboard)
    setRedirect(true)
  }


  useEffect(() => {
    const fetchTemplate = async (templateId) => {
      try {
        const { data } = await getTemplate(templateId)
        changePage(dispatch, ['dashboards', _.get(data, ['routerId'])], _.get(data, ['submenuid']))
        dispatch(saveTemplate(data))
      } catch (error) {
        setRedirect(true)
      }
    }
    fetchTemplate(id)
  }, [id])


  const header = template && (
    <header className='pageContainer__header'>
      <div className='pageContainer__titleEditor'>
        <span>Title</span>
        <BkTextField value={template.title} onChange={changeTitleHandler} />
      </div>
      <div className='pageContainer__actions'>
      <BkButton
          type='button'
          onClick={() => setDisplayModal(true)}
          className='bkButton--noPadding'
          disabled={_.isNull(template.title) || _.isEmpty(template.title)}
        >
          <BkIcon path='plus-white' alt='Add a widget' />
        </BkButton>
        {displayModal && (
          <WidgetConfigModal onClose={() => setDisplayModal(false)} menuId={template.menuid} />
      )}
        <BkButton
          type='button'
          onClick={saveTemplateHandler}
          className='bkButton--noPadding'
          disabled={_.isNull(template.title) || _.isEmpty(template.title)}
        >
          <BkIcon path='save' alt='Save the current template' />
        </BkButton>
        <BkButton
          type='button'
          onClick={() => displayRemoveModal(true)}
          className='bkButton--noPadding bkButton--alternate'
        >
          <BkIcon path='trash' alt='Remove the current template' />
        </BkButton>
      </div>
    </header>
  )

  return (
    <>
      {_.isNull(template) && redirect && <Redirect exact to='/' />}
      {!_.isNull(template) && redirect && <Redirect exact to={'/dashboard/' + template.routerId} />}
      {!_.isNull(template) && (
        <>
          <div className='pageContainer'>
            <Scrollable>
              <div className='pageContainer__section'>
                {header}
                <DndProvider backend={HTML5Backend}>
                  <WidgetsManager contentBlocks={template.contentBlocks} menuId={template.menuid} />
                </DndProvider>
              </div>
            </Scrollable>
            <Footer />
          </div>
          {removeModal &&
            <Modal title='Please confirm' onClose={() => displayRemoveModal(false)}>
              Do you want to remove the custom dashboard named "{template.title}" ?
              <div className='modal__footer'>
                <div className='modal__footer'>
                  <BkButton
                    onClick={removeTemplateHandler}
                    className='bkButton--delete'
                  >
                  Confirm
                  </BkButton>
                  <BkButton
                    onClick={() => displayRemoveModal(false)}
                    className='bkButton--alternate'
                  >
                  Cancel
                  </BkButton>
                </div>
              </div>
            </Modal>}
        </>
      )}

    </>
  )
}

export default CustomDashboardEditor
