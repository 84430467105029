import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { selectPeriod,  getPreviousPeriodDate} from '../../filters/store/FiltersSlice'
import {
  toString,
  calcEnd,
  toTimestamp
} from '../../../services/period.service'
import BkFieldSet from '../../../components/forms/FieldSet'
import { Grid, Paper } from '@material-ui/core'
import BkButton from '../../../components/Button'

export const Compare = ({ onChange }) => {
  const period = useSelector(selectPeriod)
  const previousPeriod = getPreviousPeriodDate(period)
  const [comparedPeriod, setComparedPeriod] = useState(previousPeriod)
  const [displayDatepicker, setDisplay] = useState(false)

  useEffect(() => {
    setComparedPeriod(previousPeriod)
  }, [period])

  const reset = () => {
    if (onChange) onChange({})
  }

  const valid = () => {
    if (onChange) onChange(toTimestamp(comparedPeriod))
  }

  return (
    <div className='compareDates'>
      <span className='compareDates__description'>
        The data currently shown (solid line) for your reference period will be
        compared to the period you define hereafter (in dotted lines).
      </span>
      <Grid container>
        <Grid item>
          <BkFieldSet
            title='Selected period'
            onFocus={() => setDisplay(true)}
            onFocusOut={() => setDisplay(false)}
            className='select select--filled'
          >
            <span className='select__button'>{toString(comparedPeriod)} </span>
            {displayDatepicker && (
              <div className='datepicker'>
                <Paper>
                  <span className='periodDuration__label'>From:</span>
                  <ReactDatePicker
                    inline
                    className='datepicker'
                    startDate={comparedPeriod.startdate}
                    endDate={comparedPeriod.enddate}
                    selected={comparedPeriod.startdate}
                    onChange={date => {
                      setComparedPeriod(
                        calcEnd({
                          duration: previousPeriod.duration,
                          startdate: date,
                          enddate: null
                        })
                      )
                    }}
                  />
                </Paper>
              </div>
            )}
          </BkFieldSet>
        </Grid>
      </Grid>
      <div className='action-barre'>
        <BkButton onClick={valid}>Apply</BkButton>
        <BkButton
          className='bkButton--alternate'
          variant='contained'
          onClick={reset}
        >
          Reset
        </BkButton>
      </div>
    </div>
  )
}
