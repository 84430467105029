//import * as _ from "lodash";
// import React from 'react'
// import { useDispatch } from "react-redux";
import React, {  useState } from 'react'
import { TextField, CircularProgress} from "@material-ui/core";
import BkSelect from '../../components/forms/Select'
import BkAlert from '../../components/Alert'
import BkButton from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { useEffectOnlyOnce} from '../../globalFunctions'
import { getConfigs, postConfigs } from '../../services/config.service'
import {updateSettingsGui} from '../settings/store/SettingsSlice'
import { useDispatch } from 'react-redux'
import * as _ from 'lodash'
//----------------------------------------------------------------------
//  Administration console settings:
//----------------------------------------------------------------------
const AdminSettings = ({mainMenu, subMenu}) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    processing:false,
    msg: [],
    severity:'info'
  })
  const [settingsList, setSettingsList] = useState({settings:[]})
  const { t } = useTranslation()

  useEffectOnlyOnce(()=> {
      setState({ ...state, processing: true })
      processSettings()
  })

  const catchError = (error) => {
    setState({
      ...state,
      processing: false,
      severity:'error',
      msg: error.message  ?  [{"message": error.message}] : []
      //errors: error.response  ? error.response.data.errors : []
    })
  }

  const processSettings = ()  => {
    getConfigs().then((res) => {
      setSettingsList({settings:res.data.gui_settings }  )
      setState({ ...state, processing: false, severity:'info' })
    }).catch(catchError)
  }

  const handleChange = (event) => {
    const msg = []
    settingsList.settings.forEach(element => {
      if (element.name === event.target.name){
        element.value = event.target.value
      }
    })
    msg.push({ message:"Submit to apply modification" })
    setState({ ...state, msg})
    setSettingsList({...settingsList})
  }

  // handle change on bkselect element (name of element is the settings name)
  const handleBkSelectChange = (event, elt) => {
    settingsList.settings.map(function(element) {
      if (element.name === event.name){
        element.selected = event.idx
        element.value = event.value
      }
      return element
    })
    setSettingsList({...settingsList})
   }


  //checking field as config webservice to manage message
  const checkField = () => {
    const msg = []
    let ret = 0
    settingsList.settings.forEach(function(element) {
      if (element.control_type === 'integer'){
        if (isNaN(element.value) || element.value === '') {
          const message = t('settings:'+ element.name) + ' should be an integer.'
          msg.push({ message })
          ret = 1
        }
      } else if (element.control_type === 'integerPositive'){
        if (isNaN(element.value) || element.value === '')  {
          const message = t('settings:'+ element.name) + ' should be an integer.'
          msg.push({ message })
          ret = 1
        }
        if (!(parseInt(element.value)>0)){
          const message = t('settings:'+ element.name) + ' should be > 0.'
          msg.push({ message })
          ret = 1
        }
        //
      } else if (element.control_type === 'stringNotNull'){
        if (element.value ===''){
          const message = t('settings:'+ element.name) + ' should be fullfilled.'
          msg.push({ message })
          ret = 1
        }
      }

    })

    setState({ ...state, msg, severity: 'error'})
    return ret

  }

  const submit = () => {
    const msg = []

    if (!checkField()) {
      setState({ ...state, processing: true })
      postConfigs(settingsList).then(res => {
        const message = _.get(res, ['data', 'message'])
        let severityLevel = 'info'
        if (message) {
          msg.push({ message })
        } else {
          msg.push({ message:"Modification applied" })
          severityLevel = 'success'
        }
        setState({ ...state, msg, processing: false, severity: severityLevel})
        console.log('submit setting', res)
        // dispatch the new settings and update value to update current gui too
        const gui_settings = {}
        settingsList.settings.forEach(function(element) {
          gui_settings[element.name] = element.value
        })
        dispatch(updateSettingsGui(gui_settings))
      }).catch(catchError)
    }
  }

  const cancel = () => {
    const msg = []
    msg.push({ message:"Settings restored" })
    setState({ ...state, processing: true})
    processSettings()
  }

  const processing = <CircularProgress />

  const displayMessages = (
    <BkAlert severity={state.severity} className='input'>
      {state &&
      state.msg &&
      state.msg.length > 0 &&
      state?.msg?.map((err, i) => (
        <div key={i}>{err.message}</div>
      ))}
    </BkAlert>
  )
  const form = (
  <div className='formList'>
    {settingsList.settings.map((element, i) => (
      <div className='inlineFieldSet fieldSetContainer' key={i}>
        <label className='label' htmlFor={element.name}>{t(`settings:${element.name}`)}</label>
        <div className='inputContainer'>
          {element.values_list ?
            <BkSelect
              key={i}
              id={element.name}
              name={element.name}
              selected={element.values_list[element.selected]}
              options={element.values_list}
              optionConfig={{ label: 'label' }}
              onChange={handleBkSelectChange}
            />
            :
            <TextField
              key={i}
              id={element.name}
              name={element.name}
              size="small"
              className="textareaField"
              variant="outlined"
              onChange={handleChange}
              value={element.value}
            />
          }
        </div>
      </div>
    ))}

    {state.msg.length > 0 ? displayMessages : null}
  </div>
  )

  return  <div className='widget widget--minHeight'>
            <div className='widget__header'>
              <h1 className='widget__title'>GUI settings</h1>
            </div>
            <div className='widget__content widget__content--form'>{state.processing ? processing : form}</div>
            <div className='widget__footer'>
              <BkButton onClick={submit}>{t(`button:apply`)}</BkButton>
              <BkButton className='bkButton--alternate' onClick={cancel}>{t(`button:reset`)}</BkButton>
            </div>
          </div>
    }

export default AdminSettings
