import React from 'react'
import BkIcon from './icon'
import './styles/badge.scss'

const Badge = ({ label, onRemove }) => {
  return (
    <span className='badge'>
      <span className='badge__label'>{label}</span>
      <button
        className='bkButton bkButton--invisible badge__closeButton'
        type='button'
        onClick={onRemove}
      >
        <BkIcon path='close' />
      </button>
    </span>
  )
}

export default Badge
