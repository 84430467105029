import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as AuthService from '../../../services/auth.service'
import * as _ from 'lodash'
import { login, logout, selectCurrentUser } from '../store/authSlice'

const useInitSession = () => {
  const dispatch = useDispatch()
  const [islogged, setIsLogged] = useState(null)
  const [isPwdToRenew, setIsPwdToRenew] = useState(null)
  const [error, setError] = useState(null)
  const user = useSelector(selectCurrentUser)

  useEffect(() => {
    if (islogged !== null) {
      setIsLogged(!_.isEmpty(user.email) )
    }
    setIsPwdToRenew(!_.isEmpty(user.email) && !_.isEmpty(user.status?.msg && user.status?.msg === "account_pwd_expired" ))
  }, [user, islogged])

  useEffect(() => {
    const initSession = async () => {
      try {
        const currentSession = await AuthService.initSession()
        if (!_.isEmpty(currentSession)) {
          dispatch(login(currentSession))
          setIsLogged(true)
        } else {
          setIsLogged(false)
          dispatch(logout())
        }
      } catch (e) {
        setIsLogged(false)
        dispatch(logout())
        if (e.response) {
          setError(e.response.data)
        }
      }
    }
    let mounted = true
    if (mounted) initSession()
    return () => (mounted = false)
  }, [dispatch])
  return [islogged, isPwdToRenew, error]
}

export default useInitSession
