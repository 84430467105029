import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // Dictionnary of table identifiers -> selected lines.
  // This is needed because we may have several TableList components loaded at the same time.
  selectedLines: {},
}

export const TableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    updateSelectedLine: (state, action) => {
      const {id, line} = action.payload
      return {
        ...state,
        selectedLines: {
          ...state.selectedLines,
          [id]: line,
        },
      }
    },
  }
})

export const {
  updateSelectedLine,
} = TableSlice.actions

export const selectSelectedLine = id => state => state.table.selectedLines[id]
export default TableSlice.reducer
