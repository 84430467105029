import { CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import BkAlert from '../../components/Alert'
import BkButton from '../../components/Button'
import BkTextField from '../../components/forms/TextField'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { changeExpiredPassword } from '../../services/auth.service'
import useLogout from './hooks/logout'


const ChangeExpiredPassword = (props) => {
    const { t } = useTranslation()
    const history = useHistory()

    const initialStatus = {
        errors: [],
        processing: false
    }

    const email = props.email;
    const oldPassword = props.password;

    const [status, setStatus] = useState(initialStatus)
    const { logoutHandler,  } = useLogout()

    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')

    //Submit password changing (no check with old password yet)
    const submitHandler = () => {
        setStatus({ errors: [], processing: true })

        if (newPassword && newPassword === confirmNewPassword) {        

            changeExpiredPassword(email, oldPassword, newPassword).then(() => {
                console.log('changeExpiredPassword email, oldPassword, newPassword =', email, oldPassword, newPassword)
                setStatus({ errors: [], processing: false })
                cancelHandler()
            }).catch((error) => {
                console.log('Errors response', error.response)
                console.log('Errors response data ', error.response.data)

                setStatus({
                    processing: false,
                    errors: (error.response && error.response.data.errors) ? error.response.data.errors : ['invalid_token']
                })
            })
        } else {
            setStatus({
                processing: false,
                errors: [{ code: 0, message: 'Password and confirm password don\'t match' }]
            })
        }
    }

    //Logout from the passwword modification session
    const cancelHandler = () => {
        //dispatch(logout())
        logoutHandler()
        history.push('/login')
    }

    const processing = <CircularProgress />

    const displayErrors = (
        <BkAlert severity='error' className='input'>
            {status &&
                status.errors &&
                status.errors.length > 0 &&
                status?.errors?.map((err, i) => (
                    <div key={i}>{t('errors:' + err.message)}</div>
                ))}
        </BkAlert>
    )

    const form = (
        <form>
            {status && status.errors.length > 0 ? displayErrors : null}
            <BkTextField
                placeholder='New password'
                label='New password'
                value={newPassword}
                type='password'
                onChange={(e) => setNewPassword(e.target.value)}
            />
            <BkTextField
                placeholder='New Password Confirm'
                label='Confirm new password'
                value={confirmNewPassword}
                type='password'
                onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            <div className='modal__footer'>

                {/* Should disconnect the user, to force him to relog? */}
                <BkButton
                    type='submit'
                    variant='contained'
                    color='primary'
                    onClick={submitHandler}
                >Submit
        </BkButton>
                {/* Should disconnect the user */}
                <BkButton onClick={cancelHandler} className='bkButton--alternate'>
                    Cancel
        </BkButton>
            </div>
        </form>
    )

    return <>{status.processing ? processing : form}</>
}

export { ChangeExpiredPassword }
