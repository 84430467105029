import { createSlice } from '@reduxjs/toolkit'
import * as _ from 'lodash'

const initialDashboardTemplate = {
  id: null,
  routerId: null,
  version: '06.00.00-0000', //Version is updated by script during build
  title: null,
  headerTitle: null,
  custom: true,
  options: {},
  contentBlocks: []
}

export const customDashboardSlice = createSlice({
  name: 'customDashboard',
  initialState: {
    template: null
  },
  reducers: {
    initTemplate: state => {
      return {
        ...state,
        template: { ...initialDashboardTemplate }
      }
    },
    changeTitle: (state, action) => {
      const title = action.payload
      return {
        ...state,
        template: {
          ...state.template,
          title,
          headerTitle: title
        }
      }
    },
    saveTemplate: (state, action) => {
      const template = action.payload === null ? null : { ...action.payload }
      if (!_.isNull(template)) {
        template.contentBlocks = template.contentBlocks.map((block, i) => ({
          ...block,
          position: i
        }))
      }
      state = { ...state, template }
      return state
    },
    addContentBlock: (state, action) => {
      const block = action.payload
      block.position = state.template.contentBlocks.length
      return {
        ...state,
        template: {
          ...state.template,
          contentBlocks: [...state.template.contentBlocks, block]
        }
      }
    },
    updateBlock: (state, action) => {
      const block = action.payload
      return {
        ...state,
        template: {
          ...state.template,
          contentBlocks: state.template.contentBlocks.map((b, i) => {
            return b.position === block.position
              ? {
                ...block,
                position: i
              }
              : {
                ...b,
                position: i
              }
          })
        }
      }
    },
    removeBlock: (state, action) => {
      const block = action.payload
      return {
        ...state,
        template: {
          ...state.template,
          contentBlocks: state.template.contentBlocks
            .filter(b => b.position !== block.position)
            .map((b, i) => ({
              ...b,
              position: i
            }))
        }
      }
    },
    moveBlock: (state, action) => {
      const { newPosition, block } = action.payload
      if (!_.isArray(state.template.contentBlocks)) {
        return state
      }
      if (newPosition === block.position) {
        return state
      }
      const orderedBlocks = state.template.contentBlocks.reduce(
        (blocks, b, i) => {
          // Si on avance un block
          if (newPosition < block.position) {
            if (i > newPosition && i <= block.position) {
              return [...blocks, { ...state.template.contentBlocks[i - 1], position: i }]
            }
            if (i < newPosition || i > block.position) {
              return [...blocks, { ...b, position: i }]
            }
            if (i === newPosition) {
              return [...blocks, { ...block, position: i }]
            }
          }
          if (newPosition > block.position) {
            if (i < block.position || i > newPosition - 1) {
              return [...blocks, { ...b, position: i }]
            }
            if (i >= block.position && i < newPosition - 1) {
              return [...blocks, { ...state.template.contentBlocks[i + 1], position: i }]
            }
            if (i === newPosition - 1) {
              return [...blocks, { ...block, position: i }]
            }
          }
          return []
        }, []
      )
      return { ...state, template: { ...state.template, contentBlocks: orderedBlocks } }
    }
  }
})

export const selectTemplate = state => state.customDashboard.template

export const {
  initTemplate,
  saveTemplate,
  changeTitle,
  addContentBlock,
  updateBlock,
  removeBlock,
  moveBlock
} = customDashboardSlice.actions

export default customDashboardSlice.reducer
