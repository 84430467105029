import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFilterForRequest,
  selectPeriodZoom,
  selectPeriod,
  selectAppliedFilters,
  selectTimeZone,
  updatePeriodZoom,
  updatePeriodQueries,
  resetPeriodZoom
} from '../filters/store/FiltersSlice'
import { Configuration } from './configuration/Configuration'
import { useTranslation } from 'react-i18next'
import useLegacy from '../../i18n/legacyEffect'
import Header from './Header'
import {
  affectSerie,
  fetchOption,
  fetchSeries,
  fetchSeriesWithCompare,
  fetchSeriesWithSplit,
  affectSessionTrackerSeries,
  changeGaugeColor,
  updateNetworkEventSerieMarker
} from './hooks/ChartsEffects'
import { selectCurrentDashboard } from '../dashboards/store/dashboardSlice'
import { MapChart } from './maps/mapChart'
import './styles/charts.scss'
import '../../components/styles/errors.scss'
import {
  toISOString} from '../../services/period.service'
import BkButton from '../../components/Button'
import Errors from '../../components/Errors'
import { PieLegend } from './pie/PieLegend'
import { SERIES_TYPE } from '../../constants'
import * as _ from 'lodash'
import { selectSelectedLine } from '../tableList/store/TableSlice'
import { getExportingFilename } from '../charts/store/ChartsSlice'

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

/**
 *
 * @param config
 * @param onFullScreen
 * @param isFullScreen
 * @param displayHeader
 * @returns {JSX.Element}
 * @constructor
 */
const Chart = ({ config, onFullScreen, isFullScreen, displayHeader, redraw }) => {
  const dispatch = useDispatch()
  const chart = useRef()
  const exportingFilename = useSelector(getExportingFilename)
  const isSessionTracker = useRef(false) //Flag for Session tracker Charts, and the useEffect associated with the tableList
  const [options, setOptions] = useState({ init: false })
  const [displayConfig, setDisplayConfiguration] = useState(false)
  const { t } = useTranslation()
  const { tLegacy } = useLegacy()
  const currentPage = useSelector(selectCurrentDashboard)

  const [optionsConfig, setOptionsConfig] = useState({

    compare: {
      selected: false
    },
    split: {
      selected: false
    },
    active: false,
    isChanged: false
  })
  const period = useSelector(selectPeriod)
  const periodZoom = useSelector(selectPeriodZoom)
  const appliedFilters = useSelector(selectAppliedFilters)
  const timezone = useSelector(selectTimeZone)
  const selectedLine = useSelector(selectSelectedLine())

  const filtersForRequest = getFilterForRequest(periodZoom, period, appliedFilters, timezone)
  //Method to update labels in series for Pies with error status (also had a "unkown" label for empty names)

  const translateSerieName = (serie, split, splitDimension) => {
      // when props "split" is added on json file apply display to include split name
      if (serie.name !== SERIES_TYPE.CDF ) {
        serie.name = tLegacy(split, serie.name)
      }
      // on serie.name when spliting chart
      if (serie.name === '') {
        serie.name = t('miscellaneous:unknown')
      } else {
        // change dimension by splitDimension to apply column (status_code, country) when exist
        serie.name = tLegacy(splitDimension, serie.name)
      }
  }

  const applyDisplayAssociation = (params, series, splitDimension) => {
    //for Gauge, polar, session tracker, do not apply display association
    if ( params && params.type && (
        params.type === SERIES_TYPE.GAUGE ||
        params.type === SERIES_TYPE.POLARLINE ||
        params.type === SERIES_TYPE.POLARAREA ||
        params.type === SERIES_TYPE.SESSIONTRACKER)) {
      return
    }
    //Extraction of config informations
    let dimension=''
    if (params && params.list && params.list[0] && config.list[0].series[0])
    {
      if (params.list[0].series[0].fieldName) {
        dimension = params.list[0].series[0].fieldName
      } else {
        dimension = params.list[0].series[0].dimension
      }
    }
    if (series) {
      series.map((serie) => {
         // when props "split" is added  on json file get value of split
         let split = params.list[0].series[0].split
        //for bars and columns categories, apply display association on categories
        if ( params.type === SERIES_TYPE.BARSCATEGORIES ||
             params.type === SERIES_TYPE.STACKBARSCATEGORIES ||
             params.type === SERIES_TYPE.STACKCOLUMNSCATEGORIES ||
             params.type === SERIES_TYPE.COLUMNSCATEGORIES ||
             params.type === SERIES_TYPE.COLUMNSCATEGORIESHOURS) {
          if (serie.categories){
            const newCat= []
            serie.categories.map(cat => {
              if (cat === '') {
                newCat.push(t('miscellaneous:unknown'))
              } else {
                newCat.push(tLegacy(dimension, cat))
              }
              return ""
            })
            serie.categories = newCat
            translateSerieName(serie, split, splitDimension)
          }
        //for area and line, apply display association on serie name (ie splitting)
        }
        else if ( params.type === SERIES_TYPE.CDF) {
          translateSerieName(serie, split, splitDimension)
        }
        else if ( params.type === SERIES_TYPE.TRENDINGLINE) {
          // For trending graphs, we remove null values beautify user interface
          const startDate = serie.data.find(elt => elt.y != 0)
          const endDate = serie.data.filter(elt => elt.y != 0).slice(-1)[0]
          if (startDate !== undefined && endDate !== undefined) {
            serie.data = serie.data.filter(function(elt) { return elt.x >= startDate.x; })
            serie.data = serie.data.filter(function(elt) { return elt.x <= endDate.x; })
          }
          else {
            serie.data = []
          }
          translateSerieName(serie, split, splitDimension)
        }
        else if ( params.type === SERIES_TYPE.AREA
                || params.type === SERIES_TYPE.LINE
                || params.type === SERIES_TYPE.STACKCOLUMNSTIMESTAMPS) {
          translateSerieName(serie, split, splitDimension)
        } else {
          const newData = []
          serie.data.map(data => {
            const dataUpdate = {...data}

            if ( data.name ){
              //Translation of status code to readable description
              if (dimension){
                dataUpdate.name = tLegacy(dimension, data.name)
              } else {
                dataUpdate.name =  t('miscellaneous:unknown')
              }
            } else if (data.name === '') {
              dataUpdate.name =  t('miscellaneous:unknown')
            }
            newData.push(dataUpdate)
            return
          })
            serie.data = newData
        }
      return
      })
    }
    else {
      console.error('CHARTS applyDisplayAssociation - series is NULL')
    }
  }

  const fetchChart = async (params, filter) => {
    let options = {}

    try {
    let chartObj = chart.current.chart;
    chartObj.showLoading();

      //console.log('FETCH SERIES - fetchChart')
      const res = await Promise.all([
        fetchOption(params, filter),
        fetchSeries(params, filter)
      ])
      chartObj.hideLoading();
      applyDisplayAssociation(params,  _.get(res, [1]))


      options = affectSerie(_.get(res, [0]), _.get(res, [1]))

      if (config.type === SERIES_TYPE.GAUGE ) {
        // redrawPieLabel(chart)
        changeGaugeColor(config, options)
      }
      {options.tooltip && options.tooltip.formatter && (
          options.tooltip.formatter = function() {
            var tooltipText = "";
            var unit = "";
            const color = "<span style='color:"+this.series.color+";'>\u25CF </span> ";
            if (this.series.options.unit !== undefined ) {
              unit = ' ' + this.series.options.unit
            }

            if (params.type === SERIES_TYPE.CDF) {
              //Specific display for CDF : Manage xToolTip =>  MesureUnit (Parcours du curseur sur le graph)  / XUnit => MesureUnit (ms)
              var xunit = "";
              if (this.series.options.xunit !== undefined ) {
                xunit = ' ' + this.series.options.xunit
              }
              tooltipText = this.series.name + ': ' + this.x + xunit
              tooltipText += '</b><br>' + color + 'Quantiles' + ': ' + Highcharts.numberFormat(this.y,2)
            }
            else if(params.type === SERIES_TYPE.STACKCOLUMNSCATEGORIES
                    || params.type === SERIES_TYPE.STACKBARSCATEGORIES
                    || params.type === SERIES_TYPE.BARSCATEGORIES
                    || params.type === SERIES_TYPE.COLUMNSCATEGORIES
                    || params.type === SERIES_TYPE.COLUMNSCATEGORIESHOURS ) {
              tooltipText = this.x
              tooltipText += '</b><br>' + color + this.series.name + ': ' + Highcharts.numberFormat(this.y,0) + unit
            }
            else {
              //tooltipText += new Date(this.x).toUTCString().slice(0, -3); OTHER WAY TO Get DATE
              tooltipText = Highcharts.dateFormat("%Y-%m-%d", this.x) + " from " + Highcharts.dateFormat("%H:%M", this.x);
              tooltipText += " to " + Highcharts.dateFormat("%H:%M", this.x + this.series.options.interval);
              tooltipText += '</b><br>' + color + this.series.name + ': ' + Highcharts.numberFormat(this.y,0) + unit


              if (currentPage && currentPage[0] !== "dashboards")
              {
                // Check if we are on CD to not impact Classic dashboard
                if((params.type === SERIES_TYPE.STACKCOLUMNSTIMESTAMPS
                  || params.type === SERIES_TYPE.STACKCOLUMNSTIMESTAMPSPERCENT))
                  tooltipText += ' ('+ Highcharts.numberFormat(this.percentage, 2) + '%)'
              }
              else {
                if((params.type === SERIES_TYPE.STACKCOLUMNSTIMESTAMPS
                  || params.type === SERIES_TYPE.STACKCOLUMNSTIMESTAMPSPERCENT)
                  && params.list[0].series[0].dimension !== undefined) {
                  // #2094 On custom Dashboard when metric Error with no dimension => 100% error
                  tooltipText += ' ('+ Highcharts.numberFormat(this.percentage, 2) + '%)'
                }
                else
                // if no dimension
                tooltipText += ''
              }
            }
            return tooltipText
          }
        )
      }

      //For session tracker, specific management of series
      if(params.type === SERIES_TYPE.SESSIONTRACKER) {
        affectSessionTrackerSeries(options)
      }
    } catch (e) {
      console.error('fetchChart error', e)
    }
    return options
  }

  redraw = () => {
    //console.log('CHART REDRAW !!!')
    if (chart.current?.chart.redraw) {
      chart.current.chart.redraw()
    }
    if (chart.current?.chart.zoomOut) {
      chart.current.chart.zoomOut()
    }
  }

  /**
   * Use this effect to reflow (https://api.highcharts.com/class-reference/Highcharts.Chart#reflow) after a window resizing
   * debounce the callback to be sure the chart component fits its container
   */
  useEffect(() => {
    const reflowChart = _.debounce(function() {
      if (chart && chart.current && chart.current.chart){
        chart.current.chart.reflow()
      }
    }, 500)

    window.addEventListener('resize', reflowChart)

    return () => {
      window.removeEventListener('resize', reflowChart)
    }
  })

  /**
   * This hooks is triggered if the selectedLine variable has changed
   * e.g. if a line is clicked in TableList Element
   */
  useEffect(() => {
    if (isSessionTracker.current) //useEffect only triggered for SERIES_TYPE.SESSIONTRACKER charts
    {
      //console.log('USE EFFECT - isSessionTracker !!!', selectedLine)
      const this_filters = {
        startdate: filtersForRequest.startdate,
        enddate: filtersForRequest.enddate,
        timezone: filtersForRequest.timezone,
        filters: [
          ...filtersForRequest.filters,
          {
            name: "session_id",
            label: "session_id",
            values: [
              {
                value: selectedLine,
                operator: "=="
              }
            ]
          }
        ]
      }
      if (chart.current !== undefined) {
        // we need to forcefully remove the series
        for (let i = 0; i < chart.current.chart.series.length; i++) {
          chart.current.chart.series[i].remove(true)
        }
      }
      fetchChart(config, this_filters).then(options => {
        setOptions({
          init: true,
          ...options
        })
      })
    }
  }, [selectedLine]) //Test in progress

  useEffect(() => {
    //console.log('Chart::useEffect config.size', config.size)
    fetchChart(config, filtersForRequest).then(options => {
      setOptions({
        init: true,
        ...options
      })
      if (config.type === SERIES_TYPE.PIE && redrawPieLabel) {
        redrawPieLabel(chart)
      }
    }
    )
    setDisplayConfiguration(isFullScreen)
  }, [config.size])

  //Triggered after the modification of dates in the datepicker (or timezone, through settings)
  useEffect(() => {
    if (options.init) {
      redraw()
      let chartObj = chart.current.chart;
      chartObj.showLoading();
      //console.log('FETCH SERIES - useEffect filters dates')
      fetchSeries(config, filtersForRequest).then(series => {
        //Update status code label
        applyDisplayAssociation(config, series)
        setOptions(affectSerie(options, series))
        if (config.type === SERIES_TYPE.PIE && redrawPieLabel) {
          //console.log('USE EFFECT - REDRAW PIE - filters dates !!!')
          redrawPieLabel(chart)
        }
        chartObj.hideLoading();
      })
    }
  }, [period, periodZoom, appliedFilters])

  //Triggered in the context of split data for charts (To complete)
  useEffect(() => {
    if (optionsConfig.isChanged) {
      if (optionsConfig.active) {
        if (optionsConfig.compare.selected) {
          fetchSeriesWithCompare(config, filtersForRequest, optionsConfig.compare).then(
            newSeries => {
              setOptions(affectSerie(options, newSeries))
            }
          )
        } else if (optionsConfig.split.selected) {
          // split by GUI
          fetchSeriesWithSplit(config, filtersForRequest, optionsConfig.split)
          .then(newSeries => {
            // add dimension of split to apply display_association
            applyDisplayAssociation(config, newSeries, optionsConfig.split.filterSelected.column)
            setOptions(affectSerie(options, newSeries))
          } )
        }
      } else {
        fetchSeries(config, filtersForRequest).then(series => {
          applyDisplayAssociation(config, series)
          // &&
          setOptions(affectSerie(options, series))
        })
      }
    }
  }, [optionsConfig])

  const addPictoForST = () => {
    if (chart.current?.chart.series[8]) {
      updateNetworkEventSerieMarker(chart.current.chart.series[8])
    }
  }

  const print = () => {
    if (chart.current?.chart.print) {
      chart.current.chart.print()
    }
  }

  const exportGraph = (graph, type, filename) => {
    if (graph.current?.chart.exportChart) {
      graph.current.chart.exportChart({type, filename})
    }
  }

   const exportGraphCSV = (graph) => {
      if (graph && graph.current && graph.current.chart) {
        graph.current.chart.update({
          exporting: {
            filename: exportingFilename
          }
        });
        graph.current.chart.downloadCSV();
    }
  }

  const exportPNG = () => exportGraph(chart, 'image/png', 'chart')
  const exportJPEG = () => exportGraph(chart, 'image/jpeg', 'chart')
  const exportPDF = () => exportGraph(chart, 'application/pdf', 'chart')

  const exportCSV = () => exportGraphCSV(chart)
  // const exportXML = () => exportGraphCSV(chart)

  const openOptions = () => {
    if (!isFullScreen) {
      setDisplayConfiguration(!displayConfig)
    }
  }

  const selectionChange = (event) => {
    let limit = 3600000 // limit is one hour (3600sec *1000 to get it in ms)
    if (Array.isArray(event.xAxis) && event.xAxis[0] && event.xAxis[0].min && event.xAxis[0].max) {

      //  change zoom if range is too small
      let range = event.xAxis[0].max - event.xAxis[0].min
      if (range < limit){
        // limit zoom to 1 hour
        event.xAxis[0].max = event.xAxis[0].min + limit
      }

      const date = toISOString({
        duration: 'day',
        startdate: event.xAxis[0].min,
        enddate: event.xAxis[0].max
      })
      console.log(date)
      dispatch(updatePeriodZoom(date))
      console.log(periodZoom)
      dispatch(updatePeriodQueries())

    }
  }

  const resetZoom = () => {
    dispatch(resetPeriodZoom())
  }

  function getDefaultChart (Highcharts, options, chart) {
    //console.log('CHART - getDefaultChart !!!')
      options = { ...options,   "loading": {
        "labelStyle": {
          "color": "white"
        },
        "style": {
          "backgroundColor": "transparent",
          "opacity":0.8
        }
      }
    }
    return (<HighchartsReact highcharts={Highcharts} options={options} ref={chart} />)
  }

  let redrawPieLabel = null

  const getChart = (config, options) => {
    // affect event for detect when zoom change
    const events = { selection: selectionChange }

    // Conditions to get noDataError from api. For the Component <Errors>
    let noData = ''
    if (options && options.series && options.series[0] && options.series[0].noData)
    {
      noData = options.series[0].noData
    }

    if (config && config.limit_to_one_filter_type && config.limit_to_one_filter_type === true &&
      (appliedFilters.length > 1 ||  (appliedFilters.length === 1 && appliedFilters[0].values.length > 1  )))  {
        // if only one filter is apllicable on current graph then an error will be displayed but reset the series so that no graph is displayed
      options.series=[]
    }
    switch (config.type) {
      case SERIES_TYPE.MAPWORLD:
        options.chart = options.chart ? { ...options.chart, events } : { events }
         return (
           // TODO add TableRegion for Maps when clicked country
          <div>
            <Errors dataError={noData} config={config} appliedFilters={appliedFilters}></Errors>
            <MapChart config={config} chart={chart} filters={filtersForRequest} />
          </div>
        )
      case SERIES_TYPE.PIE:
        return (

          <div className='pieContainer'>
            <Errors dataError={noData} config={config} appliedFilters={appliedFilters}></Errors>
            <div className='pie-chart'>{getDefaultChart(Highcharts, options, chart)}</div>
            <PieLegend chart={chart} getRedraw={(redraw) => { redrawPieLabel = redraw }} />
          </div>
        )
      case SERIES_TYPE.AREA:
      case SERIES_TYPE.SOLIDLINE:
      case SERIES_TYPE.DASHLINE:
      case SERIES_TYPE.LINE:
      case SERIES_TYPE.STACKBARSTIMESTAMPS:
      case SERIES_TYPE.STACKCOLUMNSTIMESTAMPS:
      case SERIES_TYPE.STACKCOLUMNSTIMESTAMPSPERCENT:
        options.chart = options.chart ? { ...options.chart, events } : { events }
        return (
          <div className="errorBlock">
            {//replacing the periodZoom boolean with a condition on period date strings
              !(periodZoom.enddate==='false' && periodZoom.startdate==='false')
              &&
              <BkButton className='bkButton--mini' onClick={resetZoom}>Reset Zoom</BkButton>
            }
            <Errors dataError={noData} config={config} appliedFilters={appliedFilters}></Errors>
            {getDefaultChart(Highcharts, options, chart)}
          </div>
        )
      case SERIES_TYPE.SESSIONTRACKER:
        isSessionTracker.current = true;
        addPictoForST()
        return (
          <div>
            {
              !(periodZoom.enddate==='false' && periodZoom.startdate==='false')
              &&
              <BkButton className='bkButton--mini' onClick={resetZoom}>Reset Zoom</BkButton>
            }
            <Errors dataError={noData} config={config} appliedFilters={appliedFilters}></Errors>
            {getDefaultChart(Highcharts, options, chart)}
          </div>
        )
      default:
        return (
          <div>
            {
              !(periodZoom.enddate==='false'&&periodZoom.startdate==='false')
              &&
              <BkButton className='bkButton--mini' onClick={resetZoom}>Reset Zoom</BkButton>
            }
            <Errors dataError={noData} config={config} appliedFilters={appliedFilters}></Errors>
            {getDefaultChart(Highcharts, options, chart)}
          </div>
        )
    }
  }

  const getSeriesInConfigChart = option => {
    const series = []
    let index = 0;

    if (Array.isArray(option.list)) {
      option.list.forEach(list => {
        if (Array.isArray(list.series)) {
          list.series.forEach(serie => {
            const value =
            {
              key: index,
              label: serie.name
            }
            series.push(value)
            index++
          })
        }
      })
    }
    return series
  }

  return (
    <div className='bka-chart'>
      <div className='widgetSection'>
        {displayHeader && (
          <Header
            print={print}
            png={exportPNG}
            pdf={exportPDF}
            jpeg={exportJPEG}
            csv={exportCSV}
            series={getSeriesInConfigChart(config)}
            // xml={exportXML}
            onFullScreen={onFullScreen}
            config={config}
            openOptions={openOptions}
            displayConfig={displayConfig}
            isFullScreen={isFullScreen}
            disableSplitButton={config.type === SERIES_TYPE.SESSIONTRACKER
                              || config.type === SERIES_TYPE.PIE
                              || config.type === SERIES_TYPE.POLARLINE
                              || config.type === SERIES_TYPE.POLARAREA
                              || config.type === SERIES_TYPE.GAUGE
                              || config.type === SERIES_TYPE.STACKCOLUMNSCATEGORIES
                              || config.type === SERIES_TYPE.STACKCOLUMNSTIMESTAMPS
                              || config.type === SERIES_TYPE.STACKBARSCATEGORIES
                              || config.type === SERIES_TYPE.STACKBARSTIMESTAMPS
                              || config.type === SERIES_TYPE.STACKCOLUMNSTIMESTAMPSPERCENT
                              || config.type === SERIES_TYPE.MAPWORLD}
          />
        )}
        {getChart(config, options)}
      </div>
      {displayConfig && config.type !== SERIES_TYPE.SESSIONTRACKER
                      && config.type !== SERIES_TYPE.PIE
                      && config.type !== SERIES_TYPE.POLARLINE
                      && config.type !== SERIES_TYPE.POLARAREA
                      && config.type !== SERIES_TYPE.GAUGE && (
        <div className='widgetSection'>
          <Configuration
            setDisplay={setDisplayConfiguration}
            config={config}
            isFullScreen={isFullScreen}
            optionsChange={setOptionsConfig}
          />
        </div>
      )}
    </div>
  )
}

export default Chart
