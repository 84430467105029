import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import BkSelect from '../../components/forms/Select'
import BkTextField from '../../components/forms/TextField'
import { selectAppliedFilters } from './store/FiltersSlice'
import * as _ from 'lodash'

export function IndicatorFilter ({ options, onChange }) {
  const [filterName, setFilterName] = useState('')
  const [filterCompare, setFilterCompare] = useState('<')
  const [filterValue, setFilterValue] = useState('0')
  const appliedFilters = useSelector(selectAppliedFilters)

  function submitHandler () {
    if (!filterName || _.isEmpty(filterValue)) {
      console.log('error no filter selected')
      return
    }

    const _filterName = filterName.columnName
    //If an identical filter exists, get it to concatenate new "values" in the original filter
    const filter = appliedFilters.find(f => f.name === _filterName)
    //needed to get the id of this filter, not just its name
    const newFilter = options.find(f => f.columnName === _filterName)

    if (filter && Array.isArray(filter.values)) {
      onChange(filter.id, _filterName, _filterName, [
        ...filter.values.filter(f => f.operator !== filterCompare),
        { operator: filterCompare, value: filterValue }
      ])
    } else {
      onChange(newFilter.id, _filterName, _filterName, [{ operator: filterCompare, value: filterValue }])
    }
  }
  return (
    <div className='indicatorFilter'>
      <BkSelect
        title='Indicators'
        selected={filterName}
        legacy='filters'
        options={options}
        optionConfig={{ value: 'id', label: 'title' }}
        onChange={setFilterName}
      />
      <Grid container alignItems='center'>
        <Grid item xs={4}>
          <BkSelect
            item
            options={['<', '>', '=', '!=', '<=', '>=']}
            selected={filterCompare}
            onChange={setFilterCompare}
          />
        </Grid>
        <Grid item xs={6}>
          <BkTextField
            onChange={e => {
              if (!_.isEmpty(e.target.value) && e.target.value !== '') {
                setFilterValue(e.target.value)
              }
            }}
            value={filterValue} //To fix a warning
            type='number'
            placeholder='Number'
          />
        </Grid>
        <Grid item xs={2}>
          <button
            className='bkButton bkButton--mini bkButton--alternate'
            type='button'
            onClick={submitHandler}
          >
            +
          </button>
        </Grid>
      </Grid>
    </div>
  )
}
