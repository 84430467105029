import React from 'react'

const CurrentFiltersCount = ({ filters }) => {
  return (
    <>
      {filters && filters.length > 0 && (
        <span className='currentFiltersCount'>
          <span className='currentFiltersCount__badge'>{filters.length}</span>
        </span>
      )}
    </>
  )
}

export default CurrentFiltersCount
