import { createTheme } from '@material-ui/core/styles'

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#33c3eb',
      main: '#00B5E6',
      dark: '#007ea1',
      contrastText: '#fff'
    },
    secondary: {
      light: '#4f5356',
      main: '#23282C',
      dark: '#181c1e',
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: ['Calibri', 'Arial', 'sans-serif'].join(',')
  }
})

export { darkTheme }
