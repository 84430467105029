import { Grid } from '@material-ui/core'
import * as _ from 'lodash'
import React, { useState } from 'react'
import '../dashboards/styles/widget.scss'
import UsersDataList from './DataList/UsersDataList'
import GroupsDataList from './DataList/GroupsDataList'
import FullScreen from '../../components/FullScreen'

export const TYPES = {
  LIST: 'list',
  LIST_GROUP: 'list_group'
}

//Widget dedicated to loading data from the "user" webservice (in opposition to data from the "requestor")
// based on "Widget.js"
const UserDataWidget = ({ config, ...props }) => {
  const [fullScreen, setFullScreen] = useState(false)
  const sizes = {
    superSmall: 2,
    extraSmall: 3,
    small: 4,
    medium: 6,
    large: 8,
    full: 12
  }
  const size = _.get(config, 'size')
  const xs = _.get(sizes, size) || 12
  const isInvisible = _.get(config, 'type') === 'summary'


  //Selection of the hook used to load data to be displayed in a table (type specified in "XX-admin.json")
  const getWidgetByConfig = config => {
    switch (config.type) {
      case TYPES.LIST:
        return <UsersDataList config={ config } displayHeader />
      case TYPES.LIST_GROUP:
        return <GroupsDataList config={ config } displayHeader />
      default:
        return <div>types widget unknown {config.type}</div>
    }
  }

  return (
    <Grid item xs={xs}>
      {config && (
        <div
          className={`widget ${
            isInvisible ? 'widget--invisible' : ''
          } ${'widget--' + size}`}
        >
          <div className='widget__content'>{getWidgetByConfig(config)}</div>

          {fullScreen && (
            <FullScreen onClose={() => setFullScreen(false)}>
              {getWidgetByConfig(config)}
            </FullScreen>
          )}
        </div>
      )}
    </Grid>
  )
}

export default UserDataWidget
