import React, { useEffect, useState } from 'react'
import './styles/paginator.scss'
import BkButton from './Button'
import { useSelector } from 'react-redux'
import { selectAppliedFilters } from '../features/filters/store/FiltersSlice'


const Paginator = ({ limit, total, onChangePage }) => {

  const appliedFilters = useSelector(selectAppliedFilters)

  const [pager, setPager] = useState({
    currentPage: 1,
    pageCount: 0
  })

  const constructPages = (limit, total) => {
    const pageCount = Math.ceil(total / limit)
    setPager({ ...pager, pageCount })
  }

  const setPage = page => {
    setPager({ ...pager, currentPage: page })
    const offset = (page - 1) * limit
    onChangePage(offset)
  }

  const previousPage = () => {
    if (pager.currentPage > 1) {
      setPage(pager.currentPage - 1)
    }
  }
  const nextPage = () => {
    if (pager.currentPage < pager.pageCount) {
      setPage(pager.currentPage + 1)
    }
  }
  const lastPage = () => {
    if (pager.currentPage < pager.pageCount) {
      setPage(pager.pageCount)
    }
  }

  useEffect(() => {
    // Re-initialize paginator to page 1 when filter is applied
    if (appliedFilters.length > 0)
    {
      return (
        setPager({ ...pager, currentPage: 1 , pageCount: Math.ceil(total / limit) })
      )
    }
    constructPages(limit, total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, total])

  return (
    <div className='paginator'>
      <div className='paginator__action'>
        <BkButton className='bkButton--mini' onClick={() => setPage(1)}>
          First
        </BkButton>
        {pager.currentPage - 1 > 0 && (
          <BkButton className='bkButton--mini' onClick={previousPage}>
            Previous
          </BkButton>
        )}
      </div>
      <div className='paginator__pages'>
        {pager.currentPage - 2 > 0 && (
          <BkButton
            className='bkButton--mini'
            onClick={() => setPage(pager.currentPage - 2)}
          >
            {pager.currentPage - 2}
          </BkButton>
        )}
        {pager.currentPage - 1 > 0 && (
          <BkButton
            className='bkButton--mini'
            onClick={() => setPage(pager.currentPage - 1)}
          >
            {pager.currentPage - 1}
          </BkButton>
        )}
        <BkButton className='bkButton--mini bkButton--mini-current'>
          {pager.currentPage}
        </BkButton>
        {pager.currentPage < pager.pageCount && (
          <BkButton
            className='bkButton--mini'
            onClick={() => setPage(pager.currentPage + 1)}
          >
            {pager.currentPage + 1}
          </BkButton>
        )}
        {pager.currentPage + 1 < pager.pageCount && (
          <BkButton
            className='bkButton--mini'
            onClick={() => setPage(pager.currentPage + 2)}
          >
            {pager.currentPage + 2}
          </BkButton>
        )}
      </div>

      <div className='paginator__action'>
        {pager.currentPage + 1 <= pager.pageCount && (
          <BkButton className='bkButton--mini' onClick={nextPage}>
            Next
          </BkButton>
        )}
        <BkButton className='bkButton--mini' onClick={lastPage}>
          Last
        </BkButton>
      </div>
    </div>
  )
}

export default Paginator
