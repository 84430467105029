import * as _ from 'lodash'
import bkApi from './bk-api'
import { AUTH_MODE,AUTH_MODE_LEGACY } from '../environment'
const initSession = async () => {
  console.log("init session")
  if (AUTH_MODE !== AUTH_MODE_LEGACY){
    console.log("init session not legacy")
    // If not legacy then login is done by auth module so login mechanism is not the same
    removeSession() // remove session if one was stored
    const req = await loginNotLegacy()
    const user = _.get(req, ['data', 'user'])
    const token = _.get(req, ['data', 'token'])
    if (token){
      saveSession(token)
    }
    return user
  } else if (isLogged()) {
    const req = await currentUser()
    const user = _.get(req, ['data', 'user'])
    return user
  }
  return false
}

const login = (credentials) => {
  return bkApi.post('/v1/auth/login', {
    username: credentials.username ? credentials.username : null,
    password: _.isString(credentials.password) ? credentials.password : null
  })
}


const loginNotLegacy = () => {
  return bkApi.post('/v1/auth/loginNotLegacy', {})
}


const changePassword = (newPassword, email, token) => {
  //Changement in case a session exists, using an email and a session token
  console.log('auth.service::changePassword - newPassword, email, token', newPassword, email, token)
  if (token && email) {
    return bkApi.post('/v1/auth/resetPassword', {
      params: {
        email: _.isString(email) ? email : null,
        token: _.isString(token) ? token : null,
        password: _.isString(newPassword) ? newPassword : null
      }
    })
  }
  //Else => //no email or token available. Using the session informations
  console.log('auth.service::changePassword [newPassword,email,token] ', newPassword, email, token)
  return bkApi.post('/v1/auth/changePassword', {
    password: _.isString(newPassword) ? newPassword : null
  })
}

const checkForgotToken = (credentials) => {
  //
  return bkApi.post('/v1/auth/checkForgotToken', {
    params: {
      email: _.isString(credentials.email) ? credentials.email : null,
      token: _.isString(credentials.token) ? credentials.token : null
    }
  })
}

//Change expired password (check of old email, and no valid session active)
const changeExpiredPassword = (email, oldPassword, newPassword) => {
  console.log('auth.service::changeExpiredPassword [email, oldPassword, newPassword] ', email, oldPassword, newPassword)
  return bkApi.post('/v1/auth/changeExpiredPassword', {
    params: {
      email: _.isString(email) ? email : null,
      oldpassword: _.isString(oldPassword) ? oldPassword : null,
      newpassword: _.isString(newPassword) ? newPassword : null
    }
  })
}

//Ask for a reset of password, using an email only (forgottent pwd)
const resetPassword = (email) => {
  console.log('auth.service::resetPassword email', email)

  return bkApi.post('/v1/auth/resetPassword', {
    params: {
      email: _.isString(email) ? email : null,
      host: window.location.origin
    }
  })
}

const logout = () => {
  return bkApi.get('/v1/auth/logout')
}

const currentUser = () => {
  return bkApi.get('/v1/auth/current')
}


const saveSession = session => {
  localStorage.setItem('bk_session', JSON.stringify(session))
}

const removeSession = () => {
  localStorage.removeItem('bk_session')
}

const getSession = () => {
  const session = localStorage.getItem('bk_session')
  if (!_.isUndefined(session) && session !== "undefined"){
    return JSON.parse(session)
  }
  return false 
}

const isLogged = () => {
  return !!getSession()
}

export {
  login,
  logout,
  changePassword,
  checkForgotToken,
  changeExpiredPassword,
  resetPassword,
  currentUser,
  initSession,
  removeSession,
  getSession,
  saveSession,
  isLogged
}
