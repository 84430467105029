import { encrypter } from "../../services/encrypter.service";
import React, { useState, useEffect } from "react";
import BkButton from "../../components/Button";
import BkAlert from '../../components/Alert';
import BkTextField from '../../components/forms/TextField';
import {Grid, CircularProgress } from "@material-ui/core";
import * as PageService from "../../services/pages.service";
import Widget from "../dashboards/Widget";
import { useTranslation } from 'react-i18next'

import "./styles/anonymization.scss";

const Anonymization = ({mainMenu, subMenu}) => {
  const { t } = useTranslation()

  const path = [mainMenu, subMenu];
  const [valueEncrypted, setValueEncrypted] = useState(null);
  const [valueToEncrypt, setValueToEncrypt] = useState(null);
  const [valueDecrypted, setValueDecrypted] = useState(null);
  const [valueToDecrypt, setValueToDecrypt] = useState(null);

  //const [test, setTest] = useState("");

  // const [state, setState] = React.useState({
  //   processing:false
  // })
  const [template, setTemplate] = useState(null);
  const [state, setState] = React.useState({
    messages: [],
    severity: 'info',
    processingEncryption: false,
    processingDecryption: false
  })

  const catchError = (error, req) => {
    const message = []
    setState({ ...state, processingEncryption: false, processingDecryption:false })
    setValueEncrypted("")
    setValueDecrypted("")
    if (error.response && error.response.data && error.response.data.errors) {
      error.response.data.errors.map((msg) => (
        message.push(msg.message)
      ))
      setState({ ...state, messages:message, severity:'error' })
    } else {
      message.push(error.response ? error.response.statusText : [])
      setState({ ...state, messages:message, severity:'error' })
    }
  }

  const encrypt = () => {
    setState({ ...state, processingEncryption: true })
    encrypter({ action: "encrypt", data: valueToEncrypt }).then((res) => {
      setState({ ...state, messages:[], severity:'info', processingEncryption: false })
      if (res && res.data && res.data.data && res.data.data.data) {
        setValueEncrypted(res.data.data.data);
      }
    }).catch(catchError);
  };

  const decrypt = () => {
    setState({ ...state, processingDecryption: true })

    encrypter({ action: "decrypt", data: valueToDecrypt }).then((res) => {
      setState({ ...state, messages:[], severity:'info', processingDecryption: false })
      if (res && res.data && res.data.data && res.data.data.data) {
        setValueDecrypted(res.data.data.data);
      }

    }).catch(catchError);
  }

  useEffect(() => {
    const getTemplate = async () => {
      const { data } = await PageService.getPage(path[1]);
      setTemplate(data);
      console.log("[Anonymiszation] reloading=")
    };
    getTemplate();
  }, [valueEncrypted, valueDecrypted]);

  const processing = <CircularProgress />

  const displayMessages = (
    <BkAlert severity={state.severity} className='input'>
      {state &&
      state.messages &&
      state.messages.length > 0 &&
      state?.messages?.map((msg, i) => (
        <div key={i}>{t('errorsEncrypter:' + msg) ? t('errorsEncrypter:' + msg) : msg}</div>
      ))}
    </BkAlert>
  )
  const resultEncryptionForm = (
    <div className="resultField"> {valueEncrypted ? valueEncrypted : ""}</div>
  )

  const resultDecryptionForm = (
    <div className="resultField"> {valueDecrypted ? valueDecrypted : ""}</div>
  )

  return (
    <div>
      <div className="pageContainer__section">
        <header className="pageContainer__header">
          <h1 className="pageContainer__title">
            <img
              src={require("../../assets/icons/key.svg").default}
              alt="Bka100"
              width="30"
              height="15"
            />
            Encryption / Decryption
          </h1>
        </header>
        <Grid item container spacing={3}>
          <Grid item xs={6}>
            <div className="widget">
              <div className="anonymizationWidget__header">
                <h2 className="Subtitle">Cipher data</h2>
              </div>
              <BkTextField
                size="small"
                className="textareaField"
                id="outlined-basic"
                placeholder="Enter here the data to decrypt"
                variant="outlined"
                onChange={(e) => setValueToDecrypt(e.target.value)}
              />
              <BkButton
                className="anonymizationButton"
                onClick={() => decrypt()}
                variant="contained"
                color="primary"
              >
                Decrypt
              </BkButton>
              <div className="resultField">Result: </div>
              {state.processingDecryption ?  processing : resultDecryptionForm}

            </div>
          </Grid>

          <Grid item xs={6}>
            <div className="widget">
              <div className="anonymizationWidget__header">
                <h2 className="Subtitle">Clear data</h2>
              </div>
              <BkTextField
                size="small"
                className="textareaField"
                id="outlined-basic"
                placeholder="Enter here the data to encrypt"
                variant="outlined"
                onChange={(e) => setValueToEncrypt(e.target.value)}
              />
              <BkButton
                className="anonymizationButton"
                onClick={() => encrypt()}
                variant="contained"
                color="primary"
              >
                Encrypt
              </BkButton>
              <div className="resultField">Result: </div>
              {state.processingEncryption ?  processing : resultEncryptionForm}
            </div>
          </Grid>
        </Grid>
        {state.messages.length > 0 ? displayMessages : null}

        { <div className="nextWidget">
          {template &&
            template.contentBlocks.map((config, i) => (
              <Widget key={i} config={config} />
            ))}
        </div> }
      </div>
    </div>
  );
};

export default Anonymization;
