import React, { useMemo } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useLegacy from '../../i18n/legacyEffect'

export const ExplanationInteraction = ({ explanation, component }) => {
  const { tLegacy } = useLegacy()
  const options = useMemo(() => {
    const data = explanation?.interactions?.[component]
    if (!data) {
      return null
    }

    // Flatten data, from [[a, b, c], [d, e, f]] to [[0, 0, a], [0, 1, b], [0, 2, c], [1, 0, d], ...]
    const scores = data.score.map((row, x) => row.map((score, y) => ([x, y, score]))).flat(1)
    const min = scores.reduce((acc, [x, y, s]) => Math.min(acc, s), Infinity)
    const max = scores.reduce((acc, [x, y, s]) => Math.max(acc, s), -Infinity)
    const lim = Math.max(-min, max)

    const subcomponents = component?.split(' x ') || []

    return {
      chart: {
        type: 'heatmap',
        height: 800,
      },

      exporting: {
        enabled: false
      },

      xAxis: {
        categories: data?.x.map(c => tLegacy(subcomponents[0], c)),
        min: 0,
        max: data?.x.length-1,
        minorTickLength: 0,
        tickLength: 0,
      },

      yAxis: {
        categories: data?.y.map(c => tLegacy(subcomponents[1], c)),
        min: 0,
        max: data?.y.length-1,
        minorTickLength: 0,
        tickLength: 0,
      },

      colorAxis: {
        stops: [
          [0, '#c4463a'],
          [0.1, '#c4463a'],
          [0.5, '#fffbbc'],
          [1, '#3060cf'],
        ],
        min: -lim,
        max: lim,
        startOnTick: false,
        endOnTick: false,
      },

      series: [{
        name: 'Score',
        borderWidth: 0,
        pointPadding: 0,
        data: scores,
        align: 'center',
        tooltip: {
          pointFormatter: function() {
            const x = tLegacy(subcomponents[0], data.x[this.x])
            const y = tLegacy(subcomponents[1], data.y[this.y])
            return `(${x}) x (${y}): ${this.value}`
          },
        },
      }],
    }
  }, [tLegacy, explanation, component])

  return <>
      <header className='widget__header'>
        <h2 className='widget__title'>Explainability score by pair of values</h2>
      </header>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
}

export default ExplanationInteraction