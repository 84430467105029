import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CustomDashboardEditor from './CustomDashboardEditor'
import CustomDashboardViewer from './CustomDashboardViewer'
import CustomDashboardImport from './CustomDashboardImport'

import NewDashboardModal from './NewDashboardModal'

const CustomDashboardManager = () => {
  return (
    <Switch>
      <Route exact path='/dashboards/new'>
        <NewDashboardModal />
      </Route>
      <Route path='/dashboards/import'>
        {/* Si customdashboard import existe alors ouvrir NewDashboard modal <NewDashboardModal />*/}
        <CustomDashboardImport />
      </Route>
      <Route path='/dashboards/:id/edit'>
        <CustomDashboardEditor />
      </Route>
      <Route path='/dashboards/:id'>
        <CustomDashboardViewer />
      </Route>
     
    </Switch>
  )
}

export default CustomDashboardManager
