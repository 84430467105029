import { createSlice } from '@reduxjs/toolkit'
import * as _ from 'lodash'

/**
 *
 * @type {
 *    {
 *      timeZone: string,
 *      email: boolean,
 *      sms: boolean,
 *    }
 *  }
 */
const initialState = {
    timeZone: 'UTC',
    email: false,
    sms: false
}

export const SettingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateSettings: (state, action) => {
            return {
                ...state,
                //settings: action.payload,
                timeZone: action.payload.timeZone,
                email: action.payload.email,
                sms: action.payload.sms,
                gui_settings: action.payload.gui_settings
            }
        },
        updateSettingsTimezone: (state, action) => {
            if (_.isEmpty(action.payload)) {
                return state
            }
            return { ...state, timeZone: action.payload }
        },

        updateSettingsEmail: (state, action) => {
            if (_.isEmpty(action.payload)) {
                return state
            }
            return { ...state, email: action.payload }
        },
        updateSettingsSms: (state, action) => {
            if (_.isEmpty(action.payload)) {
                return state
            }
            return { ...state, sms: action.payload }
        },
        updateSettingsGui: (state, action) => {
            return {
                ...state,
                gui_settings: action.payload
            }
        },
        setSettingsTimeZone: (state, action) => {
            return { ...state, timeZone: action.payload }
        },

        setSettingsEmail: (state, action) => {
            return { ...state, email: action.payload }
        },
        setSettingsSms: (state, action) => {
            return { ...state, sms: action.payload }
        }
    }
})

export const {
    updateSettings,
    updateSettingsTimeZone,
    updateSettingsEmail,
    updateSettingsSms ,
    updateSettingsGui
} = SettingsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectSettings = state => state.settings

export const selectSettingsTimeZone = state => state.settings.timeZone
export const selectSettingsEmail = state => state.settings.email
export const selectSettingsSms = state => state.settings.sms
export const selectGuiSettings = state => state.settings.gui_settings

export default SettingsSlice.reducer
