import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchOption, fetchSeries, fetchMap } from '../hooks/ChartsEffects'
import {   getFilterForRequest,
  selectPeriodZoom,
  selectPeriod,
  selectAppliedFilters,
  selectTimeZone } from '../../filters/store/FiltersSlice'
import BkButton from '../../../components/Button'
import * as _ from 'lodash'
import '../styles/map.scss'

//TODO refresh map when period and filter change

const MapChart = ({ config, chart }) => {
  var oriOptions=  {
    "loading": {
      "labelStyle": {
        "color": "white"
      },
      "style": {
        "backgroundColor": "transparent",
        "opacity":0.8
      }
    },
    chart: {
      events: {
        drillup:  function(e) {
         // console.log("mapChart::fetchMaps: drillup")
          setCountry(null);
        },
        drilldown: async function(e) {
            //console.log("mapChart::fetchMaps: drilldown 2"+ e.point.drilldown)
            setCountry(e.point.drilldown)
        }

      }
    },
    series: [{}]
  }
  const [options, setOptions]  = useState({...oriOptions})
  const periodZoom = useSelector(selectPeriodZoom)
  const period = useSelector(selectPeriod)
  const appliedFilters = useSelector(selectAppliedFilters)
  const timezone = useSelector(selectTimeZone)
  const filtersForRequest = getFilterForRequest(periodZoom, period, appliedFilters, timezone)
  const [country, setCountry]  = useState(config.list[0].series[0].map)
  //console.log("render highchart component")

  const fetchChart = async (params, filter) => {
    let res = 0
    chart.current.chart.showLoading()
    try {
      // first get map
      let name = "world"
      if (country && country !== "world"){// map is the country if a country is selected else its world
        name = country
      }
      if (!Highcharts.maps[name]){ //map not yet downloaded
        console.log("download new map:"+name)
        res = await fetchMap(name)
        Highcharts.maps[name]= res // store map to avoid download it several time
      }

      let newConfig = _.cloneDeep( config) // copy config to add filter in case of country selected
      if (country&& country !== "world"){// add the country filter if map country is selected to get series data
        let newFilter = {
          name: params.list[0].series[0].dimension,
          values: [{ operator: '==', value: country }]
        }

        filtersForRequest.filters = [...filtersForRequest.filters, newFilter]
        newConfig.list.map(list => {
          list.series.map(serie =>{
            serie.dimension = serie.subDimension
            delete serie.subDimension
          })
        })
      }

      res = await Promise.all([
          fetchOption(newConfig, filter),
          fetchSeries(newConfig, filter),
        ])

      let rcvOption = _.get(res, [0])
      let newOptions = {...rcvOption, ...oriOptions}

      var serie = _.get(res, [1])

      // format map to a highchart comprehensible data
      var data = Highcharts.geojson(Highcharts.maps[name])
      // Set drilldown pointers
      data.forEach(function(el, i) {
        let val = 0
        if (!country ||  country === "world"){  // in case of world to display should get the hc-a2 key
          val = serie[0].data.filter(elt => elt.code === el.properties["hc-a2"])
          if (val.length > 0) // use drilldown only if some value on this country
            el.drilldown = el.properties["hc-a2"];
        } else { // No drilldown if already country selected and use of hc-ac3 to link region
          val = serie[0].data.filter(elt => elt.code === el.properties["hc-a3"])
        }

        if (val.length === 0)
          el.value =  0
        else
          el.value =  val[0].value// Non-random bogus data
      });

      newOptions.series[0].data=data
      newOptions.series[0].name=serie[0].name
      newOptions.series[0].tooltip = serie[0].tooltip
      newOptions.series[0].unit = serie[0].unit

     // mapChart.hideLoading()
      //console.log(newOptions)
      setOptions(newOptions)
      chart.current.chart.hideLoading()
     // chart.current.chart.showDrillUpButton()
    } catch (e) {
      console.error('fetchChart error', e)
      chart.current.chart.showLoading("Map  "+ country + " not available ...")
    }
  }

  //get char on first call?
  useEffect(() => {
    //console.log("reload map")
    fetchChart(config, filtersForRequest)
  }, [country, period, periodZoom, appliedFilters])

  const zoom = (graph, ratio) => {
    if (graph.current.chart) {
      graph.current.chart.mapZoom(ratio)
    }
  }

  return (
    <div className='map'>
        <BkButton className='bkButton--mini' onClick={() => zoom(chart, 0.5)}>
          +
        </BkButton>
        <BkButton className='bkButton--mini' onClick={() => zoom(chart, 1.5)}>
          -
        </BkButton>
        <BkButton className='bkButton--mini' onClick={() => zoom(chart)}>
          Reset Map Zoom
        </BkButton>
        {//Back to world button has a weird behavior, zooms out much more than default map
        country && country !== "world" && <BkButton className='bkButton--mini' onClick={() => {setCountry(null);}}>Back to world</BkButton>}
        <HighchartsReact
          constructorType='mapChart'
          highcharts={Highcharts}
          options={options}
          ref={chart}
        />


    </div>
  )
}

export { MapChart }
