import * as _ from 'lodash'
import React, { useState } from 'react'

const PieLegend = ({ chart, getRedraw }) => {
  const [items, setItems] = useState([])

  const generateItems = (chart) => {
    const items = []
    if (_.isArray(chart.current?.chart?.series)) {
      chart.current.chart.series.forEach(serie => {
        if (_.isArray(serie.data)) {
          serie.data.forEach(data => {
            items.push({
              color: data.color,
              name: data.name,
              percentage: data.percentage,
              top: data.top,
              total: data.total
            })
          })
        }
      })
    }
    return items
  }

  if (getRedraw) {
    getRedraw((chart) => {
      setItems(generateItems(chart))
    })
  }

  return (
    <ul className='pieLegend'>
      {items && items.length > 0 && items.map((data, i) => {
        return (
          <li className='pieLegend__item' key={`${data.name}${i}`}>
            <span className='pieLegend__color' style={{ background: data.color }} />
            {data.name} : {Math.round(data.percentage * 100) / 100} %
          </li>
        )
      })}
    </ul>
  )
}

export { PieLegend }
