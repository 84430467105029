import React, { useEffect, useState } from 'react'
import BkFieldSet from './FieldSet'

const BkTextField = ({
  placeholder,
  error,
  label,
  id,
  type,
  onChange,
  helper,
  value,
  disabled,
}) => {
  let inputField = null
  const [v, setValue] = useState('')

  useEffect(() => {
    setValue(value)
  }, [value])

  const onChangeHandler = e => {
    setValue(e.target.value)
    onChange(e)
  }
  return (
    <BkFieldSet
      className='textField'
      title={label}
      helper={helper}
      onFocus={() => {
        if (inputField) {
          inputField.focus()
        }
      }}
    >
      <input
        className='textField__input'
        ref={input => (inputField = input)}
        id={id}
        type={type || 'text'}
        onChange={onChangeHandler}
        placeholder={placeholder || 'Enter text...'}
        value={v}
        disabled={disabled}
      />
    </BkFieldSet>
  )
}

export default BkTextField
