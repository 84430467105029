import { useEffect, useState } from 'react'

import { getGroups } from '../../../../services/group.service'

//Hook needed in UsersDataList.js to load user data
const useGroupsList = config => {
    const [list, setlist] = useState(null)

    useEffect(() => {
      const fetchGroupList = async config => {
        //Request for the list of item to fill the groups table
        const data = await getGroups({
          ...config,
          limit: config.nbItemsPerPage,
          offset: config.offset || 0
        })
        setlist(data)
      }

      fetchGroupList(config)
    }, [config.offset])

    return list
  }

export default useGroupsList