import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sessionDescription } from '../../../services/sessiondescription.service'
import * as _ from 'lodash'
import { logout } from '../../authentication/store/authSlice'
import {
  getFilterForRequest,
  selectPeriodZoom,
  selectPeriod,
  selectAppliedFilters,
  selectTimeZone
} from '../../filters/store/FiltersSlice'
import { selectSelectedLine } from '../../tableList/store/TableSlice'

const useSessionDescription = config => {
  const [sessionDescriptionData, setData] = useState(null)
  const dispatch = useDispatch()
  const period = useSelector(selectPeriod)
  const periodZoom = useSelector(selectPeriodZoom)
  const appliedFilters = useSelector(selectAppliedFilters)
  const timezone = useSelector(selectTimeZone)
  const filtersForRequest = getFilterForRequest(periodZoom, period, appliedFilters, timezone)
  const selectedLine = useSelector(selectSelectedLine())

  useEffect(() => {
      const this_filters = {
        startdate: filtersForRequest.startdate,
        enddate: filtersForRequest.enddate,
        timezone: filtersForRequest.timezone,
        filters: [
          ...filtersForRequest.filters,
          {
            name: "session_id",
            label: "session_id",
            values: [
              {
                value: selectedLine,
                operator: "=="
              }
            ]
          }
        ]
      }

      const getSessionDescData = async () => {
        const response = await sessionDescription({
          ...config
        }, this_filters)
        .catch((error) => {
          if (error.response.status === 403)
          {
            dispatch(logout())
          }
        })
        //console.log(response)
        setData(_.get(response, 'data'))
      }

      getSessionDescData(config)

  }, [selectedLine,period, periodZoom, appliedFilters, timezone])

  return sessionDescriptionData
}

export default useSessionDescription
