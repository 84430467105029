import React, { useState } from 'react'
import BkTextField from '../../components/forms/TextField'
import BkButton from '../../components/Button'

import { addGroup} from '../../services/group.service'
import BkSelect from '../../components/forms/Select'

import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import BkAlert from '../../components/Alert'
import * as _ from 'lodash'

import { useTranslation } from 'react-i18next'

export const CreateGroup = ({ params, close, handleNewGroup }) => {

  const { t } = useTranslation()
  const initialStatus = {
    errors: [],
    processing: false
  }
  const [status, setStatus] = useState(initialStatus)

  //Conditionnal profil list
  const groupType =
  [
    { //0
      type: 0,
      label: 'Operator'
    },
    { //1
      type: 1,
      label: 'Content provider'
    }
  ]

  const [widgetParams, setWidgetParam] = useState(params || {
    enableQuotas: false,
    groupName: '',
    type: _.get(groupType, [0]),
    filter: '',
    supervisorQuotas: 0,
    administratorQuotas: 0
  })

  //Modification handlers
  const onChangeGroupNameHandler = async (groupName) => {
    setWidgetParam({ ...widgetParams, groupName })
  }
  const onChangeTypeHandler = async (type) => {
    setWidgetParam({ ...widgetParams, type })
  }
  const onChangeFilterHandler = async (filter) => {
    setWidgetParam({ ...widgetParams, filter })
  }
  // const onChangeEnableQuotas = async (enableQuotas) => {
  //   setWidgetParam({ ...widgetParams, [enableQuotas.target.name]: enableQuotas.target.checked })
  // }
  // const onChangeSupQuotasHandler = async (supervisorQuotas) => {
  //   setWidgetParam({ ...widgetParams, supervisorQuotas })
  // }
  // const onChangeAdminQuotasHandler = async (administratorQuotas) => {
  //   setWidgetParam({ ...widgetParams, administratorQuotas })
  // }

  //---------------------------------------------------------------------------------------
  //WARNING: Check the need for this
  const history = useHistory()
  const closeHandler = () => {
    if (close) {
      close()
    } else {
      history.push('/login')
    }
  }

  // ---------------------------------------------------------------------------
  //Creation of a new group, with basic check on set/selected parameters
  const createGroup = () => {
    setStatus({ errors: [], processing: true })
    if (widgetParams.groupName === '') {
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'Providing a groupname is mandatory.' }]
      })
    // } else if (widgetParams.enableQuotas === true && (isNaN(widgetParams.supervisorQuotas) || parseInt(widgetParams.supervisorQuotas) <= 0)) {
    //   setStatus({
    //     processing: false,
    //     errors: [{ code: 0, message: 'Supervisor quota must be a positive number.' }]
    //   })
    // } else if (widgetParams.enableQuotas === true && (isNaN(widgetParams.administratorQuotas) || parseInt(widgetParams.administratorQuotas) <= 0)) {
    //   setStatus({
    //     processing: false,
    //     errors: [{ code: 0, message: 'Administrator quota must be a positive number.' }]
    //   })
    } else { //groupName, groupType, filterName, filterValue, quotaOperator, quotaAdmin} )
      var filterName = '';
      var filterValue = '';
      if (widgetParams.type.type === 1) {
        filterName = 'provider_name'; // for the moment only provider name is supported
        filterValue = widgetParams.filter;
      }
      //if the new group is successfully added then refresh the table so that changes are displayed
      // addGroup(widgetParams.groupName, widgetParams.type.type, filterName, filterValue, widgetParams.supervisorQuotas, widgetParams.administratorQuotas).then(
      addGroup(widgetParams.groupName, widgetParams.type.type, filterName, filterValue).then(
        () => {
        setStatus({ errors: [], processing: false })
        handleNewGroup(true)
        closeHandler()
      }
      ).catch((error) => {
        setStatus({
          processing: false,
          errors: error.response ? error.response.data.errors : []
        })
        //if the group is not successfully added there is no need to actualize the table
        handleNewGroup(false)
      } )
      handleNewGroup(false)
    }
  }

  const manageContentProvider = () => {
    if (widgetParams.type.type === 1) {
      return (
        <BkTextField
        placeholder='Content Provider filter name'
        label='Content Provider name'
        id='ContentProviderName'
        onChange={(e) => onChangeFilterHandler(e.target.value)}
      />
      )
    }
    return
  }

  // const manageQuotasSupervisor = () => {
  //   if (widgetParams.enableQuotas === true) {
  //     return (
  //     <BkTextField
  //       placeholder='Supervisors allowed'
  //       label='Supervisors allowed'
  //       value={widgetParams.supervisorQuotas}
  //       onChange={(e) => onChangeSupQuotasHandler(e.target.value)}
  //     />
  //     )
  //   }

  // }
  // const manageQuotasAdministrator = () => {
  //   if (widgetParams.enableQuotas === true) {
  //     return (
  //     <BkTextField
  //       placeholder='Admistrators allowed'
  //       label='Admistrators allowed'
  //       value={widgetParams.administratorQuotas}
  //       onChange={(e) => onChangeAdminQuotasHandler(e.target.value)}
  //     />
  //     )
  //   }

  // }

  //---------------------------------------------------------------------------------------
  //Error popup displaying - TODO: find/create more specific messages?
  const displayErrors = (
    <BkAlert severity='error' className='input'>
      {status &&
      status.errors &&
      status.errors.length > 0 &&
      status?.errors?.map((msg, i) => (
        <div key={i}>{t('errorsGroups:' + msg.message)}</div>
      ))}
    </BkAlert>
  )

  //---------------------------------------------------------------------------------------
  //Request processing status
  const processing = <CircularProgress />

  //---------------------------------------------------------------------------------------
  const form = (
    <div className='formGroup'>
      {status.errors.length > 0 ? displayErrors : null}
      <BkTextField
        placeholder='Group name'
        label='Group name'
        value={widgetParams.groupName}
        onChange={(e) => onChangeGroupNameHandler(e.target.value)}
      />
      <BkSelect
        title='Type'
        selected={widgetParams.type}
        options={groupType}
        optionConfig={{ label: 'label' }}
        onChange={onChangeTypeHandler}
        displayHelper helper='Select type'
      />
      {
        manageContentProvider()
      }
      {/* <div className='inlineFieldSet'>
        <div className='inputContainer'><Checkbox checked={widgetParams.enableQuotas} onChange={onChangeEnableQuotas} id='enableQuotas' name='enableQuotas' color='primary' /></div>
        <label className='label' htmlfor='enableQuotas'>Enable maximum simultaneous connections quotas</label>
      </div>
      {
        manageQuotasSupervisor()
      }
      {
        manageQuotasAdministrator()
      } */}
      <div className='modal__footer'>
        <BkButton
          type='submit'
          variant='contained'
          color='primary'
          onClick={createGroup} >
            Save
        </BkButton>
        <BkButton onClick={closeHandler} className='bkButton--alternate'>
          Cancel
        </BkButton>
      </div>
    </div>
  )

  return (
    <div>{status.processing ? processing : form}</div>
  )
}
