import React from 'react'
import { useSelector } from 'react-redux'
import BkAppBar from '../components/AppBar'
import { selectCurrentDashboard, selectMenu, getMenuAndSubMenuConfig  } from '../features/dashboards/store/dashboardSlice'
import DatepickerMenu from '../features/datepicker/DatepickerMenu'
import FiltersContainer from '../features/filters/FiltersContainer'
import BkButton from './Button'
import BkIcon from './icon'
import FullScreenPortal from './portals/FullScreenPortal'
import Scrollable from './Scrollable'
import './styles/full-screen.scss'
import * as _ from 'lodash'

const FullScreen = ({ children, onClose }) => {

  const currentdashboard = useSelector(selectCurrentDashboard)
  const menu = useSelector(selectMenu)
  const breadCrumb = getMenuAndSubMenuConfig(currentdashboard,menu)

  
  return (
    <FullScreenPortal>
      <div className='fullScreen bkDark'>
        <BkAppBar>
          <div>
            <BkButton onClick={onClose} className='appBar__darkButton'>
              <BkIcon path='arrow' modifier='back' />
            </BkButton>
            <span className='fullScreen__title'>
              <BkIcon
                path={_.get(breadCrumb, [0, 'routerId'])}
                modifier='active'
                className='fullScreen__icon'
              />
              {breadCrumb.map(path => path.title).join(' / ')}
            </span>
          </div>
          <DatepickerMenu />
        </BkAppBar>
        <div className='container container--fullPage'>
          <FiltersContainer />
          <div className='pageContainer'>
            <Scrollable>
              <div className='pageContainer__section'>{children}</div>
            </Scrollable>
          </div>
        </div>
      </div>
    </FullScreenPortal>
  )
}

export default FullScreen
