import React, { useEffect, useState } from 'react'
import BkTextField from '../../components/forms/TextField'
import BkButton from '../../components/Button'
import BkSelect from '../../components/forms/Select'
import { addUserAccount} from '../../services/user.service'
import { getGroups } from '../../services/group.service'
import { getCurrentUserGroup } from '../../services/group.service'

//User profil info
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../authentication/store/authSlice.js'

import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import BkAlert from '../../components/Alert'
import * as _ from 'lodash'
import { useTranslation } from 'react-i18next'

//import SelectFilter from './SelectFilter'

export const CreateUser = ({ params, close, handleNewUser }) => {

  const { t } = useTranslation()

  const initialStatus = {
    errors: [],
    processing: false
  }
  const [status, setStatus] = useState(initialStatus)
  const user = useSelector(selectCurrentUser)

  //Conditionnal profil list if super admin or only admin
  const profilTypes = ( user.pfl_id === 1) ?
  [
    { //1
      type: 1, //'ADMINISTRATOR_USER_PFL_ID',
      label: 'super administrator'
    },
    { //2
      type: 2, //'GROUP_ADMIN_USER_PFL_ID',
      label: 'administrator'
    },
    { //3
      type: 3, //'GROUP_ADVANCED_SUPERVISOR_USER_PFL_ID',
      label: 'advanced supervisor'
    },
    { //Other value
      type: 0, //'NORMAL_USER_PFL_ID',
      label: 'supervisor'
    }
  ]
  :
  [
    { //2
      type: 2, //'GROUP_ADMIN_USER_PFL_ID',
      label: 'administrator'
    },
    { //3
      type: 3, //'GROUP_ADVANCED_SUPERVISOR_USER_PFL_ID',
      label: 'advanced supervisor'
    },
    { //Other value
      type: 0, //'NORMAL_USER_PFL_ID',
      label: 'supervisor'
    }
  ]

  //Group map for the selector, using the list of available groups
  const [groupList, setGroupList] = useState(null)
  const [pflId, setPflIdParam] = useState("")

  //TODO ? improve this to get only once groups from DB instead to get these each time
  useEffect(() => {
    //current user group (used in Administrator context only)
    const fetchUserGroup = async () => {
      /*await*/ getCurrentUserGroup().then((res) =>{
        const groups = [{type : res.data.groupId, label : res.data.groupname}]
        setGroupList(groups)
      })
    }
    //Generic fetchGroupList (useable by Super Administrator)
    const fetchGroupList = async () => {
        //Request only possible for "Super Administrator"
        /*await*/ getGroups().then((res) =>{
        //Groups extraction from "res.data.data"
        const tmpData = _.get(res, 'data')
        const listGroup =  _.get(tmpData, 'groups')

        let groups=[];
        if (listGroup && listGroup.length > 0){
          listGroup.forEach((group) =>  {
            // if profil is not super admin, then add all group found in DB.
            // if profil is super admin and group is not a provider, add group
            if (pflId.type !== 1 || (pflId.type === 1 && group.type !== 1))
              groups.push( {type : group.id, label : group.groupname})
          })
          setGroupList(groups)
        }
        else {
          //Administrator case: Getting the current user groupName, directly from DB, without going through the webservice, using session informations
          fetchUserGroup()
        }
      })
    }
    //
    fetchGroupList()
  }, [pflId])

  //NOTE: It's juste another way to group parameters together (set with setWidgetParam({ ...widgetParams, [param] }) )
  // const [userName, setUserName] = useState('')
  // const [email, setEmail] = useState('')
  // const [phoneNumber, setPhoneNumber] = useState('')
  // const [groupId, setGroupId] = useState('')
  // const [profileId, setProfileId] = useState('')
  // const [newPassword, setNewPassword] = useState('')
  // const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [widgetParams, setWidgetParam] = useState(params || {
    userName: '',
    email: '',
    phoneNumber: '',
    profilId: _.get(profilTypes, [0]),
    groupId: _.get(groupList, [0]),
    newPassword: '',
    confirmNewPassword: ''
  })

  //Group list loading in case of modification
  useEffect(() => {
    onChangeGroupIdHandler(_.get(groupList, [0]))
  }, [groupList])


  //Modification handlers
  const onChangeUserNameHandler = async (userName) => {
    setWidgetParam({ ...widgetParams, userName })
  }
  const onChangeEmailHandler = async (email) => {
    setWidgetParam({ ...widgetParams, email })
  }
  const onChangePhoneNumberHandler = async (phoneNumber) => {
    setWidgetParam({ ...widgetParams, phoneNumber })
  }
  const onChangeProfilIdHandler = async (profilId) => {
    setWidgetParam({ ...widgetParams, profilId })
    setPflIdParam(profilId)
  }
  const onChangeGroupIdHandler = async (groupId) => {
    setWidgetParam({ ...widgetParams, groupId })
  }
  const onChangeNewPasswordHandler = async (newPassword) => {
    setWidgetParam({ ...widgetParams, newPassword })
  }
  const onChangeConfirmNewPasswordHandler = async (confirmNewPassword) => {
    setWidgetParam({ ...widgetParams, confirmNewPassword })
  }

  //---------------------------------------------------------------------------------------
  //WARNING: Check the need for this
  const history = useHistory()
  const closeHandler = () => {
    if (close) {
      close()
    } else {
      history.push('/login')
    }
  }

  // ---------------------------------------------------------------------------
  //Creation of a new user, with basic check on set/selected parameters
  const createUser = () => {
    setStatus({ errors: [], processing: true })
    if (widgetParams.email === "")
    {
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'Providing an email is mandatory.' }]
      })
    }
    else if (widgetParams.userName === "")
    {
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'Providing a user name is mandatory.' }]
      })
    }
    //if the new user is successfully added then refresh the table so that changes are displayed
    else if (widgetParams.newPassword && widgetParams.newPassword === widgetParams.confirmNewPassword) {
      //addUserAccount(username, email, phone, password, group, profile, user_preferences)
      addUserAccount(widgetParams.userName, widgetParams.email, widgetParams.phoneNumber, widgetParams.newPassword, '' + widgetParams.groupId.type, widgetParams.profilId.type, "").then(
        () => {
        setStatus({ errors: [], processing: false })
        handleNewUser(true)
        closeHandler()
      }
      ).catch((error) => {
        setStatus({
          processing: false,
          errors: error.response ? error.response.data.errors : []
        })
        //if the user is not successfully added there is no need to actualize the table
        handleNewUser(false)
      } )
      handleNewUser(false)
    } else {
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'Password and confirm password don\'t match' }]
      })
    }
  }

  //---------------------------------------------------------------------------------------
  //Error popup displaying - TODO: find/create more specific messages?
  const displayErrors = (
    <BkAlert severity='error' className='input'>
      {status &&
      status.errors &&
      status.errors.length > 0 &&
      status?.errors?.map((err, i) => (

        <div key={i}>{t('errors:' + err.message)}</div>
      ))}
    </BkAlert>
  )

  //---------------------------------------------------------------------------------------
  //Request processing status
  const processing = <CircularProgress />

  //---------------------------------------------------------------------------------------
  const form = (
    <form>
      {status.errors.length > 0 ? displayErrors : null}
      <BkTextField
        placeholder='User name'
        label='User name'
        value={widgetParams.userName}
        type='userName'
        onChange={(e) => onChangeUserNameHandler(e.target.value)}
      />
      <BkTextField
        placeholder='Email'
        label='Email'
        value={widgetParams.email}
        type='email'
        onChange={(e) => onChangeEmailHandler(e.target.value)}
      />
      <BkTextField
        placeholder='Phone number'
        label='Phone number'
        value={widgetParams.phoneNumber}
        type='phoneNumber'
        onChange={(e) => onChangePhoneNumberHandler(e.target.value)}
      />

      <BkSelect
        title='Profil type'
        selected={widgetParams.profilId}
        options={profilTypes}
        optionConfig={{ label: 'label' }}
        onChange={onChangeProfilIdHandler}
        displayHelper helper='Select profile'
      />
      <BkSelect
        title='Group'
        selected={widgetParams.groupId}
        options={groupList}
        optionConfig={{ label: 'label' }}
        onChange={onChangeGroupIdHandler}
        displayHelper helper='Select group'
      />

      <BkTextField
        placeholder='New password'
        label='New password'
        value={widgetParams.newPassword}
        type='password'
        onChange={(e) => onChangeNewPasswordHandler(e.target.value)}
      />
      <BkTextField
        placeholder='New Password Confirm'
        label='Confirm new password'
        value={widgetParams.confirmNewPassword}
        type='password'
        onChange={(e) => onChangeConfirmNewPasswordHandler(e.target.value)}
      />
      <div className='modal__footer'>
        <BkButton
          type='submit'
          variant='contained'
          color='primary'
          onClick={createUser} >
            Save
        </BkButton>
        <BkButton onClick={closeHandler} className='bkButton--alternate'>
          Cancel
        </BkButton>
      </div>
    </form>
  )

  return (
    <div>{status.processing ? processing : form}</div>
  )
}
