import React from 'react'
import './styles/scrollable.scss'

const Scrollable = ({ children, className }) => {
  const classes = className ? 'scrollable ' + className : 'scrollable'
  return (
    <div className={classes}>
      <div className='scrollable__zone'>{children}</div>
    </div>
  )
}

export default Scrollable
