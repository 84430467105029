import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Paginator from '../../../components/Paginator'
import useLegacy from '../../../i18n/legacyEffect'
import { stringDateTime } from '../../../services/period.service'
import useUsersList from './hooks/usersListEffect'
import '../../tableList/styles/table.scss'

import BkButton from '../../../components/Button'
import BkIcon from '../../../components/icon'
import IconButton from '@material-ui/core/IconButton'
import Modal from '../../../components/Modal'
import { currentUser } from '../../../services/auth.service'

import { removeUserAccount, lockUserAccount, unlockUserAccount, logoutUserSessions } from '../../../services/user.service'

import { ChangePassword } from '../ChangePassword'
import { Grid } from '@material-ui/core'

export const TYPES = {
  LIST: 'list',
  LIST_GROUP: 'list_group'
}

const UsersDataList = ({ config, displayHeader }) => {
  const { tLegacy } = useLegacy()

  //Pagination offset
  const [offset, setOffset] = useState(0)
  const changePage = offset => {
    setOffset(offset)
  }

  // List of users data to display, and format
  const userListData = useUsersList({ ...config, offset })

  const tmpUserListData = _.get(userListData, 'data')
  const list = _.get(tmpUserListData, 'data')
  const total = _.get(list, 'length')


  //Selected item in the table
  const [selectedItem, setSelectedItem] = useState(0)
  //Current user data
  const [currentUserData, setCurrentUserData] = useState(null)

  useEffect(() => {
    const getUserItem = async () => {
      currentUser().then((res) => {
        const data = _.get(res, 'data')
        const user = _.get(data, 'user')
        setCurrentUserData(user)
      })
    }
    getUserItem()
  }, [])


  //const currentUserData = currentUser()
  //const currentUserData = _.get(currentUserData, 'data')

  const [changePasswordModalToggle, setChangePasswordModalToggle] = useState(false)


  const [removeModalToggle, displayRemoveModalToggle] = useState(false)
  const [lockModalToggle, displayLockModalToggle] = useState(false)


  //----------------------- CHANGE USER PASSWORD ---------------------------------------------------------
  const doChangePwdModal = (item) => {
    setSelectedItem(item)
    setChangePasswordModalToggle(true)
  }
  //
  const changePasswordModal = (
    <Modal title='Modify the user password' onClose={() => setChangePasswordModalToggle(false)}>
      <ChangePassword close={() => setChangePasswordModalToggle(false)} selectedItem={selectedItem} />
    </Modal>
  )
  //
  const displayChangePasswordActionButton = (item) =>{
    if (currentUserData && item && (item.pfl_id !== 1 || currentUserData.pfl_id === 1) ) {
      return  <IconButton aria-label="settings" onClick={() => doChangePwdModal(item) }>
        {<BkIcon path='key' modifier='active' className='icon' />}
      </IconButton>
    }
  }

  //----------------------- LOCK USER ---------------------------------------------------------
  const lockUserHandler = () => {

    if (selectedItem.banned !== 0)
    {
      unlockUserAccount(selectedItem.id)
      // reload window when Unlock User
      window.location.reload()
    //
    }
    else
    {
      lockUserAccount(selectedItem.id)
      logoutUserSessions(selectedItem.id)
      // reload window when Lock User
     window.location.reload()
    }
    displayLockModalToggle(false)
  }
  const doLockModal = (item) => {
      setSelectedItem(item)
      displayLockModalToggle(true)
  }
  const displayLockUserActionButton = (item) =>{
    var iconPath = 'lock'
    if (item && item.banned !== 0)
    {
      iconPath = 'unlock'
    }
    if (currentUserData && item && currentUserData.email !== item.email && (item.pfl_id !== 1 || currentUserData.pfl_id === 1)) {
      return <IconButton aria-label="lock" onClick={() => doLockModal(item) /*openModalForChangePassword(item)*/}>
        {<BkIcon path={iconPath} modifier='active' className='icon' />}
      </IconButton>
    }
  }

  //----------------------- REMOVE USER ---------------------------------------------------------
  const removeUserHandler = () => {
    removeUserAccount(selectedItem.id)
    displayRemoveModalToggle(false)
    // reload window when Remove User
    window.location.reload()
  }
  const doRemoveModal = (item) => {
      setSelectedItem(item)
      displayRemoveModalToggle(true)
  }
  const displayRemoveUserActionButton = (item) =>{
    if (currentUserData && item && currentUserData.email !== item.email && (item.pfl_id !== 1 || currentUserData.pfl_id === 1)) {
      return <IconButton aria-label="delete" onClick={() => doRemoveModal(item) /*openModalForRemoveUser(item)*/}>
        {<BkIcon path='trash' modifier='active' className='icon' />}
      </IconButton>
    }
  }

  //----------------------- Adding action buttons in action column ---------------------------------------------------------
  const addActionButtons = (item) => {
    return <div>
      { displayChangePasswordActionButton(item) }
      { displayLockUserActionButton(item) }
      { displayRemoveUserActionButton(item) }
    </div>
  }

  //Transform the displayed value, depending on the column treshold or unit
  const transformField = (value, fieldConfig) => {
    if (fieldConfig.threshold) {
      return value > fieldConfig.threshold ? (
        <span className='highlight--success'>{_.round(value, 2)}</span>
      ) : (
        <span className='highlight--alert'>{_.round(value, 2)}</span>
      )
    }

    if (fieldConfig.unit && fieldConfig.unit === 'timestamp') {
      const date = new Date(value * 1000)
      return stringDateTime(date)
    }

    return tLegacy(fieldConfig.dimension, value)
  }


  //Transform columns content, depending on the column dimension
  const manageColumnContent = (item, fieldConfig) => {
    //Access level / Profil
    if (fieldConfig.dimension === 'pfl_id') {
      return tLegacy('profil', String(item.pfl_id))
    }
    //Action buttons
    else if (fieldConfig.dimension === 'actions'){
      return addActionButtons(item)
    }
    //default:
    return transformField(item[fieldConfig.dimension], fieldConfig)
  }

  const paginationValue = () => {
    const nbElementOnPage = offset + config.nbItemsPerPage
    if (total === 0) {
      return  '0 / 0'
    }
    else if (nbElementOnPage > total ) {
      return offset + 1 + ' - ' + total + ' / ' + total
    }
    else {
      return offset + 1 + ' - ' + nbElementOnPage + ' / ' + total
    }
  }

  return (
    <>
      {config && list && (
        <>
          {displayHeader && (
            <header className='widget__header'>

              <h2 className='widget__title'>
                {/* HIDE TITLE*/}
                {/* {_.get(config, 'title') || _.get(config, 'type')} */}
              </h2>
              <div className='widget__actions'>{paginationValue()}</div>
            </header>
          )}
          <div className='widget__content'>
            <div className='tableContainer'>
              <table className='table'>
                <thead>
                  <tr className='table__head'>
                    {config.fields.map((field, i) => {
                      return !field.hidden && <th key={i}>{field.title}</th>
                    })}
                  </tr>
                </thead>
                <tbody>
                  {/* Table data mapping */}
                  {list.slice(offset,offset + config.nbItemsPerPage).map((item, i) => (
                    <tr key={i} className='table__row'>
                      {config.fields.map((field, k) => {
                          return (
                            !field.hidden && (
                              field.dimension === 'actions' ? (
                                <td key={k + '_' + i} className="buttonTd">
                                {
                                  //Field content management
                                  manageColumnContent(item, field)
                                }
                              </td>
                              ) : (
                              <td key={k + '_' + i} >
                              {
                                //Field content management
                                manageColumnContent(item, field)
                              }
                            </td>
                             )
                            )
                          )
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paginator
              limit={config.nbItemsPerPage}
              total={total}
              onChangePage={changePage}
            />
          </div>
        </>
      )}
      {/*removeUserModalToggle && removeUserModal*/}
      {removeModalToggle &&
        <Modal title='Please confirm' onClose={() => displayRemoveModalToggle(false)} >
          <Grid container>
            <Grid item xs={1}>
              <BkIcon path='alert' modifier='active' className='icon' />
            </Grid>
            <Grid item xs={11}>
              Do you want to remove the account of '{selectedItem.username}'?
              All reporting created by this user will be deleted.
            </Grid>
          </Grid>
          <div className='modal__footer'>
            <div className='modal__footer'>
              <BkButton onClick={removeUserHandler} className='bkButton--delete'>
                Confirm
              </BkButton>
              <BkButton className='bkButton--alternate' onClick={() => displayRemoveModalToggle(false)} >
                Cancel
              </BkButton>
            </div>
          </div>
        </Modal>}
      {lockModalToggle &&
        <Modal title='Please confirm' onClose={() => displayLockModalToggle(false)} >
          <Grid container>
            <Grid item xs={1}>
              <BkIcon path='alert' modifier='active' className='icon'/>
            </Grid>
            <Grid item xs={11}>
              {selectedItem.banned === 0 ? (
                "This user, '" + selectedItem.username + "', will be locked and will not be able to login until being unlocked."
                + " Its current sessions will be left unchanged. Are you sure?"
                ) : (
                  "This user, '" + selectedItem.username + "', will be allowed to login again into the product. " +
                  " Are you sure?"
                )
              }
            </Grid>
          </Grid>
          <div className='modal__footer'>
            <div className='modal__footer'>
              <BkButton onClick={lockUserHandler} >
                Confirm
              </BkButton>
              <BkButton className='bkButton--alternate' onClick={() => displayLockModalToggle(false)} >
                Cancel
              </BkButton>
            </div>
          </div>
        </Modal>}
      {changePasswordModalToggle && changePasswordModal /* TODO: Form + Check/fix/test */}
    </>
  )
}

export default UsersDataList
