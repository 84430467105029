import bkApi from './bk-api'

const getConfigs = () => {
  return bkApi.get('/v1/config?action=get_gui_setting_config')
}

const postConfigs = (settingsList) => {
  return bkApi.post('/v1/config?action=save_gui_settings_config', {settingsList})
}

export { getConfigs, postConfigs}
