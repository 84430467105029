import React from 'react'
import ContainsFilter from './ContainsFilter'
import SearchFilter from './SearchFilter'
import SelectFilter from './SelectFilter'
import { useSelector } from 'react-redux'
import { findByName } from './store/FiltersSlice'
import { filterType } from '../../constants'
import * as _ from 'lodash'

const Filter = ({ title, type, id, columnName, onChange, legacy, exclude }) => {
  const selected = useSelector(state => {
    return findByName(state, legacy)
  })
  let filter = ''
  const labelKey = id.match(/[a-z0-9A-Z]*_([a-z0-9A-Z_]*)/)[1]

  switch (type) {
    case filterType.AUTOCOMPLETE:
      filter = (
        <SearchFilter
          type={type}
          id={id}
          title={title}
          legacy={legacy}
          selected={_.get(selected, ['values'])}
          onChange={values => onChange(id, columnName, labelKey, values)}
          exclude = {exclude}
        />
      )
      break
    case filterType.DROPDOWN:
    case filterType.DROPDOWNFIX:
    case filterType.DROPDOWNFIXCONTAINS:
      filter = (
        <SelectFilter
          type={type}
          id={id}
          title={title}
          legacy={legacy}
          selected={_.get(selected, ['values'])}
          onChange={values => onChange(id, columnName, labelKey, values)}
          exclude = {exclude}
        />
      )
      break
    case filterType.PATTERN:
      filter = (
        <ContainsFilter
          type={id}
          title={title}
          legacy={legacy}
          selected={_.get(selected, ['values'])}
          onChange={values => onChange(id, columnName, labelKey, values)}
          exclude = {exclude}
        />
      )
      break
      default:
  }

  return filter
}

export default Filter
