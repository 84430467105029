import React, { useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { Split } from './Split'
import { Compare } from './Compare'
import BkButton from '../../../components/Button'
import BkIcon from '../../../components/icon'

const Configuration = ({ setDisplay, config, optionsChange, isFullScreen }) => {
  const [tab, setTab] = useState(0)
  const [split, setSplit] = useState({})
  const [compare, setCompare] = useState({})

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  useEffect(() => {
    if (optionsChange) {
      const compareSelected = !!(
        tab === 0 &&
        compare.startdate &&
        compare.enddate
      )
      const splitSelected = !!(split.selectedValue && tab === 1)
      optionsChange({
        compare: {
          ...compare,
          selected: compareSelected
        },
        split: {
          ...split,
          selected: splitSelected
        },
        active: !!(splitSelected || compareSelected),
        isChanged: true
      })
    }
  }, [split, compare]) // eslint-disable-line react-hooks/exhaustive-deps

  const getSeriesInConfigChart = option => {
    const series = []
    if (Array.isArray(option.list)) {
      option.list.forEach(list => {
        if (Array.isArray(list.series)) {
          series.push(...list.series)
        }
      })
    }
    return series
  }

  return (
    <div className='bk-tabs'>
      {isFullScreen && (
        <header className='widget__header'>
          <h2 className='widget__title'>
            Incoming&nbsp;
            <BkIcon path='config' modifier='active' />
          </h2>
        </header>
      )}
      {!isFullScreen && (
        <BkButton
          className='bkButton--invisible bk-tabs__closeButton'
          onClick={() => setDisplay(false)}
        >
          <BkIcon path='close' alt='Options' />
        </BkButton>
      )}
      <AppBar position='static'>
        <Tabs value={tab} onChange={handleChange}>
          <Tab label='Compare a period to the current reference' />
          <Tab label='Chart splitting' />
        </Tabs>
      </AppBar>
      <Box hidden={tab !== 0}>
        <Compare onChange={setCompare} />
      </Box>
      <Box hidden={tab !== 1}>
        <Split series={getSeriesInConfigChart(config)} onchange={setSplit} />
      </Box>
    </div>
  )
}

export { Configuration }
