import React, { useState } from 'react'
import BkTextField from '../../components/forms/TextField'
import BkButton from '../../components/Button'
// import { changePassword, checkForgotToken } from '../../services/auth.service'
import * as AuthService from '../../services/auth.service'
import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { selectCurrentDashboard  } from '../dashboards/store/dashboardSlice'
import { useSelector } from 'react-redux'
import BkAlert from '../../components/Alert'
import { useTranslation } from 'react-i18next'


export const ChangePassword = (props) => {
  const { t } = useTranslation()

  const initialStatus = {
    errors: [],
    processing: false
  }
  const [status, setStatus] = useState(initialStatus)
  const [statusToken, setStatusToken] = useState('')

  const [newPassword, setNewPassword] = useState('')
  const currentdashboard = useSelector(selectCurrentDashboard)
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const history = useHistory()
  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    } else {
      history.push('/login')
    }
  }

  // --------------------------------------------------------------
  const token = props.password
  const email = props.email
  const [credentials, setCredentials] = useState({
    email: email,
    token: token
  })
  // --------------------------------------------------------------

  // Method 'onload' permits to load only at the charging page
  window.onload = function() {
    // currentDashboard permit to know if we are in Login ForgotPwd or In UserSession ChangePwd
    if (currentdashboard === null) {
      AuthService.checkForgotToken(credentials)
      .then((data) => {
          setStatusToken(data.data)
      })
      .catch((error) => {
        if (error.response) {
          console.log('AuthService.login - e.response ', error)
          setStatus({ processing: false, errors: error.response ? error.response.data.errors : [] })
        }
      })
    }
  };

  const updatePassword = () => {
    setStatus({ errors: [], processing: true })
      if (newPassword && newPassword === confirmNewPassword) {
        AuthService.changePassword(newPassword, email, token).then(() => {
          setStatus({ errors: [], processing: false })
          closeHandler()
        }).catch((error) => {
          setStatus({
            processing: false,
            errors: error.response ? error.response.data.errors : []
          })
        })
      } else {
        setStatus({
          processing: false,
          errors: [{ code: 0, message: 'Password and confirm password don\'t match' }]
        })
      }
  }

  const displayErrors = (
    <BkAlert severity='error' className='input'>
      {status &&
      status.errors &&
      status.errors.length > 0 &&
      status?.errors?.map((err, i) => (
        <div key={i}>{'errors: ' + err.message}</div>
      ))}
    </BkAlert>
  )

  const processing = <CircularProgress />
  const form = (
    <form>
      {(status &&
      status.errors && status.errors.length > 0) ? displayErrors : null}
      {/* currentDashboard permit to know if we are in Login ForgotPwd or In UserSession ChangePwd */}
      {((statusToken && statusToken === 'success') || currentdashboard) ? (
        <div>
          <BkTextField
              placeholder='New password'
              label='New password'
              value={newPassword}
              type='password'
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <BkTextField
              placeholder='New Password Confirm'
              label='Confirm new password'
              value={confirmNewPassword}
              type='password'
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            <div className='modal__footer'>
              <BkButton
                type='submit'
                variant='contained'
                color='primary'
                onClick={updatePassword}
              >Save
              </BkButton>
              <BkButton onClick={closeHandler} className='bkButton--alternate'>
                Cancel
              </BkButton>
            </div>
        </div>
      ) : (
        <div>
        <div>
        <BkAlert severity='error' className='input'>
          <div>{t('errors:' + statusToken)}</div>
        </BkAlert>
         </div>
           <div className='modal__footer'>
             <BkButton onClick={closeHandler} className='bkButton--alternate'>
               cancel
             </BkButton>
           </div>
           </div>
      )}

    </form>
  )

  return (
    <div>{status.processing ? processing : form}</div>
  )
}
