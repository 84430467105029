import React, { useEffect, useState, useMemo } from 'react'
import { Link, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import BkButton from '../../components/Button'
import Modal from '../../components/Modal'
import IconButton from '@material-ui/core/IconButton'
import BkIcon from '../../components/icon'
import Diagnostic from './Diagnostic'
import CreateDiagnostic from './CreateDiagnostic'
import '../../components/styles/icon.scss'
import {
  deleteDiagnostic,
  listDiagnostics,
  listAlertSeverities,
} from '../../services/diagnostic.service'
import {
  updateDiagnostics,
  selectDiagnostics,
  removeDiagnostic,
  updateAlertSeverities,
} from './store/DiagnosticsSlice'
import TableList from '../tableList/TableList'
import { selectAppliedFilters, resetapplyFilter } from '../filters/store/FiltersSlice'

const Details = () => {
  const { id } = useParams()
  const diagnostics = useSelector(selectDiagnostics)
  const diagnostic = (diagnostics || []).find(e => e.id === id)
  return <Diagnostic diagnostic={diagnostic} />
}

export const AIDataDiagnostics = () => {
  const dispatch = useDispatch()
  const diagnostics = useSelector(selectDiagnostics)
  const appliedFilters = useSelector(selectAppliedFilters)
  const [createDiagnosticModalToggle, setCreateDiagnosticModalToggle] = useState(false)
  const { path } = useRouteMatch()

  const deleteOne = (id) => {
    deleteDiagnostic(id)
    dispatch(removeDiagnostic(id))
  }

  // reset filter when loading page
  useEffect(() => {
    dispatch(resetapplyFilter(appliedFilters))
  }, []);

  useEffect(() => {
    const refresh = async () => {
      const res = await listDiagnostics()
      res.data.data.forEach(e => {
        e.status = e.files.some(f => f.endsWith('.json')) ? 'finished' : 'pending'
        e.package = e.files.find(f => f.endsWith('.tar.gz'))
        e.id = e.id
        e.name = e.id?.replaceAll('_',' ')
      })

      dispatch(updateDiagnostics(res.data.data))
    }

    refresh()
    const interval = setInterval(refresh, 5e3)
    return () => {
      clearInterval(interval)
      dispatch(resetapplyFilter(appliedFilters))
    }
  }, [dispatch])

  useEffect(() => {
    const refresh = async () => {
      const res = await listAlertSeverities()
      dispatch(updateAlertSeverities(res.data))
    }
    refresh()
  }, [dispatch])

  const createDiagnosticModal = (
    <Modal title='Create new diagnostic' onClose={() => setCreateDiagnosticModalToggle(false)}>
      <CreateDiagnostic onClose={() => setCreateDiagnosticModalToggle(false)} />
    </Modal>
  )

  const config = useMemo(() => ({
    type: 'list',
    exportCSV: false,
    nbItemsPerPage: 20,
    items: diagnostics,
    rowIdKey: 'id',
    orderOn: 'id asc',
    fields: [{
      'title': 'Name',
      'dimension': 'name',
    }, {
      'title': 'Status',
      'dimension': 'status'
    }, {
      'title': 'Last modified (UTC)',
      'dimension': 'mtime_utc'
    }]
  }), [diagnostics])

  const actionsCallback = (diagnostic) => (
    <>
      {diagnostic.status === 'finished' &&
        <Link to={`${path}/${diagnostic.id}`}>
          <IconButton aria-label='view'>
            <BkIcon path='search' className='icon' alt='Details' />
          </IconButton>
        </Link>}
      {diagnostic.package &&
        <a href={`/api/diagnostics/${diagnostic.id}/${diagnostic.package}`}>
          <IconButton aria-label='view'>
            <BkIcon path='save' className='icon' alt='Save' />
          </IconButton>
        </a>}
      <IconButton aria-label='delete' onClick={() => deleteOne(diagnostic.id)}>
        <BkIcon path='trash' className='icon' alt='Delete' />
      </IconButton>
    </>
  )


  return (
    <Switch>
      <Route path={`${path}/:id`} children={<Details />} />
      <Route>
        <>
          <div className='widget widget-minHeight'>
            <div className='widget__header'>
              <h1 className='widget__title'>AI Data Diagnostics</h1>

              <div className='widget__actions'>
                <BkButton onClick={() => setCreateDiagnosticModalToggle(true)}>
                  Generate new diagnostic
                </BkButton>
              </div>
            </div>
            <div>
              {diagnostics.length > 0 ?
                <TableList config={config} actionsCallback={actionsCallback} />
                : <p>No diagnostic available.</p>}
            </div>
          </div>
          {createDiagnosticModalToggle && createDiagnosticModal}
        </>
      </Route>
    </Switch>
  )
}

export default AIDataDiagnostics
