import bkApi from './bk-api'
import { getQueryStringValue, setQueryStringValue, removeQueryStringValue } from './querystring.service'

const getFilters = (params) => {
  return bkApi.get('/v1/filter/filters', { params: { action: 'getFilters', ...params } })
}

// const getSavedPresets = (menuid,email ) => {
//   console.log("------------>GET SAVED PRESETS")
//   return bkApi.get('/v1/filter/savedFilters', { params: { menuid: menuid, email: email } })
// }

const getSavedPresets = (menuid, email ) => {
  console.log("------------>GET SAVED PRESETS")
  return bkApi.get('/v1/filter/preset', { params: { menuid: menuid, email: email } })
}

const getPresetFilters = (presetId) => {
  console.log("------------>GET PRESET FILTERS")
  return bkApi.get('/v1/filter/presetFilters', { params: { presetid: presetId } })
}

const savePresetFilters = (presetId, filters) => {
  console.log("------------>SAVE PRESET FILTERS")
  return bkApi.post('/v1/filter/presetFilters', { params: { presetid: presetId, filters: filters } })
}


const savePreset = (preset, filters) => {
  console.log("------------>SAVE PRESET")
  return bkApi.post('/v1/filter/preset', 
  { params:  { preset: preset, filters: filters } }    
  )
}

const removePreset = presetId => {
  console.log("------------>REMOVE PRESET ", presetId)
  return bkApi.delete('/v1/filter/preset', { params: { presetid: presetId } } ) //presetId)
}

const updatePreset = (preset) => {
  console.log("------------>UPDATE PRESET")
  return bkApi.post('/v1/filter/presetUpdate', 
  { params:  { preset: preset } }    
  )
}

const getFilterFromQueries = () => {
  if(getQueryStringValue("filters"))
  {

    const storedFilterString = getQueryStringValue("filters")
    let arrayFil = storedFilterString.split('|')
    let arrFilterObj = arrayFil.map( (str) => {
      let temp = str.split('+')
      return {
        operator: temp[0],
        value: temp[1],
        id: temp[2],
        column_name: temp[3]
      }
    })
    const filtersToApply = []
    arrFilterObj.map((filter) => {
      //Sub value of a filter
      const filterValue = { operator: filter.operator, value: filter.value }
      //Searching for a filter with the same id, in the list of filters to apply
      let filterToFill = filtersToApply.find(e => e.id === filter.id)
      if (filterToFill) {
          //Adding a new value to values, if a filter of the same id was already existing
          filterToFill.values.push(filterValue)
      }
      else {
          //New filter. There was no existing filter with the same id
          const newFilter = { id: filter.id, name: filter.column_name, label: filter.column_name, values: [filterValue] }
          filtersToApply.push(newFilter)
      }
    })
    return filtersToApply
  }
  else return false
}

const updateFilterQueries = (filters) => {
  let strFilters
  strFilters =  filters?.reduce((a,b) => a? a + '|' + filterToString(b) : filterToString(b), '')

  if(strFilters) {
    setQueryStringValue("filters", strFilters) }
  else{
    console.log("no filters selected")
    removeQueryStringValue("filters")
  }
   
}

const filterToString = (filter) => {
  let fVal = filter.values

  //deconstructing the filter object to make it into URL string without losing information
  let arrayObj = fVal?.map( (value) => {
    return value.operator + '+' + value.value + '+' + filter.id + '+' + filter.name
  })
  return arrayObj.join('|')

}

export { updateFilterQueries, getFilters, getSavedPresets, getPresetFilters, savePresetFilters, savePreset, removePreset, updatePreset, getFilterFromQueries }
