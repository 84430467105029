import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import useLogout from './hooks/logout'
const Logout = () => {
  const { t } = useTranslation()
  const { loggedStatus } = useLogout()

  return (
    <>
      {loggedStatus.isLogged === false && <Redirect to='/login' />}
      {loggedStatus.isLoading && <span>{t('actions:isSigninOut')}</span>}
      {!loggedStatus.isLoading && (
        <span>{t('actions:logout')}</span>
      )}
    </>
  )
}

export default Logout
