import * as _ from 'lodash'
import React, {useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Paginator from '../../components/Paginator'
import useLegacy from '../../i18n/legacyEffect'

import BkButton from '../../components/Button'
import BkIcon from '../../components/icon'
import IconButton from '@material-ui/core/IconButton'
import Modal from '../../components/Modal'

import { useSelector } from 'react-redux'
import { selectMenu, getDashboardTitleFromRouters } from '../dashboards/store/dashboardSlice.js'
import { selectCurrentUser } from '../authentication/store/authSlice'

import { deleteReporting, getReporting } from '../../services/reporting.service'
import '../tableList/styles/table.scss'
import '../../components/styles/icon.scss'
import { Grid } from '@material-ui/core'


const ReportingList = ({ config, displayHeader }) => {
  const { tLegacy } = useLegacy()
  const { t } = useTranslation()
  const menu = useSelector(selectMenu)

  //Pagination offset
  const [offset, setOffset] = useState(0)
  //Selected item in the table
  const [selectedItem, setSelectedItem] = useState(0)

  const changePage = offset => {
    setOffset(offset)
  }

  //Current user data
  const currentUserData= useSelector(selectCurrentUser)

  //reporting date
  const [reportingData, setReportingData] = useState([])
  const [nbReporting, setNbReporting] = useState(0)

  useEffect(() => {
    const getReportings = async () => {
      getReporting().then((res) => {
        const nbReporting = _.get(res, ['data', 'data', 'length'])
        const data = _.get(res, ['data', 'data'])
        setReportingData(data)
        setNbReporting(nbReporting)
      })
    }
    getReportings()
  }, [config.offset])


  const [removeModal, displayRemoveModal] = useState(false)

  //----------------------- REMOVE Reporting ---------------------------------------------------------
  const removeReportingHandler = () => {
    deleteReporting(selectedItem.reporting_name, selectedItem.user_id, selectedItem.dashboard_path, selectedItem.periodicity)
    displayRemoveModal(false)
    // reload window when Remove REporting
    window.location.reload()
  }
  const doRemoveModal = (item) => {
      setSelectedItem(item)
      displayRemoveModal(true)
  }
  const displayRemoveActionButton = (item) =>{
    if (currentUserData && item && (currentUserData.user_id === item.user_id || currentUserData.pfl_id === 1)) {
      return <IconButton aria-label="delete" onClick={() => doRemoveModal(item) }>
        {<BkIcon path='trash' modifier='active' className='icon' />}
      </IconButton>
    }
  }

  //----------------------- Adding action buttons in action column ---------------------------------------------------------
  const addActionButtons = (item) => {
    return <div>
      { displayRemoveActionButton(item) }
    </div>
  }


  //Transform columns content, depending on the column dimension
  const manageColumnContent = (item, fieldConfig) => {
    let elt = item
    //Action buttons
    if (fieldConfig.dimension === 'actions'){
      return addActionButtons(item)
    }
    else if (fieldConfig.dimension === 'reporting_name') {
      elt.reporting_name = item.reporting_name.replaceAll('_',' ')
    }
    else if (fieldConfig.dimension === 'dashboard_name') {
      if (menu && item && item.dashboard_path){
        const path = item.dashboard_path.split('/')
        if (path.length === 2) {
          elt.dashboard_name = getDashboardTitleFromRouters(menu, path[0], path[1])
        }
      }
    }
    return tLegacy(String(elt), item[fieldConfig.dimension])
  }

  const paginationValue = () => {
    const nbElementOnPage = offset + config.nbItemsPerPage
    if (nbReporting === 0) {
      return  '0 / 0'
    }
    else if (nbElementOnPage > nbReporting ) {
      return offset + 1 + ' - ' + nbReporting + ' / ' + nbReporting
    }
    else {
      return offset + 1 + ' - ' + nbElementOnPage + ' / ' + nbReporting
    }
  }

  return (
    <>
      {config && reportingData && (
        <>

            <header className='widget__header'>
              <h2 className='widget__title'>
                {/* HIDE TITLE*/}
                {/* {_.get(config, 'title') || _.get(config, 'type')} */}
              </h2>
              <div className='widget__actions'>{paginationValue()}</div>
            </header>

          <div className='widget__content'>
            <div className='tableContainer'>
              <table className='table'>
                <thead>
                  <tr className='table__head'>
                    {config.fields.map((field, i) => {
                      return !field.hidden && <th key={i}>{field.title}</th>
                    })}
                  </tr>
                </thead>
                <tbody>
                  {reportingData.slice(offset,offset + config.nbItemsPerPage).map((item, i) => (
                    <tr key={i} className='table__row'>
                      {config.fields.map((field, k) => {
                        return (
                          !field.hidden && (
                            field.dimension === 'actions' ? (
                              <td key={k + '_' + i} className="buttonTd">
                              {
                                //Field content management
                                manageColumnContent(item, field)
                              }
                            </td>
                            ) : (
                            <td key={k + '_' + i}>
                            {
                              //Field content management
                              manageColumnContent(item, field)
                            }
                          </td>
                           )
                          )
                        )
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paginator
              limit={config.nbItemsPerPage}
              total={nbReporting}
              onChangePage={changePage}
            />
          </div>
        </>
      )}
      {removeModal &&
        <Modal title='Please confirm' onClose={() => displayRemoveModal(false)} >
           <Grid container>
            <Grid item xs={1}>
              <BkIcon path='alert' modifier='active' className='icon' />
            </Grid>
            <Grid item xs={11}>
              Do you want to remove the Reporting of '{selectedItem.reporting_name}'?
            </Grid>
          </Grid>
          <div className='modal__footer'>
            <div className='modal__footer'>
              <BkButton onClick={removeReportingHandler} className='bkButton--delete' >
              {t('button:confirm')}
              </BkButton>
              <BkButton className='bkButton--alternate' onClick={() => displayRemoveModal(false)} >
              {t('button:cancel')}
              </BkButton>
            </div>
          </div>
        </Modal>}
    </>
  )
}

export default ReportingList
