import React from 'react'
import { Link } from 'react-router-dom'
import BkAppBar from '../components/AppBar'
import BkIcon from '../components/icon'
import Scrollable from '../components/Scrollable'

const PageLayout = ({ icon, title, children }) => {
  return (
    <>
      <BkAppBar>
        <div>
          <Link to='/' className='appBar__darkButton'>
            <BkIcon path='arrow' modifier='back' />
          </Link>
          <span className='fullScreen__title'>
            <BkIcon
              path={icon}
              modifier='active'
              className='fullScreen__icon'
            />
            {title}
          </span>
        </div>
      </BkAppBar>
      <div className='container container--fullPage'>
        <div className='pageContainer'>
          <Scrollable>
            <div className='pageContainer__section'>{children}</div>
          </Scrollable>
        </div>
      </div>
    </>
  )
}

export default PageLayout
