import { Grid } from '@material-ui/core'
import React from 'react'
import Widget from './Widget'

const WidgetContainer = ({ template }) => (
  <Grid
    container
    direction='row'
    justifyContent='flex-start'
    alignItems='stretch'
    spacing={3}
  >
    {template && template.contentBlocks.length === 0 && <Grid item><span>No content</span></Grid>}
    {template &&
      template.contentBlocks.map((config, i) => (
        <Widget key={template.id + i} config={config} />
      ))}
  </Grid>
)

export default WidgetContainer
