import bkApi from './bk-api'

export const getReporting = () => {
  return bkApi.get('/v1/reporting')
}

export const createNewReporting = (reportingName, dashboard, periodicity, emailList) => {
  return bkApi.post('/v1/reporting', {
    reporting_name: reportingName,
    dashboard_path:dashboard,
    periodicity:periodicity,
    email_list:emailList
  })
}

export const deleteReporting = (name, user_id, dashboard, periodicity) => {
  return bkApi.delete(`/v1/reporting/${name}`, {params:{
    user_id:user_id,
    dashboard_path:dashboard,
    periodicity:periodicity
  }})
}
