import React, { useMemo } from 'react'
import useLegacy from '../../i18n/legacyEffect'
import TableList from '../tableList/TableList'

export const ExplanationUnivariate = ({ explanation, component }) => {
  const { tLegacy } = useLegacy()
  const items = useMemo(() => {
    const scores = explanation.univariate_scores[component]
    const densities = explanation.univariate_densities[component]
    const n = densities.density.reduce((acc, d) => acc + d, 0)
    return scores.value.map((value, i) => ({
      i,
      value,
      score: +scores.score[i].toFixed(2),
      density: densities.density[i],
      density_rel: +(densities.density[i] / n * 100).toFixed(2),
    }))
  }, [explanation, component])

  const config = useMemo(() => ({
    title: 'Explainability score by value',
    type: 'list',
    compact: true,
    exportCSV: false,
    nbItemsPerPage: 30,
    items: items.map(i => ({
      ...i,
      value: tLegacy(component, i.value),
    })),
    rowIdKey: 'i',
    orderOn: 'score asc',
    fields: [{
      'title': 'Value',
      'dimension': 'value',
    }, {
      'title': 'Score',
      'dimension': 'score',
    }, {
      'title': 'Density (%)',
      'dimension': 'density_rel',
    }]
  }), [tLegacy, items, component])

  return <TableList config={config} displayHeader />
}

export default ExplanationUnivariate
