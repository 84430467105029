import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDrag } from 'react-dnd'
import { DragTypes } from './CustomDashboardEditor'
import { useDispatch } from 'react-redux'
import BkButton from '../../components/Button'
import BkIcon from '../../components/icon'
import Modal from '../../components/Modal'
import Chart from '../charts/Chart'
import Ranking from '../rankings/Ranking'
import Summary from '../summaries/Summary'
import TableList from '../tableList/TableList'
import { WIDGETS_TYPES } from '../../constants'
import { removeBlock, updateBlock } from './store/customDashboardSlice'
import WidgetConfigModal from './WidgetConfigModal'
import WidgetDropZone from './WidgetDropZone'
import ReactTooltip from 'react-tooltip';

const WidgetEditor = ({ config, menuId }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: DragTypes.WIDGET, position: config.position, config },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  })
  const { widgetParams, position, ...widgetConfig } = config
  const dispatch = useDispatch()
  const sizes = {
    superSmall: 2,
    extraSmall: 3,
    small: 4,
    medium: 6,
    large: 8,
    full: 12
  }
  const size = _.get(widgetConfig, ['size'])
  const widgetSize = _.get(sizes, [_.get(widgetConfig, ['size'])])
  const [xs, setSize] = useState(widgetSize)
  const [editModal, setEditModal] = useState(false)
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false)
  const isInvisible = _.get(widgetConfig, 'type') === 'summary'

  useEffect(() => {
    setSize(_.get(sizes, [_.get(widgetConfig, ['size'])]))
  }, [widgetConfig])

  const getWidgetByConfig = widgetConfig => {
    switch (widgetConfig.type) {
      case WIDGETS_TYPES.AREA:
      case WIDGETS_TYPES.GAUGE:
      case WIDGETS_TYPES.MAPWORLD:
      case WIDGETS_TYPES.PIE:
      case WIDGETS_TYPES.POLARAREA:
      case WIDGETS_TYPES.POLARLINE:
      case WIDGETS_TYPES.COLUMNSCATEGORIES:
      case WIDGETS_TYPES.STACKCOLUMNSTIMESTAMPS:
      case WIDGETS_TYPES.STACKCOLUMNSTIMESTAMPSPERCENT:
      case WIDGETS_TYPES.STACKCOLUMNSCATEGORIES:
      case WIDGETS_TYPES.BARSCATEGORIES:
      case WIDGETS_TYPES.STACKBARSCATEGORIES:
      case WIDGETS_TYPES.STACKBARSTIMESTAMPS:
      case WIDGETS_TYPES.CDF:
        return <Chart config={widgetConfig} onFullScreen={() => {}} />
      case WIDGETS_TYPES.SUMMARY:
        return <Summary config={widgetConfig} />
      case WIDGETS_TYPES.RANKING:
        //change value widgetConfig To Config
        return <Ranking config={config} onFullScreen={() => {}} />
      case WIDGETS_TYPES.LIST:
        return <TableList config={widgetConfig} />
      default:
        return <div>types widget unknown {widgetConfig.type}</div>
    }
  }

  const onResizeHandler = size => {
    dispatch(
      updateBlock({
        ...widgetConfig,
        position,
        size,
        widgetParams
      })
    )
  }

  return (
    <>
      <WidgetDropZone newPosition={config.position} xs={xs}>
        {widgetConfig && (
          <div
            className={`widget widget--editor ${
                  isInvisible ? 'widget--invisible' : ''
                } ${'widget--' + size}`}
            ref={drag}
            style={{
              opacity: isDragging ? 0.5 : 1,
              cursor: 'move'
            }}
          >
            <div className='widget__header' >
              <ReactTooltip />
              {/* Add tooltip for customDashboard in mode Edition */}
              {config.type !== WIDGETS_TYPES.SUMMARY && (<h2 className='widget__title' data-tip={widgetConfig.title} data-border="true">
                {widgetConfig.title || 'Summary'
                }
              </h2>)}
              <nav className='widget__actionsContainer'>
                <ul className='widget__actions'>
                {config.type === WIDGETS_TYPES.SUMMARY && (<li className='widget__action'>
                    <BkButton
                      className='bkButton--mini'
                      onClick={() => onResizeHandler('superSmall')}
                    >
                      <BkIcon path='size-16'/>
                    </BkButton>
                  </li>)}
                {/* {config.type === WIDGETS_TYPES.SUMMARY && (<li className='widget__action'>
                    <BkButton
                      className='bkButton--mini'
                      onClick={() => onResizeHandler('superSmall')}
                    >
                      <BkIcon path='size-25' />
                    </BkButton>
                  </li>)} */}
                  <li className='widget__action'>
                    <BkButton
                      className='bkButton--mini'
                      onClick={() => onResizeHandler('small')}
                    >
                      <BkIcon path='size-33' />
                    </BkButton>
                  </li>
                  <li className='widget__action'>
                    <BkButton
                      className='bkButton--mini'
                      onClick={() => onResizeHandler('medium')}
                    >
                      <BkIcon path='size-50' />
                    </BkButton>
                  </li>

                  <li className='widget__action'>
                    <BkButton
                      className='bkButton--mini'
                      onClick={() => onResizeHandler('large')}
                    >
                      <BkIcon path='size-66' />
                    </BkButton>
                  </li>

                  <li className='widget__action'>
                    <BkButton
                      className='bkButton--mini'
                      onClick={() => onResizeHandler('full')}
                    >
                      <BkIcon path='size-100' />
                    </BkButton>
                  </li>
                  {(!widgetConfig.options  || widgetConfig.options.editMode !== false) && (
                  <li className='widget__action'>
                    <BkButton
                      className='bkButton--mini'
                      onClick={() => setEditModal(true)}
                    >
                      <BkIcon path='edit' />
                    </BkButton>
                  </li>)}
                  <li className='widget__action'>
                    <BkButton
                      className='bkButton--mini'
                      onClick={() => setConfirmRemoveModal(true)}
                    >
                      <BkIcon path='close' alt='Remove the widget' />
                    </BkButton>
                  </li>
                </ul>
              </nav>
            </div>
            <div className='widget__content'>{getWidgetByConfig(widgetConfig)}</div>
          </div>
        )}
      </WidgetDropZone>
      {editModal && <WidgetConfigModal onClose={() => setEditModal(false)} menuId={menuId} params={{ ...widgetParams, position }} size={size} />}
      {confirmRemoveModal && (
        <Modal
          title='Please confirm'
          onClose={() => setConfirmRemoveModal(false)}
        >
          Do you want to remove the block named "{config.title}" ?
          <div className='modal__footer'>
            <BkButton
              onClick={() => {
                dispatch(removeBlock(config))
                setConfirmRemoveModal(false)
              }}
            >
              Confirm
            </BkButton>
            <BkButton
              onClick={() => setConfirmRemoveModal(false)}
              className='bkButton--alternate'
            >
              Cancel
            </BkButton>
          </div>
        </Modal>
      )}
    </>
  )
}

export default WidgetEditor
