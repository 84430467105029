import React from 'react'
import useSessionDescription from './hooks/SessionDescriptionEffect'
import SessionDescriptionItem from './SessionDescriptionItem'
import './styles/SessionDescription.scss'

const SessionDescription = ({ config, displayHeader }) => {


  //console.log('session description', config)
  const sessionDescription = useSessionDescription(config)
  let itemsPerRow = config.nbFieldsPerRows ? config.nbFieldsPerRows : 7

  if (itemsPerRow > 10) {
    itemsPerRow = 10
  }

  const arrayToDiv = (sessionDesc)=>{
    //Arrays containing Row components.
    let primaryListDivArray = sessionDesc["primarylist"]
    let containingComponent = []
    let i =0
    if(primaryListDivArray){
      for(i; i < (primaryListDivArray.length/itemsPerRow);i++ )
      {containingComponent.push(
        <div className='sessionDescriptionRowPrimary' key = {i}>
          {primaryListDivArray.slice(i*itemsPerRow,(i+1)*itemsPerRow).map((SessionDescription,j) => (
                  <SessionDescriptionItem key={j+i*itemsPerRow} sessionDescriptionItem={SessionDescription} config={config} itemsPerRow={itemsPerRow}/>))}
        </div>)
      }
    }

    let secondaryListDivArray = sessionDesc["secondarylist"]
    //this is for clean code's sake, we use different indexes for the two loops
    let  lastIndex = primaryListDivArray ? primaryListDivArray.length/itemsPerRow : 0
    let j = 0
    if(secondaryListDivArray && primaryListDivArray)
      containingComponent.push(<div key='DescriptionRowSeparator' className='rowSeparator'></div>)

    if(secondaryListDivArray){
      for(j; j < (secondaryListDivArray.length/itemsPerRow);j++ )
      {containingComponent.push(<div className='sessionDescriptionRow' key = {j + lastIndex}>
        {secondaryListDivArray.slice(j*itemsPerRow,(j+1)*itemsPerRow).map((SessionDescription,k) => (
                <SessionDescriptionItem key={k+(j + lastIndex)*itemsPerRow} sessionDescriptionItem={SessionDescription} config={config} itemsPerRow={itemsPerRow}/>))}
      </div>)
      }
    }

    return <React.Fragment>{containingComponent}</React.Fragment>
  }

  return (
    <>
      {
        <div className='widget__header'>
          <h2 className='widget__title'>Session Description</h2>
        </div>
      }
      <div className='sessionDescription'>
        {
          sessionDescription?(sessionDescription["primarylist"]?.length > 0 ||sessionDescription["secondarylist"]?.length > 0)&&
            arrayToDiv(sessionDescription)
            //JSON.stringify(_.head(sessionDescription.items))

            :<div></div>

    }
      </div>
    </>
  )
}

export default SessionDescription
