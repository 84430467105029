import bkApi from './bk-api'

const selectedExport = (filters, menu, sessionId, nameSelectedSession) => {
    return bkApi.get('/v1/export/selectedexport',  {params : {filters, menu:menu, sessionId:sessionId, nameSelectedSession:nameSelectedSession}})
}

const generateExport = (filters, limit, menu, nameAllSession) => {
    return bkApi.get('/v1/export/generateexport',  {params : {filters, limit, menu:menu, nameAllSession:nameAllSession}})
}

const getExport = (menu) => {
    return bkApi.get('/v1/export/exports', {params : { menu:menu }})
}

const deleteExport = (exportName, menu, userId, filtervalue) => {
    console.log(`the following export will be deleted ${exportName}`)
    return bkApi.delete('/v1/export/delete', {params : {name: exportName,  menu:menu, userId: userId, filtervalue: filtervalue}})
}

export {
    generateExport,
    selectedExport,
    getExport,
    deleteExport
}