import React, { useState } from 'react'
import BkIcon from './icon'
import './styles/collapsable.scss'

const CollapsableBlock = ({ title, children }) => {
  const [isToggled, setIsToggled] = useState(false)
  
  return (
    <div className='collapsableBlock'>
      <header className='collapsableBlock__header'>
        <span className='collapsableBlock__title'>{title}</span>
        
        {!isToggled && (
          <button
            className='collapsableBlock__action'
            onClick={() => setIsToggled(!isToggled)}
          >
            <BkIcon path='minus' />
          </button>
        )}
        {isToggled && (
          <button
            className='collapsableBlock__action'
            onClick={() => setIsToggled(!isToggled)}
          >
            <BkIcon path='plus' />
          </button>
        )}
      </header>
      {!isToggled && (
        <div className='collapsableBlock__content'>
          {children}</div>
      )}
    </div>
  )
}

export default CollapsableBlock
