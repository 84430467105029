import React, { useState } from 'react'
import BkTextField from '../../components/forms/TextField'
import BkButton from '../../components/Button'
import BkSelect from '../../components/forms/Select'
import { createNewReporting} from '../../services/reporting.service'

import { useSelector } from 'react-redux'
import { selectMenu, getAllDashboard } from '../dashboards/store/dashboardSlice.js'

import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import BkAlert from '../../components/Alert'
import * as _ from 'lodash'
import { useTranslation } from 'react-i18next'


export const CreateReporting = ({ params, close, handleNewReporting }) => {

  const { t } = useTranslation()

  const initialStatus = {
    errors: [],
    processing: false
  }
  const [status, setStatus] = useState(initialStatus)
  const menu = useSelector(selectMenu)
  const dashboards = getAllDashboard(menu)

  const periodicityList =
  [
    {
      type: 'daily',
      label: 'Every day'
    },
    {
      type: 'weekly',
      label: 'Every week'
    },
    {
      type: 'monthly',
      label: 'Every month'
    }
  ]

  const dashboardList = dashboards
  // const dashboardList = dashboards.map((board) => {
  //   return {type: board, label: board}
  // }
  // )

  const [widgetParams, setWidgetParam] = useState(params || {
    reportingName: '',
    dashboard: _.get(dashboardList, [0]),
    periodicity: _.get(periodicityList, [0]),
    emailList: ''
  })

  //Modification handlers
  const onChangeReportingNameHandler = async (reportingName) => {
    setWidgetParam({ ...widgetParams, reportingName })
  }
  const onChangeDashboardHandler = async (dashboard) => {
    setWidgetParam({ ...widgetParams, dashboard })
  }
  const onChangePeriodicityHandler = async (periodicityId) => {
    setWidgetParam({ ...widgetParams, periodicity:periodicityId })
  }
  const onChangeEmailListHandler = async (emailList) => {
    setWidgetParam({ ...widgetParams, emailList })
  }

  //---------------------------------------------------------------------------------------
  //WARNING: Check the need for this
  const history = useHistory()
  const closeHandler = () => {
    if (close) {
      close()
    } else {
      history.push('/login')
    }
  }

// Expected output: true
  // ---------------------------------------------------------------------------
  //Creation of a new user, with basic check on set/selected parameters
  const checkAndCreateReporting = () => {
    setStatus({ errors: [], processing: true })
    let isOk = true
    let messageErrors=[]

    if (widgetParams.emailList === "") {
      isOk = false
      messageErrors.push({code: 0, message: t("errorsSyntax:no_email")})
    } else if (widgetParams.emailList !== "") {
      const emails = widgetParams.emailList.split(';')
      for (let i =0 ; i< emails.length; i ++ ) {
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(emails[i].trim())) {
          isOk = false
          messageErrors.push({code: 0, message: t("errorsSyntax:not_an_email") + emails[i].trim()})
        }
      }
    }
    if (widgetParams.reportingName === "") {
      isOk = false
      messageErrors.push({code: 0, message: t("errorsSyntax:no_name")})
    }

    //if the new user is successfully added then refresh the table so that changes are displayed
    if (!isOk) {
      setStatus({
        processing: false,
        errors: messageErrors
      })
    }
    else {
      createNewReporting(widgetParams.reportingName, widgetParams.dashboard.type, widgetParams.periodicity.type, widgetParams.emailList).then(
        () => {
          setStatus({ errors: [], processing: false })
          handleNewReporting(true)
          closeHandler()
        }
      ).catch((error) => {
        console.log(error)
        setStatus({
          processing: false,
          errors: error.response ? error.response.data.errors : []
        })
        //if the user is not successfully added there is no need to actualize the table
        handleNewReporting(false)
      } )
      handleNewReporting(false)
    }
  }

  //---------------------------------------------------------------------------------------
  //Error popup displaying - TODO: find/create more specific messages?
  const displayErrors = (
    <BkAlert severity='error' className='input'>
      {status &&
      status.errors &&
      status.errors.length > 0 &&
      status?.errors?.map((msg, i) => (
        <div key={i}>{t('errorsReporting:' + msg.message)}</div>
      ))}
    </BkAlert>
  )

  //---------------------------------------------------------------------------------------
  //Request processing status
  const processing = <CircularProgress />

  //---------------------------------------------------------------------------------------
  const form = (
    <div>
      {status.errors.length > 0 ? displayErrors : null}
      <BkTextField
        placeholder='Name'
        label='Name'
        value={widgetParams.reportingName}
        type='reportingName'
        onChange={(e) => onChangeReportingNameHandler(e.target.value)}
      />
      <BkSelect
        title='Periodicity'
        selected={widgetParams.periodicity}
        options={periodicityList}
        optionConfig={{ label: 'label' }}
        onChange={onChangePeriodicityHandler}
        displayHelper helper='Select periodicity'
      />
      <BkSelect
        title='Dashboards'
        selected={widgetParams.dashboard}
        options={dashboardList}
        optionConfig={{ label: 'label' }}
        onChange={onChangeDashboardHandler}
        displayHelper helper='Select dashboard'
      />
      <BkTextField
        placeholder='Recipients'
        label='Recipients'
        value={widgetParams.emailList}
        type='emailList'
        onChange={(e) => onChangeEmailListHandler(e.target.value)}
        displayHelper helper="Enter emails using ';' as separator"
      />

      <div className='modal__footer'>
        <BkButton
          type='submit'
          variant='contained'
          color='primary'
          onClick={checkAndCreateReporting} >
            Save
        </BkButton>
        <BkButton onClick={closeHandler} className='bkButton--alternate'>
          Cancel
        </BkButton>
      </div>
    </div>
  )

  return (
    <div>{status.processing ? processing : form}</div>
  )
}
