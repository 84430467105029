import React from 'react'
import './styles/menu.scss'
import './styles/menu-item.scss'
import BkIcon from './icon'
import { Link } from 'react-router-dom'
import * as _ from 'lodash'

const BkMenuItem = ({
  to,
  className,
  children,
  icon,
  active,
  onClick,
  isLink,
  id
}) => {
  icon = icon && (
    <BkIcon
      path={icon}
      modifier={active ? 'active' : null}
      className='bkMenuItem__icon'
    />
  )
  if (_.isUndefined(isLink)) {
    isLink = true
  }
  const item = isLink ? (
    <Link className='bkMenu__link' to={to} onClick={onClick} id={id}>
      {icon}
      {children}
    </Link>
  ) : (
    <span className='bkMenu__link' onClick={onClick} id={id}>
      {icon}
      {children}
    </span>
  )
  return (
    <li className={'bkMenu__item bkMenuItem ' + (className || '')}>{item}</li>
  )
}

export default BkMenuItem
