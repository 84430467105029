import * as _ from 'lodash'
import React, { useState } from 'react'
import BkIcon from '../icon'
import BkMenu from '../Menu'
import BkMenuItem from '../MenuItem'
import BkFieldSet from './FieldSet'
import BkSelectedValues from './SelectedValues'

const BkAutocomplete = ({
  title,
  placeholder,
  helper,
  onChange,
  onApply,
  onFocus,
  onRemove,
  options,
  selected
}) => {
  const [displayMenu, setDisplayMenu] = useState(false)
  const [term, setTerm] = useState('')

  const onFocusHandler = () => {
    setDisplayMenu(true)
    if (!_.isUndefined(onFocus)) {
      onFocus()
    }
  }
  const onFocusOutHandler = () => {
    setDisplayMenu(false)
  }
  const onChangeHandler = e => {
    setTerm(e.target.value)
    if (!_.isUndefined(onChange)) {
      onChange(term)
    }
  }
  const onApplyHandler = () => {
    setDisplayMenu(false)
    if (!_.isEmpty(_.trim(term))) {
      onApply(term)
      setTerm('')
    }
  }

  const selectedValues = displayMenu &&
    _.isEmpty(_.trim(term)) &&
    selected &&
    selected.length > 0 && (<BkSelectedValues selected={selected} onRemove={onRemove} />)

  return (
    <BkFieldSet
      title={title}
      onFocusOut={onFocusOutHandler}
      className='autocomplete'
      helper={displayMenu && <span>{helper}</span>}
    >
      <input
        type='text'
        className='autocomplete__input'
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
        value={term}
        placeholder={placeholder}
      />
      {(displayMenu || !_.isEmpty(_.trim(term))) && (
        <button
          className='autocomplete__button bkButton--mini'
          onClick={onApplyHandler}
          disabled={_.isEmpty(_.trim(term))}
        >
          <BkIcon path='check' />
        </button>
      )}
      {selectedValues}
      {displayMenu &&
      !_.isEmpty(_.trim(term)) &&
      options &&
      options.length > 0 && (
        <BkMenu className='autocomplete__menu' open={displayMenu}>
          {options.map((o, i) => {
            if (o.includes(term)){
              return (
                <BkMenuItem
                  key={i}
                  isLink={false}
                  className='autocomplete__item'
                  onClick={() => {
                    setTerm(o)
                    onFocusOutHandler()
                  }}
                >
                  {o}
                </BkMenuItem>
                )
            }  
          })}
        </BkMenu>
      )}
    </BkFieldSet>
  )
}

export default BkAutocomplete
