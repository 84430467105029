// file contaning all fixed values for filter type 'dropdown fix' and 'dropdown fiw contains'
// json structure permit to access quickly using filter id as key

export const filtersList ={
    pl_network_used_list:
    [
        '10',
        '20',
        '10-20',
        '10-30',
        '30'
    ],

    pl_network_used_subtype_list:
    [
        '0',
        '1',
        '11',
        '12',
        '21',
        '22',
        '23',
        '24',
        '99'
    ],

    nano_network_used_list:
    [
        '10',
        '20',
        '10-20',
        '10-30',
        '30'
    ],

    nano_network_used_subtype_list:
    [
        '0',
        '1',
        '11',
        '12',
        '21',
        '22',
        '23',
        '24',
        '99'
    ],

    ad_ad_type:
    [
        '0',
        '1',
        '2'
    ],

}