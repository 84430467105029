import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'

import * as PageService from "../../services/pages.service";

import BkButton from '../../components/Button'
// import UserDataWidget from "./UserDataWidget";
import Modal from '../../components/Modal'
import { CreateReporting } from './CreateReporting'
import ReportingList from './ReportingList';

//----------------------------------------------------------------------
//  Administration console Groups:
//----------------------------------------------------------------------
const Reporting = ({mainMenu, subMenu}) => {

  const path = [mainMenu, subMenu];
  const [createReportingModalToggle, setCreateReportingModalToggle] = useState(false)
  const [template, setTemplate] = useState(null);  //const [template, setTemplate] = React.useState([]);
  const [refresh, setRefresh] = useState(false)
  useEffect(()=>{
      if(refresh){
          setTemplate(null)
          getTemplate(path);
      }

  },[refresh])

  useEffect(() => {
    getTemplate(path);
  }, [path[1] /*, template*/ ]);

  //----------------------------------------------------------------------
  const createReportingModal = (
    <Modal title='Create new report' onClose={() => {
          setCreateReportingModalToggle(false)
        }
      }>
      <CreateReporting close={() => {
          setCreateReportingModalToggle(false)
         }
        }
        handleNewReporting = {(refr)=>{setRefresh(refr)}}
      />
    </Modal>
  )

   //Page loading using path (check next useEffect)
   const getTemplate = async (path) => {
    const { data } = await PageService.getPage(path[1]);
    setTemplate(data);
    console.log("[REPORTING] reloading=")
  };

  return (
    <div>
      <div className="pageContainer__section">
        <header className="pageContainer__header">

          <h1 className="pageContainer__title">
            <img
              src={require("../../assets/icons/reporting.svg").default}
              alt="Bka100"
              width="50"
              height="25"
            />
            Reporting
          </h1>
          <div  className='widget__actions'>
            <BkButton className="addReportingButton" onClick={() => setCreateReportingModalToggle(true)}  variant="contained" color="primary" >
                Add reporting
            </BkButton>
          </div>
        </header>


        <div className="tabReportingWidget">
          {template &&
            template.contentBlocks.map((config, i) => (
              <Grid key={i} item xs={12}>
              {config && (
                <div className={`widget  ${'widget--' + config.size}`} >
                  <div className='widget__content'>
                    <ReportingList key={i} config={ config } />
                  </div>
                </div>
              )}
              </Grid>
            ))
          }
        </div>
        { createReportingModalToggle && createReportingModal /*createUserModalToggle && createUserModal&& */}
      </div>
    </div>
  );


}

export default Reporting