import React, { Fragment, useEffect, useState } from 'react'
import * as PageService from "../../services/pages.service";
import BkButton from '../../components/Button'
import UserDataWidget from "./UserDataWidget";
import Modal from '../../components/Modal'
import { CreateGroup } from './CreateGroup'

//----------------------------------------------------------------------
//  Administration console Groups:
//----------------------------------------------------------------------
const AdminGroups = ({mainMenu, subMenu}) => {

    const path = [mainMenu, subMenu];
    const [createGroupModalToggle, setCreateGroupModalToggle] = useState(false)
    const [template, setTemplate] = useState(null);  //const [template, setTemplate] = React.useState([]);
    const [refresh, setRefresh] = useState(false)
    useEffect(()=>{
        if(refresh){
            setTemplate(null)
            getTemplate(path);
        }

    },[refresh])

  //----------------------------------------------------------------------
  const createGroupModal = (
    <Modal title='Create new group' onClose={() => {
          setCreateGroupModalToggle(false)
        }
      }>
      <CreateGroup close={() => {
          setCreateGroupModalToggle(false)
         }
        }
        handleNewGroup = {(refr)=>{setRefresh(refr)}}
      />
    </Modal>
  )

  useEffect(() => {
    // //All the code unneeded to perform when reloading?
    // const getTemplate = async (path) => {
    //   const { data } = await PageService.getPage(path[1]);
    //   // if (data && data.filters) {
    //   //   const defaultFilters = {};
    //   //   for (const [key, value] of Object.entries(data.filters)) {
    //   //     switch (key) {
    //   //       // case "current_filters":
    //   //       //   break;
    //   //       // case "percentage_table":
    //   //       //   defaultFilters.table = value;
    //   //       //   break;
    //   //       default:
    //   //         defaultFilters[key] = value;
    //   //         break;
    //   //     }
    //   //   }
    //   //   dispatch(setDefaultFilter(defaultFilters));
    //   // }
    //   setTemplate(data);
    // };
    getTemplate(path);
  }, [path[1] /*, template*/ ]);

   //Page loading using path (check next useEffect)
   const getTemplate = async (path) => {
    const { data } = await PageService.getPage(path[1]);
    setTemplate(data);
    console.log("[ADMIN] reloading=")
  };

  return <Fragment>
            <header className="pageContainer__header">
              <h2>
                <img
                  src={require("../../assets/icons/admin.svg").default}
                  alt="Bka100"
                  width="30"
                  height="15"
                />
              Add/Remove Groups</h2>
              <div  className='widget__actions'>
                <BkButton className="addGroupButton" onClick={() => setCreateGroupModalToggle(true)} variant="contained" color="primary" >
                    Add group
                </BkButton>
              </div>
            </header>
            <div className="tabGroupsWidget">
              {template &&
                template.groupsContentBlocks.map((config, i) => (
                  <UserDataWidget key={i} config={config} />
                ))}
            </div>
            { createGroupModalToggle && createGroupModal /*createUserModalToggle && createUserModal&& */}
        </Fragment>

}

export default AdminGroups