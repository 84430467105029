import React, { useRef, useEffect, useState } from 'react'
import './styles/filters.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'


import {
  selectDisplayFilters,
  toggleFilters,
  selectCurrentDashboard,
  selectSubMenuType,
  selectMenuType
} from '../dashboards/store/dashboardSlice'
import {
  getFilterCategories,
  getReducedFilters,
  updateAppliedFilter,
  selectAppliedFilters,
  selectFiltersConfig,
  setFiltersConfig,
  resetapplyFilter
} from './store/FiltersSlice'
import { getFilters, updateFilterQueries } from '../../services/filter.service'
import CurrentFilters from './CurrentFilters'
import CurrentFiltersCount from './CurrentFiltersCount'
import FiltersCategoryBlock from './FiltersCategoryBlock'
import CollapsableBlock from '../../components/CollapsableBlock'
import Scrollable from '../../components/Scrollable'

import TotalSessions from './TotalSessions'
import useLegacy from '../../i18n/legacyEffect'

import FilterPresetMngt from './FilterPresetMngt'
//import '../../components/styles/modal.scss'

const FiltersContainer = () => {
  const containerEl = useRef();
  const { tLegacy } = useLegacy()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const submenuType = useSelector(selectSubMenuType)
  const menuType = useSelector(selectMenuType)
  const currentPage = useSelector(selectCurrentDashboard)

  const isDisplayedFilter = useSelector(selectDisplayFilters)
  const filtersConfig = useSelector(selectFiltersConfig)
  const appliedFilters = useSelector(selectAppliedFilters)

  const filtersCategoriesList = getFilterCategories(filtersConfig)
  const reducedFilters = getReducedFilters(appliedFilters)
  const [hideFilters, setHideFilters] = useState(false)
  const [previousSubmenuType, setPreviousSubmenuType] = useState(0)
  // maybe use submenutype to replace 0
  let tooOldRequest = 0


  //Submenu has change, get list of filter associate to it
  // IF = No filter for Hybrid CustomDashboard
  useEffect(() => {
    //console.log('useEffect - FiltersContainer - submenuType', submenuType)
    if (submenuType) {
      // when switch between 2 different sub menu, reset all filters
      if (currentPage[0] === 'dashboard' && previousSubmenuType !== submenuType) {
        dispatch(resetapplyFilter(appliedFilters))
      }
      setPreviousSubmenuType(submenuType)
      getFilters({ submenuid: submenuType }).then(res => {
        // Condition permit to keep the good filters when refresh(F5) with the good layout(PLayer/CDN...)
        if (tooOldRequest === 0) {
          dispatch(setFiltersConfig(res.data))
        }
      })
      // hide filter for hybrid custom dashboard and trending dashboard
      if (submenuType === 90 || menuType === 60) {
        setHideFilters(true)
      } else {
        setHideFilters(false)
      }

    }
    return (() => {
      tooOldRequest = 1
    })
  }, [submenuType])



  const collapseHandler = () => {
    dispatch(toggleFilters())
  }
  

 useEffect(() => {
    updateFilterQueries(appliedFilters)
  },[appliedFilters]

  )

  //New filter is applied, update the store with it
  function onChange(id, name, label, values) {
    //console.log('onChange - dispatch(updateAppliedFilter(')
    dispatch(updateAppliedFilter({ id, name, label, values }))
  }

  //Remove a filter value from applied filters 
  const removeValueHandler = filterValue => {
    const filter = appliedFilters.find(f => f.name === filterValue.name)
    //As there could be more than one value associate to a filter name, remove only the right value)
    onChange(
      filter.id,
      filter.name,
      filter.label,
      filter.values.filter(v => v.value !== filterValue.value.value)
    )
  }

  if (containerEl.current) {
    containerEl.current.ontransitionend = () => {
      window.dispatchEvent(new Event('resize'));
    }
  }



  //---------------------------------------------------------------------------------------
  const displayFilters =
    <div className={`filtersContainer ${!isDisplayedFilter ? 'filtersContainer--collapsed' : ''}`} ref={containerEl} >
      <header className='filtersContainer__header' onClick={collapseHandler}>
        <span className='filtersContainer__title'>
          {t('app:filters')}

          {isDisplayedFilter && (
            <CurrentFiltersCount filters={reducedFilters} />
          )}

        </span>
        {isDisplayedFilter && <TotalSessions />}
      </header>

      {
        //TODO (BKA100-1443): Filter preset component (Visible even when no filters are selected)
        isDisplayedFilter && 
        <FilterPresetMngt onUpdateFilters={onChange} />
      }

      {reducedFilters.length > 0 && (
        <>
          <CurrentFilters
            filters={reducedFilters}
            count={!isDisplayedFilter}
            onRemove={removeValueHandler}
          />
        </>
      )}

      {isDisplayedFilter && filtersCategoriesList && filtersCategoriesList.length > 0 && (
        <Scrollable>
          <div className='filtersContainer__filters'>
            {filtersCategoriesList.map((filters_category_block, i) => (
              <CollapsableBlock
                key={i}
                title={tLegacy('filters_category', filters_category_block.category_name)}
              >
                <FiltersCategoryBlock
                  filtersCategory = {filters_category_block}
                  onChange = {onChange}
                />
              </CollapsableBlock>
            ))}
          </div>
        </Scrollable>
      )}
    </div>


  // When filters must be hidden, create filters container collapsed to resize correctly the graph 
  return <>
    {
      hideFilters ? <div className={`filtersContainer filtersContainer--collapsed`} ref={containerEl} >
      </div> : displayFilters
    }
  </>
}

export default FiltersContainer
