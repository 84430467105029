/**
 * @param {LegacyEffect} tLegacy
 * @param {String} component
 * @returns {String} Display association applied to the component, taking interaction and splits into account.
 */
export const getComponentDisplayAssociation = (tLegacy, component) => {
  if (!component) {
    return ''
  }

  const interaction = component.split(' x ')
  if (interaction.length > 1) {
    return interaction.map(i => getComponentDisplayAssociation(tLegacy, i)).join(' x ')
  }

  const split = component.split('/')
  if (split.length > 1) {
    return `${getComponentDisplayAssociation(tLegacy, split[0])}/${split.slice(1).join('/')}`
  }

  return tLegacy('fields', component)
}
