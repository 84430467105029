import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import BkButton from '../../components/Button'
import BkSelect from '../../components/forms/Select'
import BkTextField from '../../components/forms/TextField'
import Modal from '../../components/Modal'
import * as DashboardManager from '../../services/dashboards.service'
import useReloadMenu from '../dashboards/hooks/reloadMenu'
import { changePage, selectRootMenu, getRootPage } from '../dashboards/store/dashboardSlice'
import { initTemplate, saveTemplate } from './store/customDashboardSlice'

const NewDashboardModal = () => {
  const dispatch = useDispatch()
  const [dashboardTypes, setTypes] = useState(null)
  const [title, setTitle] = useState('')
  const [template, setTemplate] = useState(null)
  const [redirect, setRedirect] = useState(false)
  const rootMenu = useSelector(selectRootMenu)
  const rootDashboard = getRootPage(rootMenu)
  const reloadMenu = useReloadMenu()

  const saveHandler = async () => {
    let _title = title
    if (_.isEmpty(_title) && !_.isEmpty(template)) {
      _title = 'My dashboard ' + template.title
    }
    if (!_.isEmpty(_title) && !_.isEmpty(template)) {
      const dashboard = {
        ...template,
        title: _title,
        headerTitle: _title
      }
      const { data } = await DashboardManager.save(dashboard)
      reloadMenu()
      dispatch(saveTemplate(data))
      changePage(dispatch, ['dashboards', data.routerId])
      setTemplate(data)
      setRedirect(true)
    }
  }

  const cancelHandler = () => {
    changePage(dispatch, rootDashboard)
    dispatch(saveTemplate(null))
    setTemplate(null)
    setRedirect(true)
  }

  useEffect(() => {
    const fetchSections = async () => {
      const { data } = await DashboardManager.getSections()
      setTypes(data)
    }
    dispatch(initTemplate())
    fetchSections()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {redirect && !_.isNull(template) && !_.isNull(template.routerId) && <Redirect to={'/dashboards/' + template.routerId + '/edit'} />}
      {redirect && _.isNull(template) && <Redirect to='/' />}
      <Modal
        title='Create a new dashboard'
        onClose={cancelHandler}
      >
        <BkTextField
          label='Title'
          placeholder='My dashboard'
          helper='The title displayed in submenu of custom dashboard.'
          value={title}
          onChange={e => setTitle(e.target.value)}
          disabled={!_.isEmpty(title) && !_.isNull(template)}
        />
        {!_.isNull(dashboardTypes) && (
          <BkSelect
            title='Type'
            // 2040 Remove temporary Cloud PVR => Only disabled CloudPVR
            options={_.concat(_.slice(dashboardTypes, [0], [3]), _.slice(dashboardTypes, [4], [9]))}
            // options={dashboardTypes}
            selected={template}
            optionConfig={{ label: 'title' }}
            onChange={selected => setTemplate(selected)}
          />
        )}
        <div className='modal__footer'>
          <BkButton onClick={saveHandler}>Save</BkButton>
          <BkButton
            onClick={cancelHandler}
            className='bkButton--alternate'
          >
            Cancel
          </BkButton>
        </div>
      </Modal>
    </>
  )
}

export default NewDashboardModal
