import * as axios from 'axios'
import { BKAPI_URL, BKA_PUBLIC_URL } from '../environment'
import { getSession, removeSession } from './auth.service'
import * as _ from 'lodash'

console.log({
  BKAPI_URL
})
const bkApi = axios.create({ baseURL: BKAPI_URL })

bkApi.interceptors.request.use(request => {
  const token = getSession()
  if (token) {
    request.headers.Authorization = 'Bearer ' + token
  }
  return request
})

bkApi.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (_.get(error, ['response', 'status']) === 401) {
      console.log("Receive 401")
      removeSession()
      // logout redirecting to login page as session is removed
      window.location.pathname = BKA_PUBLIC_URL
    } else 
      return Promise.reject(error)
  }
)

export default bkApi
