import { Grid } from '@material-ui/core'
import React from 'react'
import NewWidget from './NewWidget'
import WidgetDropZone from './WidgetDropZone'
import WidgetEditor from './WidgetEditor'

const WidgetsManager = ({ contentBlocks, menuId }) => {
  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='stretch'
      spacing={3}
    >
      {contentBlocks.map((config, i) => (
        <WidgetEditor key={i} config={config} menuId={menuId} />
      ))}
      <WidgetDropZone newPosition={contentBlocks.length}>
        <NewWidget menuId={menuId} />
      </WidgetDropZone>
    </Grid>
  )
}
export default WidgetsManager
