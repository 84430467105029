import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { summary } from '../../../services/summary.service'
import {   getFilterForRequest,
  selectPeriodZoom, 
  selectPeriod,
  selectAppliedFilters,
  selectTimeZone } from '../../filters/store/FiltersSlice'
import * as _ from 'lodash'
import { logout } from '../../authentication/store/authSlice'

const useSummary = config => {
  const [summaryData, setData] = useState(null)
  const dispatch = useDispatch()

  const period = useSelector(selectPeriod)
  const periodZoom = useSelector(selectPeriodZoom)
  const appliedFilters = useSelector(selectAppliedFilters)
  const timezone = useSelector(selectTimeZone)

  const filtersForRequest = getFilterForRequest(periodZoom, period, appliedFilters, timezone)
  
  useEffect(() => {
    const requests = _.get(config, 'requestItems')
    const getSummaryData = async () => {
      const response = await summary({
        ...config,
        requestItems: requests.map(requestConfig => ({
          ...requestConfig
        }))
      }, filtersForRequest)
      .catch((error) => {
          if (error.response.status === 403)
        {
          dispatch(logout())
        }
    })
      setData(_.get(response, 'data'))
    }
    
    // put a loading message on each summary items
    let tmpSummary = _.get(config, 'summaryItems')
    tmpSummary = tmpSummary.map(summaryConfig => ({
      ...summaryConfig, 'value':'Loading...'
    }))
    setData(tmpSummary)
    //setData([{'name':'data', 'value':'Loading...'}])
    getSummaryData()
  }, [config, period, periodZoom, appliedFilters, timezone])

  return summaryData
}

export default useSummary
