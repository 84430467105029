import React, { useRef, useEffect, useState } from 'react'
import './styles/filters.scss'
import { useDispatch, useSelector } from 'react-redux'

import BkSelect from '../../components/forms/Select'
import { selectMenuType } from '../dashboards/store/dashboardSlice'
import { selectCurrentUser } from '../authentication/store/authSlice'
import {
    updateAppliedFilterList,
    selectAppliedFilters,
    resetapplyFilter,
    selectCurrentPreset,
    setCurrentPreset
} from './store/FiltersSlice'

import BkIcon from '../../components/icon'
import BkButton from '../../components/Button'

import '../../components/styles/modal.scss'
import Modal from "../../components/Modal";
import BkAlert from '../../components/Alert'
import BkTextField from '../../components/forms/TextField'

import {
    getSavedPresets,
    getPresetFilters,
    savePresetFilters,
    savePreset,
    removePreset,
    updatePreset
} from '../../services/filter.service'


// BKA100-1443 : Management of preset filters
const FilterPresetMngt = (config, { onUpdateFilters }) => {
    // const { tLegacy } = useLegacy()
    // const { t } = useTranslation()
    const dispatch = useDispatch()
    const processing = '' //<CircularProgress />

    const initialStatus = { errors: [], processing: false }
    const [status, setStatus] = useState(initialStatus)

    const presetNone = [{ id: -1, name: "none" }]

    const user = useSelector(selectCurrentUser) //Get user email
    const menuId = useSelector(selectMenuType) //Get menu id
    const appliedFilters = useSelector(selectAppliedFilters) //Get applied filters (needed for saving and reseting)
    //const reducedFilters = getReducedFilters(appliedFilters)

    const currentPreset = useSelector(selectCurrentPreset) //To improve: persistance of

    const currentId = useRef(-1)
    const currentMenu = useRef(10)
    const previousPreset = useRef(-2)

    const [presetName, setPresetName] = useState('') //For modal's textfield's callback
    const [currentSelectedPreset, setCurrentSelectedPreset] = useState(currentPreset) //useState(presetNone[0])
    const [currentPresetList, setCurrentPresetList] = useState(presetNone[0]) //(presetListStored)

    const [showFilterPreset, setShowFilterPreset] = useState(false)
    const [isSavingModalVisible, setIsSavingModalVisible] = useState(false)
    const [isUpdatingModalVisible, setIsUpdatingModalVisible] = useState(false)
    const [isDeletingModalVisible, setIsDeletingModalVisible] = useState(false)

    //----------------------------------------------------------------------------------
    const doOpenSavePreset = () => {
        //console.log('DO SAVE PRESET => ')
        //toggleModalVisible()
        if (!currentSelectedPreset || currentSelectedPreset.id === presetNone[0].id) {
            setIsSavingModalVisible(true)
        }
        else {
            setIsUpdatingModalVisible(true)
        }
    }
    //-----------------------------------------
    function doDisplayDeletePreset(currentPreset) {
        //console.log('DO DISPLAY DELETE PRESET => ', currentPreset)
        setIsDeletingModalVisible(true)
    }
    //-----------------------------------------
    function doClearPreset() {
        //console.log('DO CLEAR PRESET')
        dispatch(resetapplyFilter(appliedFilters))
    }

    //----------------------------------------------------------------------------------
    //Triggered while changing menu or reloading
    useEffect(() => {

        if (currentMenu === menuId && currentPreset && currentPreset.id !== -1){
            currentId.current = currentPreset.id
            setCurrentSelectedPreset( currentPreset )
        }

        fetchPresetList()
    }, [user.email, menuId])

    //Should be triggered after any preset list reloading (saving, refreshing)
    useEffect(() => {
        let selectedPreset = presetNone[0]
        if (currentPresetList && currentPresetList.length > 0 /*&& currentId.current !== -1*/ && currentPreset.id !== -1) {
            //let index = currentPresetList.findIndex(currentSelectedPreset)
            selectedPreset = currentPresetList.find((elmt) => elmt.id === currentPreset.id /*currentId.current*/)

            //Error case
            if (!selectedPreset) {
                selectedPreset = presetNone[0]
            }
        }

        currentId.current = selectedPreset.id
        setCurrentSelectedPreset(selectedPreset) //Will trigger the 2nd UseEffect ([currentSelectedPreset])
    }, [currentPresetList])

    //Should be triggered after any preset selection (adter saving, through the Select component)
    useEffect(() => {
        if (currentPresetList && currentPresetList.length > 0 && currentSelectedPreset) {
            changeCurrentPresetId( currentSelectedPreset )
        }
    }, [currentSelectedPreset])

    //---------------------------------------------------------------------------------------
    const fetchPresetList = async () => {
        /*await*/
        getSavedPresets(menuId, user.email).then((res) => {
            if (res.data.preset && res.data.preset.length > 0) {
                let presets = [presetNone[0]]
                res.data.preset.map((preset) =>
                    presets.push({ id: preset.id, name: preset.name })
                )
                //console.log('Dispatch setAvailablePresets - presets : ', presets)

                //Saving the list of user presets in the Select
                setCurrentPresetList(presets)
                //
                setShowFilterPreset(true)
            }
            else {
                let presets = [presetNone[0]]
                setCurrentPresetList(presets)

                setShowFilterPreset(false)
            }
        })
    }

    //---------------------------------------------------------------------------------------
    // "Select" change handler
    const onChangeCurrentSelectHandler = (selectedPreset) => {
        //console.log('onChangeCurrentSelect::selectedPreset', selectedPreset)
        currentId.current = selectedPreset.id

        dispatch(setCurrentPreset(selectedPreset))
        setCurrentSelectedPreset(selectedPreset)
    }
    //---------------------------------------------------------------------------------------
    //Loading of filters for a selected preset
    const changeCurrentPresetId = /*async*/ (selectedPreset) => {

        //console.log('LOG onChangeCurrentPresetIdHandler 0', selectedPreset)
        if (selectedPreset && selectedPreset != null && selectedPreset.id !== -1) {
            setPresetName(selectedPreset.name)
            if (previousPreset.current !== -2 ){ // apply preset filters only if not coming from another submenu. If coming from another submenu then keep applied filters
                //Loading filters
                getPresetFilters(selectedPreset.id).then((res) => {
                    if (res.data.preset && res.data.preset.length > 0) {
                        //regroup filters by id/name
                        const filtersToApply = []
                        res.data.preset.map((filter) => {
                            //Sub value of a filter
                            const filterValue = { operator: filter.operator, value: filter.value }
                            //Searching for a filter with the same id, in the list of filters to apply
                            let filterToFill = filtersToApply.find(e => e.id === filter.fk_filters_list_id)
                            if (filterToFill) {
                                //Adding a new value to values, if a filter of the same id was already existing
                                filterToFill.values.push(filterValue)
                            }
                            else {
                                //New filter. There was no existing filter with the same id
                                const newFilter = { id: filter.fk_filters_list_id, name: filter.column_name, label: filter.column_name, values: [filterValue] }
                                filtersToApply.push(newFilter)
                            }
                        })

                        //Direct assignement
                        dispatch(updateAppliedFilterList(filtersToApply))
                    }
                })
            }
        }
        else {
            // If currentPresetFilter === none Reset all filter allready selected
            if (previousPreset.current !== -2 ){//reset filter only if not coming from another submenu
                doClearPreset(appliedFilters)
            }

        }
        previousPreset.current = selectedPreset.id
    }

    //---------------------------------------------------------------------------------------
    //Modal's textfield modification handler
    const onChangePresetNameHandler = async (_presetName) => {
        setPresetName(_presetName)
    }
    //---------------------------------------------------------------------------------------
    function onSavePreset() {
        setStatus({ errors: [], processing: true })
        if (presetName === '') {
            setStatus({
                processing: false,
                errors: [{ code: 0, message: 'Providing a preset name is mandatory.' }]
            })
        }
        else if (presetName === presetNone[0].name) {
            setStatus({
                processing: false,
                errors: [{ code: 0, message: 'Providing a preset name other than "None" is strongly advised.' }]
            })
        }
        else {
            //Format a preset for saving
            let preset = { name: presetName, email: user.email, menuid: menuId }
            //Formating filters for saving in DB
            let filters = []
            appliedFilters.map((filter) => {
                filter.values.map((filterValues) => {
                    let filterElmt = { filter_id: filter.id, operator: filterValues.operator, value: filterValues.value }
                    filters.push(filterElmt)
                })
            })
            //Saving
            savePreset(preset, filters).then((res) => {
                console.log('Saved successfully - res.data=', res.data.preset_id)
                setStatus({ errors: [], processing: false })

                currentId.current = res.data.preset_id
                let savedPresetId = res.data.preset_id

                //Reload presets
                fetchPresetList()

                //Set new currently selected Preset
                getSavedPresets(menuId, user.email).then((res) => {
                    console.log('RES=', res.data.preset)
                    if (res.data.preset && res.data.preset.length > 0) {

                        let savedPreset = res.data.preset.find((element) => {
                            console.log('element.id', element.id)
                            console.log('savedPresetId', savedPresetId)
                            if (element.id === savedPresetId){
                                console.log('ELEMENT=', element)
                                return element}
                            }
                        )
                        setCurrentSelectedPreset(savedPreset)
                    }
                })

                //Hidding the modal
                closePresetModal()

            }
            ).catch((error) => {
                setStatus({
                    processing: false,
                    errors: error.response ? error.response.data.errors : []
                })
            })

            //reducedFilters
            setStatus({ errors: [], processing: false })
        }
    }

    //---------------------------------------------------------------------------------------
    function onUpdatePresetFilters() {

        //Only modify the name if something was suggested
        let updatedName = currentSelectedPreset.name
        if (presetName.length > 0){
            updatedName = presetName
        }

        let presetId = currentSelectedPreset.id
        let id = currentSelectedPreset.id
        let preset = { id: id, name: updatedName, email: user.email, menuid: menuId }

        updatePreset(preset).then((res) => {
            console.log('Updated successfully - res=', res)
            setStatus({ errors: [], processing: false })

            currentId.current = id
        }
        ).catch((error) => {
            console.error('Updating - catch error: ', error)
            setStatus({
                processing: false,
                errors: error.response ? error.response.data.errors : []
            })
        })
        //Formating filters for saving in DB
        let filters = []
        appliedFilters.map((filter) => {
            filter.values.map((filterValues) => {
                console.log('Filter', filter.name, filter.label, filterValues.operator, filterValues.value)
                let filterElmt = { filter_id: filter.id, operator: filterValues.operator, value: filterValues.value }
                filters.push(filterElmt)
            })
        })

        savePresetFilters(presetId, filters).then((res) => {
            console.log('Saved successfully - res=', res)
            setStatus({ errors: [], processing: false })

            console.log('Saved successfully - res=', res)

            currentId.current = id
            //Reload presets
            fetchPresetList()

            //setIsUpdatingModalVisible(false)
            closePresetModal()
        }
        ).catch((error) => {
            setStatus({
                processing: false,
                errors: error.response ? error.response.data.errors : []
            })
        })
    }
    //---------------------------------------------------------------------------------------
    function onDeletePreset() {
        //TODO
        console.log('DO DELETE PRESET => ', currentPreset)
        removePreset(currentSelectedPreset.id).then((res) => {
            currentId.current = -1
            //Reload presets
            fetchPresetList()
            closePresetModal()
        }
        ).catch((error) => {
            console.error('WARNING onDeletePreset::removePreset')
            setStatus({
                processing: false,
                errors: error.response ? error.response.data.errors : []
            })
        })
    }

    //---------------------------------------------------------------------------------------
    //Closing and cleaning
    function closePresetModal() {
        //toggleModalVisible()
        setIsSavingModalVisible(false)
        setIsUpdatingModalVisible(false)
        setIsDeletingModalVisible(false)
    }
    //---------------------------------------------------------------------------------------
    //Error popup displaying in modals
    const displayErrors = (
        <BkAlert severity='error' className='input'>
            {status &&
                status.errors &&
                status.errors.length > 0 &&
                status?.errors?.map((err, i) => (
                    <div key={i}>{'errors: ' + err.message}</div>
                ))}
        </BkAlert>
    )
    //---------------------------------------------------------------------------------------
    //Modal's content
    const SavePresetChildren = (
        <div>
            {status.errors.length > 0 ? displayErrors : null}

            Do you want to save this preset?
            <div>
                <BkTextField id="preset_name_textfield" label="Preset name" variant="outlined"
                    onChange={(e) => onChangePresetNameHandler(e.target.value)} />
            </div>

            <div className='modal__footer'>
                <BkButton type='submit' variant='contained' color='primary' onClick={onSavePreset} >
                    Save
                </BkButton>
                <BkButton onClick={closePresetModal} className='bkButton--alternate'>
                    Cancel
                </BkButton>
            </div>
        </div>
    )
    //---------------------------------------------------------------------------------------
    //Modal's content
    const UpdatingPresetFilters = (
        <div>
            {status.errors.length > 0 ? displayErrors : null}
            You can change the name of the current preset:
            <div>
                <BkTextField id="preset_name_textfield" label="Preset name" variant="outlined" value={presetName}
                    onChange={(e) => onChangePresetNameHandler(e.target.value)} />
            </div>
            {'\n'}
            Are you sure you want to update this preset Filters?
            <div></div>
            <div className='modal__footer'>
                <BkButton type='submit' variant='contained' color='primary' onClick={onUpdatePresetFilters} >
                    Update
                </BkButton>
                <BkButton onClick={closePresetModal} className='bkButton--alternate'>
                    Cancel
                </BkButton>
            </div>
        </div>
    )
    //---------------------------------------------------------------------------------------
    //Modal's content
    const DeletingPresetFilters = (
        <div>
            {status.errors.length > 0 ? displayErrors : null}

            Are you sure you want to delete this preset?
            <div></div>
            <div className='modal__footer'>
                <BkButton type='Delete' variant='contained' color='primary' onClick={onDeletePreset} >
                    Delete
                </BkButton>
                <BkButton onClick={closePresetModal} className='bkButton--alternate'>
                    Cancel
                </BkButton>
            </div>
        </div>
    )
    /************************************************************************************************/
    const displayFilterPreset =
        <div className={(showFilterPreset ? 'filterPresetsContainer preset' : 'filterPresetsContainerHidden')} >
            <>
                {(showFilterPreset || appliedFilters.length > 0) && <div className='inputContainer'>
                    {
                        <BkSelect
                            labelId='select-preset-label'
                            id='select-preset'
                            name='preset'
                            selected={currentSelectedPreset}
                            value={currentSelectedPreset}
                            options={currentPresetList}
                            optionConfig={{ type: 'id', label: 'name' }}
                            onChange={onChangeCurrentSelectHandler}
                            displayHelper helper='Select preset'
                        />}
                </div>}
                <div className='presetFilterElmt' >
                    {appliedFilters.length > 0 && <BkButton aria-label="save" onClick={() => doOpenSavePreset()} className='bkButton bkButton--mini bkButton--alternate'>
                        {<BkIcon path='save' className='icon' />}
                    </BkButton>}
                    { showFilterPreset && currentSelectedPreset && currentSelectedPreset.id !== -1 &&
                        <>
                            <BkButton aria-label="delete" onClick={() => doDisplayDeletePreset(currentSelectedPreset)} className='bkButton bkButton--mini bkButton--alternate'>
                                {<BkIcon path='trash' modifier='active' className='icon' />}
                            </BkButton>
                        </>
                    }
                    {
                    }
                </div>
            </>
            {
                isSavingModalVisible && <Modal title='Save filters preset'
                    onClose={closePresetModal}
                    children={SavePresetChildren}>
                </Modal>
            }
            {
                isUpdatingModalVisible && <Modal title='Updating preset filters'
                    onClose={closePresetModal}
                    children={UpdatingPresetFilters}>
                </Modal>
            }
            {
                isDeletingModalVisible && <Modal title='Deleting preset filters'
                    onClose={closePresetModal}
                    children={DeletingPresetFilters}>
                </Modal>
            }
        </div>

    //-------------------------------------------------------------------
    return <>{displayFilterPreset}</>
}
export default FilterPresetMngt