import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'
import BkFieldSet from './FieldSet'
import BkMenu from '../Menu'
import BkMenuItem from '../MenuItem'
import useLegacy from '../../i18n/legacyEffect'

const BkMultiSelect = ({
  title,
  legacy,
  helper,
  options,
  optionConfig,
  selected,
  onChange,
  onFocus
}) => {
  const { tLegacy } = useLegacy()
  const [displayMenu, setDisplayMenu] = useState(false)
  const { t } = useTranslation()
  const focusHandler = () => {
    setDisplayMenu(true)
    if (!_.isUndefined(onFocus)) {
      onFocus()
    }
  }
  const focusOutHandler = () => {
    setDisplayMenu(false)
  }

  const getLabel = label => {
    if (!_.isUndefined(optionConfig)) {
      label = _.get(label, [optionConfig.label])
    }

    if (!_.isUndefined(legacy))
      if (_.isEmpty(tLegacy(legacy, label)))
        return t('miscellaneous:empty')
      else
        return tLegacy(legacy, label)
    else
     return label

  }

  const onSelectHandler = value => {
    const alreadySelected = !!_.find(selected, s => s === value)
    let values = [...selected]
    if (!alreadySelected) {
      values.push(value)
    } else {
      values = selected.filter(f => f !== value)
    }
    if (!_.isUndefined(onChange)) {
      onChange(values)
    }

    setDisplayMenu(false)
  }

  const setClasses = value => {
    let classes = 'multiselect__item'
    if (selected && selected.length > 0) {
      const values = selected.filter(selectedValue => selectedValue === value)
      if (values.length > 0) {
        classes = classes + ' multiselect__item--selected'
      }
    }
    return classes
  }

  return (
    <BkFieldSet
      title={title}
      onFocusOut={focusOutHandler}
      helper={displayMenu && helper}
      className='multiselect'
    >
      {!options ||
        (options.length === 0 && (
          <span
            className='multiselect__button multiselect__button--disabled'
            onClick={focusHandler}
          >
            Select
          </span>
        ))}
      {options && options.length > 0 && (
        <span
          className='multiselect__button'
          tabIndex='0'
          onClick={focusHandler}
        >
          Select
        </span>
      )}
      {displayMenu && options && options.length > 0 && (
        <BkMenu className='multiselect__menu' open={displayMenu}>
          {options.map((o, i) => (
            <BkMenuItem
              key={i}
              className={setClasses(o)}
              isLink={false}
              onClick={() => onSelectHandler(o)}
            >
              {getLabel(o)}
            </BkMenuItem>
          ))}
        </BkMenu>
      )}
    </BkFieldSet>
  )
}
export default BkMultiSelect
