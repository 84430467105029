import React from 'react'
const BkIcon = ({ path, modifier, alt, ...props }) => {
  if (modifier) {
    path += '-' + modifier
  }
  return (
    <img
      src={require('../assets/icons/' + path + '.svg')}
      alt={alt}
      {...props}
    />
  )
}

export default BkIcon
