import React, { useState } from 'react'
import BkIcon from '../../components/icon'
import './styles/limite-selector.scss'
import BkMenu from '../../components/Menu'
import BkMenuItem from '../../components/MenuItem'

const LimitSelector = ({ limit, limitHandler }) => {
  const currentLimit = limit || 10

  const [toggled, setToggled] = useState(false)
  const iconModifier = toggled ? 'top' : 'bottom'

  const toggleHandler = () => {
    setToggled(!toggled)
  }
  const setLimitHandler = limit => {
    toggleHandler()
    limitHandler(limit)
  }

  return (
    <span className='limitSelector'>
      <span className='limitSelector__header' onClick={toggleHandler}>
        Top {currentLimit}{' '}
        <BkIcon
          path='arrow'
          modifier={iconModifier}
          className='limitSelector__icon'
        />
      </span>
      <BkMenu
        className='limitSelector__list'
        onCollapse={toggleHandler}
        open={toggled}
      >
        {[10, 20, 50, 100, 250].map((limit, i) => (
          <BkMenuItem
            key={i}
            onClick={() => setLimitHandler(limit)}
            className='bkMenuItem--small'
            isLink={false}
          >
            Top {limit}
          </BkMenuItem>
        ))}
      </BkMenu>
    </span>
  )
}

export default LimitSelector
