import React from 'react'
import useSummary from './hooks/SummaryEffect'
import SummaryItem from './SummaryItem'
import './styles/Summary.scss'

const Summary = ({ config, displayHeader }) => {
  const summaries = useSummary(config.summaryConfiguration)

  return (
    <>
      {displayHeader && (
        <div className='widget__header'>
          <h2 className='widget__title'>Summary</h2>
        </div>
      )}
      <div className='summary'>
        {summaries &&
          summaries.map((summary, i) => (
            <SummaryItem key={i} summary={summary} config={config}/>
          ))}
      </div>
    </>
  )
}

export default Summary
