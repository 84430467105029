import { requestor } from '../../../services/requestor.service'
import bkApi from './../../../services/bk-api'
import Highcharts from 'highcharts'
import { BKAPI_URL } from '../../../environment'
import { SERIES_TYPE } from '../../../constants'
import moment from 'moment'
import { indexOf } from 'lodash'

const _ = require('lodash')

/**
 * Get Options from nodejs
 *
 * @param params
 * @param filters
 * @returns {Promise<null|*>}
 */
export const fetchOption = async (params, filters) => {
  const res = await bkApi.get('/v1/graph/option', {
    params: params
  })
  return res.data
}

/**
 * Get Series from nodejs
 *
 * @param params
 * @param filters
 * @returns {Promise<null|*>}
 */
export const fetchSeries = async (params, filters) => {
  try {
    const res = await bkApi.get('/v1/graph/series', {
      params: { params, filters }
    })
    //apply timezone only for some graph
    if ( params.type === SERIES_TYPE.AREA ||
        params.type === SERIES_TYPE.LINE ||
        params.type === SERIES_TYPE.SOLIDLINE ||
        params.type === SERIES_TYPE.DASHLINE ||
        params.type === SERIES_TYPE.STACKBARSTIMESTAMPS ||
        params.type === SERIES_TYPE.STACKCOLUMNSTIMESTAMPS ||
        params.type === SERIES_TYPE.STACKCOLUMNSTIMESTAMPSPERCENT) {
      //Timezone - Switch result date back to UTC, for display in Charts
      res.data.map(serie => {
        let highestVal = 0
        serie.data.map(mapData => {
          mapData.x = mapData.x - (filters.timezone * 1000) // Date returned from the Requestor in Epoch - milliseconds
          if ( params.type === SERIES_TYPE.AREA){
            //TODO: Temp fix - Prevent the issue of the last entry in a serie to have a timestamp ( *.x) inferior to the timestamp from the previous data (by a factor 1000)
            //(Should only happen for the last data of the serie)
            if (mapData.x >= highestVal){
              highestVal = mapData.x
            }
            else {
              console.log('Error: ChartEffects::fetchSeries - error [highestVal] / [mapData.x] in serie.data', serie.data, highestVal, mapData.x)
              mapData.x = highestVal
            }
          }
        })
      })
    }
    if (params.type === SERIES_TYPE.COLUMNSCATEGORIESHOURS) {
      res.data.map(serie => {
        let newCategories = new Array()
        let dataInTimezoneOfOrigin = serie.data
        let dataInNewTimezone = new Array(24)

        if (serie.categories){
          serie.categories.map(category => {
            let newCat = category
            //set date to unix origin time + hour set by the request
            var date = moment.utc().day(1).month(1).year(1970).hour(newCat).minute(0).second(0);
            // recreate date object with date epoch minus timezone
            var ajustedTime = moment.unix(date.unix() - filters.timezone).utcOffset(0)
            //set category with hour
            newCat = ajustedTime.hour().toString() + 'h'
            //set category with minute
            if (ajustedTime.minute() !== 0){
              newCat += ajustedTime.minute().toString() + 'min'
            }
            console.log('UTC : ',date.hour())
            console.log('Timezoned : ', ajustedTime.hour() + ':' + ajustedTime.minute())

            newCategories[ajustedTime.hour()] = newCat
            dataInNewTimezone[ajustedTime.hour()] = dataInTimezoneOfOrigin[date.hour()]
          })
        }
        else {} // no category found probably a filter error so continue with it
        serie.categories = newCategories
        serie.data = dataInNewTimezone
      })

    }
    if (params.type === SERIES_TYPE.STACKCOLUMNSCATEGORIES) {
      if (!_.isNil(res.data[0].noData) || !_.isNil(res.data[1].noData)){
          return res.data
        }
        else if (Array.isArray(res.data[0].data)&& Array.isArray(res.data[1].data)) {
          // we need to align histogram data on two different x axes
          //(categories)
          // categories and data are not linked, there are two separate arrays with same index
          //
          // To make it easier, we create a new Categories array containing the union of the two series, sorted
          //
          // Then we align both series
          //
          let totalcategories = res.data[0].categories.concat(res.data[1].categories)
          let newserie1 = []
          let newserie2 = []
          totalcategories.sort()


              //remove array duplicates
              totalcategories.filter((c,i)=> totalcategories.indexOf(c)==i)
                //align the graph data on same x axis, by replacing categories of serie 1 by newserie1 and adding newserie1[index] to data of serie 1 (same with serie 2)
                //x axis = categories 
                //y axis = data
                .map((c,i)=>{
                if(!res.data[0].categories.includes(c)){
                  newserie1.push({"category":c,
                  "data":0})
                }
                else{
                  let j = res.data[0].categories.indexOf(c)
                  newserie1.push({"category":c,
                  "data":res.data[0].data[j]})
                }
                if(!res.data[1].categories.includes(c)){
                  newserie2.push({"category":c,
                  "data":0})
                }
                else{
                  let k = res.data[1].categories.indexOf(c)
                  newserie2.push({"category":c,
                  "data":res.data[1].data[k]})
                }
              })
            
            res.data[0].categories = newserie1.map(obj => obj.category)
            res.data[0].data = newserie1.map(obj => obj.data)
            res.data[1].categories = newserie2.map(obj => obj.category)
            res.data[1].data = newserie2.map(obj => obj.data)
            
        }
       else {
        console.error('error in fetch - error with compare: incorrect format result', res)
        
      }
    }
    return res.data
  } catch (err) {
    return null
  }
}

/**
 * Get SeriesDrilldown from nodejs
 *
 * @param params
 * @param filters
 * @param filterValue
 * @returns {Promise<null|*>}
 */
export const fetchSeriesDrilldown = async (config, filters, filterValue) => {
  let newFilter = {}
  const newConfig = _.cloneDeep( config)

  if (config && config.list) {
    // Add Filter to get the good region of the country
     newFilter = {
        name: config.list[0].series[0].dimension,
        values: [{ operator: '==', value: filterValue }]
    }
  }
  if ( newConfig && Array.isArray(newConfig.list) )
  {
     newConfig.list.map(list => {
       list.series.map(serie =>{
         serie.dimension = serie.subDimension
         delete serie.subDimension
       })
     })
  }
  return fetchSeries(newConfig, { ...filters, filters: [...filters.filters, newFilter] })
}

/**
 *
 * @param config
 * @param filters
 * @param compare
 * @returns {Promise<*[]|*>}
 */
export async function fetchSeriesWithCompare (config, filters, compare) {
  const compareFilter = {
    ...filters,
    startdate: compare.startdate,
    enddate: compare.enddate,
    timezone: compare.timezone
  }
  try {
    const res = await Promise.all([
      fetchSeries(config, filters),
      fetchSeries(config, compareFilter)
    ])
    if (Array.isArray(res[0]) && Array.isArray(res[1])) {
      console.log(res)
      return res[0].concat(res[1].map((serie, i) => {
        // we need to align histogram data on two different x axes
        //(categories)
        // categories and data are not linked, there are two separate arrays with same index
        //
        // To make it easier, we create a new Categories array containing the union of the two series, sorted
        //
        // Then we align both series
        //

        if (config.type === SERIES_TYPE.COLUMNSCATEGORIES || config.type === SERIES_TYPE.BARSCATEGORIES){
          let totalcategories = res[0][0].categories.concat(serie.categories)
          let newserie1 = []
          let newserie2 = []
          totalcategories.sort()
          //remove array duplicates
          totalcategories.filter((c,i)=> totalcategories.indexOf(c)==i).map((c,i)=>{
            if(!serie.categories.includes(c)){
              newserie1.push({"category":c,
              "data":0})
            }
            else{
              let j = serie.categories.indexOf(c)
              newserie1.push({"category":c,
              "data":serie.data[j]})
            }
            if(!res[0][0].categories.includes(c)){
              newserie2.push({"category":c,
              "data":0})
            }
            else{
              let k = res[0][0].categories.indexOf(c)
              newserie2.push({"category":c,
              "data":res[0][0].data[k]})
            }
          })
            serie.categories = newserie1.map(obj => obj.category)
            serie.data = newserie1.map(obj => obj.data)
            res[0][0].categories = newserie2.map(obj => obj.category)
            res[0][0].data = newserie2.map(obj => obj.data)
        }

        serie.name = serie.name ? `${serie.name} (reference)` : `reference ${i}`

        if (config.type !== SERIES_TYPE.COLUMNSCATEGORIES
          && config.type !== SERIES_TYPE.COLUMNSCATEGORIESHOURS
          && config.type !== SERIES_TYPE.BARSCATEGORIES) {
          serie.data = serie.data.map((d,i)=> {
            return {
              x: (res[0][0].data[i]?.x) ? res[0][0].data[i].x : d.x,
              y: d.y
            }
          })
        }
        return serie
      }))
    } else {
      console.error('error in fetch - error with compare: incorrect format result', res)
      return []
    }
  } catch (error) {
    console.error('error in fetch - error with compare', error)
    throw error
  }
}

/**
 *
 * @param config
 * @param filters
 * @param split
 * @returns {Promise<[]>}
 */
export async function fetchSeriesWithSplit (config, filters, split) {
  const customFilters = split.selectedValue.map(filterValue => ({
    name: split.filterSelected.column,
    values: [{ operator: '==', value: filterValue }]
  }))
  const newConfig = { ...config }
  config.list.forEach(list => {
    const serie = list.series.find(serie => serie.name === split.serieSelected.name)
    if (serie) {
      newConfig.list = [{ ...list, series: [serie] }]
    }
  })
  try {
    const res = await Promise.all(customFilters.map(filter => {
      return fetchSeries(newConfig, { ...filters, filters: [...filters.filters, filter] })
    }).concat([fetchSeries(newConfig, filters)]))
    return res.map((r, i) => {
      if (customFilters[i]) {
        r[0].name = customFilters[i].values[0].value
      }
      return r[0]
    })
  } catch (error) {
    console.error('fetchSeriesWithSplit error', error)
    throw error
  }
}

/**
 * Get Options from nodejs
 *
 * @param params
 * @param filters
 * @returns {Promise<null|*>}
 */
 export const fetchMap = async (name) => {
  const res = await bkApi.get('/v1/graph/maps', {
    params: {'name' : name}
  })
  return res.data
}

/**
 *
 * @param {Object} serie
 */
export const updateNetworkEventSerieMarker = (serie) => {
  var points = serie.points;
  points.forEach(function(point, index) {
      var symbol;
      if (!point.networkType || point.networkType === 0) {//Disconnected
          symbol = 'url('+ BKAPI_URL + '/images/tt_network_lost.png)' //'url('+ BKAPI_URL + '/images/
      } else if (point.networkType >= 10 && point.networkType < 20) {//Wifi
          symbol = 'url('+ BKAPI_URL + '/images/tt_network_wifi_24.png)'
          if (point.networkType === 11) {
              symbol = 'url('+ BKAPI_URL + '/images/tt_network_wifi_24.png)'
          } else if (point.networkType === 12) {
              symbol = 'url('+ BKAPI_URL + '/images/tt_network_wifi_5.png)'
          }
      } else if (point.networkType >= 20 && point.networkType < 30) {//Mobile
          symbol = 'url('+ BKAPI_URL + '/images/tt_network_mobile.png)'
          if (point.networkType === 21) {
              symbol = 'url('+ BKAPI_URL + '/images/tt_network_mobile_2G.png)'
          } else if (point.networkType === 22) {
              symbol = 'url('+ BKAPI_URL + '/images/tt_network_mobile_3G.png)'
          } else if (point.networkType === 23) {
              symbol = 'url('+ BKAPI_URL + '/images/tt_network_mobile_4G.png)'
          } else if (point.networkType === 24) {
              symbol = 'url('+ BKAPI_URL + '/images/tt_network_mobile_5G.png)'
          }
      } else if (point.networkType >= 30 && point.networkType < 40) {//Ethernet
          symbol = 'url('+ BKAPI_URL + '/images/tt_network_ethernet.png)'
      } else { //unknown
          symbol = 'url('+ BKAPI_URL + '/images/tt_network_ethernet.png)'
      }

      point.update({
          marker: {
          symbol: symbol
          }
      });

  });
};

//TODO: use display association instead
function getNetworkStateString( val ){
  var str = "";
  if (!val || val === 0) {//Disconnected
      str = "disconnected";
  } else {
      switch (val){
          case 11:
              str = "wifi 2.4Ghz";
              break;
          case 12:
              str = "wifi 5Ghz";
              break;
          case 21:
              str = "mobile 2G";
              break;
          case 22:
              str = "mobile 3G";
              break;
          case 23:
              str = "mobile 4G";
              break;
          case 24:
              str = "mobile 5G";
              break;
          case 30:
              str = "ethernet";
              break;
          default:
              str="connected";
              break;

      }
  }//Wifi
  return str;
}

export function affectSessionTrackerSeries(options) {
  if (options.yAxis !== undefined) {
    // put that in backend
    if (options && options.series[5] && options.series[5].summary_data){
      let data = options.series[5].summary_data
      options.yAxis.max = data.layers.length + 1
      options.yAxis.labels = {}
      options.yAxis.labels.formatter = function() {

        if (this.value >= 1 && this.value < data.layers.length + 1) {
          if (!isNaN(data.layers[this.value - 1])) {
              return data.layers[this.value - 1] + 'kbps';
          } else {
              return data.layers[this.value - 1]
          }
        } else {
            return ''
        }
      }
    }
  }
  if (options.tooltip !== undefined && options.series && options.series[5] && options.series[5].summary_data && options.series[5].summary_data !== undefined) {
    if (options.xAxis !== undefined) {
      options.xAxis.labels = {}
      options.xAxis.labels.formatter = function() {
        var data = options.series[5].summary_data;
        var summary = data.summary;
        if (summary !== undefined) {
          var xMin = summary.date;
          var xMax = summary.date + summary.duration;
          if (this.value <= xMin || this.value >= xMax ) {
            var offset = 0;
            if (this.value >= xMax) {
              offset = summary.summaryDuration - summary.duration;
            }

            if (this.value % 1000 === 0) {
                return Highcharts.dateFormat("%H:%M:%S", this.value + offset);

            } else {
                return Highcharts.dateFormat("%H:%M:%S.%L", this.value + offset);
            }
          } else {
            return '';
          }
        } else {
          if (this.value % 1000 === 0) {
            return Highcharts.dateFormat("%H:%M:%S", this.value);
          } else {
            return Highcharts.dateFormat("%H:%M:%S.%L", this.value);
          }
        }
      }
    }
    options.tooltip.formatter = function () {
    var data = options.series[5].summary_data;
    var summary = data.summary;
    var tooltipStr = "";
    var dateX = this.x + data.originalStartDate;
    if (summary !== undefined) {
        var xMin = summary.date  ;
        var xMax = summary.date + summary.duration;

        if (this.x >= xMin && this.x < xMax) {
            var networkEvent = "";
            if (summary.nbNetworkDisconnected !== undefined){
                networkEvent += '<br/><u>Network event:</u><br/>';
                networkEvent += 'Disconnect/Wifi/Mobile/Ethernet:<b>' + summary.nbNetworkDisconnected + '/' + summary.nbNetworkWifi + '/' + + summary.nbNetworkMobile + '/' + summary.nbNetworkEthernet + '</b><br/>';
            }
            return '<i>Duration</i>: <b>' + Highcharts.dateFormat("%H:%M:%S", summary.summaryDuration) + '</b><br/>'
                + '<i>Pause</i>: <b>' + Highcharts.dateFormat("%H:%M:%S", summary.pauseDuration) + '</b><br/>'
                + '<i>Stalls number</i>: <b>' + summary.stallsNumber + '</b><br/>'
                + '<i>Stalls duration</i>: <b>' + Highcharts.dateFormat("%H:%M:%S", summary.totalStallsDuration) + '</b><br/>'
                + '<i>Rebufferings number</i>: <b>' + summary.rebufferingsNumber + '</b><br/>'
                + '<i>Rebufferings duration</i>: <b>' + Highcharts.dateFormat("%H:%M:%S", summary.totalRebufferingDuration) + '</b><br/>'
                + '<i>Layer switches number</i>: <b>' + summary.layerSwitchesNumber + '</b><br/>'
                + '<i>Min layer</i>: <b>' + summary.minBitrate + 'kbps</b><br/>'
                + '<i>Max layer</i>: <b>' + summary.maxBitrate + 'kbps</b><br/>'
                + '<i>Average layer</i>: <b>' + summary.averageBitrate + 'kbps</b>'
                + networkEvent ;
        } else {
            if (this.y >= 1 && this.y < data.layers.length + 1) {

                if (data.summary !== undefined && this.x >= data.summary.date + data.summary.duration) {
                    dateX += data.summary.summaryDuration - data.summary.duration;
                }
                tooltipStr += Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", dateX) + '<br/><i>Layer</i>: <b>' + data.layers[this.y - 1] + 'kbps</b>';
            } else if (this.x === 0) {
                //first point
                tooltipStr += Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", data.originalStartDate) +
                  '<br/><i>StartupTime</i>: <b>' + data.startupTime + 'ms</b>' +
                  '<br/><i>RedirectionTime</i>: <b>' + data.redirectionTime + 'ms</b>';
            }
        }
    } else {
        if (this.y >= 1 && this.y < data.layers.length + 1) {
            //var dateX = this.x; //+ data.originalStartDate;
            /*if (data.summary !== undefined && this.x >= data.summary.date + data.summary.duration) {
                dateX += data.summary.summaryDuration - data.summary.duration;
            }*/
            tooltipStr += Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", dateX) + '<br/><i>Layer</i>: <b>' + data.layers[this.y - 1] + 'kbps</b>';
        } else if (this.x === 0) {
            //first point
            tooltipStr += Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", data.originalStartDate) +
              '<br/><i>StartupTime</i>: <b>' + data.startupTime + 'ms</b>' +
              '<br/><i>RedirectionTime</i>: <b>' + data.redirectionTime + 'ms</b>';
        }
    }
    if (data.networkEvents.length > 0){
        let lastEvent = data.networkEvents[0];
        //display current network state
        for (let i = 0; i < data.networkEvents.length; i++){
            let event = data.networkEvents[i];
            if (event.date > this.x){
                break;
            } else {
                lastEvent = event;
            }
        }
        tooltipStr += '<br><i>Network</i>: <b>' + getNetworkStateString(lastEvent.networkType) + '</b>';
    }

    return tooltipStr;
  }
  }
  if (options.series !== undefined && options.series[8] !== undefined) {
    options.series[8].marker =  {
      symbol: 'url('+ BKAPI_URL + '/images/tt_network_wifi_24.png)',
      enabled:true
    }
  }
}


export function changeGaugeColor (config, options) {
  // Check if gauge need to be red for high value and not Green the initialColor
  // if stops exist replace by dashboardjson stops
  if (config && config.stops)
     options.yAxis.stops = config.stops
}

/**
 *
 * @param options
 * @param series
 * @returns {{xAxis: *}}
 */
export function affectSerie (options, series) {
  let res = {
    ...options,
    xAxis: (options.xAxis ? {
      ...options.xAxis
    } : {}),
    series: Array.isArray(series) ? series.map(({ categories, id, data, ...s }) => ({
      ...s,
      data: Array.isArray(data) ? data.slice() : []
    })) : []
  }

  if (Array.isArray(series) && series[0] && res) {
    if (series[0].yMax && res.yAxis) {
      res.yAxis.max = series[0].yMax
    }
    if (series[0].yMin && res.yAxis) {
      res.yAxis.min = series[0].yMin
    }

    if (series[0].unit && res.yAxis && res.yAxis.labels) {
      options.yAxis.labels.formatter = function() {
          return this.value + ' ' + series[0].unit;
      }
    }

    if (series[0].xunit && res.xAxis && res.xAxis.labels) {
      options.xAxis.labels.formatter = function() {
          return this.value + ' ' + series[0].xunit;
      }
    }

    if (Array.isArray(series[0].categories)) {
      res.xAxis.categories = series[0].categories
    }
  }

  return res
}

/**
 *
 * @param params
 * @returns {Promise<{[p: string]: *}>}
 */
export const fetchChart = async params => {
  const res = await requestor(params.action, params)
  return { ...res.data }
}
