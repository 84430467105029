import { Grid, IconButton } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import BkSelect from '../../components/forms/Select'
import BkIcon from '../../components/icon'
import Filter from '../filters/Filter'
import { filterType } from '../../constants'
import { updateAppliedFilter } from '../filters/store/FiltersSlice'

export const CreateDiagnosticFilter = ({ dimensions, data, onChange, onDelete }) => {
  const dispatch = useDispatch()
  const dimension = dimensions.find(d => d.columnName === data?.dimension)
  const operators = dimension?.type === filterType.PATTERN ? ['None', '~', '!~'] : ['None', '=', '!=']
  const operator = data?.operator ? data.operator : operators[0]

   return (
    <Grid container direction='row' spacing={3}>
      <Grid item xs={4} style={{ paddingBottom: '2.2em' }}>
        <BkSelect
          title='Dimension'
          legacy='filters'
          options={dimensions.map(d => d.columnName)}
          selected={data?.dimension}
          onChange={dimension => onChange({ ...data, values: [], operator:operators[0], dimension })}
        />
      </Grid>
      <Grid item xs={3}>
        <BkSelect
          title='Operator'
          options={operators}
          selected={operator}
          onChange={operator => onChange({ ...data, operator })}
        />
      </Grid>

      <Grid item xs={4}>
        { dimension && <Filter
          title='Value'
          legacy={dimension.columnName}
          type={dimension.type}
          id={dimension.id}
          columnName={dimension.columnName}
          onChange={(id, name, label, values) => {
            onChange({ ...data, operator, values: values.map(({ value }) => value) })
            dispatch(updateAppliedFilter({ id, name, label, values }))
          }}
          exclude={false}
        /> }
      </Grid>
      <Grid item xs={1} style={{ paddingTop: '1em' }}>
        <IconButton onClick={onDelete} aria-label='delete'>
          <BkIcon path='trash' className='icon' alt='delete' />
        </IconButton>
      </Grid>
    </Grid>
  )
}