import React, { useEffect, useState } from 'react'
import './styles/page.scss'
import { useDispatch, useSelector } from 'react-redux'
import { changePage, selectCurrentDashboard } from './store/dashboardSlice'
import * as PageService from '../../services/pages.service'
import Footer from '../../layouts/Footer'
import { setDefaultFilter } from '../filters/store/FiltersSlice'
import Scrollable from '../../components/Scrollable'
import WidgetContainer from './WidgetContainer'

const PageContainer = ({ path }) => {
  const dispatch = useDispatch()
  const currentPage = useSelector(selectCurrentDashboard)
  const [template, setTemplate] = useState(null)

  //Compare new path to current page to check if its a new page
  // There are two entry in each array: the main menu and the submenu
  // If one of them is not identical in path and in current page 
  //    then result array will be inferior or equal to 1 
  useEffect(() => {
    const p = path.filter((id, i) => currentPage[i] === path[i])
    if (p.length <= 1) {
      changePage(dispatch, path)
    }
  }, [path, currentPage])

  // Get submenu template from API to get list of charts
  useEffect(() => {
    const getTemplate = async path => {
      const { data } = await PageService.getPage(path[1])
      if (data && data.filters) {
        const defaultFilters = {}
        for (const [key, value] of Object.entries(data.filters)) {
          switch (key) {
            case 'current_filters':
              // defaultFilters.filters = value TODO merge preselected filter
              break
            case 'percentage_table':
              defaultFilters.table = value
              break
            default:
              defaultFilters[key] = value
              break
          }
        }
        dispatch(setDefaultFilter(defaultFilters))
      }

      setTemplate(data)
    }
    getTemplate(path)
  }, [path])

  const header = template && (
    <header className='pageContainer__header'>
      <h1 className='pageContainer__title'>{template.title}</h1>
    </header>
  )

  return (
    <div className='pageContainer'>
      <Scrollable>
        <div className='pageContainer__section'>
          {header}
          <WidgetContainer template={template} />
        </div>
      </Scrollable>
      <Footer />
    </div>
  )
}

export default PageContainer
