import React, { useState, useEffect, useRef } from 'react'
import { CSVLink } from "react-csv";
import BkButton from './Button'

/// Object use to get data from backend with onclick function and write csv file on front
const CsvLinkButton =  ({ onClick, onQuit, filename })  => {
  const csvLink = useRef()
  const [data, setData] = useState( [] )
  
  // Get data from backend
  const fetchData = ()  => {
    onClick().then( (res) => {
      setData( res.measures)
    })    
  }

  /// Trigger csv file when data all is received and quit 
  useEffect(() => {
    if (data.length > 0){
      setTimeout(() => {
        csvLink.current.link.click();
        onQuit()
      });
    }
  }, [data])

  return (
      <BkButton         
        type='submit'
        variant='contained'          
        color='primary'
        onClick={fetchData}
      >Download
      {data && <CSVLink
        data={data}
        filename={filename}
        className="hidden"
        ref={csvLink}
        target="_blank" 
        />
      }      
      </BkButton>
  )
}
export default CsvLinkButton