import { Box } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more.js'
import drilldown from 'highcharts/modules/drilldown'
import exportData from 'highcharts/modules/export-data'
import exporting from 'highcharts/modules/exporting'
import highchartsMap from 'highcharts/modules/map'
import exportOffline from 'highcharts/modules/offline-exporting'
import solidGauge from 'highcharts/modules/solid-gauge.js'
import { createBrowserHistory } from 'history'
import { React } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import useInitSession from './features/authentication/hooks/initSession'
import useInitMenu from './features/dashboards/hooks/initMenu'
import useInitSettings from './features/settings/hooks/initSettings'
import DashboardLayout from './layouts/DashboardLayout'
import LoginLayout from './layouts/LoginLayout'
import PageLayout from './layouts/PageLayout'
// Burger menu info
import './theme/app.scss'
// Highchart import
import setHighchartsTheme from './theme/highcharts'
import { darkTheme } from './theme/theme'
import LoginForm from './features/authentication/LoginForm'
import { useTranslation } from 'react-i18next'
import { ForgotPasswordForm } from './features/authentication/ForgotPasswordForm'
import { ChangeExpiredPassword } from './features/authentication/ChangeExpiredPassword'
//User profil info
import { useSelector} from 'react-redux'
import { selectCurrentUser } from './features/authentication/store/authSlice.js'
import { ChangePassword } from './features/authentication/ChangePassword'
import { selectBurgerMenu } from './components/store/burgerSlice'
//Component used
import Settings from './features/settings/Settings'
import About from './features/pages/About'
import Help from './features/pages/Help'
import Admin from './features/admin/Admin'
import Anonymization from './features/anonymization/Anonymization'
import AIDataDiagnostics from './features/diagnostics/AIDataDiagnostics'
import Reporting from './features/reporting/Reporting'
//Auth module
import { AUTH_MODE, AUTH_MODE_LEGACY } from './environment'

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
})

// global config for highcharts
exporting(Highcharts)
exportData(Highcharts)
exportOffline(Highcharts)
highchartsMap(Highcharts)
highchartsMore(Highcharts)
solidGauge(Highcharts)
drilldown(Highcharts)  //Disabling of Drilldown in HighCharts Maps (the way to do it is a bit of an overkill, but it's temporary)
setHighchartsTheme()
// application
const App = () => {

  const [isLogged, isPwdToRenew, sessionError] = useInitSession()
  const [menu, currentDashboard] = useInitMenu(isLogged)
  const { t } = useTranslation()
  const user = useSelector(selectCurrentUser)
  const burgerMenu = useSelector(selectBurgerMenu)
  useInitSettings(isLogged)
  
  const pathname = window.location.pathname



  const dashboardRouter =
    isLogged === true &&
    menu &&
    menu.length > 0 &&
    menu.map(section => (
      <Route path={'/' + section.routerId} key={section.routerId}>
        <DashboardLayout section={section} />
      </Route>
    ))

  const getComponent = (componentName) => {
    // 01-Usermenu -> no page for this
    // 08-Admin -> not manage here
    const componentsMap = {
      "02-settings": <Settings />,
      "03-anonymization": <Anonymization mainMenu="1000-burgerMenu" subMenu={componentName}/>,
      "04-reporting": <Reporting mainMenu="1000-burgerMenu" subMenu={componentName}/>,
      "05-ai-data-diagnostics": <AIDataDiagnostics />,
      "06-help": <Help />,
      "07-about": <About />
    };
    return componentsMap[componentName]
  }
  
  const burgerMenuRoutes =
    isLogged === true &&
    user && burgerMenu &&
    burgerMenu.length > 0 &&
    burgerMenu.map((item) => (
       (item.pfl_id.includes(user.pfl_id) || item.pfl_id.includes(-1)) ?
        <Route key={item.to} path={item.to} >
          <PageLayout icon={item.icon} title={item.title}>
            {getComponent(item.routerId)}
          </PageLayout>
        </Route>
        : ""
    ))

  {/* Administration displayed for Administrator and Super admimnistrator */}
  const admin =
    user &&
    (user.pfl_id === 1 || user.pfl_id === 2) &&
    <Route path='/admin'>
      <PageLayout icon='admin' title='Admin Console'>
        <Admin mainMenu="1000-burgerMenu" subMenu="08-admin"/>
      </PageLayout>
    </Route>

  return (
    <ThemeProvider theme={darkTheme}>
      <Box className='bkDark'>
        <Router basename='/v2'>

          <Switch>
            {dashboardRouter}
            {burgerMenuRoutes}
            {admin}

            <Route path='/login'>
              <LoginLayout>
                <h1 className='loginBox__title'>{t('actions:signin')}</h1>
                <LoginForm error={sessionError} />
              </LoginLayout>
            </Route>

            {/* Forgot Password Form on Login Page to send Email */}
            <Route path='/forgot-password'>
              <LoginLayout>
                <h1 className='loginBox__title'>Forgot password</h1>
                <ForgotPasswordForm />
              </LoginLayout>
            </Route>

            {/*Rename Forgot Password on Login Page*/}
            <Route path='/renameForgotPassword/:email/:token' render={(props) => {
              return (
                <LoginLayout>
                  <h1 className='loginBox__title'>Rename password</h1>
                  <ChangePassword email={props.match.params.email} password={props.match.params.token} />
                </LoginLayout>
              )
            }}
            />

            <Route
              // path='/renewExpiredPassword/:email/:token' render= {(props) => {
              path='/renewExpiredPassword' render={(props) => {
                return (
                  <LoginLayout>
                    <h1 className='loginBox__title'>Change expired password</h1>
                    {/* <ChangeExpiredPassword params={_.get(props, ['match', 'params']) } /> */}
                    <ChangeExpiredPassword email={props.location.email} password={props.location.password} />
                  </LoginLayout>
                )
              }}
            />

            <Route>
              {isLogged === true && isPwdToRenew === false && currentDashboard && (
                <Redirect to={'/' + currentDashboard.join('/')} />
              )}
            </Route>
          </Switch>
          {isLogged === true && isPwdToRenew === true && <Redirect to='/renewExpiredPassword' />}
          {/* check if pathname includes renameForgotPassword redirect to /renameForgotPassword else /login */}
          {isLogged === false && AUTH_MODE === AUTH_MODE_LEGACY && !pathname.includes("renameForgotPassword") && <Redirect to='/login' />}
          {sessionError && <Redirect to='/login' /> }
        </Router>
      </Box>
    </ThemeProvider>
  )
}

export default App


