import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  diagnostics: [],
  alertSeverities: [],
}

export const DiagnosticsSlice = createSlice({
  name: 'diagnostics',
  initialState,
  reducers: {
    updateDiagnostics: (state, action) => ({
      ...state,
      diagnostics: action.payload,
    }),
    removeDiagnostic: (state, action) => ({
      ...state,
      diagnostics: state.diagnostics.filter(e => e.id !== action.payload),
    }),
    updateAlertSeverities: (state, action) => ({ // update all
      ...state,
      alertSeverities: action.payload,
    }),
    updateAlertSeverity: (state, action) => { // update one
      const severities = [...state.alertSeverities]
      const idx = severities.findIndex(a => (
        a.field === action.payload.field && a.alerter === action.payload.alerter
      ))

      if (idx < 0) {
        severities.push(action.payload)
      } else {
        severities[idx] = action.payload
      }

      return {
        ...state,
        alertSeverities: severities,
      }
    },
  },
})

export const {
  updateDiagnostics,
  removeDiagnostic,
  updateAlertSeverities,
  updateAlertSeverity,
} = DiagnosticsSlice.actions

export const selectDiagnostics = state => state.diagnostics.diagnostics
export const selectAlertSeverities = state => state.diagnostics.alertSeverities
export default DiagnosticsSlice.reducer