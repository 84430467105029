import React from 'react'
import './styles/button.scss'

export default function BkButton ({
  type,
  id,
  onClick,
  children,
  className,
  disabled
}) {
  return (
    <button
      type={type}
      id={id}
      className={`bkButton ${className}`}
      disabled={disabled}
      onClick={onClick}
      
    >
      {children}
    </button>
  )
}
