import { useDispatch, useSelector } from 'react-redux'
import {
  selectMenu,
  selectCurrentDashboard,
  changePage
} from '../store/dashboardSlice'

import { resetapplyFilter, selectAppliedFilters, selectCurrentPreset, resetPresetFilter } from '../../filters/store/FiltersSlice'


const useMenu = () => {
  const menu = useSelector(selectMenu)
  const currentDashboard = useSelector(selectCurrentDashboard)
  const appliedFilters = useSelector(selectAppliedFilters)
  const currentPreset = useSelector(selectCurrentPreset)
  const dispatch = useDispatch()


  const onChangePage = menuAndSubMenu => 
  {
    if (menuAndSubMenu && currentDashboard)
    {
      if (menuAndSubMenu[0] === currentDashboard[0])
      {//same menu so could keep filter
        // Sous menu
        changePage(dispatch, menuAndSubMenu)
        // TODO when filter selected from any other view of dashboard and click dashboard
      }
      else {        
        // menu is not the same (ex: switch from nano to player menu) so need to reset filter
        changePage(dispatch, menuAndSubMenu)
        dispatch(resetapplyFilter(appliedFilters))
        dispatch(resetPresetFilter(currentPreset))
      }
    }
  }
  return { menu, currentDashboard, onChangePage }
}

export default useMenu
