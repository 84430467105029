import React from 'react'
import BkAutocomplete from '../../components/forms/Autocomplete'
import * as _ from 'lodash'
import CurrentFiltersCount from './CurrentFiltersCount'

const ContainFilter = ({ title, onChange, selected, exclude }) => {
  const onChangeHandler = value => {
    const oper = exclude? '!~': '~'
    const v = { operator: oper, value }
    const values = !_.isUndefined(selected) ? [...selected, v] : [v]
    onChange(values)
  }

  const fieldLabel = (
    <>
      {title} <CurrentFiltersCount filters={selected} />
    </>
  )

  return (
    <BkAutocomplete
      title={fieldLabel}
      placeholder='Contains...'
      options={[]}
      onApply={onChangeHandler}
      onRemove={onChange}
      selected={selected}
    />
  )
}

export default ContainFilter
