import { ClickAwayListener } from '@material-ui/core'
import * as _ from 'lodash'
import React from 'react'
import './styles/menu.scss'

const BkMenu = ({ onCollapse, children, open, className }) => {
  const nav = (
    <nav className={className}>
      <ul className='bkMenu'>{children}</ul>
    </nav>
  )
  const menu = !_.isUndefined(onCollapse) ? (
    <ClickAwayListener onClickAway={onCollapse}>{nav}</ClickAwayListener>
  ) : (
    nav
  )

  return open ? menu : ''
}

export default BkMenu
