import React, { useState } from 'react'
import { BKAPI_URL } from '../../environment'
import { getLicences, getDocumentations, getVersion } from '../../services/support.service'
import { CircularProgress} from "@material-ui/core";

import '../../components/styles/icon.scss'
import '../../theme/app.scss'
import { useEffectOnlyOnce} from '../../globalFunctions'

const About = () => {
  const [licences, setLicences] = useState([])
  const [documentations, setDocumentations] = useState([])
  const [version, setVersion] = useState("")
  const [state, setState] = useState({
    processingLicence:false,
    processingDocumentation:false
  })

  const getLicenceFiles = async () => {
    getLicences().then(res => {
      setLicences(res.data)
      setState({ ...state, processingLicence: false })
    }).catch(_ => {
      console.error("No licence file")
     })
   }

   const getDocumentationFiles = async () => {
    getDocumentations().then(res => {
      setDocumentations(res.data)
      setState({ ...state, processingDocumentation: false })
    }).catch(_ => {
      console.error("No documentation file")
     })
   }

   const getBkaVersion = () => {
    getVersion().then(res => {
      setVersion(res.data.version)
    }).catch(_ => {
      console.error("No Version found")
     })
   }
   /**
    * Call get package to build list when component is mounting
    */
   useEffectOnlyOnce(()=> {
      setState({ ...state, processingLicence: true, processingDocumentation: true })
      getLicenceFiles()
      getDocumentationFiles()
      getBkaVersion()
    })

  const processing = <CircularProgress />

  const documentationForm= () => {
    return (
      <tbody>
        {documentations.map((doc, index) => (
          <tr key={index}>
          <td className="picto"> <img src={require('../../assets/images/pdf.svg').default} alt='pdf' width='117' height='42'/> </td>
          <td> <p> <a className="link" href={`${BKAPI_URL}/documentations/${doc.data}`}>{doc.data.split(".")[0].split("_").join(" ")}</a></p> </td>
          </tr>
        ))}
      </tbody>
    )
  }

  const versionDetailForm= () => {
    return (
      <tbody>
        <tr>
          <td className="picto"> <img src={require('../../assets/images/BkA100.png')} alt='BkA100' width='117' height='42'/> </td>
          <td> <p>BKA {version} - ©{new Date().getFullYear()} Broadpeak - All Rights Reserved</p> </td>
        </tr>
        <tr>
          <td className="picto"> <img src={require('../../assets/images/Apache_PoweredBy.svg').default} alt='Apache' width='117' height='42'/> </td>
          <td> <p>Apache HTTP Server - ©{new Date().getFullYear()} The Apache Software Foundation.</p> </td>
        </tr>
        {licences.map((licence, index) => (
            <tr key={index}>
            <td className="picto"></td>
            <td> <p>This product is powered by third-party components, see complete <a className="link" href={`${BKAPI_URL}/licences/${licence.data}`}>licences</a>.</p> </td>
            </tr>
        ))}
      </tbody>
    )
  }

  return (
    <div className='widget widget-minHeight'>
      <div className='widget__header'>
        <h2 className="Subtitle">Version details</h2>
      </div>
      <div className='widget__content'>
        <table className='table'>
          {state.processingLicence ? processing : versionDetailForm()}
        </table>
      </div>
      <div className='widget__header'>
        <h2 className="Subtitle">Contact information</h2>
      </div>
      <div className='widget__content'>
        <table>
          <tbody>
            <tr>
              <td className="picto"></td>
              <td> <p>Web site: <a className="link" target="_blank" rel="noreferrer" href="http://www.broadpeak.tv/">http://www.broadpeak.tv/</a></p> </td>
            </tr>
            <tr>
              <td className="picto"></td>
              <td> <p>Support: For any question regarding product support, please contact <a className="link" href="mailto:support@broadpeak.tv">support@broadpeak.tv</a>.</p> </td>
            </tr>
            <tr>
              <td className="picto"></td>
              <td> <p>Sales: If you need to learn more about our product lines, our roadmap or our new features, please contact <a className="link" href="mailto:sales@broadpeak.tv">sales@broadpeak.tv</a>.</p> </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='widget__header'>
        <h2 className="Subtitle">Documentation</h2>
      </div>
      <div className='widget__content'>
        <table className='table'>
          {state.processingDocumentation ? processing : documentationForm()}
        </table>
      </div>
    </div>
  )
}

export default About
