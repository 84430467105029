import bkApi from './bk-api'

const getPages = () => {
  return bkApi.get('/v1/routes/pages')
}

const getPage = routerId => {
  return bkApi.get('/v1/routes/pages/' + routerId)
}

export { getPages, getPage }
