import React from 'react'
import BkIcon from '../../components/icon'
import useLegacy from '../../i18n/legacyEffect'
const CurrentFilterItem = ({ filter, onRemove }) => {
  const { tLegacy } = useLegacy()

  return (
    <div className='currentFilter'>
      <span>
        {tLegacy('filters', filter.name)} {filter.value.operator}{' '}
        {tLegacy(filter.name, filter.value.value)}
      </span>
      <button
        onClick={() => onRemove(filter)}
        className='bkButton
          bkButton--invisible currentFilter__closeButton'
      >
        <BkIcon path='close' />
      </button>
    </div>
  )
}

export default CurrentFilterItem
