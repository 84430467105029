import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { selectMenuType, selectSubMenuType } from '../dashboards/store/dashboardSlice'
const { RadioGroup, FormControlLabel, Radio } = require('@material-ui/core')
const { useTranslation } = require('react-i18next')
const { PERIOD } = require('../../services/period.service')

const DurationSelector = ({ period, onChange }) => {
  const { t } = useTranslation()
  const currentSubMenu = useSelector(selectSubMenuType)
  const currentMenu = useSelector(selectMenuType)

  const datePickerCloudPvr = () => {
    return (
      <Fragment>
        <FormControlLabel
          value={PERIOD.day}
          control={<Radio color='primary' />}
          label={t('period:day')}
          disabled= {true}
        />
        <FormControlLabel
          value={PERIOD.week}
          control={<Radio color='primary' />}
          label={t('period:week')}
          disabled= {true}
        />

        <FormControlLabel
          value={PERIOD.month}
          control={<Radio color='primary' />}
          label={t('period:month')}
        />

        <FormControlLabel
          value={PERIOD.custom}
          control={<Radio color='primary' />}
          label={t('period:custom')}
          disabled= {true}
        />
      </Fragment>
    )
  }

  const datePickerTrending = () => {
    return (
      <Fragment>
        <FormControlLabel
          value={PERIOD.day}
          control={<Radio color='primary' />}
          label={t('period:day')}
          disabled= {true}
        />
        <FormControlLabel
          value={PERIOD.week}
          control={<Radio color='primary' />}
          label={t('period:week')}
          disabled= {true}
        />

        <FormControlLabel
          value={PERIOD.month}
          control={<Radio color='primary' />}
          label={t('period:month')}
          disabled= {true}
        />

        <FormControlLabel
          value={PERIOD.custom}
          control={<Radio color='primary' />}
          label={t('period:custom')}
        />
      </Fragment>
    )
  }

  return (
    <div className='periodDuration'>
      <span className='periodDuration__label'>{t('period:predefined')}</span>
      <RadioGroup
        aria-label='periodDuration'
        name='periodDuration'
        value={period.duration}
        onChange={onChange}
      >

    {/* Special datePicker for cloudPvr dashboard */}
    {currentSubMenu && currentSubMenu === 41 ?
        datePickerCloudPvr()
        : currentMenu && currentMenu === 90 ?
        datePickerTrending()
        : (
        <Fragment>
          <FormControlLabel
            value={PERIOD.day}
            control={<Radio color='primary' />}
            label={t('period:day')}
          />
          <FormControlLabel
            value={PERIOD.week}
            control={<Radio color='primary' />}
            label={t('period:week')}
          />

          <FormControlLabel
            value={PERIOD.month}
            control={<Radio color='primary' />}
            label={t('period:month')}
          />

          <FormControlLabel
            value={PERIOD.custom}
            control={<Radio color='primary' />}
            label={t('period:custom')}
          />
        </Fragment>
        )}
      </RadioGroup>
    </div>
  )
}

export default DurationSelector
