import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import BkMenu from '../../components/Menu'
import { selectCurrentUser } from './store/authSlice'
import Logout from './Logout'
import './styles/user-menu.scss'
import BkMenuItem from '../../components/MenuItem'
import BkIcon from '../../components/icon'
import { ChangePassword } from './ChangePassword'
import { UserInformation} from './UserInformation'
import Modal from '../../components/Modal'
import useLogout from './hooks/logout'

const UserMenu = () => {
  const user = useSelector(selectCurrentUser)
  const [toggled, setToggled] = useState(true)
  const [userInfoModalToggle, setUserInfoModalToggle] = useState(false)
  const [resetModalToggle, setResetModalToggle] = useState(false)
  const { logoutHandler } = useLogout()

  
  const changePasswordModal = (
    <Modal title='Reset the user password' onClose={() => setResetModalToggle(false)}>
      <ChangePassword onClose={() => setResetModalToggle(false)} />
    </Modal>
  )
  const userInfoModal = (
    <Modal title='User Information' onClose={() => setUserInfoModalToggle(false)}>
      <UserInformation onClose={() => setUserInfoModalToggle(false)} />
    </Modal>
  )

  return (
    <>
      <BkMenuItem icon='ID' isLink={false} id="user" onClick={() => setToggled(!toggled)}>
        {user.user_name}
        <BkIcon
          path='arrow'
          modifier='right-active'
          className='bkMenu__subIcon'
        />
      </BkMenuItem>
      <BkMenu
        open={!toggled}
        className='userMenu__menu'
        onCollapse={() => setToggled(false)}
      >
        <BkMenuItem icon='ID' isLink={false} id="logout" onClick={logoutHandler}>
          <Logout />
        </BkMenuItem>
        <BkMenuItem icon='settings' isLink={false} id= "chpwd">
          <span onClick={() => setResetModalToggle(true)}>Change password</span>
        </BkMenuItem>
        <BkMenuItem icon='help' isLink={false}>
          <span onClick={() => setUserInfoModalToggle(true)}>User Information</span>
        </BkMenuItem>
      </BkMenu>
      {resetModalToggle && changePasswordModal}
      {userInfoModalToggle && userInfoModal}
    </>
  )
}

export default UserMenu
