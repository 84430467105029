import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useParams } from 'react-router-dom'
import BkIcon from '../../components/icon'
import Scrollable from '../../components/Scrollable'
import Footer from '../../layouts/Footer'
import BkButton from '../../components/Button'
import Modal from '../../components/Modal'
import { getTemplate } from '../../services/dashboards.service'
import { changePage } from '../dashboards/store/dashboardSlice'
import WidgetContainer from '../dashboards/WidgetContainer'
import {
  saveTemplate,
  selectTemplate
} from './store/customDashboardSlice'

const CustomDashboardViewer = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const template = useSelector(selectTemplate)
  const [exportModal, displayExportModal] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [editMode, setEditMode] = useState(true)
  
  const downloadDashboard = () => {
    const element = document.createElement("a");
    const filename = "bka_custom_dashboard.json";
    const current_dashboard =  template;
    const dashboard_to_save = JSON.parse(JSON.stringify(current_dashboard));
    const text = JSON.stringify(dashboard_to_save);

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    // document.body.removeChild(element);
  }

  useEffect(() => {
    const fetchTemplate = async (templateId) => {
      try {
        const { data } = await getTemplate(templateId)
        dispatch(saveTemplate(data))
        if (data && data.options && data.options.editMode !== undefined) {
          setEditMode(data.options.editMode)
        } else {
          setEditMode(true)
        }
      } catch (error) {
        setRedirect(true)
      }
    }
    fetchTemplate(id)
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    changePage(dispatch, ['dashboards', _.get(template, ['routerId']) ] , _.get(template, ['submenuid']) )
  }, [template]) // eslint-disable-line react-hooks/exhaustive-deps

  const header = template && (
    <header className='pageContainer__header'>
      <div className='pageContainer__titleEditor'>
        <h1 className='pageContainer__title'>{template.title}</h1>
      </div>
      <div className='pageContainer__actions'>
        {editMode && (
        <Link
          to={'/dashboards/' + template.routerId + '/edit'}
          className='bkButton bkButton--icon bkButton--noPadding'
        >
        <BkIcon path='edit' className="" alt='Edit the current template' />
        </Link>
        )}
        <BkButton
          type='button'
          onClick={() => displayExportModal(true)}
          className='bkButton bkButton--icon bkButton--noPadding'
        >
        <BkIcon path='download' alt='Export Json File' />
        </BkButton>

      </div>
    </header>
  )

  return (
    <>
      {_.isNull(template) && redirect && <Redirect exact to='/' />}
      {!_.isNull(template) && (
        <>
          <div className='pageContainer'>
            <Scrollable>
              <div className='pageContainer__section'>
                {header}
                <WidgetContainer template={template} />
              </div>
            </Scrollable>
            <Footer />
          </div>
          {exportModal &&
            <Modal title='Share dashboard' onClose={() => displayExportModal(false)}>
              Export as a file
              <BkButton className="bkButton-export" onClick={downloadDashboard} >
              <BkIcon path='download' alt='Export Json File' className="fullScreen__icon"  />
                Download
              </BkButton>
              <div className='modal__footer'>
                <div className='modal__footer'>
                  <BkButton
                    onClick={() => displayExportModal(false)}
                    className='bkButton--alternate'
                  >
                  Cancel
                  </BkButton>
                </div>
              </div>
            </Modal>}
        </>
      )}

    </>
  )
}

export default CustomDashboardViewer
