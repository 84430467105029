import authReducer from './features/authentication/store/authSlice'
import customDashboardReducer from './features/custom-dashboards/store/customDashboardSlice'
import dashboardReducer from './features/dashboards/store/dashboardSlice'
import charts from './features/charts/store/ChartsSlice'
import filters from './features/filters/store/FiltersSlice'
import table from './features/tableList/store/TableSlice'
import settingsReducer from './features/settings/store/SettingsSlice'
import diagnosticsReducer from './features/diagnostics/store/DiagnosticsSlice'
import burgerReducer from './components/store/burgerSlice'
import { configureStore } from '@reduxjs/toolkit'

export default configureStore({
  reducer: {
    auth: authReducer,
    burger: burgerReducer,
    customDashboard: customDashboardReducer,
    dashboards: dashboardReducer,
    charts,
    filters,
    table: table,
    settings: settingsReducer,
    diagnostics: diagnosticsReducer,
  }
})
