import { useEffect, useState } from 'react'

import { listUserAccounts } from '../../../../services/user.service'

//Hook needed in UsersDataList.js to load user data
const useUsersList = config => {
  const [list, setlist] = useState(null)

  useEffect(() => {
    const fetchUserList = async config => {
      //Request for the list of item to fill the users table
      const data = await listUserAccounts({
        ...config,
        limit: config.nbItemsPerPage,
        offset: config.offset || 0
      }
      )
      setlist(data)
    }
    fetchUserList(config)
  }, [/*filters,*/ config.offset])

  return list
}

export default useUsersList