import React, { useState, useEffect } from 'react'
import BkButton from '../../components/Button'
import { CircularProgress } from '@material-ui/core'
import { getCurrentUserInfos } from '../../services/user.service'
export const UserInformation = ( { onClose }) => {
  const [user, setUser] = useState({})
  const group = user.group_type === 0 ? "Operator" : "Content provider"
  const last_modified = user.last_modified ?  user.last_modified.split('.')[0] : ""
  useEffect(() => {
    try {
      getCurrentUserInfos().then(response => {
        setUser(response.data)
      })
       
    } catch (err) {
      return null
    }
  }, [])
  const loading = <CircularProgress /> 
  // -------------------------------------------------------------- 
  return (
    <div>
        {!user.user_name && loading } 
        {user.user_name &&
        <div>
        <div className='widget__content'>
        <table className='table'>
        <tbody>
        <tr>
          <td >User Name:  </td>
          <td> <p>{user.user_name}</p> </td>
        </tr>
        <tr>
          <td >Email:  </td>
          <td> <p>{user.email}</p> </td>
        </tr>
        <tr>
          <td >Group Type:  </td>
          <td> <p>{group}</p> </td>
        </tr>
        <tr>
          <td >Group Name:  </td>
          <td> <p>{user.group_name}</p> </td>
        </tr>
        {user.group_type === 1 && 
          <tr> 
            <td >Provider name:  </td>
            <td> <p>{user.filter_value}</p> </td>
          </tr>
        }
        <tr> 
          <td >Last login date:  </td>
          <td> <p>{user.last_login}</p> </td>
        </tr>
        <tr>
          <td >Last modified password date:  </td>
          <td> <p>{last_modified}</p> </td>
        </tr>
        </tbody>
        </table>
        </div>
          <div className='modal__footer'>
          
            <BkButton onClick={onClose} className='bkButton--alternate'>
              Cancel
            </BkButton>
          </div>
        </div>
      }
    </div>
  )
}
