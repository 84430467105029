import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import BkButton from '../../components/Button'
import BkSelect from '../../components/forms/Select'
import BkTextField from '../../components/forms/TextField'
import { CircularProgress, Paper } from '@material-ui/core'
import { createDiagnostic } from '../../services/diagnostic.service'
import { getSections } from '../../services/dashboards.service.js'
import PeriodSelector from '../datepicker/PeriodSelector'
import { selectPeriod, getPeriodString } from '../filters/store/FiltersSlice'
import { getFilters } from '../../services/filter.service'
import useLegacy from '../../i18n/legacyEffect'
import { CreateDiagnosticFilter } from './CreateDiagnosticFilter'
import BkAlert from '../../components/Alert'

import { useTranslation } from 'react-i18next'

const types = [
  {title: 'Root cause analysis', value: 'explain'},
  {title: 'Profiling', value: 'columns'},
]

export const CreateDiagnostic = ({ onClose }) => {
  const { tLegacy } = useLegacy()
  const { t } = useTranslation()

  // Form state
  const [processing, setProcessing] = useState(false)
  const [sections, setSections] = useState([])

  // Form fields
  const [type, setType] = useState(types[0])
  const [name, setName] = useState('')
  const [section, setSection] = useState(null)
  const [preset, setPreset] = useState(null)
  const [presets, setPresets] = useState([])
  const [query, setQuery] = useState('')
  const [dateOpen, setDateOpen] = useState(false)
  const storePeriod = useSelector(selectPeriod)
  const periodStr = getPeriodString(storePeriod)
  const [filters, setFilters] = useState([])
  const [dimensions, setDimensions] = useState([])

  const initialStatus = {
    errors: []
  }
  const [status, setStatus] = useState(initialStatus)

  const onCreate = async () => {
    setStatus({ errors: []})

    if (!section) {
      setStatus({
        errors: [{ code: 0, message: 'Providing a Data source is mandatory.' }]
      })
      return
    }

    if (type?.value === 'explain') {
      if (!(name?.length > 0)) {
        setStatus({
          errors: [{ code: 0, message: 'Providing a Diagnostic name is mandatory.' }]
        })
        return
      }

      if (!(query?.length > 0)) {
        setStatus({
          errors: [{ code: 0, message: 'Providing a Query is mandatory.' }]
        })
        return
      }

      if (filters.length > 0) {
        if (!filters.at(-1).dimension) {
          setStatus({
            errors: [{ code: 0, message: 'Providing a Filter dimension is mandatory.' }]
          })
          return
        }
        if (!filters.at(-1).operator || filters.at(-1).operator === 'None') {
          setStatus({
            errors: [{ code: 0, message: 'Providing a Filter operator is mandatory.' }]
          })
          return
        }

      }
    }
    setProcessing(true)
    createDiagnostic({
      type: type?.value,
      name: type?.value === 'explain' ? name : undefined,
      table_name: section.filters?.percentage_table,
      startdate: storePeriod.startdate,
      enddate: storePeriod.enddate,
      filters: filters.filter(({ values }) => values.length > 0),
      query,
    }).then(
      () => {
        setStatus({ errors: [] })
        onClose()
      }
    ).catch((error) => {
      setProcessing(false)
      setStatus({ errors: error.response ? error.response.data.errors : []})
    })
    return
  }

  useEffect(() => {
    const fetchSections = async () => {
      setSections((await getSections()).data.filter(s => s.disableProfiling !== true))
    }

    fetchSections()
  }, [])

  useEffect(() => {
    const fetchIndicators = async () => {
      const f = (await getFilters({ submenuid: section?.submenuid }))
        ?.data
        ?.filters_categories

      const indicators = f?.find(c => c.category_name === 'indicators_filters')
        ?.filters
        ?.map(({ columnName }) => ({
          label: tLegacy('filters', columnName),
          value: columnName,
        }))
        ?.sort((a, b) => (a.label > b.label ? 1 : -1))
        || []
      setPresets(indicators)

      const dimensions = f?.filter(c => c.category_name !== 'indicators_filters')
        ?.map(e => e.filters)
        ?.flat()
        ?.sort((a, b) => tLegacy('filters', a.columnName) > tLegacy('filters', b.columnName) ? 1 : -1)
        || []
      setDimensions(dimensions)
    }

    if (section) {
      fetchIndicators()
    }
  }, [tLegacy, section])

  const displayErrors = (
    <BkAlert severity='error' className='input'>
      {status &&
      status.errors &&
      status.errors.length > 0 &&
      status?.errors?.map((err, i) => (
        <div key={i}>{t('errorsDiagnostic:' + err.message)}</div>
      ))}
    </BkAlert>
  )

  const form = (
    <div className='formGroup'>
      {status.errors.length > 0 ? displayErrors : null}
      <BkSelect
        title='Diagnostic Type'
        options={types}
        selected={type}
        optionConfig={{label: 'title'}}
        onChange={setType}
      />

      <BkSelect
        title='Data source'
        options={sections}
        selected={section}
        optionConfig={{label: 'title'}}
        onChange={s => { setSection(s); setPreset(null); setQuery(''); setFilters([]) }}
      />

      { type?.value === 'explain' && section && (
        <>
          <BkTextField
            label='Diagnostic name'
            value={name}
            onChange={e => setName(e.target.value)}
          />

          <BkSelect
            title='Query'
            options={[...presets, {label: 'Custom', custom: true}]}
            selected={preset}
            optionConfig={{label: 'label'}}
            onChange={p => { setPreset(p); setQuery(p?.value || '') }}
          />

          { preset?.custom && (
            <BkTextField
              label='Custom query'
              value={query}
              helper='The query to explain. Can be a column name for regression, or a boolean expression of columns for binary classification.'
              onChange={(e) => setQuery(e.target.value)}
            />
          ) }
        </>
      )}

      { filters.map((filter, i) => (
        <CreateDiagnosticFilter
          key={i}
          dimensions={dimensions}
          data={filter}
          onChange={(newFilter) => setFilters(filters.map((oldFilter, j) => (
            i === j ? newFilter : oldFilter
          )))}
          onDelete={() => setFilters(filters.filter((_, j) => i !== j))}
        />
      )) }

      { section && (
        <BkButton
          onClick={() => setFilters([...filters, {}])}
          className='bkButton--alternate'>
          Add Filter
        </BkButton>
      ) }

      {dateOpen ? (
        <Paper elevation={2} square>
          <PeriodSelector onApply={() => setDateOpen(false)} onCancel={() => setDateOpen(false)} />
        </Paper>
      ) : (
        <BkButton onClick={() => setDateOpen(true)} className='bkButton--alternate'>
          Period: { periodStr }
        </BkButton>
      )}

      <div className='modal__footer'>
        <BkButton
          type='submit'
          variant='contained'
          color='primary'
          onClick={onCreate} >
            Create
        </BkButton>
        <BkButton onClick={onClose} className='bkButton--alternate'>
          Cancel
        </BkButton>
      </div>
    </div>
  )

  return (
    <div>{processing ? <CircularProgress /> : form}</div>
  )
}

export default CreateDiagnostic
