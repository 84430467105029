import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPeriod, getPeriodDate, updatePeriod, resetPeriodZoom } from '../filters/store/FiltersSlice'
import { toggleFilters, selectDisplayFilters, selectSubMenuType } from '../dashboards/store/dashboardSlice'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './styles/periodSelector.scss'
import { PERIOD, calcEnd, toISOString } from '../../services/period.service'
import DurationSelector from './DurationSelector'
import BkButton from '../../components/Button'
import { useTranslation } from 'react-i18next'

const PeriodSelector = ({ onApply, onCancel }) => {
  const { t } = useTranslation()
  const storePeriod = useSelector(selectPeriod)
  const statePeriod = getPeriodDate(storePeriod)
  const [period, setPeriod] = useState(statePeriod)
  const dispatch = useDispatch()
  const isDisplayedFilter = useSelector(selectDisplayFilters)
  const currentSubMenu = useSelector(selectSubMenuType)

  const collapseHandler = () => {
    dispatch(toggleFilters())
  }

  const changeDurationHandler = e => {
    let p = { ...period, duration: e.target.value }
    // WARNING : re-calculate endDate
    p = calcEnd(p)
    setPeriod(p)
  }

  const changeStartHandler = date => {
    // Correct pb seen when timezone is negative
    //    The wrong start day was applied when clicking on apply
    //    Note that there is still a pb because selector color is on the wrong day but it's a datepicker issue
    let hour = date.getTimezoneOffset()/60
    if (hour > 0)  {
      date.setHours(hour, 0,1)
    }

    let p = { ...period, startdate: date }
    if (period.duration !== PERIOD.custom) {
      p = calcEnd(p)
    }
    setPeriod(p)
  }

  const changeEndHandler = date => {
    setPeriod({ ...period, enddate: date })
  }

  const applyHandler = () => {
    console.log(period)
    dispatch(updatePeriod(toISOString(period)))
    // Reset zoom when changing date is applicated
    dispatch(resetPeriodZoom())
    onApply()
  }

  const DatePickerCloudPvr = () => {
    let p = { ...period, duration: "month" }
    p = calcEnd(p)
    setPeriod(p)
    return (
      <DatePicker
        selected={period.startdate}
        inline
        onChange={changeStartHandler}
        startDate={period.startdate}
        endDate={period.enddate}
      />
    )
  }

  return (
    <div className='periodSelector'>
      {period && period.duration === PERIOD.day && (
        <div className='periodSelector__zone'>
          <span className='periodDuration__label'>{t('period:from')}</span>
          {console.log('start and end date', {"start": period.startdate, "end":period.enddate} )}
          {currentSubMenu && currentSubMenu === 41 ?
           DatePickerCloudPvr()
          :(
          <DatePicker
            selected={period.startdate}
            inline
            onChange={changeStartHandler}
            startDate={period.startdate}
          />
        )}
        </div>
      )}
      {period && period.duration === PERIOD.week && (
        <div className='periodSelector__zone'>
          <span className='periodDuration__label'>{t('period:from')}</span>
          {console.log('start and end date', {"start": period.startdate, "end":period.enddate} )}
          {currentSubMenu && currentSubMenu === 41 ?
           DatePickerCloudPvr()
          :(
          <DatePicker
            selected={period.startdate}
            inline
            onChange={changeStartHandler}
            startDate={period.startdate}
            endDate={period.enddate}
          />
          )}
        </div>
      )}
      {period && period.duration === PERIOD.month && (
        <div className='periodSelector__zone'>
          <span className='periodDuration__label'>{t('period:from')}</span>
          {console.log('start and end date', {"start": period.startdate, "end":period.enddate} )}
          <DatePicker
            selected={period.startdate}
            inline
            onChange={changeStartHandler}
            startDate={period.startdate}
            endDate={period.enddate}
          />
        </div>
      )}
      {period && period.duration === PERIOD.custom && (
        <>
          <div className='periodSelector__zone'>
            <span className='periodDuration__label'>{t('period:from')}</span>
            {console.log('start and end date', {"start": period.startdate, "end":period.enddate} )}
            {currentSubMenu && currentSubMenu === 41 ?
           DatePickerCloudPvr()
          :(
            <DatePicker
              selected={period.startdate}
              inline
              onChange={changeStartHandler}
              maxDate={period.enddate}
              startDate={period.startdate}
              endDate={period.enddate}
            />
          )}
          </div>
          <div className='periodSelector__zone'>
            <span className='periodDuration__label'>{t('period:to')}</span>
            <DatePicker
              selected={period.enddate}
              inline
              onChange={changeEndHandler}
              minDate={period.startdate}
              startDate={period.startdate}
              endDate={period.enddate}
            />
          </div>
        </>
      )}
      <div className='periodSelector__zone periodSelector__zone--duration'>
        <DurationSelector period={period} onChange={changeDurationHandler} />

        {/* Hide FilterBlock when apply date. Permit to refresh filter. FIX 1939 */}
        {!isDisplayedFilter
        ? <BkButton onClick={applyHandler}>{t('actions:apply')}</BkButton>
        : <BkButton onClick={() => { collapseHandler() ;applyHandler()}}>{t('actions:apply')}</BkButton>
        }
        {/* <BkButton onClick={applyHandler}>{t('actions:apply')}</BkButton>&nbsp; */}
        <BkButton onClick={onCancel} className='bkButton--alternate'>
          {t('actions:cancel')}
        </BkButton>
      </div>
    </div>
  )
}

export default PeriodSelector
