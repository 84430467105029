import React, { useEffect, useState, useRef } from 'react'
import { CircularProgress } from "@material-ui/core";

import BkButton from '../../components/Button'
import { generateSupportPackage, getSupportPackages, deleteSupportPackage } from '../../services/support.service'
import IconButton from '@material-ui/core/IconButton'
import BkIcon from '../../components/icon'
import { BKAPI_URL } from '../../environment'
import '../../components/styles/icon.scss'

/**
 * @function Help: Help page: create support packages and support package list
 * @returns 
 */
const Help = () => {

  const [packages, setPackages] = useState([])
  const [packageGenerating, setPackageGenerating] = useState(false)
  const [stateProcessing, setStateProcessing] = useState(false)

  const interval = useRef(null)
  const processing = <CircularProgress />

  /**
   * Send request to backend to generqte a new support package
   */
  const generatePackage = () => {
    setPackageGenerating(true)
    generateSupportPackage().then(res => {
      interval.current = setInterval(() => {
        getPackages()
      }, 2e3)
    }).catch((error) => {
      setPackageGenerating(false)
      setStateProcessing(false)
      console.error(error)
    })
  }

  /**
   * Deletes the package `packageName` on the backend's filesystem
   * @param {String} packageName 
   */
  const deletePackage = (packageName) => {
    deleteSupportPackage(packageName).then(res => {
      setPackages(res.data.data)
    })
  }

  /**
   * Get the lists of the support packages
   * If the generating field is true, this method will be called
   * every the second, and once the package is generated the setInterval is cleared
   */
  const getPackages = async () => {
    getSupportPackages().then(res => {
      setPackages(res.data.data)
      setStateProcessing(false)
      if (res.data.generating === 'false') {
        setPackageGenerating(false)
        if (interval.current) {
          clearInterval(interval.current)
          interval.current = undefined
        }
      } else {
        setPackageGenerating(true)
        if (!interval.current) {
          interval.current = setInterval(() => {
            getPackages()
          }, 1e3)
        }
      }
      //setPackageGenerating(res.data.generating)
    }).catch(_ => {
      setStateProcessing(false)
      setPackageGenerating(false)
     })
   }

   /**
    * Call get package to build list when component is mounting
    */
  useEffect(() => {
    setStateProcessing(true)
    getPackages()
    /**
     * Cleanup function => clear interval if the component is destroyed
     */
    return function() {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [interval])

  return (
    <div className='widget widget-minHeight'>
      <div className='widget__header'>
        <h1>Support package</h1>
      </div>
      <div className='widget__content widget__content--form'>
        {packageGenerating ? (
        <p>Support Package generation in progress...</p>
        ) : (
          <BkButton onClick={generatePackage}>Generate Support Package</BkButton>
        )}
      </div>
      <div>
        <table className='table'>
          <thead>
            <tr className='table__head'>
              <th>Available Support Package</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {stateProcessing ? processing : packages.map((pkg, index) => (
              <tr key={index} className='table__row'>
                <td>{pkg.name}</td>
                <td className='buttonTd'>
                  <div>
                    <a href={`${BKAPI_URL}/packages/${pkg.name}`}>
                      <IconButton aria-label="delete">
                        <BkIcon path='save' className='icon'></BkIcon>
                      </IconButton>
                    </a>
                    <IconButton aria-label="delete" onClick={()=>deletePackage(pkg.name)}>
                      <BkIcon path='trash' className='icon'></BkIcon>
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Help
