import { createSlice } from '@reduxjs/toolkit'
export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    email: null,
    group_id: null,
    pfl_id: null,
    user_id: null,
    user_name: null,
    status: null//,
    //pwd_to_renew: null
  },
  reducers: {
    login: (state, action) => {
      state = { ...action.payload }
      return state
    },
    logout: state => {
      state = {
        email: null,
        group_id: null,
        pfl_id: null,
        user_id: null,
        user_name: null,
        status: null//,
        //pwd_to_renew: null
      }
      return state
    }
  }
})

export const { login, logout } = authSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCurrentUser = state => state.auth
export default authSlice.reducer
