import React from 'react'
import { Paper } from '@material-ui/core'
import '../theme/login.scss'
import { useTranslation } from 'react-i18next'
import Footer from './Footer'

const LoginLayout = ({ children }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className='container container--fullPage container--login'>
        <div className='branding'>{t('app:branding')}</div>
        <Paper className='loginBox'>
          {children}
        </Paper>
      </div>
      <Footer />
    </>
  )
}

export default LoginLayout
