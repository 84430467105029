import * as _ from 'lodash'
import React, {useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Paginator from '../../../components/Paginator'
import useLegacy from '../../../i18n/legacyEffect'

import BkButton from '../../../components/Button'
import BkIcon from '../../../components/icon'
import IconButton from '@material-ui/core/IconButton'
import Modal from '../../../components/Modal'

import { currentUser } from '../../../services/auth.service'
import { removeGroup } from '../../../services/group.service'
import useGroupsList from './hooks/groupsListEffect'
import '../../tableList/styles/table.scss'
import '../../../components/styles/icon.scss'
import { Grid } from '@material-ui/core'
import BkAlert from '../../../components/Alert'

export const TYPES = {
  LIST: 'list',
  LIST_GROUP: 'list_group'
}

const GroupsDataList = ({ config, displayHeader }) => {
  const { tLegacy } = useLegacy()
  const { t } = useTranslation()
  //Pagination offset
  const [offset, setOffset] = useState(0)
  //Selected item in the table
  const [selectedItem, setSelectedItem] = useState(0)
  const initialStatus = {
    errors: []
  }
  const [status, setStatus] = useState(initialStatus)

  const changePage = offset => {
    setOffset(offset)
  }

  const [currentUserData, setCurrentUserData] = useState(null)
  useEffect(() => {
    const getGroupItem = async () => {
      currentUser().then((res) => {
        const data = _.get(res, 'data')
        const user = _.get(data, 'user')
        setCurrentUserData(user)
      })
    }
    getGroupItem()
  }, [])

  //List of groups to map/display
  const data = useGroupsList({ ...config, offset })
  const tmpData = _.get(data, 'data')
  const list = _.get(tmpData, 'groups')
  const total = _.get(list, 'length')

  const [removeModal, displayRemoveModal] = useState(false)

  const displayErrors = (
    <BkAlert severity='error' className='input'>
      {status &&
      status.errors &&
      status.errors.length > 0 &&
      status?.errors?.map((err, i) => (
        <div key={i}>{t('errorsGroups:' + err.message)}</div>
      ))}
    </BkAlert>
  )

  //----------------------- REMOVE GROUP ---------------------------------------------------------
  const removeGroupHandler = async () => {
    setStatus({
      errors: []
    })
    await removeGroup(selectedItem.id)
    .then(() => {
      displayRemoveModal(false)
      // reload window when Remove Group
      window.location.reload()
    })
    .catch((error) => {
      setStatus({
            errors: [{ code: 0, message: error.response.data.errors[0].message }]
          })
    });
  }
  const doRemoveModal = (item) => {
      setSelectedItem(item)
      displayRemoveModal(true)
  }
  const displayRemoveGroupActionButton = (item) =>{
     if (currentUserData && item && (currentUserData.group_id !== item.id && item.id !== 1)) {
      return <IconButton aria-label="delete" onClick={() => doRemoveModal(item) /*openModalForRemoveGroup(item)*/}>
        {<BkIcon path='trash' modifier='active' className='icon' />}
      </IconButton>
     }
  }

  //----------------------- Adding action buttons in action column ---------------------------------------------------------
  const addActionButtons = (item) => {
    return <div>
      { displayRemoveGroupActionButton(item) }
    </div>
  }

  //Transform the id value of a profile to the corresponding label
  // const transformQuotas = (item) => {
  //   if (item.connections_quota_admin === 0 && item.connections_quota_operator === 0)
  //     return "None";
  //   else
  //     return item.connections_quota_operator + "/" + item.connections_quota_admin
  // }

  //Transform the displayed value, depending on the column treshold or unit
  const transformField = (value, fieldConfig) => {
    if (fieldConfig.dimension === 'type') {
      return tLegacy('group_type', String(value))
    } else {
      return tLegacy(fieldConfig.dimension, String(value))
    }
  }

  //Transform columns content, depending on the column dimension
  const manageColumnContent = (item, fieldConfig) => {
    //Access level / Profil
    // if (fieldConfig.dimension === 'quotas') {
    //   return transformQuotas(item)
    // }
    //Action buttons
    if (fieldConfig.dimension === 'actions'){
      return addActionButtons(item)
    }
    //default:
    return transformField(item[fieldConfig.dimension], fieldConfig)
  }

  const paginationValue = () => {
    const nbElementOnPage = offset + config.nbItemsPerPage
    if (total === 0) {
      return  '0 / 0'
    }
    else if (nbElementOnPage > total ) {
      return offset + 1 + ' - ' + total + ' / ' + total
    }
    else {
      return offset + 1 + ' - ' + nbElementOnPage + ' / ' + total
    }
  }

  return (
    <>
      {config && list && (
        <>
          {displayHeader && (
            <header className='widget__header'>
              <h2 className='widget__title'>
                {/* HIDE TITLE*/}
                {/* {_.get(config, 'title') || _.get(config, 'type')} */}
              </h2>
              <div className='widget__actions'>{paginationValue()}</div>
            </header>
          )}
          <div className='widget__content'>
            <div className='tableContainer'>
              <table className='table'>
                <thead>
                  <tr className='table__head'>
                    {config.fields.map((field, i) => {
                      return !field.hidden && <th key={i}>{field.title}</th>
                    })}
                  </tr>
                </thead>
                <tbody>
                  {list.slice(offset,offset + config.nbItemsPerPage).map((item, i) => (
                    <tr key={i} className='table__row'>
                      {config.fields.map((field, k) => {
                        return (
                          !field.hidden && (
                            field.dimension === 'actions' ? (
                              <td key={k + '_' + i} className="buttonTd">
                              {
                                //Field content management
                                manageColumnContent(item, field)
                              }
                            </td>
                            ) : (
                            <td key={k + '_' + i}>
                            {
                              //Field content management
                              manageColumnContent(item, field)
                            }
                          </td>
                           )
                          )
                        )
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paginator
              limit={config.nbItemsPerPage}
              total={total}
              onChangePage={changePage}
            />
          </div>
        </>
      )}
      {/*removeGroupModalToggle && removeGroupModal*/}
      {removeModal &&
        <Modal title='Please confirm' onClose={() => {
          displayRemoveModal(false)
          setStatus({
            errors: []
          })
        }} >
         {status.errors && status.errors.length && status.errors.length > 0 ? displayErrors : ''}
           <Grid container>
            <Grid item xs={1}>
              <BkIcon path='alert' modifier='active' className='icon' />
            </Grid>
            <Grid item xs={11}>
            Do you want to remove the group of '{selectedItem.groupname}'?
            </Grid>
          </Grid>
          <div className='modal__footer'>
            <div className='modal__footer'>
              <BkButton onClick={removeGroupHandler} className='bkButton--delete' >
                Confirm
              </BkButton>
              <BkButton className='bkButton--alternate' onClick={() => {
                displayRemoveModal(false)
                setStatus({
                  errors: []
                })
              }} >
                Cancel
              </BkButton>
            </div>
          </div>
        </Modal>}
    </>
  )
}

export default GroupsDataList
