import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getRanking } from '../../../services/rankings.service'
import {   getFilterForRequest,
  selectPeriodZoom, 
  selectPeriod,
  selectAppliedFilters,
  selectTimeZone } from '../../filters/store/FiltersSlice'

const useRanking = (config, limit) => {
  const [rankings, setRankings] = useState(null)

  const periodZoom = useSelector(selectPeriodZoom)
  const period = useSelector(selectPeriod)
  const appliedFilters = useSelector(selectAppliedFilters)
  const timezone = useSelector(selectTimeZone)
  
  const filtersForRequest = getFilterForRequest(periodZoom, period, appliedFilters, timezone)

  useEffect(() => {
    const fetchRankings = async () => {
      setRankings(null)
      const res = await getRanking({ ...config, limit }, filtersForRequest)
      setRankings(res)
    }
    fetchRankings()
  }, [config, period, periodZoom, appliedFilters, timezone, limit])

  return rankings
}

export default useRanking
