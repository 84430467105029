import React, { useMemo, useEffect } from 'react'
import TableList from '../tableList/TableList'
import useLegacy from '../../i18n/legacyEffect'
import { useSelector } from 'react-redux'
import { selectSelectedLine } from '../tableList/store/TableSlice'
import { getComponentDisplayAssociation } from './utils'

export const ExplanationSummary = ({ explanation, onClick }) => {
  const { tLegacy } = useLegacy()
  const selectedLine = useSelector(selectSelectedLine('summary'))

  const importances = useMemo(() => (
    (explanation?.importances?.component || [])
    .map((component, i) => (
      { component,
        name: getComponentDisplayAssociation(tLegacy, component),
        importance: explanation?.importances?.importance?.[i] }
    ))
    .filter(({ importance }) => importance > 1e-3)
  ), [tLegacy, explanation])

  useEffect(() => {
    if (selectedLine) {
      onClick(selectedLine)
    }
  }, [onClick, selectedLine])

  const config = useMemo(() => ({
    title: 'Importance',
    type: 'list',
    compact: true,
    exportCSV: false,
    nbItemsPerPage: 20,
    items: importances,
    rowIdKey: 'component',
    orderOn: 'importance desc',
    fields: [{
      'title': 'Component',
      'dimension': 'name',
    }, {
      'title': 'Importance',
      'dimension': 'importance',
    }]
  }), [importances])

  return (
    <>
      <header className='widget__header'>
        <h2 className='widget__title'>Summary</h2>
      </header>
      <table className='table'>
        <tbody>
          <tr className='table__row'>
            <td>Mode</td>
            <td>{ explanation?.mode }</td>
          </tr>
          <tr className='table__row'>
            <td>Target query</td>
            <td>{ (explanation?.target || []).split(' ').map(e => tLegacy('fields', e)).join(' ') }</td>
          </tr>
          <tr className='table__row'>
            <td>Expectation</td>
            <td>{ explanation?.intercept?.toFixed(2) }</td>
          </tr>
          <tr className='table__row'>
            <td>R2 Score</td>
            <td>{ explanation?.test_scores?.r2?.toFixed(2) }</td>
          </tr>
        </tbody>
      </table>

      <div style={{marginTop: '2rem'}} />
      <TableList id='summary' config={config} displayHeader />
    </>
  )
}

export default ExplanationSummary
