import React, { useEffect, useState } from 'react'
import BkFieldSet from './FieldSet'
import BkMenu from '../Menu'
import BkMenuItem from '../MenuItem'
import * as _ from 'lodash'
import useLegacy from '../../i18n/legacyEffect'

const BkSelect = ({
  title,
  legacy,
  helper,
  displayHelper,
  options,
  optionConfig,
  selected,
  onChange,
  onFocus,
  className
}) => {
  const { tLegacy } = useLegacy()
  const [displayMenu, setDisplayMenu] = useState(false)

  const focusHandler = () => {
    setDisplayMenu(true)
    if (!_.isUndefined(onFocus)) {
      onFocus()
    }
  }
  const focusOutHandler = () => {
    setDisplayMenu(false)
  }

  const getLabel = label => {
    if (!_.isUndefined(optionConfig)) {
      label = _.get(label, [optionConfig.label])
    }
    return _.isUndefined(legacy) ? label : tLegacy(legacy, label)
  }

  const onSelectHandler = value => {
    setDisplayMenu(false)
    onChange(value)
  }

  useEffect(() => {
    if (!_.isUndefined(selected)) {
      onChange(selected)
    }
  }, [])

  const setClasses = value => {
    let classes = 'select__item'

    if (selected === value) {
      classes = classes + ' select__item--selected'
    }
    return classes
  }

  if (selected) {
    className = _.isUndefined(className)
      ? 'select--filled'
      : className + ' select--filled'
  }
  return (
    <BkFieldSet
      title={title}
      onFocusOut={focusOutHandler}
      helper={(displayMenu && helper) || (displayHelper && helper)}
      className={'select ' + (className || '')}
    >
      {!options ||
        (options.length === 0 && (
          <span
            className='select__button select__button--disabled'
            onClick={focusHandler}
          >
            Select
          </span>
        ))}
      {options && options.length > 0 && (
        <span className='select__button' tabIndex='0' onClick={focusHandler}>
          {getLabel(selected) || 'Select'}
        </span>
      )}
      {displayMenu && options && options.length > 0 && (
        <BkMenu className='select__menu' open={displayMenu}>
          {options.map((o, i) => (
            <BkMenuItem
              key={i}
              className={setClasses(o)}
              isLink={false}
              onClick={() => onSelectHandler(o)}
            >
              {getLabel(o)}
            </BkMenuItem>
          ))}
        </BkMenu>
      )}
    </BkFieldSet>
  )
}
export default BkSelect
