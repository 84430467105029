import { Button, ClickAwayListener, Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import BkIcon from '../../components/icon'
import { selectPeriod, getPeriodString, getPeriodDate,updatePeriod, updatePeriodQueries, selectFormerPeriod, updateFormerPeriod } from '../filters/store/FiltersSlice'
import { PERIOD, calcEnd, calcTrendingDates, toISOString, today } from '../../services/period.service'
import PeriodSelector from './PeriodSelector'
import './styles/datepickerMenu.scss'
import { useDispatch } from 'react-redux'
import { selectSubMenuType, selectMenuType } from '../dashboards/store/dashboardSlice'
const DatepickerMenu = () => {
  const dispatch = useDispatch()
  const currentSubMenu = useSelector(selectSubMenuType)
  const currentMenu = useSelector(selectMenuType)

  const storePeriod = useSelector(selectPeriod)
  const statePeriod = getPeriodDate(storePeriod)
  const periodStr = getPeriodString(storePeriod)
  const [period, setPeriod] = useState(statePeriod)

  const storeFormerPeriod = useSelector(selectFormerPeriod)
  const stateFormerPeriod = getPeriodDate(storeFormerPeriod)
  const [formerPeriod, setFormerPeriod] = useState(stateFormerPeriod)

  const [state, setState] = useState({
    toggled: false
  })
  const toggleMenu = () => {
    setState({
      toggled: !state.toggled
    })
  }

  const saveFormerPeriod = p => {
    // keep former period in memory to avoid date picker issues
    if (formerPeriod.duration === undefined){
      setFormerPeriod(period)
    }
    else {
      // by default we keep former period = today()
      setFormerPeriod(today())
    }
    setPeriod(p)
  }

  const restoreFormerPeriod = () => {
    if (formerPeriod.duration !== undefined){
      setPeriod(formerPeriod)
    }

    let p = { ...period, duration: undefined}
    setFormerPeriod(p)
  }

  useEffect(() =>{
    //update state with store
    setPeriod(statePeriod)

    if (currentSubMenu && currentSubMenu === 41 )
    {
      //in case of cloudpvr dashboardSlice, set duration to month
      let p = { ...period, duration: PERIOD.month}
      p = calcEnd(p)
      saveFormerPeriod(p)

    }
    else if (currentMenu && currentMenu === 90)    
    {
      //in case of trending dashboardSlice, set custom duration (1 year in the past, 1 in the future)
      let p = { ...period, duration: PERIOD.custom}
      p = calcTrendingDates()
      saveFormerPeriod(p)
    }
    else {
      restoreFormerPeriod()
    }

  },[currentSubMenu])

  useEffect(() =>{
    //in case of cloudpvr, state is updated then update store
    dispatch(updatePeriod(toISOString(period)))
  },[period])

  useEffect(() =>{
    //when store is updated, url must be also updated
    dispatch(updatePeriodQueries())
  },[storePeriod])

  useEffect(() =>{
    // update former date to avoid issues due to trending intervals
    dispatch(updateFormerPeriod(toISOString(formerPeriod)))
  },[formerPeriod])

  return (
    <div className='datepickerMenu'>
      <Button
        onClick={toggleMenu}
        className={`datepickerMenu__button mainMenu__button ${
          state.toggled ? 'mainMenu__button--active' : ''
        }`}
      >
        <BkIcon
          path='calendar'
          modifier={state.toggled ? 'active' : null}
          className='mainMenu__icon'
        />
        {periodStr}

      </Button>
      {state.toggled && (
        <div className='datepickerMenu__menu'>
          <ClickAwayListener onClickAway={toggleMenu}>
            <Paper elevation={2} square>
              <PeriodSelector onApply={toggleMenu} onCancel={toggleMenu} />
            </Paper>
          </ClickAwayListener>
        </div>
      )}
    </div>
  )
}

export default DatepickerMenu
