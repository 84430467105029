import React, { useState } from 'react'
import useMenu from './hooks/menu'
import BkMenu from '../../components/Menu'
import BkMenuItem from '../../components/MenuItem'
import './styles/main-navigation.scss'
import { Button } from '@material-ui/core'
import BkIcon from '../../components/icon'

const MainNavigation = () => {
  const { menu, currentDashboard, onChangePage } = useMenu()
  const [toggled, setToggled] = useState(
    menu.reduce((toggled, section) => {
      return { ...toggled, [section.routerId]: true }
    }, {})
  )

  return (
    <nav className='mainNavigation'>
      <ul className='mainMenu'>
        {menu &&
          menu.map(section => (
            <li className='mainMenu__item' key={'main-nav' + section.routerId}>
              <Button
                onClick={() =>
                  setToggled({
                    ...toggled,
                    [section.routerId]: !toggled[section.routerId]
                  })}
                className={`mainMenu__button ${!toggled[section.routerId] ? 'mainMenu__button--active' : ''
                  }
              ${currentDashboard[0] === section.routerId
                    ? 'mainMenu__button--current'
                    : ''
                }`}
              >
                <BkIcon
                  path={section.routerId.includes("10-cdn-selector") || section.routerId.includes("20-broadpeak-cdn") || 
                  section.routerId.includes("30-nanocdn")  || section.routerId.includes("40-cloud-pvr") || section.routerId.includes("50-players") || 
                  section.routerId.includes("80-ad-insertion") || section.routerId.includes("90-trending") ||
                  section.routerId.includes("dashboards") ? section.routerId : "default"}
                  modifier={
                    !toggled[section.routerId] ||
                      currentDashboard[0] === section.routerId
                      ? 'active'
                      : null
                  }
                  className='mainMenu__icon'
                />
                {section.title}
              </Button>
              <BkMenu
                open={!toggled[section.routerId]}
                className='mainMenu__subMenu'
                onCollapse={() =>
                  setToggled({
                    ...toggled,
                    [section.routerId]: !toggled[section.routerId]
                  })}
              >
                {section.children.length > 0 &&
                  section.children.map(subMenu => (
                    <BkMenuItem
                      key={'main-subnav' + subMenu.routerId}
                      className={
                        currentDashboard[1] === subMenu.routerId
                          ? 'bkMenu__item--active'
                          : ''
                      }
                      onClick={() => {
                        onChangePage([section.routerId, subMenu.routerId])
                        setToggled({
                          ...toggled,
                          [section.routerId]: !toggled[section.routerId]
                        })
                      }}
                      to={'/' + section.routerId + '/' + subMenu.routerId}
                      icon={subMenu.icon}
                    >
                      {subMenu.title}
                    </BkMenuItem>
                  ))}
              </BkMenu>
            </li>
          ))}
      </ul>
    </nav>
  )
}

export default MainNavigation
