import { createSlice } from '@reduxjs/toolkit'

const chartsSelectAll = state => state.charts
const chartsSelectById = (state, id) => chartsSelectAll(state)?.find(chart => chart.id === id)

const setCharts = (state, newCharts) => ({ ...state, charts: newCharts })

const initialState = {
  charts: [],
  exportingFilename: ''
}

const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    add: (state, action) => setCharts(state, [...chartsSelectAll(state), action.payload]),
    update: (state, action) => {
      const { id } = action.payload
      const charts = chartsSelectAll(state).map(c => c)
      const chart = charts.find(chart => chart.id === id)
      if (chart) {
        charts[charts.indexOf(chart)] = action.payload
      } else {
        charts.push(action.payload)
      }
      return setCharts(state, charts)
    },
    remove: (state, action) => {
      const chart = chartsSelectById(action.payload.id)
      if (chart) {
        chartsSelectAll(state).splice(chartsSelectAll(state).indexOf(chart), 1)
      }
      return setCharts(state, [...chartsSelectAll(state)])
    },
    setExportingFilename: (state, action) => ({
      ...state,
      exportingFilename: action.payload,
    })
}})



export const { add, update, remove, setExportingFilename } = chartsSlice.actions
export const getExportingFilename = state => state.charts.exportingFilename
export default chartsSlice.reducer
