import React, { useRef, useEffect, useState } from 'react'
import './styles/filters.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BkSwitch from '../../components/forms/Switch'
import Filter from './Filter'
import { IndicatorFilter } from './IndicatorFilter'
import useLegacy from '../../i18n/legacyEffect'

const FiltersCategoryBlock = ({    filtersCategory, onChange}) =>
{
    const { tLegacy } = useLegacy()
    const [exclude,setExclude] = useState(false)
    const switchLabels = {
        checkedValue : 'Exclude',
        uncheckedValue : 'Include'
      }
    function onToggleExcludeFilter() {
        setExclude(!exclude)
        
        
      }
    function renderFiltersCategoryBlock() {
        switch (filtersCategory.category_name) {
          case 'indicators_filters':
            return <IndicatorFilter options={filtersCategory.filters} onChange={onChange} />
          default:
            return <div>
                 <BkSwitch
                    stateLabel = {switchLabels}
                    option = {switchLabels}
                    onChange={onToggleExcludeFilter}
                />
                {

                    filtersCategory.filters.map((f, j) => {
                      const name = tLegacy('filters', f.title)
                      return (
                        <Filter
                          key={j}
                          title={name}
                          legacy={f.title}
                          type={f.type}
                          id={f.id}
                          columnName={f.columnName}
                          onChange={onChange}
                          exclude={exclude}
                          category={filtersCategory.category_name}
                        />
                      )
                    })
                }
                </div>
        }
      }
    return (
        
          renderFiltersCategoryBlock()
    )
}

export default FiltersCategoryBlock

