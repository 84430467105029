import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BkButton from '../../components/Button'
import BkSelect from '../../components/forms/Select'
import BkTextField from '../../components/forms/TextField'
import Modal from '../../components/Modal'
import useLegacy from '../../i18n/legacyEffect'
import { getConfig } from '../../services/dashboards.service'
import { getMetrics } from '../../services/requestor.service'
import { selectFiltersConfig, getDimensionsFromFilters } from '../filters/store/FiltersSlice'
import { addContentBlock, updateBlock } from './store/customDashboardSlice'
import BkAlert from '../../components/Alert'
import { WIDGETS_TYPES } from '../../constants'

const WidgetConfigModal = ({ onClose, menuId, params, size }) => {
  const dispatch = useDispatch()
  const { tLegacy } = useLegacy()
  const initialStatus = {
    errors: [],
    processing: false
  }
  const [status, setStatus] = useState(initialStatus)
  const [metrics, setMetrics] = useState(null)
  const [displayedDimensions, setDisplayedDimensions] = useState([])
  const [legacyType, setLegacyType] = useState('metrics')
  // display dimension select => hide if false
  const [displayDimensionSelect, setDisplayDimensionSelect] = useState(true)
  const [displayFunctionSelect, setDisplayFunctionSelect] = useState(true)

  const filtersConfig = useSelector(selectFiltersConfig)

  const dimensions = getDimensionsFromFilters(filtersConfig)
  const [userTitle, setUserTitle] = useState("")

  const dimNone = [{
    id: "none",
    columnName: "none",
    title: "None",
    type: "dropdown"
  }]

  const widgetTypes = [
    {
      type: WIDGETS_TYPES.NONE,
      label: 'Select a widget'
    },
    {
      type: WIDGETS_TYPES.AREA,
      label: 'Timeline'
    },
    {
      type: WIDGETS_TYPES.RANKING,
      label: 'Ranking'
    },
    {
      type: WIDGETS_TYPES.COLUMNSCATEGORIES,
      label: 'Histogram'
    },
    {
      type: WIDGETS_TYPES.BARSCATEGORIES,
      label: 'Bar chart'
    },
    {
      type: WIDGETS_TYPES.MAPWORLD,
      label: 'Map'
    },
    {
      type: WIDGETS_TYPES.PIE,
      label: 'Pie'
    },
    {
      type: WIDGETS_TYPES.SUMMARY,
      label: 'Metric'
    },
    {
      type: WIDGETS_TYPES.CDF,
      label: 'CDF'
    }
  ]

  const widgetTypesForMetricFilter = [
    // Pie only allow metric_type=filter
    {
      type: WIDGETS_TYPES.NONE,
      label: 'Select a widget'
    },
    {
      type: WIDGETS_TYPES.AREA,
      label: 'Timeline'
    },
    {
      type: WIDGETS_TYPES.RANKING,
      label: 'Ranking'
    },
    {
      type: WIDGETS_TYPES.COLUMNSCATEGORIES,
      label: 'Histogram'
    },
    {
      type: WIDGETS_TYPES.BARSCATEGORIES,
      label: 'Bar chart'
    },
    {
      type: WIDGETS_TYPES.MAPWORLD,
      label: 'Map'
    },
    {
      type: WIDGETS_TYPES.SUMMARY,
      label: 'Metric'
    },
    {
      type: WIDGETS_TYPES.PIE,
      label: 'Pie'
    }
  ]

  const widgetTypesForMetricDimension = [
    // Pie only allow metric_type=filter
    {
      type: WIDGETS_TYPES.NONE,
      label: 'Select a widget'
    },
    {
      type: WIDGETS_TYPES.AREA,
      label: 'Timeline'
    },
    {
      type: WIDGETS_TYPES.RANKING,
      label: 'Ranking'
    },
    {
      type: WIDGETS_TYPES.COLUMNSCATEGORIES,
      label: 'Histogram'
    },
    //not supported
    // {
    //   type: WIDGETS_TYPES.STACKCOLUMNSCATEGORIES,
    //   label: 'Stacked Histogram'
    // },
    {
      type: WIDGETS_TYPES.BARSCATEGORIES,
      label: 'Bar chart'
    },
    // {
    //   type: WIDGETS_TYPES.STACKBARSCATEGORIES,
    //   label: 'Stacked Bar chart'
    // },
    {
      type: WIDGETS_TYPES.MAPWORLD,
      label: 'Map'
    },
    {
      type: WIDGETS_TYPES.SUMMARY,
      label: 'Metric'
    }
  ]

  const widgetTypesForMetricRate  = [
    // Pie only allow metric_type=filter
    {
      type: WIDGETS_TYPES.NONE,
      label: 'Select a widget'
    },
    {
      type: WIDGETS_TYPES.AREA,
      label: 'Timeline'
    },
    {
      type: WIDGETS_TYPES.RANKING,
      label: 'Ranking'
    },
    {
      type: WIDGETS_TYPES.COLUMNSCATEGORIES,
      label: 'Histogram'
    },
    {
      type: WIDGETS_TYPES.BARSCATEGORIES,
      label: 'Bar chart'
    },
    {
      type: WIDGETS_TYPES.MAPWORLD,
      label: 'Map'
    },
    {
      type: WIDGETS_TYPES.SUMMARY,
      label: 'Metric'
    },
    {
      type: WIDGETS_TYPES.CDF,
      label: 'CDF'
    }
  ]

  const widgetTypesConcurrent = [
    // {
    //   type: WIDGETS_TYPES.NONE,
    //   label: 'Select a widget'
    // },
    {
      type: WIDGETS_TYPES.AREA,
      label: 'Timeline'
    }
  ]

  const functionList = [{
      type: 'avg',
      label: 'Average'
    },
    {
      type: 'max',
      label: 'Max'
    },
    {
      type: 'min',
      label: 'Min'
    },
    {
      type: 'percent',
      label: 'Percentil 90'
    },
    {
      type: 'sum',
      label: 'Sum'
    }
  ]
  const functionListFilter = [{
      type: 'count',
      label: 'Count'
    }
  ]
  const functionListRate = [{
      type: 'avg',
      label: 'Average'
    },
    {
      type: 'max',
      label: 'Max'
    },
    {
      type: 'min',
      label: 'Min'
    },
    {
      type: 'percent',
      label: 'Percentil 90'
    }
  ]
  const functionListDimension = [{
      type: 'unique',
      label: 'Unique'
    }
  ]
  const functionNone = [{
      type: 'none',
      label: 'None'
    }
  ]
  const metricNone = [{
    filter: "",
    id: "none",
    measure: "none",
    metric_type: "",
    table_name: "",
  }]

  const [widgetParams, setWidgetParam] = useState(params || {
    title: '',
    name: '',
    metric: metricNone[0],
    dimension: dimNone[0],
    type: widgetTypes[0],
    function: functionNone[0],
    position: null
    })

    useEffect(() => {
      const fetchMetrics = async () => {
        try {
          const ids = {menuid: menuId}
          // 1090 = hybrid mode, we ask for metrics for ALL type of dashboard
          if (menuId === 1090) {
            ids.menuid = '1010,1020,1030,1050,1080' //TODO when cloud pvr will be available : adding 1040
            setLegacyType('metrics_hybrid')
          }

          let { data } = await getMetrics(ids)
          let list = data.metrics_list || data.menu_id
          const metric_list = [metricNone[0]]

          list.map((menu) => {
            menu.metrics.map((metric) => {
              metric.menuid = menu.menu_id
              metric_list.push(metric)
            })
          })

          setMetrics(metric_list)
          (params || params!==undefined) ? onSelectMetricsHandler(params.metric) : onSelectMetricsHandler(metricNone[0])
        } catch (error) {
          console.error('error getting metrics')
        }
      }
      fetchMetrics()
    }, [menuId])

  useEffect(() => {
    onSelectDimensionHandler(_.get(dimensions, [0]))
    // select dimension on list of Edit CustomDashboard if already choosen by User
    widgetParams.dimension.id !== 'none' ? onSelectDimensionHandler(widgetParams.dimension) : onSelectDimensionHandler(_.get(dimensions, [0]))

  }, [filtersConfig])

  const onChangeTitleHandler = async (title) => {
    setWidgetParam({ ...widgetParams, title })
    setUserTitle(title)
  }

  /**
   *
   * @param {Object} metric
   * @returns function according to metric type, if the metric is changed
   * we choose the first function acording to metric (same behavior as V5)
   */
  const selectDefaultFunction = (metric) => {
    if (metric && metric.id === "none") {
       return functionNone[0]
    } else {
      switch(metric && metric.metric_type) {
        case 'dimension':
          return functionListDimension[0]
        case 'filter':
          return functionListFilter[0]
        case 'rate':
         return functionListRate[0]
        default:
          return functionList[0]
      }
    }
  }
  /**
   *
   * @param {Object} metric
   * @returns function according to metric type, if the metric is changed
   * we choose the first widget_types acording to metric (same behavior as V5)
   */
  const selectDefaultWidgetTypes = (metric) => {
    if (metric && metric.id
        && (metric.id === "cdn_concurrent_sessions" || metric.id === "cdn_concurrent_bitrate"
        || metric.id === "pl_concurrent_sessions" || metric.id === "pl_concurrent_bitrate"
        || metric.id === "nano_concurrent_sessions" || metric.id === "nano_concurrent_bitrate")) {
          return widgetTypesConcurrent[0]
        }
    else if (metric && metric.id === "none") {
       return widgetTypes[0]
    } else {
      switch(metric && metric.metric_type) {
        case 'dimension':
          return widgetTypesForMetricDimension[0]
        case 'filter':
          return widgetTypesForMetricFilter[0]
        case 'rate':
        case 'numeric':
          return widgetTypesForMetricRate[0]
        default:
          return widgetTypes[0]
      }
    }
  }

  /**
   * Change dimension accordingly to the menuid and metric.
   * in case of hybrid menu, then display only metric associated to the menu defined in metric
   * in case of concurrent metrics, limit dimension
   *
   * @param {*} metric
   */
  const updateDimension = (metric) => {
    let dim = []
    if (menuId === 1090) {
      dim = dimNone
      for (let dimension of dimensions) {
        //remove 1000 to menu id to match filters views and menuid (in filter table submenuid = 20 in metric menuid = 1020)
        if (dimension.menuid === metric.menuid - 1000) {
          dim.push(dimension)
        }
      }
    } else {
      dim = dimensions
    }

    let dimFinal = []
    if (metric.id === "pl_concurrent_sessions" || metric.id === "pl_concurrent_bitrate") {
      dimFinal.push(dim.find(filter => filter.id === "none"))
      dimFinal.push(dim.find(filter => filter.id === "pl_device_type"))
      dimFinal.push(dim.find(filter => filter.id === "pl_service_type"))
      dimFinal.push(dim.find(filter => filter.id === "pl_os_family"))
      dimFinal.push(dim.find(filter => filter.id === "pl_player_name"))
      dimFinal.push(dim.find(filter => filter.id === "pl_provider_name"))
    } else if (metric.id === "cdn_concurrent_bitrate" || metric.id === "cdn_concurrent_sessions") {
      dimFinal.push(dim.find(filter => filter.id === "none"))
      dimFinal.push(dim.find(filter => filter.id === "br_delivery_pop"))
      dimFinal.push(dim.find(filter => filter.id === "br_video_server"))
      dimFinal.push(dim.find(filter => filter.id === "br_provider_name"))
      dimFinal.push(dim.find(filter => filter.id === "br_service_id"))
      dimFinal.push(dim.find(filter => filter.id === "br_service_type"))
    } else if (metric.id === "nano_concurrent_sessions" || metric.id === "nano_concurrent_bitrate") {
      dimFinal.push(dim.find(filter => filter.id === "none"))
      dimFinal.push(dim.find(filter => filter.id === "nano_device_type"))
      dimFinal.push(dim.find(filter => filter.id === "nano_protocol"))
      dimFinal.push(dim.find(filter => filter.id === "nano_service_id"))
      dimFinal.push(dim.find(filter => filter.id === "nano_provider_name"))
    } else (
      dimFinal = dim
    )
    setDisplayedDimensions(dimFinal)
  }

  const onSelectMetricsHandler = async (metric) => {
    // Change dimension accordingly to menu id of selected metric and metric
    updateDimension(metric)

    const defaultWidget = selectDefaultWidgetTypes(metric)
    const defaultFunction = selectDefaultFunction(metric)
    const defaultDimension = displayedDimensions[0]

    setWidgetParam({ ...widgetParams, metric, function: defaultFunction, type: defaultWidget, dimension:defaultDimension})
  }

  const onSelectDimensionHandler = async (dimension) => {
    setWidgetParam({ ...widgetParams, dimension })
  }

  const onSelectWidgetTypeHandler = async (widgetType) => {
    // Hide dimension Selector if Metric Widget is selected
    if (widgetType.type === WIDGETS_TYPES.SUMMARY) {
      setDisplayDimensionSelect(false)
    } else {
      setDisplayDimensionSelect(true)
    }
    //BkA100-1672 - AB Testing
    if (widgetType.type === WIDGETS_TYPES.CDF) {
      setDisplayFunctionSelect(false)
    } else {
      setDisplayFunctionSelect(true)
    }

    setWidgetParam({ ...widgetParams, type: widgetType })
  }

  const onSelectFunctionHandler = async (functionName) => {
    setWidgetParam({ ...widgetParams, function: functionName })
  }


  const getDefaultFilterFromMetric = (metric) => {
    let default_filters = "";
    if (metric === "cdn_concurrent_sessions" || metric === "cdn_concurrent_bitrate" ){
      default_filters = [{
        type : 'delivery_pop',
        value : ''
      }, {
        type : 'provider_name',
        value : ''
      }, {
        type : 'service_id',
        value : ''
      }, {
        type : 'service_type',
        value : ''
      }, {
        type : 'videoserver',
        value : ''
      }];
    } else if (metric === "nano_concurrent_sessions" || metric === "nano_concurrent_bitrate"){
      default_filters = [{
        type : 'service_id',
        value : ''
      }, {
        type : 'protocol',
        value : ''
      }, {
        type : 'device_type',
        value : ''
      }, {
        type: 'provider_name',
        value : ''
      }];
    } else if (metric === "pl_concurrent_sessions" || metric === "pl_concurrent_bitrate"){
      default_filters = [{
        type : 'service_type',
        value : ''
      }, {
        type : 'player_name',
        value : ''
      }, {
        type : 'os_family',
        value : ''
      }, {
        type : 'device_type',
        value : ''
      }, {
        type : 'provider_name',
        value : ''
      }];
    }
    return default_filters;
  }

  const getFilterFieldFromMetric = (metric) => {
    let filterFields = "";
    if (metric === "cdn_concurrent_sessions" || metric === "cdn_concurrent_bitrate" ){
      filterFields = "delivery_pop,videoserver,provider_name,service_id,service_type";
    } else if (metric === "nano_concurrent_sessions" || metric === "nano_concurrent_bitrate"){
      filterFields = "device_type,service_id,protocol,provider_name";
    } else if (metric === "pl_concurrent_sessions" || metric === "pl_concurrent_bitrate"){
      filterFields = "service_type,player_name,os_family,device_type,provider_name";
    }
    return filterFields;
  }

  const onCreateHandler = async () => {
    const wParams = _.cloneDeep(widgetParams)
    setStatus({ errors: [], processing: true })
    if (widgetParams && widgetParams.type && widgetParams.dimension.columnName === "none" &&
        (widgetParams.type.type === WIDGETS_TYPES.RANKING ||
        widgetParams.type.type === WIDGETS_TYPES.PIE ||
        widgetParams.type.type === WIDGETS_TYPES.BARSCATEGORIES)) {
      // when Ranking or Pie, obligation to chosse a dimension
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'Please select a dimension for this type of widget.' }]
      })
    }
    else if (widgetParams && widgetParams.type && widgetParams.dimension.columnName !== "none" &&
        widgetParams.type.type === WIDGETS_TYPES.GAUGE) {
      // when Ranking or Pie, obligation to chosse a dimension
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'Please select None dimension for this type of widget.' }]
      })
    }
    // else if (widgetParams && widgetParams.dimension && widgetParams.type.label !== "Ranking" &&
    //  (widgetParams.dimension.title !== 'None' && widgetParams.dimension.type !== 'dropdown' )) {
    //   limitOption = 20
    //    // Error when DimensionInput === Type Dropdown and WidgetType !== Ranking
    //   setStatus({
    //     processing: false,
    //     errors: [{ code: 0, message: 'WidgetType must be Ranking for this Kind of dimension' }]
    //     })
    //   }
      else if (widgetParams && widgetParams.type && widgetParams.type.type === WIDGETS_TYPES.NONE ) {
       // Error when any widget type is selected
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'Please select a Widget type' }]
        })
      }

    else if(widgetParams && widgetParams.metric && widgetParams.metric.id === "none") {
      // Error quand Sauvegarde en laissant Metric à None
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'Please select a Metric to save your widget' }]
      })
    }
    else if (widgetParams && widgetParams.type && widgetParams.type.type === WIDGETS_TYPES.MAPWORLD &&
      (widgetParams.dimension.title !== "country")) {
      // Error quand Map dimension !== country || None
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'For a map dimension should be country' }]
      })
    }
    else if (widgetParams && widgetParams.metric && widgetParams.type.type === WIDGETS_TYPES.MAPWORLD &&
      widgetParams.metric.measure.includes("concurrent")) {
      // Error quand concurrent_session/bitrate avec un type MAP
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'Cannot create Map for Concurrent Sessions and Concurrent Bitrate' }]
      })
    }
    else if (widgetParams && widgetParams.metric && widgetParams.dimension &&
      (widgetParams.metric.id === "pl_concurrent_sessions" || widgetParams.metric.id === "pl_concurrent_bitrate")
       && (widgetParams.dimension.columnName !== "device_type" && widgetParams.dimension.columnName !== "service_type"
       && widgetParams.dimension.columnName !== "os_family" && widgetParams.dimension.columnName !== "player_name"
       && widgetParams.dimension.columnName !== "none" && widgetParams.type.label !== "Ranking")){
      // Error quand Dimension != None, os_family, deviceType, serverType, playerName for Metric ConcurrentBitrate/Sessions
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'For player concurrent bitrate or sessions , dimension must be \'none\' or Device Type or Service Type or Device OS or Player' }]
      })
    }
    else if (widgetParams && widgetParams.metric && widgetParams.dimension &&
      (widgetParams.metric.id === "cdn_concurrent_bitrate" || widgetParams.metric.id === "cdn_concurrent_sessions")
       && (widgetParams.dimension.columnName !== "delivery_pop" && widgetParams.dimension.columnName !== "videoserver"
       && widgetParams.dimension.columnName !== "provider_name" && widgetParams.dimension.columnName !== "service_id"
       && widgetParams.dimension.columnName !== "service_type" && widgetParams.dimension.columnName !== "none"
       && widgetParams.type.label !== "Ranking")){
      // Error quand Dimension != None, os_family, deviceType, serverType, playerName for Metric ConcurrentBitrate/Sessions
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'For CDN concurrent bitrate or sessions , dimension must be \'none\' or Delivery Pop or Viveo Server or Provider Name or Service Id or Service Type' }]
      })
    }
    else if (widgetParams && widgetParams.metric && widgetParams.dimension &&
      (widgetParams.metric.id === "nano_concurrent_sessions" || widgetParams.metric.id === "nano_concurrent_bitrate")
       && (widgetParams.dimension.columnName !== "device_type" && widgetParams.dimension.columnName !== "protocol"
       && widgetParams.dimension.columnName !== "service_id" && widgetParams.dimension.columnName !== "none"
       && widgetParams.type.label !== "Ranking")){
      // Error quand Dimension != None, os_family, deviceType, serverType, playerName for Metric ConcurrentBitrate/Sessions
      setStatus({
        processing: false,
        errors: [{ code: 0, message: 'For nano concurrent bitrate or sessions , dimension must be \'none\' or Device Type or Protocol or Service Id' }]
      })
    }
    else {
      wParams.type.type = widgetParams.type.type

      if(widgetParams.type.type === WIDGETS_TYPES.COLUMNSCATEGORIES && widgetParams.dimension.title === 'None' ) {
        wParams.type.type = WIDGETS_TYPES.STACKCOLUMNSTIMESTAMPS
      }

      // Define a title and a separate name to split name and title for tooltip
      wParams.title = ''

      if (_.isEmpty(widgetParams.title)) {
        if (widgetParams.type.type !== WIDGETS_TYPES.CDF
          && widgetParams.metric.metric_type !== "filter"
          && widgetParams.metric.metric_type !== "dimension") {
            wParams.title += tLegacy('functions', widgetParams.function.type) + ' '
        }
        wParams.title += tLegacy('metrics', widgetParams.metric.id)
        if (widgetParams.type.type !== WIDGETS_TYPES.SUMMARY
          && widgetParams.dimension.columnName !== "none") {
          wParams.title += ' per ' + tLegacy('filters', widgetParams.dimension.title)
        }
      } else {



        let classicTitle = tLegacy('functions', widgetParams.function.type) + ' ' + tLegacy('metrics', widgetParams.metric.id) + ' Per ' + tLegacy('filters', widgetParams.dimension.title)
        let classicTitleWithoutDim = tLegacy('functions', widgetParams.function.type) + ' ' + tLegacy('metrics', widgetParams.metric.id)
        // Check if Title of CustomDashboard is UserTitle ex : "my dashboard" or classic Title ex : "average concurent session per ...."
        if (userTitle)
          widgetParams.title = userTitle
        else if (userTitle && tLegacy('metrics', wParams.function.type))
          widgetParams.title = userTitle
        else if (userTitle && tLegacy('metrics', wParams.dimension.title))
          widgetParams.title = userTitle
        else if (userTitle && tLegacy('metrics', wParams.metric.id))
          widgetParams.title = userTitle
        else {
          if (widgetParams.dimension.columnName !== "none")
          {
            widgetParams.title = classicTitle
          }
          else {
            widgetParams.title =  classicTitleWithoutDim
          }
        }
        wParams.title = widgetParams.title
      }

      wParams.name = tLegacy('metrics', widgetParams.metric.id)

      wParams.limit_to_one_filter_type = false
      if (widgetParams.metric.measure.includes("concurrent")) {
        wParams.limit_to_one_filter_type = true
        if (widgetParams.dimension.columnName !== "none") {
          wParams.metric.filter = widgetParams.dimension.columnName + "(!='')"
        }
      }

      wParams.default_filters=''
      wParams.filterFields = getFilterFieldFromMetric(widgetParams.metric.id)
      if (widgetParams.dimension.columnName === "none") {
        wParams.default_filters = getDefaultFilterFromMetric(widgetParams.metric.id)
      }

      let blockConfig = {
        title: wParams.title,
        name: wParams.name,
        type: wParams.type.type,
        dimension: widgetParams.dimension.columnName,
        measure: widgetParams.metric.measure,
        table: widgetParams.metric.table_name,
        unit: widgetParams.metric.id,
        filter: wParams.metric.filter,
        function: widgetParams.function.type,
        default_filters: wParams.default_filters,
        filterFields: wParams.filterFields,
        limit_to_one_filter_type: wParams.limit_to_one_filter_type
      }

      if (widgetParams.type.label !== "Ranking" &&
        (widgetParams.dimension.columnName !== 'none' && widgetParams.dimension.type !== 'dropdown' )) {
          blockConfig.limit = 20
      }

      const { data } = await getConfig(blockConfig)

      if (_.isUndefined(params)) {
        dispatch(addContentBlock({ ...data, widgetParams: wParams }))
      } else {
        data.size = size
        dispatch(updateBlock({ ...data, widgetParams: wParams, position: widgetParams.position }))
      }
      if (!_.isUndefined(onClose)) {
        onClose()
      }
      setWidgetParam(wParams)
    }
  }

  //update dimension list in function of metric id
  const functionListAll = () => {
    if (widgetParams && widgetParams.metric && widgetParams.metric.measure === "none") {
      return (
        <BkSelect title='Function' selected={widgetParams.function} options={functionNone} optionConfig={{ label: 'label' }} onChange={onSelectFunctionHandler} displayHelper helper='Function to apply on metric field' />
      )
    }
    else if (widgetParams && widgetParams.metric && widgetParams.metric.metric_type === "dimension") {
      return (
        <BkSelect title='Function' selected={widgetParams.function} options={functionListDimension} optionConfig={{ label: 'label' }} onChange={onSelectFunctionHandler} displayHelper helper='Function to apply on metric field' />
      )
    }
    else if(widgetParams && widgetParams.metric && widgetParams.metric.metric_type === "filter") {
      return (
          <BkSelect title='Function' selected={widgetParams.function} options={functionListFilter} optionConfig={{ label: 'label' }} onChange={onSelectFunctionHandler} displayHelper helper='Function to apply on metric field' />
      )
    }
    else if (widgetParams && widgetParams.metric && widgetParams.metric.metric_type === "rate") {
      return (
        <BkSelect title='Function' selected={widgetParams.function} options={functionListRate} optionConfig={{ label: 'label' }} onChange={onSelectFunctionHandler} displayHelper helper='Function to apply on metric field' />
      )
    }
    else {
      return (
        <BkSelect title='Function' selected={widgetParams.function} options={functionList} optionConfig={{ label: 'label' }} onChange={onSelectFunctionHandler} displayHelper helper='Function to apply on metric field' />
      )
    }
  }

  const widgetTypeListAll = () => {
    if(widgetParams && widgetParams.metric &&
        (widgetParams.metric.id === "cdn_concurrent_sessions" || widgetParams.metric.id === "cdn_concurrent_bitrate"
        || widgetParams.metric.id === "pl_concurrent_sessions" || widgetParams.metric.id === "pl_concurrent_bitrate"
        || widgetParams.metric.id === "nano_concurrent_sessions" || widgetParams.metric.id === "nano_concurrent_bitrate")) {
      return (
        <BkSelect title='Widget Type' selected={widgetParams.type} options={widgetTypesConcurrent} optionConfig={{ label: 'label' }} onChange={onSelectWidgetTypeHandler} displayHelper helper='The type of widget to create' />
     )
    }
    if(widgetParams && widgetParams.metric && widgetParams.metric.metric_type === "dimension") {
      return (
         <BkSelect title='Widget Type' selected={widgetParams.type} options={widgetTypesForMetricDimension} optionConfig={{ label: 'label' }} onChange={onSelectWidgetTypeHandler} displayHelper helper='The type of widget to create' />
      )
    }
    else if (widgetParams && widgetParams.metric && (widgetParams.metric.metric_type === "filter")) {
      return(
        <BkSelect title='Widget Type' selected={widgetParams.type} options={widgetTypesForMetricFilter} optionConfig={{ label: 'label' }} onChange={onSelectWidgetTypeHandler} displayHelper helper='The type of widget to create' />
      )
    }
    else if (widgetParams && widgetParams.metric && (widgetParams.metric.metric_type === "rate" || widgetParams.metric.metric_type === "numeric")) {
      return(
        <BkSelect title='Widget Type' selected={widgetParams.type} options={widgetTypesForMetricRate} optionConfig={{ label: 'label' }} onChange={onSelectWidgetTypeHandler} displayHelper helper='The type of widget to create' />
      )
    }
    else {
      return(
        <BkSelect title='Widget Type' selected={widgetParams.type} options={widgetTypes} optionConfig={{ label: 'label' }} onChange={onSelectWidgetTypeHandler} displayHelper helper='The type of widget to create' />
      )
    }
  }

  const displayErrors = (
    <BkAlert severity='error' className='input'>
      {status &&
      status.errors &&
      status.errors.length > 0 &&
      status?.errors?.map((err, i) => (
        <div key={i}>{'errors: ' + err.message}</div>
      ))}
    </BkAlert>
  )

  return (
    <Modal title={_.isUndefined(params) ? 'Add widget' : 'Edit widget'} onClose={onClose}>
      <div>      {status.errors.length > 0 ? displayErrors : null}
        <BkTextField
          label='Title'
          placeholder='My widget'
          helper='Name the widget. This will be displayed as block title.'
          value={widgetParams.title}
          onChange={e => onChangeTitleHandler(e.target.value)}
        />
        <BkSelect title='Metric' selected={widgetParams.metric} options={metrics} optionConfig={{ label: 'id' }} legacy={legacyType} onChange={onSelectMetricsHandler} displayHelper helper='The metric will be displayed in the widget' />
        {displayFunctionSelect &&  functionListAll()}
        {displayDimensionSelect && (<BkSelect title='Dimension' selected={widgetParams.dimension} options={displayedDimensions} optionConfig={{ label: 'title' }} legacy='filters' onChange={onSelectDimensionHandler} displayHelper helper='The dimension is used to create groups of data' />)}
        {/*{widgetParams && widgetParams.dimension && (widgetParams.dimension.type === "dropdown" || widgetParams.dimension.title === "None") ? (
        <BkSelect title='Widget Type' selected={widgetParams.type} options={widgetTypes} optionConfig={{ label: 'label' }} onChange={onSelectWidgetTypeHandler} displayHelper helper='The type of widget to create' />
        ) : (
          <BkSelect title='Widget Type' selected={widgetParams.type} options={widgetTypesRanking} optionConfig={{ label: 'label' }} onChange={onSelectWidgetTypeHandler} displayHelper helper='The type of widget to create' />
        )}*/}
        {widgetTypeListAll()}
        <div className='modal__footer'>
          <BkButton onClick={onCreateHandler}>{_.isUndefined(params) ? 'Save' : 'Update'}</BkButton>
          <BkButton
            className='bkButton--alternate'
            onClick={onClose}
          >
            Cancel
          </BkButton>
        </div>
      </div>
    </Modal>
    )
}

export default WidgetConfigModal
