import * as _ from 'lodash'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BkButton from '../../components/Button'
import BkTextField from '../../components/forms/TextField'
import { Redirect } from 'react-router-dom'
import Modal from '../../components/Modal'
import * as DashboardManager from '../../services/dashboards.service'
import useReloadMenu from '../dashboards/hooks/reloadMenu'
import { changePage, selectRootMenu, getRootPage } from '../dashboards/store/dashboardSlice'
import { saveTemplate } from './store/customDashboardSlice'

const CustomDashboardImport = () => {
  const dispatch = useDispatch()
  const [title, setTitle] = useState('')
  const [template, setTemplate] = useState(null)
  const [redirect, setRedirect] = useState(false)
  const rootMenu  = useSelector(selectRootMenu)
  const rootDashboard = getRootPage(rootMenu)
  let [files, setFiles] = useState(null);
  const reloadMenu = useReloadMenu()

   const readAndParseFileImported = e => {
    var fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
        setFiles(JSON.parse(e.target.result));
        setTitle(JSON.parse(e.target.result).headerTitle)
    };
  }

  const saveHandler = async () => {
    let _title = title

    if (_.isEmpty(_title) && !_.isEmpty(files)) {
      _title = files.title
    }
    
    if (!_.isEmpty(_title) && !_.isEmpty(files)) {
      // Add Reset id to Null to regenerate id, routerId, filename 
      files.id = null
      const dashboard = {
        ...files,
        title: _title,
        headerTitle: _title
      }

      const { data } = await DashboardManager.save(dashboard)
      reloadMenu()
      dispatch(saveTemplate(data))
      changePage(dispatch, ['dashboards', data.routerId])
      setTemplate(data)
      setRedirect(true)
    }
  }

  const cancelHandler = () => {
    changePage(dispatch, rootDashboard)
    dispatch(saveTemplate(null))
    setTemplate(null)
    setRedirect(true)
  }

  return (
    <>
      {redirect && !_.isNull(template) && !_.isNull(template.routerId) && <Redirect to={'/dashboards/' + template.routerId + '/edit'} />}
      {redirect && _.isNull(template) && <Redirect to='/' />}
      <Modal
        title='Import a new Dashboard'
        onClose={cancelHandler}
      >
        <BkButton className="bkButton-import">
          <label className="label-import">Import a Json file
          <input type="file" className='bkButton--input' onChange={readAndParseFileImported}/></label>
        </BkButton>
        <BkTextField
          label='Title'
          placeholder='My dashboard'
          helper='The title displayed in submenu of custom dashboard.'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          disabled={!_.isEmpty(title) && !_.isNull(template)}
        />  
        <div className='modal__footer'>
          <BkButton onClick={saveHandler}>Save</BkButton>
          <BkButton
            onClick={cancelHandler}
            className='bkButton--alternate'
          >
          Cancel
          </BkButton>
        </div>
      </Modal>
    </>
  )
}

export default CustomDashboardImport