
export const filterType = {
    AUTOCOMPLETE: 'autocomplete',
    DROPDOWN: 'dropdown',
    DROPDOWNFIX: 'dropdownFix',
    DROPDOWNFIXCONTAINS: 'dropdownFixContains',
    PATTERN: 'pattern'
  }

  export const SERIES_TYPE = {
    AREA: 'area',
    LINE: 'line',
    GAUGE: 'gauge',
    MAPWORLD: 'mapWorld',
    PIE: 'pie',
    POLARLINE: 'polarLine',
    POLARAREA: 'polarArea',
    STACKCOLUMNSCATEGORIES: 'stackColumnsCategories',
    COLUMNSCATEGORIES: 'columnsCategories',
    COLUMNSCATEGORIESHOURS: 'columnsCategoriesHours',
    STACKCOLUMNSTIMESTAMPS: 'stackColumnsTimestamps',
    STACKCOLUMNSTIMESTAMPSPERCENT: 'stackColumnsTimestampsPercent',
    BARSCATEGORIES: 'barsCategories',
    STACKBARSCATEGORIES: 'stackBarsCategories',
    STACKBARSTIMESTAMPS: 'stackBarsTimestamps',
    SESSIONTRACKER: 'sessionTracker',
    CDF: 'cdf',
    SOLIDLINE: 'solidLine',
    DASHLINE: 'dashLine',
    TRENDINGLINE: 'trendingLine'
  }

  export const WIDGETS_TYPES = {
    ...SERIES_TYPE,
    RANKING: 'ranking',
    REALTIME: 'Realtime',
    SESSIONDESCRIPTION: 'sessionDescription',
    SUMMARY: 'summary',
    LIST: 'list',
    NONE: 'none'
  }