import { Grid } from '@material-ui/core'
import { Fragment } from 'react'

import React from 'react'
import useLegacy from '../../i18n/legacyEffect'
import RankingGauge from './RankingGauge'
import ReactTooltip from 'react-tooltip';

const RankingItem = ({ position, ranking, dimension, config }) => {
  const { tLegacy } = useLegacy()

  function formatDurationDHMS(value) {
    var seconds = Math.round(value);
    var minutes = 0;
    var hours = 0;
    var days = 0;


    if (value >= 60) {
       seconds = Math.round(value % 60);
       minutes = Math.floor(value / 60);
    }
    if (minutes >= 60) {
       hours = Math.floor(minutes / 60);
       minutes = minutes % 60;
    }
    if (hours >= 24) {
       days = Math.floor(hours / 24);
       hours = hours % 24;
    }

    var ret = '';
    if (days > 0)  ret += days + 'd ';
    if (hours > 0) ret += hours + 'h ';
    if (minutes > 0) ret += minutes + 'mn ';
    if (seconds > 0) ret += seconds + 's';

    if (ret === '')
       ret = '0'
    return ret;
  }

  function formatNumber(value) {
    return Intl.NumberFormat('fr',{maximumFractionDigits:2,}).format(value).replace(/,/g, '.')
  }


 function valueOfRanking() {

    if (config.showPercentColumn === false )
    {
      return (
        <Fragment>
          {/* Ranking Without percentColumn Grid=12 -> 2 columns*/}
          <Grid item xs={9} className='rankingItem__title' data-tip={tLegacy(dimension, ranking[config.dimension])} data-border="true">
            {position + 1}.&nbsp;
            {tLegacy(dimension, ranking[config.dimension])}
            <ReactTooltip />
          </Grid>
          <Grid item xs={3} className='rankingItem__legend'>
          {/*Si ranking.percent is not integer Affiche NAN that's why next step verification*/}
          {Number.isInteger(ranking[config.resultName]) === true && config.resultName === "Duration" ? (
              formatDurationDHMS(ranking[config.resultName])
            ) : config.resultName && ranking[config.resultName] !== "" ? (
              formatNumber(ranking[config.resultName])
            ) : (
              formatNumber(ranking.value)
            )
          }
          </Grid>
        </Fragment>
      )
    }
    else {
      return (
        <Fragment>
        {/* Ranking With percentColumn Grid=12 -> 3 columns*/}
        <Grid item xs={6} className='rankingItem__title' data-tip={tLegacy(dimension, ranking[config.dimension])} data-border="true">
          {position + 1}.&nbsp;
          {tLegacy(dimension, ranking[config.dimension])}
          <RankingGauge value={ranking.percent} />
          <ReactTooltip />
        </Grid>
        <Grid item xs={3} className='rankingItem__legend'>
        {/*TotalSessionRanking with Column Percent with separate thousand*/}
        {config.resultName ? (
          formatNumber(ranking[config.resultName])
        ) : (
          formatNumber(ranking.value)
        )}
        </Grid>
        <Grid item xs={3} className='rankingItem__legend'>
          {formatNumber(ranking.percent)}%
        </Grid>
      </Fragment>
      )
    }
}

  return (
    <Grid container alignItems='center' className='rankingItem'>
      {valueOfRanking()}
    </Grid>
  )
}

export default RankingItem
