import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as PagesServices from '../../../services/pages.service'
import {
  initMenu,
  selectCurrentDashboard,
  selectMenu,
  extractBoardType,
  extractMenuType
} from '../store/dashboardSlice'
import { selectBurgerMenu, updateBurgerMenu } from '../../../components/store/burgerSlice'

const useInitMenu = isLogged => {
  const dispatch = useDispatch()
  const menu = useSelector(selectMenu)
  const burgerMenu = useSelector(selectBurgerMenu)
  const currentDashboard = useSelector(selectCurrentDashboard)
  const URLpathname = window.location.pathname

  useEffect(() => {
   // set value of URL on localstorage when ctrl+c ctrl+v LINK for someone Else
   window.localStorage.setItem('pathname', URLpathname);
 }, [URLpathname]);

  useEffect(() => {
    // get value of URL on localstorage
    const pathName = window.localStorage.getItem('pathname', URLpathname).split('/')

    if (burgerMenu && burgerMenu.length === 0 && window.localStorage.getItem('burgerMenu') !== undefined) {
    dispatch(updateBurgerMenu(JSON.parse(window.localStorage.getItem('burgerMenu'))))
    }

    // BKA100-2831 : reset currentDashboard to call getPages() when switching user
    let currentDashboard = null

    const getPages = async () => {
      PagesServices.getPages().then(response => {
        currentDashboard = []

        // If Url has been ctrl+c and ctrl+v, keep URL path after login 
        // to not go to 10-cdn-selector by default
        pathName[2] && pathName[2] !== 'admin' && pathName[3] ?  
          currentDashboard = [
            pathName[2], // URL with ex : 50-players
            pathName[3]  // URL with ex : 51-dashboard
          ]
        : currentDashboard = [
            response.data[0].routerId,
            response.data[0].children[0].routerId
          ]
        const currentSubMenuType = extractBoardType(currentDashboard)
        const currentMenuType = extractMenuType(currentDashboard)
        initMenu(dispatch, { currentDashboard, menu: response.data, currentSubMenuType, currentMenuType })
      })
    }
    if (isLogged && !currentDashboard) getPages()
    else initMenu(dispatch, { currentDashboard, menu, currentSubMenuType: extractBoardType(currentDashboard), currentMenuType: extractMenuType(currentDashboard) })
  }, [isLogged])

  return [menu, currentDashboard]
}

export default useInitMenu
