import { Grid } from '@material-ui/core'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import BkAlert from '../../components/Alert'
import useLegacy from '../../i18n/legacyEffect'

const PERCENTILES = ['p1', 'p10', 'p25', 'p50', 'p75', 'p90', 'p99']
const SPECIAL_STATS = ['extract', 'outliers_extract', 'str_length']

const Array = ({ columns, data }) => {
  return <div className='tableContainer'>
    <table className='table table--compact'>
      <thead>
        <tr className='table__head'>
          {columns.map((c, i) => <td key={i}>{c}</td>)}
        </tr>
      </thead>

      <tbody>
        {data.map((d, i) => (
          <tr key={i} className='table__row'>
            {columns.map((_, j) => (
              <td key={`${i}_${j}`}>{
                d[j] instanceof Date ? d[j].toISOString() : d[j]
              }</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
}

const Stats = ({ stats, name }) => {
  const { tLegacy } = useLegacy()

  const entries = Object.entries(stats).filter(([k, v]) => {
    return !([PERCENTILES, SPECIAL_STATS].flat().includes(k))
  }).map(entry => (
    [tLegacy('statistic', entry[0]), entry[1]]
  ))

  return (
    <Array columns={[name, '']} data={entries} />
  )
}

const Extract = ({ extract }) => {
  const entries = extract?.index.map((e, i) => [e, extract.count[i]])
  return entries ? (
    <Array columns={['Popular values', 'Count']} data={entries} />
  ) : null
}

const Outliers = ({ n_outliers, n, extract }) => {
  const frac = (n_outliers / n).toFixed(2)

  if (extract.example) {
    const entries = extract.index.map((e, i) => [e, extract.count[i], extract.example[i]])
    return (
      <Array columns={[`Outliers (${frac}%)`, 'Count', 'Example']} data={entries} />
    )
  } else {
    const entries = extract.index.map((e, i) => [e, extract.count[i]])
    return (
      <Array columns={[`Outliers (${frac}%)`, 'Count']} data={entries} />
    )
  }
}

const ECDF = ({ stats, name }) => {
  const datetime = stats.inferred_dtype === 'DateTime'
  const x_raw = [stats.min, PERCENTILES.map(p => stats[p]), stats.max].flat()
  const x = datetime ? x_raw.map(e => e.getTime()) : x_raw
  const y = [0, PERCENTILES.map(p => p.slice(1) / 100), 1].flat()
  const data = x.map((e, i) => [e, y[i]])

  const options = {
    exporting: {
      enabled: false,
    },
    chart: {
      zooming: {
        type: 'xy',
      },
    },
    xAxis: {
      type: 'linear',
    },
    yAxis: {
      min: 0,
      max: 1,
      text: { text: 'percentile' },
    },
    series: [{
      name: `${name} Quantiles (CDF)`,
      data: data,
      step: true,
    }],
  }

  if (datetime) {
    options.xAxis.type = 'datetime'
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export const Details = ({ alerts, details }) => {
  if (details.inferred_dtype === 'DateTime') {
    // We need to deep copy details because we modify date values in place
    const pDetails = { ...details }
    const factor_map = {
      's': 1000,
      'ms': 1,
      'us': 0.001,
    }

    for (const k of ['min', 'max', 'mean', PERCENTILES].flat()) {
      pDetails[k] = new Date(details[k] * factor_map[details.timestamp_unit])
    }

    if (pDetails.outliers_extract !== undefined) {
      pDetails.outliers_extract = { ...details.outliers_extract }
      pDetails.outliers_extract.index = details.outliers_extract.index.map(e =>
        new Date(e * factor_map[details.timestamp_unit]))
    }

    details = pDetails
  }

  const alertsComponent = alerts && (alerts.length > 0) && (
    <BkAlert firstAlert severity='error'>
      Alerts:
      <ul>
        {alerts.map((alert, i) => (
          <li key={i}>{alert.description}</li>
        ))}
      </ul>
    </BkAlert>
  )

  return (
    <>
      <Grid container direction='row' justifyContent='flex-start' alignItems='stretch' spacing={3}>
        <Grid item xs={4}>
          <hr />
          <Stats stats={details} name='Stats' />
        </Grid>
        <Grid item xs={8}>
          {details.p50 !== undefined ?
            <ECDF stats={details} name='Value' /> :
            <Extract extract={details.extract} />}
          {details.n_outliers !== undefined && details.n_outliers > 0 &&
            <Outliers n={details.n} n_outliers={details.n_outliers} extract={details.outliers_extract} />
          }
        </Grid>
        {details.str_length !== undefined &&
          <>
            <Grid item xs={4}>
              <hr />
              <Stats stats={details.str_length} name='String length stats' />
            </Grid>
            <Grid item xs={8}>
              <ECDF stats={details.str_length} name='String length' />
              {details.str_length.n_outliers !== undefined && details.str_length.n_outliers > 0 &&
                <Outliers n={details.n} n_outliers={details.str_length.n_outliers} extract={details.str_length.outliers_extract} />
              }
            </Grid>
          </>
        }
      </Grid>

      {alertsComponent}
    </>
  )
}

export default Details
