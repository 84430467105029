import * as _ from 'lodash'
import * as moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Paginator from '../../components/Paginator'
import BkButton from '../../components/Button'
import BkIcon from '../../components/icon'
import Modal from '../../components/Modal'
import useLegacy from '../../i18n/legacyEffect'
import { stringDateTime } from '../../services/period.service'
import useTableList from './hooks/tableListEffect'
import {   getFilterForRequest,
  selectPeriodZoom,
  selectPeriod,
  selectAppliedFilters,
  selectTimeZone,
  resetPeriodZoom
 } from '../filters/store/FiltersSlice'
import { generateExport, selectedExport, getExport, deleteExport } from '../../services/export.service'
import { updateSelectedLine }  from './store/TableSlice'
import { selectCurrentDashboard } from '../dashboards/store/dashboardSlice'
import BkAlert from '../../components/Alert'
import { CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton'
import { BKAPI_URL } from '../../environment'

import './styles/table.scss'
import '../../components/styles/icon.scss'

const TableList = ({ id, config, displayHeader, actionsCallback }) => {
  const defaultOrderOn = orderOnFromString(config.orderOn)
  const defaultOrderOnState = {
    field: defaultOrderOn?.[0]?.[0],
    direction: defaultOrderOn?.[0]?.[1],
    default: defaultOrderOn,
    isDefault: true,
  }
  const [orderOn, setOrderOn] = useState(defaultOrderOnState)
  const [activeOrderOn, setActiveOrderOn] = useState(defaultOrderOn)
  const [offset, setOffset] = useState(0)
  const { tLegacy } = useLegacy()
  const [displayTableListCSVModal, setDisplayTableListCSVModal] = useState(false)
  const currentPage = useSelector(selectCurrentDashboard)

  const data = useTableList({
    config,
    offset,
    orderOn: activeOrderOn,
  })
  const list = _.get(data, 'items')
  const total = _.get(data, 'distinct')
  const limit = _.get(config, 'exportMaxNbLines', 100000)
  const rowIdKey = _.get(config, 'rowIdKey', 'session_id')
  const rowsClass = (() => {
    if (list) {
      return list.map(()=> 'table__row')
    }
  })()

  const [classes, setClasses] = useState(rowsClass)
  const dispatch = useDispatch()

  const [exports, setExports] = useState([])
  const [exportGenerating, setExportGenerating] = useState(true)
  const [exportClicked, setExportClicked] = useState(false)
  const exporting = exportGenerating || exportClicked

  const period = useSelector(selectPeriod)
  const periodZoom = useSelector(selectPeriodZoom)
  const appliedFilters = useSelector(selectAppliedFilters)
  const timezone = useSelector(selectTimeZone)
  const filtersForRequest = getFilterForRequest(periodZoom, period, appliedFilters, timezone)
  const currentdashboard = useSelector(selectCurrentDashboard)
  const menu = currentdashboard ? currentdashboard[0] : currentdashboard

  const [rowId, setRowId] = useState(0)
  const [dateName, setDateName] = useState(0)
  const [isSelected, setIsSelected] = useState(false)

  const [activeIndex, setActiveIndex] = useState(undefined)

  const nameSelectedRow = dateName
  const nameAllRows = 'All'

  const msgLimit = `The maximum number of rows to be exported in the csv is set to ${limit} only`
  const msgSelected = `To export "Selected Row", a line must be selected in the table`

  /**
  * Send request to backend to generate a new export csv with the selected line
  */

  const generateSelectedExportCSV = async (rowId, nameSelectedRow) => {
    setExportClicked(true)
    try {
      const res = await selectedExport(filtersForRequest, menu, rowId, nameSelectedRow)
      setExportGenerating(res.data.generating = true)
    } catch (error) {
      setExportClicked(false)
      console.error(error)
    }
  }

 /**
 * Send request to backend to generqte a new export csv
 */
  const generateExportCSV = async (nameAllRows) => {
    setExportClicked(true)
    try {
      const res = await generateExport(filtersForRequest, limit, menu, nameAllRows)
      setExportGenerating(res.data.generating = true)
    } catch (error) {
      setExportClicked(false)
      console.error(error)
    }
  }

/**
 * Deletes the export `exportName` on the backend's filesystem
 * @param {String} exportName
 */
  const deleteExportCSV = async (exportName, userId, filtervalue) => {
    const res = await deleteExport(exportName, menu, userId, filtervalue)
    setExports(res.data.data)
  }

  const resetZoom = () => {
    dispatch(resetPeriodZoom())
  }
  const exportTable = (
    <>
      <table className='table'>
        <thead>
          <tr className='table__head'>
            <th>Available Export CSV</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {menu && exports.map((exp, index) => (
          <tr key={index} className='table__row'>
            <td>{exp.name}</td>
            <td className='buttonTd'>
              <div>
                <a href={`${BKAPI_URL}/exports/${menu}/${exp.filterValue}/${exp.userId}/${exp.name}`}>
                  <IconButton aria-label='save'>
                  <BkIcon path='save' className='icon'></BkIcon>
                  </IconButton>
                </a>
                <IconButton aria-label='delete' onClick={()=> deleteExportCSV(exp.name, exp.userId, exp.filterValue, menu)}>
                  <BkIcon path='trash' className='icon'></BkIcon>
                </IconButton>
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </>
  )

  useEffect(() => {
    // Clear table when unmounting component
    return () => dispatch(updateSelectedLine({ id, line: null }))
  }, [id, dispatch])

  useEffect(() => {
    /**
    * Get the lists of the exports
    * If the generating field is true, this method will be called
    * every the second, and once the package is generated the setInterval is cleared
    */

    const getExports = async () => {
      try {
        const res = await getExport(menu)
        setExports(res.data.data)
        setExportGenerating(res.data.generating === 'true')
      } catch (error) {
        setExportGenerating(false)
      }
    }

    if (!exportGenerating) {
      setExportClicked(false)
      return
    }

    getExports()
    const interval = setInterval(getExports, 2e3)
    return () => clearInterval(interval)
  }, [exportGenerating])

  useEffect(() => {
    // When filter is applied, Re-initialize table to first page
    if (appliedFilters.length > 0)
    {
      setOffset(0)
    }
  }, [appliedFilters])

  useEffect(() => {
    // if page changed when line is selected => reformat table
    setClasses(rowsClass)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])


  
  useEffect(() => {
    /**
    * useEffect use to manage keyboards actions (keyup, keydown) for sessionTracker
    * activeIndex use for catch event keyboard and to redefine index
    * index is redefined in hooks to setState the new state of index
    */

    function handleKey(event) {
      if (event.keyCode === 40  && activeIndex < 9 ) {
        // keyboard down, 40 is keyboard arrows ▼
        rowsClass[activeIndex] = 'table__row'
        rowsClass[activeIndex + 1] = 'table__row line-selected'
        setActiveIndex(activeIndex + 1)

        const  item =  list[activeIndex + 1]
        setRowId(item[rowIdKey])
        setClasses(rowsClass)
        // redefine sessionDescription
        dispatch(updateSelectedLine({ id, line: item[rowIdKey] }))
      }
      else if (event.keyCode === 38 && activeIndex > 0) {
        // keyboard up, 38 is keyboard arrows ▲
        rowsClass[activeIndex] = 'table__row'
        rowsClass[activeIndex - 1] = 'table__row line-selected'
        setActiveIndex(activeIndex - 1)

        const  item =  list[activeIndex - 1]
        setRowId(item[rowIdKey])
        setClasses(rowsClass)
        // redefine sessionDescription
        dispatch(updateSelectedLine({ id, line: item[rowIdKey] }))
      }
    }

    // add event keydown to remove this event after
    // keydown is an event for a keyboard action
    document.addEventListener("keydown", handleKey);
    // remove event keydown when new click on line or change view. like destructor in cpp
    return () => document.removeEventListener("keydown", handleKey);
  }, [activeIndex])

  const changePage = offset => {
    setOffset(offset)
  }

  const transformField = (value, fieldConfig) => {
    if (fieldConfig.threshold) {
      return value > fieldConfig.threshold ? (
        <span className='highlight--success'>{_.round(value, 2)}</span>
      ) : (
        <span className='highlight--alert'>{_.round(value, 2)}</span>
      )
    }

    if (fieldConfig.unit && fieldConfig.unit === 'timestamp') {
      const date = new Date(value * 1000)
      return stringDateTime(date)
    }
    return tLegacy(fieldConfig.dimension, value)
  }

  const paginationValue = () => {
    const nbElementOnPage = offset + config.nbItemsPerPage
    if (total === 0) {
      return  '0 / 0'
    }
    else if (nbElementOnPage > total ) {
      return offset + 1 + ' - ' + total + ' / ' + total
    }
    else {
      return offset + 1 + ' - ' + nbElementOnPage + ' / ' + total
    }
  }


  const managePaginationIndex = () => {
    const exportCSVEnabled = config.exportCSV ||
      ((config.exportCSV === undefined) && (currentPage?.[0] === '50-players' || currentPage?.[0] === '20-broadpeak-cdn'))

    if (exportCSVEnabled)
    {
      return (
        <div className='widget__actions'>
          <div className='tableHeader'>
            <div className='tableBouton'>
              <BkButton
                className='bkButton--invisible'
                onClick={() => setDisplayTableListCSVModal(true)}
                >
                <BkIcon path='download' modifier='unactive' />
              </BkButton>
            </div>
            {paginationValue()}
          </div>
        </div>
      )
    }
    else {
      return <div className='widget__actions'> {paginationValue()} </div>
    }
  }


  const clickOnLine = (item, index) => {
    if (item.date_epoch)
    {
      // Get dateEpoch and add specific format for selectedLine NameFile
      let date = new Date(item.date_epoch * 1000)
      let newdate = moment(date).utc().format('YYYYMMDD_HHmmss')
      setDateName(newdate)
    }

    // assign value index to our new value ActiveIndex to manage index with hooks for keyboardActions
    setActiveIndex(index)
    dispatch(updateSelectedLine({ id, line: item[rowIdKey] }))
    rowsClass[index] = rowsClass[index] + ' line-selected'
    setClasses(rowsClass)
    setRowId(item[rowIdKey])
    setIsSelected(true)
  }

  const clickOnHeader = (field) => {
    /**
     * State machine for orderOn object:
     *
     *       │Initial state                      Click on another field g
     *       │                                   ┌──┐
     *      ┌▼──────────────┐                    │  │
     * ┌───►│isDefault: true│Click on field g  ┌─┴──▼───────────┐
     * │    │field: f       ├─────────────────►│isDefault: false│◄┐
     * │  ┌►│direction: d   │                  │field: g        │ │
     * │  │ └┬──────────────┘                ┌►│direction: 1    │ │
     * │  │  │Click on field f               │ └┬───────────────┘ │
     * │  │  │                               │  │Click on same g  │
     * │  │  │                               │  │                 │Click on
     * │  │ ┌▼──────────────┐                │ ┌▼───────────────┐ │another
     * │  │ │isDefault: true│Click on field g│ │isDefault: false├─┘field g
     * │  │ │field: f       ├────────────────┘ │field: g        │
     * │  │ │direction: !d  │                  │direction: -1   │
     * │  │ └┬──────────────┘                  └┬───────────────┘
     * │  │  │Click on field f                  │Click on same g
     * │  └──┘                                  │
     * └────────────────────────────────────────┘
     */
    if (field !== orderOn.field) {
      setOrderOn({ ...orderOn, field, direction: 1, isDefault: false })
      setActiveOrderOn([[field, 1], ...(defaultOrderOn || [])])
    } else if (orderOn.direction === 1 || orderOn.isDefault) {
      const direction = orderOn.direction === 1 ? -1 : 1
      setOrderOn({ ...orderOn, direction })
      setActiveOrderOn([[field, direction], ...(defaultOrderOn || [])])
    } else {
      setOrderOn(defaultOrderOnState)
      setActiveOrderOn(defaultOrderOn)
    }
  }

  const displayErrors = () => {
    if (isSelected !== true)
    {
      if (total > limit)
      {
        return (
          <>
            <BkAlert severity='info' firstAlert='yes'>
              {msgSelected}
            </BkAlert>
            <BkAlert severity='info' className='input'>
              {msgLimit}
            </BkAlert>
          </>
        )
      }
      else {
        return (
          <BkAlert severity='info' firstAlert='input'>
            {msgSelected}
          </BkAlert>
          )
      }
    }
    else {
      if (total > limit)
      {
        return (
          <BkAlert severity='info' className='input'>
            {msgLimit}
          </BkAlert>
          )
      }
    }
  }


  return (
    <>
      {config && list && (
        <>
          {displayHeader && (
            <header className='widget__header'>
              {_.get(config, 'title') === 'History' ? (
                <h2 className='widget__title_ano'>
                  <img
                      src={require('../../assets/icons/anonym.svg').default}
                      alt='Bka100'
                      width='30'
                      height='15'
                />
                History
                </h2>
                    )  : (
                  <h2 className='widget__title'>
                      {_.get(config, 'title') || _.get(config, 'type')}
                  </h2>
                )
              }
              {
                managePaginationIndex()
              }
            </header>
          )}
          {displayTableListCSVModal &&
            <Modal title='Download CSV document' onClose={() => setDisplayTableListCSVModal(false)}>
            <div> {displayErrors()}
              <div className='modal__popUpfooter'>
                <CircularProgress size='20px' className='circularProgress' style={{visibility: exporting ? '' : 'hidden'}}/>
                <BkButton
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={exporting || !isSelected}
                  onClick={() => { console.log('clicked!'); generateSelectedExportCSV(rowId, nameSelectedRow)}}>
                  Selected row
                </BkButton>
                <BkButton
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={exporting}
                  onClick={() => generateExportCSV(nameAllRows)}>
                  All rows
                </BkButton>
                <BkButton
                  onClick={() => setDisplayTableListCSVModal(false)}
                  className='bkButton--alternate'>
                  Cancel
                </BkButton>
              </div>
              <div className='modal__popUpfooter'>
                {exportTable}
              </div>
            </div>
          </Modal>
          }
          <div className='widget__content'>
            <div className='tableContainer'>
              {//replacing the periodZoom boolean with a condition on period date strings
              !(periodZoom.enddate==='false'&&periodZoom.startdate==='false')
              &&
              <BkButton className='bkButton--mini' onClick={resetZoom}>Reset Zoom</BkButton>
            }
              <table className={config.compact ? 'table table--compact' : 'table'}>
                <thead>
                  <tr className='table__head'>
                    {config.fields.map((field, i) => {
                      return !field.hidden && (
                        <th key={i} onClick={() => clickOnHeader(field.dimension)}>
                          <>
                            <span title='Click to sort' className='sortable'>{field.title}</span>
                            {orderOn.field === field.dimension && orderOn.direction === 1 && '▲'}
                            {orderOn.field === field.dimension && orderOn.direction === -1 && '▼'}
                          </>
                        </th>
                      )
                    })}
                    {actionsCallback && <th key='a' className='small'>{config.compact ? '' : 'Actions'}</th>}
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, i) => (
                    <tr key={i} className={classes ? classes[i] : 'table__row'} onClick={() => clickOnLine(item, i)}>
                      {config.fields.map((field, k) => {
                        return (
                          !field.hidden && (
                            <td key={`${i}_${k}`}>
                              {transformField(item[field.dimension], field)}
                            </td>
                          )
                        )
                      })}
                      {actionsCallback &&
                        <td key={`${i}_a`} className='buttonTd small'>{actionsCallback(item)}</td> }
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paginator
              limit={config.nbItemsPerPage}
              total={total}
              onChangePage={changePage}
            />
          </div>
        </>
      )}
    </>
  )
}

/**
 * Convert a string description of ordering to a list of ordering pairs.
 * @param {String} orderOn example "date desc, uuid"
 * @returns {Array[Array]} example [['date', -1], ['uuid', 1]]
 */
const orderOnFromString = (orderOn) => {
  if (!orderOn) {
    return null
  }

  return orderOn.split(',').map((pair) => {
    const [field, direction] = pair.trim().split(' ')
    if (direction?.toLowerCase().trim() === 'desc') {
      return [field, -1]
    } else {
      return [field, 1]
    }
  })
}

export default TableList
