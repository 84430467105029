import { Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import React, {  useState } from 'react';
import ReactTooltip from 'react-tooltip';
import '../styles/switch.scss';
import { useTranslation } from 'react-i18next'

const BkSwitch = ({
  name,
  title,
  onChange,
  option,
  stateLabel,
  disabled,
}) => {
  const [state, setState] = useState(false);
  const { t }= useTranslation();

  const handleChange = () => {
    onChange();
    setState(!state)
  };

  return (
    <div className="switch-container">

      <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item  data-tip={t('miscellaneous:exclude_switch')}>{stateLabel.uncheckedValue}</Grid>
        <Grid item>
          <Switch
            checked={state}
            onChange={handleChange}
            name={name}
            className="switch-input"
          />
        </Grid>
          <Grid item  data-tip={t('miscellaneous:exclude_switch')}>{stateLabel.checkedValue}</Grid>
        <ReactTooltip />
      </Grid>
    </div>
  );
};

export default BkSwitch;

BkSwitch.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  stateLabel: PropTypes.shape({
    checkedValue: PropTypes.string,
    uncheckedValue: PropTypes.string,
  }),
  option: PropTypes.shape({
    checkedValue: PropTypes.any,
    uncheckedValue: PropTypes.any,
  }).isRequired,
  onChange: PropTypes.func,
};
