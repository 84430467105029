import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import BkButton from '../../../components/Button'
import BkMultiSelect from '../../../components/forms/MultiSelect'
import BkSelect from '../../../components/forms/Select'
import BkSelectedValues from '../../../components/forms/SelectedValues'
import { getFilterList } from '../../../services/requestor.service'
import { selectFiltersConfig, selectPeriod, getPeriodTimeStamp } from '../../filters/store/FiltersSlice'
import * as _ from 'lodash'

export const Split = ({ series, onchange }) => {
  const splitInitialValue = {
    serieSelected: false,
    filterSelected: false,
    selectedValue: [],
    selected: false,
    list: []
  }

  const [split, setSplit] = useState(splitInitialValue)
  const filtersConfig = useSelector(selectFiltersConfig)
  const period = useSelector(selectPeriod)
  const duration = getPeriodTimeStamp(period)
  const { t } = useTranslation()

  useEffect(() => {
    if (!split.filterSelected) {
      setSplit(splitInitialValue)
    } else {
      console.log('use effect split.filterSelected', {
        filterid: split.filterSelected.value,
        startdate: duration.startdate,
        enddate: duration.enddate
      })
      getFilterList({
        filterid: split.filterSelected.value,
        startdate: duration.startdate,
        enddate: duration.enddate
      }).then(res => {
        if (Array.isArray(res.data) && res.data[0] != null) {
          if (split.list.length !== 0 && split.list !== res.data)
          {
            // If dimension change,  reset chart splitting
            setSplit({serieSelected: split.serieSelected,
                      filterSelected: split.filterSelected,
                      selectedValue: [],
                      selected: false,
                      list: res.data
            })
          }   
          else {
            setSplit({ ...split, list: _.compact(res.data) })
          }         
        }
      })
    }
  }, [split.filterSelected])

  const generateOptions = filterCategories => {
    const options = []
    if (Array.isArray(filterCategories)) {
      filterCategories.forEach((filterCategorie, i) => {
        if (Array.isArray(filterCategorie.filters)) {
          options.push(
            ...filterCategorie.filters
              .filter(filter => filter.type === 'dropdown' || filter.type === 'dropdownFix')
              .map((filter, j) => ({
                  value: filter.id,
                  title: filter.title,
                  column: filter.columnName,
                  label: t(`legacy:filters:${filter.title}`)
              }))
          )
        }
      })
    }
    return options
  }

  const splitChangeFilterSelected = filterSelected => setSplit({ ...split, filterSelected: filterSelected })
  const splitChangeSelectedValue = selectedValue => setSplit({ ...split, selectedValue: selectedValue })

  const splitChangeSerie = serieSelected => {
    if (serieSelected) {
      setSplit({ ...split, serieSelected})
    } else {
      setSplit(splitInitialValue)
    }
  }

  const reset = () => {
    setSplit(splitInitialValue)
    if (onchange) {
      onchange({})
    }
  }
  const valid = () => {
    if (onchange) {
      onchange(split)
    }
  }

  return (
    <div className='split'>
      <span className='split__description'>
        Define your chart splitting by selecting chart serie, dimension and
        filter values.
      </span>
      <BkSelect
        title='Chart'
        onChange={splitChangeSerie}
        selected={split.serieSelected}
        options={series}
        optionConfig={{ label: 'name', value: 'chart-split-series' }}
      />
      {split.serieSelected && (
        <BkSelect
          title='Split dimension'
          selected={split.filterSelected}
          onChange={splitChangeFilterSelected}
          options={generateOptions(filtersConfig.filters_categories)}
          optionConfig={{ label: 'label', value: 'value' }}
        />
      )}
      {split.filterSelected && split.list.length > 0 && (
        <BkMultiSelect
          title='Filter values'
          selected={split.selectedValue}
          onChange={splitChangeSelectedValue}
          options={split.list}
          legacy={split.filterSelected.column}
        />
      )}
      {split.selectedValue && split.selectedValue.length > 0 && (
        <BkSelectedValues
          selected={split.selectedValue}
          onRemove={splitChangeSelectedValue}
          legacy={split.filterSelected.column}
        />
      )}
      <div className='action-barre'>
        <BkButton onClick={valid}>Apply</BkButton>
        <BkButton
          className='bkButton--alternate'
          variant='contained'
          onClick={reset}
        >
          Reset
        </BkButton>
      </div>
    </div>
  )
}