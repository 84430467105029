import React, { useEffect, useState, useMemo } from 'react'
import { Chip, CircularProgress, Grid } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { Link, useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import useLegacy from '../../i18n/legacyEffect'
import BkIcon from '../../components/icon'
import BkSelect from '../../components/forms/Select'
import { populateDiagnosticFiles } from '../../services/diagnostic.service'
import Alerts from './Alerts'
import Details from './Details'
import ExplanationSummary from './ExplanationSummary'
import ExplanationUnivariate from './ExplanationUnivariate'
import ExplanationInteraction from './ExplanationInteraction'
import { getComponentDisplayAssociation } from './utils'

const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

export const Diagnostic = ({ diagnostic }) => {
  const [loading, setLoading] = useState(true)
  const { tLegacy } = useLegacy()
  const id = diagnostic?.id
  const query = useQuery()
  const [alerts, setAlerts] = useState([])
  const [explanation, setExplanation] = useState(null)
  const [columns, setColumns] = useState([])
  const getComponent = useMemo(() => c => ({ value: c, label: getComponentDisplayAssociation(tLegacy, c) }), [tLegacy])
  const [currentColumn, setCurrentColumn] = useState(getComponent(query.get('column')))
  const clickRowHandler = useMemo(() => c => setCurrentColumn(getComponent(c)), [getComponent])

  // Computed from state
  const currentAlerts = alerts.filter(({ column }) => column === currentColumn?.value)
  const currentDetails = columns.find(([c, d]) => c === currentColumn?.value)
  const formattedColumns = columns.map(([c, d]) => getComponent(c))

  useEffect(() => {
    // Load all JSON files
    const loadFiles = async () => {
      const files = (diagnostic?.files || []).filter(e => e.endsWith('.json'))
      const content = await populateDiagnosticFiles({ id, files })

      const allAlerts = content.map(c =>
        (c.data.alerts || []).map(a => ({ ...a, input: c.data.input }))
      ).flat()

      const allColumns = content.map(c =>
        Object.entries(c.data.columns || {})
      ).flat()

      setAlerts(allAlerts)
      setColumns(allColumns)
      setLoading(false)

      content.forEach(c => {
        if (c.data.type === 'ExplainProfiler') {
          setExplanation(c.data.ebm)
          setColumns([
            ...Object.entries(c.data.ebm.univariate_scores),
            ...Object.entries(c.data.ebm.interactions),
          ])
        }
      })
    }

    loadFiles()
  }, [JSON.stringify(diagnostic)]) // eslint-disable-line react-hooks/exhaustive-deps

  const history = useHistory()
  const { url } = useRouteMatch();

  useEffect(() => {
    currentColumn?.value && history.push(`${url}?column=${currentColumn.value}`)
  }, [currentColumn, history, url])

  if (!diagnostic) {
    return <p>No diagnostic with this ID found</p>
  }

  return (
    <Grid container direction='row' justifyContent='flex-start' alignItems='stretch' spacing={3}>
      <Grid item xs={12}>
        <div className='widget widget--medium'>
          <header className='widget__header'>
            <h1 className='widget__title'>
              <Link to='/admin/diagnostics'>
                <IconButton aria-label='view'>
                  <BkIcon path='arrow-left' className='icon' alt='Back' />
                </IconButton>
              </Link>
              {' '}
              Diagnostic {diagnostic.id}
              {' '}
              { diagnostic.package &&
                <a href={`/api/diagnostics/${diagnostic.id}/${diagnostic.package}`}>
                  <IconButton aria-label='view'>
                    <BkIcon path='save' className='icon' alt='Save' />
                  </IconButton>
                </a> }
              { !loading &&
                <Chip size='small' label={explanation ? 'Root cause analysis' : 'Profiling'}/> }
            </h1>
          </header>
        </div>
      </Grid>
      <Grid item xs={5}>
        <div className='widget'>
          { alerts.length > 0 && <Alerts alerts={alerts} onClick={clickRowHandler} /> }
          { explanation && <ExplanationSummary explanation={explanation} onClick={clickRowHandler} /> }
        </div>
      </Grid>
      <Grid item xs={7}>
        <div className='widget'>
          <div className='widget__content'>
            { loading ?
              <CircularProgress />
              : <BkSelect
                title={explanation ? 'Component' : 'Field'}
                options={formattedColumns}
                optionConfig={{label: 'label'}}
                selected={currentColumn}
                onChange={setCurrentColumn} /> }

            { !explanation && currentColumn?.value && currentDetails &&
              <Details alerts={currentAlerts} details={currentDetails[1]} /> }

            { explanation && currentColumn?.value && currentDetails?.[1].value &&
              <ExplanationUnivariate explanation={explanation} component={currentColumn.value} /> }
            { explanation && currentColumn?.value && !currentDetails?.[1].value &&
              <ExplanationInteraction explanation={explanation} component={currentColumn.value} /> }
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default Diagnostic
