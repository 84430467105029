import bkApi from './bk-api'

//--------------------------------------------------------------
const getGroups = () => {
  return bkApi.get('/v1/groups/getGroups')
}

//--------------------------------------------------------------
// const addGroup =(groupName, groupType, filterName, filterValue, quotaOperator, quotaAdmin) => {
//   console.log("------------>ADD GROUP")
//   return bkApi.post('/v1/groups/addGroup', {groupName, groupType, filterName, filterValue, quotaOperator, quotaAdmin} )
// }
const addGroup =(groupName, groupType, filterName, filterValue) => {
  return bkApi.post('/v1/groups/addGroup', {groupName, groupType, filterName, filterValue} )
}
//--------------------------------------------------------------
const removeGroup =(groupId) => {
  return bkApi.post('/v1/groups/removeGroup', {groupId} )
}
//--------------------------------------------------------------s
const getCurrentUserGroup = (userId) => {
  return bkApi.get('/v1/groups/getCurrentUserGroup')
}
//--------------------------------------------------------------

export { getGroups,
  addGroup, removeGroup,
  getCurrentUserGroup
}
