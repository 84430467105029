import bkApi from './bk-api'

const generateSupportPackage = () => {
    return bkApi.get('/v1/support/generatepackage')
}

const getSupportPackages = () => {
    return bkApi.get('/v1/support/packages')
}

const deleteSupportPackage = (packageName) => {
    console.log(`the following package will be deleted ${packageName}`)
    return bkApi.delete('/v1/support/package', {params : {name: packageName}})
}

const getLicences = () => {
    return bkApi.get('/v1/support/licences')
}

const getDocumentations = () => {
    return bkApi.get('/v1/support/documentations')
}

const getVersion = () => {
    return bkApi.get('/v1/support/version')
}

export { 
    generateSupportPackage,
    getSupportPackages,
    deleteSupportPackage,
    getLicences,
    getDocumentations,
    getVersion
}