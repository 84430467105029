import { Grid } from '@material-ui/core'
import * as _ from 'lodash'
import React, { useState } from 'react'
import Summary from '../summaries/Summary'
import './styles/widget.scss'
import Chart from '../charts/Chart'
import Ranking from '../rankings/Ranking'
import TableList from '../tableList/TableList'
import FullScreen from '../../components/FullScreen'
import { WIDGETS_TYPES } from '../../constants'
import SessionDescription from '../session-description/SessionDescription'

const Widget = ({ config }) => {
  const [fullScreen, setFullScreen] = useState(false)
  const sizes = {
    superSmall:2,
    extraSmall: 3,
    small: 4,
    medium: 6,
    large: 8,
    full: 12
  }
  const size = _.get(config, 'size')
  const xs = _.get(sizes, size) || 12
  const isInvisible = _.get(config, 'type') === 'summary'

  const getWidgetByConfig = config => {
    switch (config.type) {
      case WIDGETS_TYPES.AREA:
      case WIDGETS_TYPES.GAUGE:
      case WIDGETS_TYPES.MAPWORLD:
      case WIDGETS_TYPES.PIE:
      case WIDGETS_TYPES.POLARAREA:
      case WIDGETS_TYPES.POLARLINE:
      case WIDGETS_TYPES.BARSCATEGORIES:
      case WIDGETS_TYPES.STACKBARSTIMESTAMPS:
      case WIDGETS_TYPES.STACKBARSCATEGORIES:
      case WIDGETS_TYPES.STACKCOLUMNSCATEGORIES:
      case WIDGETS_TYPES.COLUMNSCATEGORIESHOURS:
      case WIDGETS_TYPES.COLUMNSCATEGORIES:
      case WIDGETS_TYPES.STACKCOLUMNSTIMESTAMPS:
      case WIDGETS_TYPES.STACKCOLUMNSTIMESTAMPSPERCENT:
      case WIDGETS_TYPES.SESSIONTRACKER:
      case WIDGETS_TYPES.TRENDINGLINE:
      case WIDGETS_TYPES.CDF:
        return (
          <Chart
            displayHeader
            config={config}
            isFullScreen={fullScreen}
            onFullScreen={() => setFullScreen(true)}
          />
        )
      case WIDGETS_TYPES.SUMMARY:
        return <Summary displayHeader={config.displayHeader} config={config} />
      case WIDGETS_TYPES.SESSIONDESCRIPTION:
        return <SessionDescription displayHeader={config.displayHeader} config={config} />
      case WIDGETS_TYPES.RANKING:
        return (
          <Ranking
            displayHeader
            config={config}
            onFullScreen={() => setFullScreen(true)}
            isFullScreen={fullScreen}
          />
        )
      case WIDGETS_TYPES.LIST:
        return <TableList displayHeader config={config} />
      default:
        return <div>types widget unknown {config.type}</div>
    }
  }

  if (size === "full") {
    //code Responsive if widget == Full
    return (
      <Grid item xs={xs}>   
         {config && (
           <div
             className={`widget ${
               isInvisible ? 'widget--invisible' : ''
             } ${'widget--' + size}`}
           >
             <div className='widget__content'>{getWidgetByConfig(config)}</div>
   
             {fullScreen && (
               <FullScreen onClose={() => setFullScreen(false)}>
                 {getWidgetByConfig(config)}
               </FullScreen>
             )}
           </div>
         )}
       </Grid>
     )}
     else if(size !== "full"){
      //code Responsive if widget == responsive keep extraSmall /small /medium
      if (config.type === "gauge")
      { // xs specific gauge
        return (
          <Grid item xs={12} md={6} lg={xs}>
       
             {config && (
               <div
                 className={`widget ${
                   isInvisible ? 'widget--invisible' : ''
                 } ${'widget--' + size}`}
               >
                 <div className='widget__content'>{getWidgetByConfig(config)}</div>
       
                 {fullScreen && (
                   <FullScreen onClose={() => setFullScreen(false)}>
                     {getWidgetByConfig(config)}
                   </FullScreen>
                 )}
               </div>
             )}
           </Grid>
         )
      }
      else {
        //other xs dashboard
        return (
          <Grid item xs={xs}>
             {config && (
               <div
                 className={`widget ${
                   isInvisible ? 'widget--invisible' : ''
                 } ${'widget--' + size}`}
               >
                 <div className='widget__content'>{getWidgetByConfig(config)}</div>
       
                 {fullScreen && (
                   <FullScreen onClose={() => setFullScreen(false)}>
                     {getWidgetByConfig(config)}
                   </FullScreen>
                 )}
               </div>
             )}
           </Grid>
         )
      }

     }
  
}

export default Widget
