import { CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import BkAlert from '../../components/Alert'
import BkButton from '../../components/Button'
import BkTextField from '../../components/forms/TextField'
import * as AuthService from '../../services/auth.service'
import { useTranslation } from 'react-i18next'
import { login } from './store/authSlice'
import { Link, Redirect } from 'react-router-dom'


const LoginForm = ({ error }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [credentials, setCredentials] = useState({
    username: null,
    email: null,
    password: null
  })
  const [status, setStatus] = useState({
    errors: [],
    processing: false
  })

  const [shouldRenew, setShouldRenew] = useState(false)
  const [isForgotten, setIsForgotten] = useState(false)
  const [canAccess, setCanAccess] = useState(false)

  const loginHandler = () => {
    setStatus({
      errors: [],
      processing: true
    })

    AuthService.login(credentials)
      .then(({ data }) => {

        if (data.user.status.msg === 'success')
        {
          if (data.token) {
            AuthService.saveSession(data.token)
          }
          data.user.status.msg= '' //emptying for initSession/authSlice test
          console.log('ACCOUNT LOGIN SUCCESS')
          dispatch(login(data.user))
          setStatus({ ...status, processing: false })
          setCanAccess(true)
        }
        else if (data.user.status.msg === 'account_pwd_expired')
        {
          console.log('ACCOUNT PWD EXPIRED')
          setStatus({ ...status, processing: false })
          setShouldRenew(true)
        }
        else if (data.user.status.msg === 'account_pwd_forgotten')
        {
          console.log('ACCOUNT PWD forgotten')
          setStatus({ ...status, processing: false })
          setIsForgotten(true)
        }
        else {
          console.log('FAILED TO LOGIN')
          console.log('Login error - data.user.status: ',data.user.status)
          setStatus({ processing: false, errors: 'login_failed' })
        }
      })
      .catch(e => {
        console.log('AuthService.login - catch', e)
        if (e.response) {
          setStatus({ processing: false, errors: e.response.data.errors })
        }
      })
  }

  const usernameHandler = e =>
    setCredentials({ ...credentials, username: e.target.value })
  const passwordHandler = e =>
    setCredentials({ ...credentials, password: e.target.value })

  const processing = <CircularProgress />
  const displayErrors = (
    <BkAlert severity='error' className='input'>
      {status &&
        status.errors &&
        status.errors.length > 0 &&
        status?.errors?.map((err, i) => (
          <div key={i}>{t('errors:' + err.message)}</div>
        ))}
    </BkAlert>
  )

  const redirect = canAccess ? (
    <Redirect
      to={{
        pathname: '/'
      }}
    />
  ) : null

  const renewPwd  = shouldRenew ? (
    <Redirect
      to={{
        pathname: '/renewExpiredPassword', //pathname: `/renewExpiredPassword/${credentials.username}/:token`,
        email: credentials.username,
        password: credentials.password
      }}
    />
  ) : null

  const renewForgotPwd  = isForgotten ? (
    <Redirect
      to={{
        pathname: '/renameForgotPassword/${credentials.username}/:token', //pathname: `/renameForgotPassword/${credentials.username}/:token`,
        email: credentials.username,

      }}
    />
  ) : null

  const form = (
    <form>
      {redirect}
      {shouldRenew ? renewPwd: null}
      {isForgotten ? renewForgotPwd: null}
      {status.errors && status.errors.length > 0 ? displayErrors : null}
      <BkTextField
        error={status.errors && status.errors.length > 0}
        label='Email address'
        className='input'
        id='login'
        placeholder='Your email'
        onChange={usernameHandler}
      />
      <BkTextField
        error={status.errors && status.errors.length > 0}
        label='Password'
        type='password'
        id='pwd'
        placeholder='Your password'
        onChange={passwordHandler}
      />
      <BkButton
        type='submit'
        id='loginForm'
        onClick={loginHandler}
        variant='contained'
        color='primary'
      >
        {t('actions:signin')}
      </BkButton>
      <div>
        <Link className='link link--login' to='/forgot-password'>Forgot password</Link>
      </div>
    </form>
  )

  return <>{status.processing ? processing : form}</>
}

export default LoginForm
