import React from 'react'
import './styles/errors.scss'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { WIDGETS_TYPES } from '../constants'

const Errors = ({ dataError, config, item, appliedFilters}) => {
  const { t } = useTranslation()

  // Component Errors Check if the dataError Empty or not
  // if not Empty show message (No data avialble) in chart else show nothing

  const checkErrors = (dataError) => {


    if (config && config.type && config.type === WIDGETS_TYPES.SUMMARY && item) {
      if (item && item.limit_to_one_filter_type && item.limit_to_one_filter_type === true && appliedFilters.length > 1){
        return (
          <div className="error">
            <span className="errorText" >{t('errors:error_one_value_in_dashboard')}</span>
          </div>
        )
      }
      else if (item && item.limit_to_one_filter_type && item.limit_to_one_filter_type === true && appliedFilters[0] && appliedFilters[0].values.length > 1){
        return (
          <div className="error">
            <span className="errorText" >{t('errors:error_one_value_in_dashboard')}</span>
          </div>
        )
      }
    }
    if (!_.isEmpty(dataError))
    {
      return (
        <div className="error">
          <span className="errorText">{t('errors:' + dataError)}</span>
        </div>
      )
    }
    // Condition to check if Only one filter is applicable in Dashboard
    else if (config && config.limit_to_one_filter_type && config.limit_to_one_filter_type === true && appliedFilters.length > 1){
      return (
        <div className="error">
          <span className="errorText" >{t('errors:error_one_value_in_dashboard')}</span>
        </div>
      )
    }
    else if (config && config.limit_to_one_filter_type && config.limit_to_one_filter_type === true && appliedFilters[0] && appliedFilters[0].values.length > 1){
      return (
        <div className="error">
          <span className="errorText" >{t('errors:error_one_value_in_dashboard')}</span>
        </div>
      )
    }
    else {
      return (null)
    }
  }
  return (
    checkErrors(dataError)
  )
}

export default Errors