import { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TableList from '../tableList/TableList'
import useLegacy from '../../i18n/legacyEffect'
import { selectSelectedLine } from '../tableList/store/TableSlice'
import { selectAlertSeverities, updateAlertSeverity } from './store/DiagnosticsSlice'
import IconButton from '@material-ui/core/IconButton'
import BkIcon from '../../components/icon'
import Modal from '../../components/Modal'
import BkTextField from '../../components/forms/TextField'
import BkSelect from '../../components/forms/Select'
import BkButton from '../../components/Button'
import { setAlertSeverity } from '../../services/diagnostic.service'

export const Alerts = ({ alerts, onClick }) => {
  const dispatch = useDispatch()
  const { tLegacy } = useLegacy()
  const selectedLine = useSelector(selectSelectedLine('alerts'))
  const alertSeverities = useSelector(selectAlertSeverities)
  const [ formattedAlerts, setFormattedAlerts ] = useState([])
  const [ selectedAlert, setSelectedAlert ] = useState(null)

  useEffect(() => {
    const getSeverity = a => alertSeverities.find(
      s => s.field === a.column && s.alerter === a.alerter
    )?.severity || 0

    setFormattedAlerts(alerts.map(a => ({
      ...a,
      name: tLegacy('fields', a.column),
      alert_severity: ''+getSeverity(a),
    })))
  }, [tLegacy, alertSeverities, alerts])

  useEffect(() => {
    if (selectedLine) {
      onClick(selectedLine)
    }
  }, [onClick, selectedLine])

  const config = useMemo(() => ({
    title: 'Alerts',
    type: 'list',
    compact: true,
    exportCSV: false,
    nbItemsPerPage: 20,
    items: formattedAlerts,
    rowIdKey: 'column',
    orderOn: 'alert_severity desc',
    fields: [{
      'title': 'Field',
      'dimension': 'name'
    }, {
      'title': 'Alert',
      'dimension': 'alerter'
    }, {
      'title': 'Description',
      'dimension': 'description'
    }, {
      'title': 'Severity',
      'dimension': 'alert_severity'
    }],
  }), [formattedAlerts])

  const onEditAlert = (e, alert) => {
    setSelectedAlert({
      field: alert.column,
      alerter: alert.alerter,
      description: alert.description,
      severity: alert.alert_severity,
    })
    e.stopPropagation()
  }

  const closeModal = () => setSelectedAlert(null)

  const saveAlert = () => {
    setAlertSeverity(selectedAlert)
    dispatch(updateAlertSeverity(selectedAlert))
    closeModal()
  }

  const actionsCallback = (item) => (
    <>
      <IconButton size='small' aria-label='edit severity' onClick={e => onEditAlert(e, item)}>
        <BkIcon path='edit' className='icon' title='Edit severity' />
      </IconButton>
    </>
  )

  const severityOptions = [-1, 0, 1, 2].map(String)

  return (
    <>
      <TableList id='alerts' config={config} displayHeader actionsCallback={actionsCallback}/>
      { selectedAlert &&
        <Modal title='Edit alert' onClose={closeModal}>
          <BkTextField
            label='Field'
            value={tLegacy('fields', selectedAlert.field)}
            disabled />
          <BkTextField
            label='Alert'
            value={selectedAlert.alerter}
            disabled />
          <BkTextField
            label='Description'
            value={selectedAlert.description}
            disabled />
          <BkSelect
            title='Severity'
            selected={selectedAlert.severity}
            options={severityOptions}
            legacy='alert_severity'
            onChange={(s) => setSelectedAlert({...selectedAlert, severity: s})}
          />

          <div className='modal__footer'>
            <BkButton
              type='submit'
              variant='contained'
              color='primary'
              onClick={saveAlert}>
                Save
            </BkButton>
            <BkButton onClick={closeModal} className='bkButton--alternate'>
              Cancel
            </BkButton>
          </div>
        </Modal> }
    </>
  )
}

export default Alerts
