import { createSlice } from '@reduxjs/toolkit'
import * as periodService from '../../../services/period.service'
import * as filterService from '../../../services/filter.service'
import * as _ from 'lodash'

/**
 *
 * @type {
 *    {
 *      period: {duration: string, enddate: string, startdate: string},
 *      periodZoom: {duration: string, enddate: string, startdate: string } | false,
 *      timeZone: int,
 *      default: {
 *        db: string,
 *        table: string
 *        filters: {name: string, value: {operator: string, value: any}}[],
 *      },
 *      appliedFilters: {
 *        id: string
 *        label: string
 *        name: string,
 *        value: {operator: string, value: any}
 *      }[],
 *      filtersConfig: {}
 *    }
 *  }
 */
const presetZoom0 = {
  startdate : 'false',
  enddate : 'false'
}
const presetNone = [{ id: -1, name: "none" }]

const initialState = {
  period: periodService.periodFromQueries() || periodService.today(),
  formerPeriod: periodService.periodFromQueries() || periodService.today(),
  periodZoom:
  periodService.periodZoomFromQueries() || presetZoom0,
  timeZone: 0,
  filtersConfig: {},
  appliedFilters: filterService.getFilterFromQueries() || [],
  currentPreset: presetNone[0],
  //availablePresets: presetNone,
  default: {
    filters: [],
  }
}

export const FiltersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateSelectedFilters: (state, action) => {
      return {
        ...state,
        filters: action.payload
      }
    },
    updateAppliedFilter: (state, action) => {

      const {id, name, label, values } = action.payload
      if (_.isEmpty(name) || _.isUndefined(name)) {
        return state
      }
      const appliedFilters = state.appliedFilters.filter(f => f.name !== name)
      if (!_.isEmpty(values)) {
        appliedFilters.push({ id, name, label, values })
      }
      return { ...state, appliedFilters }
    },
    updateAppliedFilterList: (state, action) => {
      return { ...state, appliedFilters: action.payload }
    },
    updatePeriod: (state, action) => {
      if (_.isEmpty(action.payload)) {
        return state
      }
      return { ...state, period: action.payload }
    },
    updateFormerPeriod: (state, action) => {
      if (_.isEmpty(action.payload)) {
        return state
      }
      return { ...state, formerPeriod: action.payload}
    },
    updatePeriodQueries: (state, action) => {
      //update queries value in address bar
      periodService.updatePeriodQueries(state.period, state.periodZoom);
    },
    resetapplyFilter: (state, action) => {
      // if (_.isEmpty(action.payload)) {
      //   return state
      // }
      return { ...state, appliedFilters : [] }
    },
    resetPresetFilter: (state, action) => {
      // if (_.isEmpty(action.payload)) {
      //   return state
      // }
      return { ...state, currentPreset : [{ id: -1, name: "none" }]}
    },
    updatePeriodZoom: (state, action) => {
      return { ...state, periodZoom: action.payload }
    },
    resetPeriodZoom: (state, action) => {
      return { ...state, periodZoom: presetZoom0}
    },
    updateTimeZone: (state, action) => {
      return { ...state, timeZone: action.payload }
    },
    setFiltersConfig: (state, action) => {
      return { ...state, filtersConfig: action.payload }
    },
    setDefaultFilter: (state, action) => {
      return { ...state, default: action.payload }
    },
    setCurrentPreset: (state, action) => {
      return { ...state, currentPreset: action.payload }
    },
    // setAvailablePresets: (state, action) => {
    //   return { ...state, availablePresets: action.payload }
    // },
  }
})

export const {
  updatePeriod,
  updateFormerPeriod,
  updatePeriodQueries,
  updatePeriodZoom,
  resetPeriodZoom,
  updateTimeZone,
  updateAppliedFilter,
  updateAppliedFilterList,
  setFiltersConfig,
  resetapplyFilter,
  resetPresetFilter,
  setDefaultFilter,
  setCurrentPreset,
  //setAvailablePresets,
  updateSelectedFilters
} = FiltersSlice.actions

export const selectFilters = state => state.filters
export const selectFiltersConfig = state => state.filters.filtersConfig
export const selectPeriod = state => state.filters.period
export const selectFormerPeriod = state => state.filters.formerPeriod
export const selectPeriodZoom = state => state.filters.periodZoom
export const selectTimeZone = state => state.filters.timeZone
export const getPeriodTimeStamp = period=> periodService.toTimestamp(period)
export const getPeriodString = period => periodService.toString(period)
export const getPeriodDate = period => periodService.toDate(period)
export const getPreviousPeriodDate = period => periodService.previousPeriodDate(period)
export const selectAppliedFilters = state => state.filters.appliedFilters
export const selectDefaultFilters = state => state.filters.default
export const selectCurrentPreset = state => state.filters.currentPreset
//export const selectAvailablePresets = state => state.availablePresets
export const getReducedFilters = appliedFilters =>
  appliedFilters.reduce(
    (filters, filter) =>
      _.concat(
        filters,
        filter.values?.map(v => ({id: filter.id, name: filter.name, label: filter.label, value: v }))
      ),
    []
  )

export const getFilterCategories = filtersConfig =>
 _.get(filtersConfig, [ 'filters_categories'])

// used to return all filters except indicators
export const getDimensionsFromFilters = filtersConfig => {
  //console.log("FiltersSlice::getDimensionsFromFilters", filtersConfig)
  const None = [{
    id: "none",
    columnName: "none",
    title: "None",
    type: "dropdown"
  }]

  if (!_.isArray(filtersConfig.filters_categories)) {
    return filtersConfig
  }

  // Hide network_subtype on CustomDashboard, (too many values receive need to parse receivedValue ex (1-12-11, 1-11-30, ...))
  return _.concat(None,filtersConfig.filters_categories
    .filter((category) => category.category_name !== 'indicators_filters')
    .reduce((filters, category) => [...filters, ...category.filters.map(filter => ({ ...filter, title: filter.columnName }))], [])).filter((dimension) => !dimension.id.includes("used_subtype_list"))
}

export const findByName = (state, name) =>
  selectAppliedFilters(state).find(f => f.name === name)

export const findById = (state, id) =>
  selectAppliedFilters(state).find(f => f.id === id)

/**
 *
 * @param state
 * @returns {{enddate: number, filters: *, startdate: number, db: *, table: *}}
 */
export const getFilterForRequest = (periodZoom, period, appliedFilters, timeZone) => {
  const timestamp = (periodZoom.enddate!=='false'&&periodZoom.startdate!=='false') ? periodService.toTimestamp(periodZoom) : getPeriodTimeStamp(period)

  return {
    //Timezone shifting at this level to prevent differences when filters is reused to present results in charts
    enddate: timestamp.enddate + timeZone, //Value in Epoch - seconds
    startdate: timestamp.startdate + timeZone, //Value in Epoch - seconds
    filters: appliedFilters,
    timezone: timeZone
  }

}



export default FiltersSlice.reducer
