import bkApi from './bk-api'

const getPreferences = () => {
  return bkApi.get('/v1/users/preferences')
}

const postPreferences = ({ email, sms, timeZone, phoneNumber }) => {
  return bkApi.post('/v1/users/preferences', {
    email, sms, timeZone, phoneNumber
  })
}
//--------------------------------------------------------------
const listUserAccounts = () => {
  console.log("------------>USERS")
  return bkApi.get('/v1/users/list')
}
//--------------------------------------------------------------

//--------------------------------------------------------------
const addUserAccount =(username, email, phone, password, group, profile, user_preferences) => {
  console.log("------------>ADD USER")
  return bkApi.post('/v1/users/add', {username, email, phone, password, group, profile, user_preferences} )
}
//--------------------------------------------------------------
const removeUserAccount =(userId) => {
  console.log("------------>REMOVE USER")
  return bkApi.post('/v1/users/remove', {userId} )
}
//--------------------------------------------------------------
const getCurrentUserInfos =(userId) => {
  console.log("------------>GET USER INFOS")
  return bkApi.post('/v1/users/getCurrentUserInfos' )
}
//--------------------------------------------------------------s
const getUserGroup = (userId) => {
  console.log("------------>GET USER GROUP")
  return bkApi.post('/v1/users/getUserGroup', {userId})
}
//--------------------------------------------------------------
const changeUserPassword = (userId, password) => {
  console.log("------------>CHANGE PASSWORD")
  return bkApi.post('/v1/users/changeUserPassword', {userId, password})
}
//--------------------------------------------------------------
const lockUserAccount =(userId) => {
  console.log("------------>LOCK USER")
  return bkApi.post('/v1/users/lock', {userId} )
}
//--------------------------------------------------------------
const logoutUserSessions =(userId) => {
  console.log("------------>LOGOUT USER", userId)
  return bkApi.post('/v1/users/logout', {userId} )
}
const unlockUserAccount =(userId) => {
  console.log("------------>UNLOCK USER")
  return bkApi.post('/v1/users/unlock', {userId} )
}
//--------------------------------------------------------------
const currentUserSession = () => {
  console.log("------------>CURRENT USER SESSION")
  return bkApi.get('/v1/users/currentUserSession')
}


export { getPreferences, postPreferences,

  listUserAccounts,
  addUserAccount, removeUserAccount,getCurrentUserInfos,
  logoutUserSessions,
  getUserGroup,
  changeUserPassword,
  lockUserAccount, unlockUserAccount,
  currentUserSession
}
