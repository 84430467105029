import { getLists } from './requestor.service'

const getList = async (params, filters) => {
  const response = await getLists({
    table: params.table,
    dimension: params.dimension,
    limit: params.limit,
    offset: params.offset || 0,
    orderOn: params.orderOn,
    orderDirection: params.orderDirection,
    startdate: filters.startdate,
    enddate: filters.enddate,
    db: params.db,
    measure: params.fields.reduce(
      (measures, field) =>
        (measures += measures.length > 0 ? ',' + field.measure : field.measure),
      ''
    )
  }, filters
  )
  return response.data
}

export { getList }
