import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BkButton from '../../components/Button'
import BkIcon from '../../components/icon'
import BkMenu from '../../components/Menu'
import BkMenuItem from '../../components/MenuItem'
import ReactTooltip from 'react-tooltip';
import Modal from '../../components/Modal'
import BkTextField from '../../components/forms/TextField'
import { selectPeriod, getPeriodString } from '../filters/store/FiltersSlice'
import { setExportingFilename, getExportingFilename } from '../charts/store/ChartsSlice'

/**
 * @function Header: Header of a Widget
 * @param {*} param0 
 * @returns 
 */
export default function Header ({
  config,
  print,
  png,
  pdf,
  jpeg,
  csv,
  // xml,
  series,
  onFullScreen,
  openOptions,
  displayConfig,
  isFullScreen,
  disableSplitButton
}) {
  const dispatch = useDispatch()
  const exportingFilename = useSelector(getExportingFilename)
  const [displayExportMenu, setDisplayExportMenu] = useState(false)
  const [toggled, setToggled] = useState(true)
  const [displayRankingCSVModal, setDisplayRankingCSVModal] = useState(false)
  const period = useSelector(selectPeriod)
  const periodStr = getPeriodString(period)

  useEffect(() => {
      dispatch(setExportingFilename("bka-chart-" + periodStr.replace(/\s/g, '')))
  }, []);

  // const [selectSeries, setSelectSeries] = useState(series)
  // const changeTitleHandler = event => {
  //   dispatch(changeTitle(event.target.value))
  // }

  const openMenuHandler = event => {
    setDisplayExportMenu(true)
  }

  const handleClose = () => {
    setDisplayExportMenu(false)
  }

  // const handleChangeSerie = (e) => {
  //   setSelectSeries()
  //   console.log("---------------------------->SERIE_CHANGE", series)

    //TODO change value of serie
 // }

  // const share = () => {
  //   console.log('SHARE', config)
  // }

  const HeaderinSessionTracker = () => {
    //Hide split and download CSV in SessionTracker
    return (
      <ul className='widget__actions'>
      {!isFullScreen && (
        <li className='widget__action'>
          <BkButton className='bkButton--invisible' onClick={onFullScreen}>
            <BkIcon path='size' modifier='unactive' />
          </BkButton>
        </li>
      )}
    </ul>
    )
  }

  const header =  (
    <header className='widget__header' >
      {/* ADD tooltip for chart title and customDashboard title */}
    <h2 className='widget__title' data-tip={config.tooltip || config.title} data-border >{config.title?.toUpperCase() || ''}</h2>
    <ReactTooltip />
    <nav className='widget__actionsContainer'>
     {config && config.type === "sessionTracker" ? (
       HeaderinSessionTracker()
       ) : (
        <ul className='widget__actions'>
        {!isFullScreen && !disableSplitButton && (
          <li className='widget__action'>
              <BkButton className='bkButton--invisible' onClick={openOptions} disabled={disableSplitButton}>
              {displayConfig && <BkIcon path='config' />}
              {!displayConfig && <BkIcon path='config' modifier='unactive' />}
            </BkButton>
          </li>
        )}
        {!isFullScreen && (
          <li className='widget__action'>
            <BkButton className='bkButton--invisible' onClick={onFullScreen}>
              <BkIcon path='size' modifier='unactive' />
            </BkButton>
          </li>
        )}
        <li className='widget__action'>
          <BkButton
            className='bkButton--invisible'
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={openMenuHandler}
          >
            {displayExportMenu && <BkIcon path='burger' alt='Menu' />}
            {!displayExportMenu && (
              <BkIcon path='burger' alt='Menu' modifier='unactive' />
            )}
          </BkButton>
        </li>
      </ul>
     )}

      <BkMenu
        className='widget__menu'
        open={displayExportMenu}
        onCollapse={handleClose}
      >
      <BkMenuItem
        onClick={() => {
          handleClose()
          print()
        }}
        icon='print'
      >
        Print
      </BkMenuItem >
      {/* // Part of Export PNG/JPEG/CSV/PDF in Header in BurgerMenu */}
      <BkMenuItem icon='download' isLink={false} onClick={() => setToggled(!toggled)}>
        Download
        <BkIcon
          path='arrow'
          modifier='right-active'
          className='bkMenu__subIcon'
        />
        </BkMenuItem>
        <BkMenu
        open={!toggled}
        className='userMenu__header'
        onCollapse={() => setToggled(false)}
        >
        <BkMenuItem
            onClick={() => {
            handleClose()
            png()
            }}
            >
        <span>PNG image</span>
        </BkMenuItem>
        <BkMenuItem
            onClick={() => {
            handleClose()
            jpeg()
            }}
            >
        <span>JPEG image</span>
        </BkMenuItem>
        <BkMenuItem
            onClick={() => {
            handleClose()
            pdf()
            }}
            >
          <span>PDF document</span>
        </BkMenuItem>
        <BkMenuItem
            onClick={() => setDisplayRankingCSVModal(true)}
            >
            <span>CSV document</span>
        </BkMenuItem>
        {/* <BkMenuItem
            onClick={() => setDisplayRankingCSVModal(true)}
            >
            <span>XML document</span>
        </BkMenuItem> */}
        </BkMenu>
      </BkMenu>
    </nav>
  </header>
  )

  return (
    <>
        <>
        {/* //Modal Export when download CSV for all widgets except rankings */}
          {header}
          {displayRankingCSVModal &&
            <Modal title='Download csv document' onClose={() => setDisplayRankingCSVModal(false)}>
              {/* // TODO add select to choose series when series > 1 */}

               {/* <div className="label">
                <label >Select the exported chart: </label>
              </div>
              <BkSelect 
                title='Chart: ' 
                selected={series[0]}
                options={series}
                optionConfig={{ label: 'label' }} 
                onChange={e => handleChangeSerie(e)}
              /> */}
              <div className="label">
                <label >Filename (eg. linegraph-02162012.) : </label>
              </div>       
              <BkTextField value={exportingFilename} onChange={e => dispatch(setExportingFilename(e.target.value))}/>
                <div className='modal__footer'>
                <BkButton
                  type='submit'
                  variant='contained'
                  color='primary'
                  onClick={() => {csv() ; setDisplayRankingCSVModal(false)}}
                  >Create
                </BkButton>
                <BkButton
                  onClick={() => setDisplayRankingCSVModal(false)}
                  className='bkButton--alternate'
                >
                Cancel
                </BkButton>
              </div>
            </Modal>}
        </>
    </>
  )
}
