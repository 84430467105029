import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import './styles/widget-manager.scss'
import WidgetConfigModal from './WidgetConfigModal'

const NewWidget = ({ onCreate, menuId }) => {
  const [displayModal, setDisplayModal] = useState(false)

  return (
    <Grid item>
      <button className='newWidget' onClick={() => setDisplayModal(true)}>
        Add widget
      </button>
      {displayModal && (
        <WidgetConfigModal onClose={() => setDisplayModal(false)} menuId={menuId} />
      )}
    </Grid>
  )
}
export default NewWidget
