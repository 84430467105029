import { CircularProgress } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import BkAlert from '../../components/Alert'
import BkButton from '../../components/Button'
import BkTextField from '../../components/forms/TextField'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { resetPassword } from '../../services/auth.service'
import { selectCurrentDashboard } from '../dashboards/store/dashboardSlice'

const ForgotPasswordForm = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [statusForgotPwd, setStatusForgotPwd] = useState(false)
  const initialStatus = {
    messages: [],
    severity: 'error',
    processing: false
  }
  const [status, setStatus] = useState(initialStatus)
  const [email, setEmail] = useState('')
  const currentDashboard = useSelector(selectCurrentDashboard)

  function isValidEmailAddress(address) {
    return !! address.match(/.+@.+/);
  }

  // compare with currenDashboard to know if we are in forgot in LoginPage or ChangePwd on Session active
  // ForgotPwd on Login Page doesn't show errors on GUI (security)
  const submitHandler = () => {
    const message = []
    if (!isValidEmailAddress(email)) {
      if (currentDashboard !== null) {
        message.push("invalid_email")
        setStatus({
          ...status,
          messages: message
        })
      } else {
        setStatusForgotPwd(true)
      } 
    } else {
      setStatusForgotPwd(true)
      setStatus({ messages: [], processing: true })
      resetPassword(email).then(res => {
        setStatus({ messages: [], processing: false })
      }).catch(e => {
        if (currentDashboard !== null)
         {
          e.response.data.errors.map((msg) => (
            message.push(msg.message)
          ))  
          setStatus({
            ...status,
            processing: false,
            messages: message
          })
         } else {
          setStatusForgotPwd(true)
          setStatus({
            ...status,
            processing: false,
            messages: message
          })
         }
      })
    }
  }

  const cancelHandler = () => {
    setStatusForgotPwd(false)
    history.push('/login')
  }

  const processing = <CircularProgress />
 
  const form = (
    <form>
      {statusForgotPwd === false ? (
        <Fragment>
          <BkTextField
        error={status.messages.length > 0}
        label='Email address'
        className='input'
        placeholder='Your email'
        onChange={(e) => { setEmail(e.target.value) }}
      />
      <BkButton
        type='submit'
        onClick={submitHandler}
      >
        CONFIRM
      </BkButton>
        </Fragment>
      ) : (
        <div>
        <BkAlert severity='info' className='input'>
          <div>{t('miscellaneous:infoForgottenPwd') + email}</div>
        </BkAlert>
         </div>
      )}
      
      <BkButton
        type='submit'
        onClick={cancelHandler}
        className='bkButton--alternate'
      >
        CANCEL
      </BkButton>
    </form>
  )

  return <>{status.processing ? processing : form}</>
}

export { ForgotPasswordForm }
