import bkApi from './bk-api'

/**
 * transform filters to http params
 * ignore undefined value
 * @param filters
 * @returns {string}
 */
const getParamsByFilters = (filters) => {
  const params = {}
  if (!(Array.isArray(filters) && filters.length > 1)) {
    return params
  }
  filters
    .filter(filter => (filter.name && filter.value !== undefined))
    .forEach(filter => {
      params[filter.name] = filter.value
    })
  return params
}

const requestor = (action, params, filters) => {
  const filtres = getParamsByFilters(filters)
  return bkApi.get('/v1/services/requestor', {
    params: {
      ...filtres,
      action,
      ...params

    }
  })
}

const getLists = (params, filters) => {
  return bkApi.get('/v1/services/list', { params: { params, filters } })
}

const getFilterList = (params) => {
  return bkApi.get('/v1/services/filterList', { params: { action: 'getFilterList', ...params } })
}

const getFilterCounts = (filters) => {
  return bkApi.get('/v1/services/filterCounts', { params: { filters } })
}

const getCounts = (params, filters) => {
  return bkApi.get('/v1/services/counts', { params: { params, filters } })
}

const getMetrics = (params) => {
  return bkApi.get('/v1/services/metrics', { params: { action: 'getMetrics', ...params } })
}

export { requestor, getFilterList, getFilterCounts, getCounts, getMetrics, getLists }
